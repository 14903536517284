let festival_col = [{
        "id": 1,
        "disp_name": "数九",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "中文名",
                        "value": "数九"
                    },
                    {
                        "title": "别名",
                        "value": "冬九九、数九寒天"
                    },
                    {
                        "title": "节日时间",
                        "value": "每年阳历12月下旬出，农历冬至开始"
                    },
                    {
                        "title": "节日类型",
                        "value": "中国民间计算寒暖日期方法"
                    }
                ],
                "detail": [
                    "数九，又称冬九九，是中国民间一种计算寒天与春暖花开日期的方法。数九是从二十四节气“冬至”逢壬日开始算起，每九天算一“九”，依此类推。一般“三九”时最冷，是一年中最冷的时段。当数到九个“九天”（九九八十一天），便春深日暖、万物生机盎然，是春耕的时候了。"
                ]
            },
            {
                "title": "养生",
                "detail": [
                    "数九时期养生建议：尽量早睡晚起、适度轻柔运动、饮食注意养阴、运用中医防病。具体如下：",
                    "一、冬季睡眠。《黄帝内经》认为冬季睡眠应该“早卧晚起，必待日光”，就是早睡晚起，起床应在日出以后。早卧晚起可以减少人体阳气的耗损，建议晚上9~11点入睡，早上7~8点起床，并适当午睡半个小时左右。",
                    "二、冬季运动。冬季不适合大量剧烈运动，中医认为剧烈运动会耗损人体阳气，导致大量出汗，损耗津液。所以冬季适合做一些轻柔的运动，出汗少又能活动筋骨，像太极拳、八段锦、散步等。",
                    "三，冬季饮食。遵循“秋冬养阴”的原则。冬季寒气盛，人体毛孔会常闭合，减少热量耗散。这样就把阳气封闭在内，阳气积郁久了就容易化热，从而耗伤人体阴津，所以要养阴。民间有“冬吃萝卜夏吃姜”的养生法则，其中萝卜就有养阴生津的功效。其它一些食物也可以用来养阴，比如梨、乌梅、麦冬、银耳、鸭肉、山药、芡实、地黄等。在中医师的诊断下，还可以用因人而异的进补方法来养阴，比如膏方、药膳、茶饮、汤药等。",
                    "四、冬季防病。“三九”在冬至以后，根据“冬至一阳生”的自然规律，中医常在“三九”这个特殊的时间段，使用“三九敷贴”(在特定穴位敷贴配制的中药)、“三九艾灸”(在特定穴位进行艾灸)的方法达到刺激穴位、激发经气、温阳散寒、扶正却邪的防病目的。"
                ]
            },
            {
                "title": "饮食",
                "detail": [
                    "冬至之后进入数九，可以多吃温性食物，少食甚至远离寒凉食物，对于平衡人体阴阳、增强抵抗力是非常重要的。在饮食调养方面，营养专家建议，公众尤其要注意“三多三少”，即多吃蛋白质、维生素、纤维素含量高的食物，少吃糖类、脂肪、盐分含量高的食物。",
                    "数九时节的饮食还宜多样化，谷、果、肉、蔬菜要合理搭配，适当地选用高钙食品。宜食一些清淡的食物，不宜吃浓浊、肥腻和过咸的食物，还不可过食太过辛辣刺激的食品。冬天阳气日衰，脾喜温恶冷，因此最好吃一些温热食品来保护脾肾，吃饭也最好是少量多餐。",
                    "数九之后，天气寒冷，羊肉、牛肉、狗肉有燥热温补的效果，能预防“三九寒冷”，但量不宜太多，过食羊肉等肥甘厚味，易导致饮食不化，聚湿生痰。冬至时还可以多吃些诸如花生、核桃、栗子、榛子和杏仁之类的坚果，因为坚果性味偏温热，在其它季节吃容易上火，而冬天天气较冷，吃坚果有御寒的作用，可增强体质、预防疾病。当然吃坚果也要适量，且因人而异。",
                    "在补充热性食物的同时，也得吃一些补充津液的食物，如荸荠、藕、梨、萝卜、白菜等。其它很多蔬菜也要多加摄入，比如土豆、番茄、菌类、韭菜、南瓜、芹菜、菠菜、山药等。",
                    "除此之外，还要适量多吃水果，冬天寒冷干燥，使人觉得鼻、咽部干燥和皮肤缺水、容易上火，因此每天能吃点水果不仅能滋阴养肺、润喉去燥，还能摄取充足的营养物质，会使人顿觉清爽舒适。猕猴桃、甘蔗、柚子、大枣、桔子、龙眼、苹果、桂圆等都是不错的选择。"
                ]
            },
            {
                "title": "习俗简介",
                "detail": [
                    "民谚云：“夏至三庚入伏，冬至逢壬数九。”数九方法在我国民间口口相传，有历史，乏记载，至于起源何时，没有确切的资料。不过，至少在南北朝时已经流行，梁代宗懔《荆楚岁时记》中就写道：“俗用冬至日数及九九八十一日，为寒尽。”在中国传统哲学中的阴阳的消长，阳长阴消就象征暖来寒去。在传统文化中，九为极数，乃最大、最多、最长久的概念。九个九即八十一更是“最大不过”之数。九，为“至阳”之数，也称老阳，九又是至大之数，“至阳之数”的积累意味着阴气的日益消减，累至九次已到了头，意味着寒去暖来，“春已深矣”了。"
                ]
            },
            {
                "title": "民间歌谣",
                "detail": [
                    "“数九”是古人世世代代在生产、生活实践基础上，利用自然界的一些生态反映和天气征兆的经验总结，具有相当大的科学性，至今仍对人民群众的生产、生活的安排起着指导作用，所以一直盛行不衰，广泛流传在人们的口头上，体现在人们的行为中。随着历史发展，各地的人们也根据各地不同的气候条件、景象特征、农事物候及风俗习惯，编排出了各种数九九的谚语、歌谣和顺口溜。中国民间广为流传的“九九消寒歌”，俗称“九九歌”。“数九歌”在南北各地都有，不过歌词版本略有不同。",
                    "数九歌谣：一九二九不出手，三九四九冰上走，五九六九沿河看柳，七九河开，八九燕来，九九加一九，耕牛遍地走。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "三伏",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "中文名",
                        "value": "三伏"
                    },
                    {
                        "title": "节日时间",
                        "value": "阳历7月中旬到8月中旬"
                    },
                    {
                        "title": "节日概念",
                        "value": "初伏、中伏和末伏的统称"
                    },
                    {
                        "title": "气候特点",
                        "value": "气温高、气压低、湿度大、风速小"
                    }
                ],
                "detail": [
                    "三伏，是初伏、中伏和末伏的统称，是一年中最热的时节。每年三伏天出现在公历7月中旬到8月中旬，其气候特点是气温高、气压低、湿度大、风速小。“伏”表示阴气受阳气所迫藏伏地下。三伏有初伏、中伏和末伏之分，它的日期是由干支历的节气日期和干支纪日的日期相配合来决定的。",
                    "我国传统的推算方法规定，夏至以后的第3个庚日、第4个庚日分别为初伏（头伏）和中伏的开始日期，立秋以后的第一个庚日为末伏的第一天。因为每个庚日之间相隔10天，所以初伏、末伏规定的时间是10天。又因为每年夏至节气后的第3个庚日（初伏）出现的迟早不同，中伏的天数就有长有短，可能是10天，也可能是20天。"
                ]
            },
            {
                "title": "三伏定义",
                "detail": [
                    "“三伏”（Dog Day），是初伏、中伏和末伏的统称，“伏”表示阴气受阳气所迫藏伏地下。“伏”可称“长夏”，“伏”是五行家对季节的另类安排，其中“伏”为“土”。“伏”表示阴气受阳气所迫藏伏在地下的意思。春、夏、秋、冬四季被五行家冠以木、火、土、金、水，一年五季春、夏、长夏、秋、冬，由秋到冬金生水、水生木、木生火，自然循环。但是由夏到秋则不然，火克金，金不敢出来。就要潜伏一段时间，“潜伏”就是“伏”的本义。",
                    "秋天属“庚辛金”所以“入伏”要从“庚日”开始。温度最高，需要潜伏的时间要在夏至后二十天以后，所以从夏至后第三个“庚日”开始，到立秋后第二个庚日结束。"
                ]
            },
            {
                "title": "三伏养生",
                "detail": [
                    "进入7月后，就到了所谓的“三伏天”。三伏天是中国农历小暑与处暑之间的一段时间，它是一年中气温最高而且潮湿、闷热的日子。气温节节攀高，各类健康问题也接踵而来，暑热暑湿就成了我们防范的重点。"
                ]
            },
            {
                "title": "饮食文化",
                "detail": [
                    "俗言道：“寒有三九，热有三伏。”每逢三伏，古人一方面以清凉饮料避暑，另一方面又举行相应的风俗活动，如“饮酒避恶”等。久而久之，形成了一种独特的三伏食俗文化。",
                    "我国幅员辽阔，不同朝代、各个地区风俗不尽相同。诸如北宋夏日节食是“伏日绿荷包子”，“绿荷”即荷叶，用荷叶裹包子有清暑的作用。浙江台州三伏日，老年人有食鸡粥的风俗，名为“补阳”。概而言之，我国三伏食俗的由来与汉代作“伏腊节”有关，最初是一种庆农活动。这也从一个侧面体现出了我国食俗文化的丰富多彩。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "春节",
        "short_desc": "辞旧迎新，阖家团圆万事兴",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "别称",
                        "value": "新春"
                    },
                    {
                        "title": "节日时间",
                        "value": "农历正月初一"
                    },
                    {
                        "title": "节日意义",
                        "value": "农历新年的开始"
                    },
                    {
                        "title": "节日饮食",
                        "value": "腊八粥、年糕、饺子、春卷、元宵（汤圆）"
                    }
                ],
                "detail": [
                    "中国人过春节已有4000多年的历史。在现代，人们把春节定于农历正月初一，但一般至少要到正月十五（上元节）新年才算结束，在民间，传统意义上的春节是指从腊月的腊祭或腊月二十三或二十四的祭灶，一直到正月十九。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "关于春节的起源有说法诸多，其中有几种较具代表性的说法，春节源于腊祭、源于巫术仪式说、源于鬼节说等，其中最被普遍接受的说法是春节由虞舜时期兴起。",
                    "公元前2000多年的一天，舜即天子位，带领着部下人员，祭拜天地。从此，人们就把这一天当作岁首。据说这就是农历新年的由来，后来叫春节。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "传统民俗",
                    "扫尘：其用意是要把一切穷运、晦气统统扫出门。",
                    "办年货：中国的家庭过年前要购买大量的“年货”，春联，福字，新衣服，过年期间的食品（过年市场多不开门）。办年货是中国人过春节的一项重要活动。与过去相比，中国人办年货的方式变得更加现代，不拘泥于传统。",
                    "守岁：除夕守岁是最重要的年俗活动之一，守岁之俗由来已久。最早记载见于西晋周处的《风土志》：除夕之夜，各相与赠送，称为“馈岁”；酒食相邀，称为“别岁”；长幼聚饮，祝颂完备，称为“分岁”；大家终夜不眠，以待天明，称曰“守岁”。拜年、贴春联、贴年画、燃爆竹等",
                    "",
                    "汉族、满族和朝鲜族过春节的风俗习惯差不多，全家团圆，人们吃年糕、水饺以及各种丰盛的饭菜、张灯结彩，燃放鞭炮，并互相祝福。在有些地区人们沿袭过去祭祖敬神活动，祈求新的一年风调雨顺，平安、丰收。古代的蒙古族，把春节叫做“白节”，正月叫白月，是吉祥如意的意思。藏族是过藏历年。回族、维吾尔族、哈萨克族等，是过“古尔邦节”。春节也是苗族、僮族、瑶族等的盛大节日。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "北方小年",
        "short_desc": "小年到，团圆迎，吃水饺，年年好",
        "area": 1,
        "type": 1,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "别称",
                        "value": "祭灶节、灶王节"
                    },
                    {
                        "title": "节日时间",
                        "value": "农历腊月二十三"
                    },
                    {
                        "title": "节日类型",
                        "value": "传统节日"
                    }
                ],
                "detail": [
                    "小年并非专指一个节日，由于各地风俗，被称为小年的节日也不尽相同。小年期间主要的民俗活动有贴春联，扫尘，祭灶等。在汉族民间，小年有“官三民四船五”的传统，也就是说，官家的小年是腊月二十三，百姓家的是腊月二十四，而水上人家则是腊月二十五。",
                    "",
                    "北方在南宋以前都是政治中心，受官气影响较重，因此小年多为腊月二十三；相反，南方远离政治中心，小年便为腊月二十四；而沿湖、海的居民，则保留了船家的传统，小年定在腊月二十五。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "农历十二月二十三和二十四，是中国民间传统的祭灶日，又称“小年”。传说灶王爷原为平民张生，娶妻之后终日花天酒地，败尽家业沦落到上街行乞。一天，他乞讨到了前妻郭丁香家，羞愧难当，一头钻到灶锅底下烧死了。",
                    "",
                    "玉帝知道后，认为张生能回心转意，还没坏到底，既然死在了锅底，就把他封为灶王，每年腊月二十三、二十四上天汇报，大年三十再回到灶底。老百姓觉得灶王一定要敬重，因为他要上天汇报。于是，民间就有了腊月二十三、二十四的祭灶“小年”，祈求来年平安和财运。",
                    "",
                    "宋代的范成大在他的《祭灶诗》中说：“古传腊月二十四，灶君朝天欲言事。云车风马小留连，家有杯盘丰典祀。猪头烂热双鱼鲜，豆沙甘松粉饵团。男儿酌献女儿避，酹酒烧钱灶君喜。婢子斗争君莫闻，猫犬角秽君莫嗔；送君醉饱登天门，杓长杓短勿复云，乞取利市归来分。”可以说，这首诗非常形象地说明了古代中国人民有关祭灶的风俗习惯。当然，这只是民间的传说而已，是不足为信的。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "1.祭灶王：小年这天，也是民间祭灶的日子",
                    "2.扫尘土：过了二十三，离春节只剩下六、七天了，过年的准备工作显得更加热烈了。要彻底打扫室内，俗称扫尘，扫尘为的是除旧迎新，拔除不祥。",
                    "3.剪窗花：所有准备工作中，剪贴窗花是最盛行的民俗活动。",
                    "4.贴春联：家家户户都要写春联。民间讲究有神必贴，每门必贴，每物必贴，所以春联数量最多，内容最全。",
                    "5.洗浴：大人、小孩都要洗浴、理发。",
                    "6.婚嫁：过了二十三，民间认为诸神上了天，百无禁忌。娶媳妇、聘闺女不用择日子，称为赶乱婚。",
                    "",
                    "祭灶节，民间讲究吃饺子，取“送行饺子迎风面”之意。 在北京，给灶王爷、灶王奶奶奉上的供品，只须一些“糖瓜“(用麦芽糖制做的像鸡蛋大小的瓜状糖块)就可以了。稍考究一点的人家，再供上一碗用糯米蒸熟的莲子八宝饭，还要供以香糟炒豆和清水。 ",
                    "而在山东半岛的人们又大多自农历腊月二十三后，家家忙做大枣饽饽(又称大馒头)、团圆饼、寿桃等面花，表示全家大团圆，日子过得年年有余。"
                ]
            },
            {
                "title": "地方禁忌",
                "detail": [
                    "小年各地的禁忌各有不同。",
                    "相传湖北部分地区，小年忌宰杀。",
                    "河南有些地方忌讳捣蒜，认为小年捣蒜会把家里捣穷了。",
                    "台湾则忌舂米，据说会有把风神捣下来之虞，恐怕给来年带来风灾。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "除夕",
        "short_desc": "鞭炮声声响，阖家共团圆，除夕人不睡，一起迎新年",
        "area": 0,
        "type": 1,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "别称",
                        "value": "大年夜、除夜、岁除"
                    },
                    {
                        "title": "节日时间",
                        "value": "农历十二月二十九或三十日"
                    },
                    {
                        "title": "起源时间",
                        "value": "先秦时期"
                    },
                    {
                        "title": "流行地区",
                        "value": "中国以及汉字文化圈诸国"
                    }
                ],
                "detail": [
                    "除，即去除的之意；夕，指夜晚。除夕也就是辞旧迎新、一元复始、万象更新的节日。与清明节、中元节、重阳节三节是中国传统的祭祖大节，也是流行于汉字文化圈诸国的传统文化节日。",
                    "除夕因常在夏历腊月二十九或三十日，故又称该日为大年三十，是汉族最重要的传统节日之一。汉族民间最为重视。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "据《吕氏春秋·季冬记》记载，古人在新年的前一天用击鼓的方法来驱逐“疫疬之鬼”，这就是“除夕”节令的由来。据称，最早提及“除夕”这一名称的，是西晋周处撰著的《风土记》等史籍。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "年夜饭",
                    "除夕这一天对华人来说是极为重要的。这一天人们准备除旧迎新，吃团圆饭。家庭是华人社会的基石，一年一度的团年饭充分表现出中华族家庭成员的互敬互爱，这种互敬互爱使一家人之间的关系更为紧密。",
                    "",
                    "贴春联",
                    "春联也叫门对、春贴、对联、对子、桃符等等，它以工整、对偶、简洁、精巧的文字描绘时代背景，抒发美好愿望，是我国特有的文学形式。每逢春节，无论城市还是农村，家家户户都要精选一副大红春联贴于门上，为节日增加喜庆气氛。这一习俗起于宋代，在明代开始盛行，到了清代，春联的思想性和艺术性都有了很大的提高。",
                    "",
                    "燃爆竹",
                    "中国汉族民间有“开门爆竹”一说。即在新的一年到来之际，家家户户开门的第一件事就是燃放爆竹，以哔哔叭叭的爆竹声除旧迎新。爆竹是中国特产，亦称“爆仗”、“炮仗”、“鞭炮”。其起源很早，至今已有两千多年的历史。放爆竹可以创造出喜庆热闹的气氛，是节日的一种娱乐活动，可以给人们带来欢愉和吉利。",
                    "",
                    "祭祖",
                    "古时，这种礼俗很盛。因各地礼俗的不同，祭祖形式也各异，有的到野外瞻拜祖墓，有的到宗祠拜祖，而大多在家中将祖先牌位依次摆在正厅，陈列供品，然后祭拜者按长幼的顺序上香跪拜。",
                    "我国部分地区有一种除夕上坟的风俗，称为送年食。上坟时间一般在除夕的下午，人们把做好的年夜饭，送到亡故亲人的坟上，让亡故的亲人与生者一起享用除夕夜的美食，从而寄托人们对亡故亲人的哀思。",
                    "",
                    "压岁钱",
                    "过年给压岁钱，体现出长辈对晚辈的关爱，和晚辈对长辈的尊敬，是一项整合家庭伦理关系的民俗活动。"
                ]
            },
            {
                "title": "节日禁忌",
                "detail": [
                    "除夕有很多的禁忌。如忌言鬼、死、杀等不吉字眼、忌打碎碗碟，忌恶声谩语，忌随地便溺，忌泼污水、灯油于地等。人们在大年三十到来时，一面欢度佳节，喜庆丰收，一面洗澡更衣、打扫卫生，以驱疫病、除恶鬼。尤其是在年夜饭时，有更多的禁忌。",
                    "",
                    "1、长辈给晚辈添饭加菜,如果吃饱了不想要,不能说“不要”，而是说“有了”；如果个别茶果吃完了，要说“吃兴了”或“太多了”，而不是直接说“没有了”⋯⋯。这些吉利话是人们的“除夕功课”，大人要教孩子说，否则新年不顺。",
                    "2、年夜饭就餐前，必须要先要准备供品祭祀祖先。",
                    "3、吃完年夜饭后，有除夕守岁的风俗。全家欢聚一堂，围炉而坐，叙旧话新，畅谈美好的祝愿，寄希望于新的一年，因而通宵不眠。",
                    "4 、除夕是去旧迎新的时候，尤其是惧怕疫病与恶鬼。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "腊八节",
        "short_desc": "过了腊八就是年，腊八粥解天寒",
        "area": 0,
        "type": 1,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "别称",
                        "value": "腊日祭、腊八祭、王侯腊"
                    },
                    {
                        "title": "节日时间",
                        "value": "新春"
                    },
                    {
                        "title": "节日饮食",
                        "value": "腊八粥、腊八蒜、腊八面"
                    },
                    {
                        "title": "节日起源",
                        "value": "民间传统、宗教祭祀"
                    }
                ],
                "detail": [
                    "相传这一天还是佛祖释迦牟尼成道之日，称为“法宝节”，是佛教盛大的节日之一。",
                    "岁终之月称“腊”的含义有三：一曰“腊者，接也”，寓有新旧交替的意思（《隋书·礼仪志》记载）；二曰“腊者同猎”，指田猎获取禽兽好祭祖祭神，“腊”从“肉”旁，就是用肉“冬祭”；三曰“腊者，逐疫迎春”，腊八节又谓之“佛成道节”，亦名“成道会”，实际上可以说是十二月初八为腊日之由来。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "自先上古起，腊八是用来祭祀祖先和神灵（包括门神、户神、宅神、灶神、井神）的祭祀仪式，祈求丰收和吉祥。据《礼记·郊特牲》记载，腊祭是“岁十二月，合聚万物而索飨之也。”夏代称腊日为“嘉平”，商代为“清祀”，周代为“大蜡”；因在十二月举行，故称该月为腊月，称腊祭这一天为腊日。先秦的腊日在冬至后的第三个戌日，后来佛教传入，为了扩大在本土的影响力遂附会传统文化把腊八节定为佛成道日。后随佛教盛行，佛祖成道日与腊日融合，在佛教领域被称为“法宝节”。南北朝开始才固定在腊月初八。",
                    "《说文》载：“冬至后三戌日腊祭百神。”可见，冬至后第三个戌日曾是腊日。后由于佛教介入，腊日改在十二月初八，自此相沿成俗。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "1.祭祀",
                    "相传释伽牟尼成佛之前，绝欲苦行，饿昏倒地。一牧羊女以杂粮掺以野果，用清泉煮粥将其救醒。释伽牟尼在菩提树下苦思，终在十二月八日得道成佛。从此佛门定此日为“佛成道日”，诵经纪念，相沿成节。到了明清，敬神供佛更是取代祭祀祖灵、欢庆丰收和驱疫禳灾，而成为腊八节的主旋律。其节俗主要是熬煮、赠送、品尝腊八粥，并举行庆丰家实。同时许多人家自此拉开春节的序慕，忙于杀年猪、打豆腐、胶制风鱼腊肉，采购年货，“年”的气氛逐渐浓厚。",
                    "",
                    "2.饮食",
                    "腊八粥、腊八醋、腊八蒜、腊八面、腊八豆腐等"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "南方小年",
        "short_desc": "小年到，福气迎，日子蒸蒸日上，生活节节高升",
        "area": 1,
        "type": 1,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "别称",
                        "value": "祭灶节、灶王节"
                    },
                    {
                        "title": "节日时间",
                        "value": "农历腊月二十四"
                    },
                    {
                        "title": "节日类型",
                        "value": "传统节日"
                    }
                ],
                "detail": [
                    "小年并非专指一个节日，由于各地风俗，被称为小年的节日也不尽相同。小年期间主要的民俗活动有贴春联，扫尘，祭灶等。在汉族民间，小年有“官三民四船五”的传统，也就是说，官家的小年是腊月二十三，百姓家的是腊月二十四，而水上人家则是腊月二十五。",
                    "北方在南宋以前都是政治中心，受官气影响较重，因此小年多为腊月二十三；相反，南方远离政治中心，小年便为腊月二十四；而沿湖、海的居民，则保留了船家的传统，小年定在腊月二十五。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "农历十二月二十三和二十四，是中国民间传统的祭灶日，又称“小年”。传说灶王爷原为平民张生，娶妻之后终日花天酒地，败尽家业沦落到上街行乞。一天，他乞讨到了前妻郭丁香家，羞愧难当，一头钻到灶锅底下烧死了。",
                    "玉帝知道后，认为张生能回心转意，还没坏到底，既然死在了锅底，就把他封为灶王，每年腊月二十三、二十四上天汇报，大年三十再回到灶底。老百姓觉得灶王一定要敬重，因为他要上天汇报。于是，民间就有了腊月二十三、二十四的祭灶“小年”，祈求来年平安和财运。",
                    "宋代的范成大在他的《祭灶诗》中说：“古传腊月二十四，灶君朝天欲言事。云车风马小留连，家有杯盘丰典祀。猪头烂热双鱼鲜，豆沙甘松粉饵团。男儿酌献女儿避，酹酒烧钱灶君喜。婢子斗争君莫闻，猫犬角秽君莫嗔；送君醉饱登天门，杓长杓短勿复云，乞取利市归来分。”可以说，这首诗非常形象地说明了古代中国人民有关祭灶的风俗习惯。当然，这只是民间的传说而已，是不足为信的。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "1.祭灶王：小年这天，也是民间祭灶的日子",
                    "2.扫尘土：过了二十三，离春节只剩下六、七天了，过年的准备工作显得更加热烈了。要彻底打扫室内，俗称扫尘，扫尘为的是除旧迎新，拔除不祥。",
                    "3.剪窗花：所有准备工作中，剪贴窗花是最盛行的民俗活动。",
                    "4.贴春联：家家户户都要写春联。民间讲究有神必贴，每门必贴，每物必贴，所以春联数量最多，内容最全。",
                    "5.洗浴：大人、小孩都要洗浴、理发。",
                    "6.婚嫁：过了二十三，民间认为诸神上了天，百无禁忌。娶媳妇、聘闺女不用择日子，称为赶乱婚。",
                    "过小年的饮食风俗习惯，南方人民则表现出不同的祭灶方式。",
                    "例如宁波人在十二月廿三夜“送灶君”，即“小年夜”。送灶君典礼要用祭灶果、糯米团供神，另用稻草扎马(勤南一带用纸折成)，饲以炒黄豆、清水。祭完今后要烧灶君像及马，送其上天庭报告。为使灶神能说蜜语蜜语，所以祭灶果全用甜食。大抵是内里的“红果”、“绿果”、“白果”的膨化食物，另有雷同麻花的小饼、芝麻糖。",
                    "而在南京，无论贫富，夜供红枣汤、元宝糖，燃香烛、放爆竹，俗谓“送灶”，即送灶神上天宫“奏善恶，保平安” 。民间又称此日为“小除夕”，或过小年。至除夕晚，再行祭礼，取下供物，民间谓之“接灶”。"
                ]
            },
            {
                "title": "地方禁忌",
                "detail": [
                    "小年各地的禁忌各有不同。",
                    "相传湖北部分地区，小年忌宰杀。",
                    "河南有些地方忌讳捣蒜，认为小年捣蒜会把家里捣穷了。",
                    "台湾则忌舂米，据说会有把风神捣下来之虞，恐怕给来年带来风灾。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "元旦",
        "short_desc": "一年复始知岁月，万象更新长精神",
        "area": 0,
        "type": 1,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "别称",
                        "value": "新历年、阳历年"
                    },
                    {
                        "title": "节日时间",
                        "value": "公历1月1日"
                    },
                    {
                        "title": "节日类型",
                        "value": "世界节日"
                    },
                    {
                        "title": "节日饮食",
                        "value": "椒柏酒、五辛盘"
                    },
                    {
                        "title": "设定时间",
                        "value": "1949年9月27日"
                    }
                ],
                "detail": [
                    "元，谓“首”；旦，谓“日”；“元旦”意即“首日”。“元旦”一词最早出现于《晋书》，但其含义已经沿用4000多年。[1]  中国古代曾以腊月、十月等的月首为元旦，汉武帝起为农历1月1日，中华民国起为公历1月1日，1949年中华人民共和国亦以公历1月1日为元旦，因此元旦在中国也被称为“阳历年”。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "中国元旦历来指的是夏历（农历、阴历）正月初一。正月初一的计算方法，在汉武帝时期以前也是很不统一的。因此，历代的元旦月、日也并不一致。夏朝的夏历以孟喜月（元月）为正月，商朝的殷历以腊月（十二月）为正月，周朝的周历以冬月（十一月）为正月。秦始皇统一中国后，又以阳春月（十月）为正月，即十月初一为元旦。",
                    "",
                    "为了“行夏正，所以顺农时，从西历，所以便统计”，民国元年决定使用公历（实际使用是1912年），并规定阳历1月1日为“新年”，但并不称为“元旦”。",
                    "1949年9月27日，第一届中国人民政治协商会议，在决定建立中华人民共和国的同时，也决定采用世界通用的公元纪年法，即我们所说的阳历。元旦，指西元纪年的岁首第一天。",
                    "为区别农历和阳历两个新年，又鉴于农历二十四节气中的 “立春”恰在农历新年的前后，因此便把农历一月一日改称为“春节”，阳历1月1日定为新年的开始――“元旦”，并列入法定假日，成为全国人民的节日。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "现代中国的元旦，根据中国政府将其列入法定假日，成为全国人民的节日。放假一天，后常常将当日前或后双休日调整，一般连续休息三天。现代中国对元旦的庆祝较之春节，重要性要小得多。一般机关、企业会举行年终集体庆祝活动，但民间活动很少。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "气象节",
        "short_desc": "阴晴圆缺，风雨雷电，都是独特的礼物",
        "area": 1,
        "type": 1,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "每年的2月10日"
                    },
                    {
                        "title": "发起国家",
                        "value": "法国"
                    }
                ],
                "detail": [
                    "在1991年2月10日，在法国巴黎附近的一个叫依西·雷莫里诺（Issy－Les－Moulineaux）的地方举办了第一个国际气象节。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "国际气象节的发起人是法国人，在1991年2月10日，在法国巴黎附近的一个叫依西·雷莫里诺（Issy－Les－Moulineaux）的地方举办了第一个国际气象节，当时有16个国家的25个电视台参加，来自世界各地的气象专家，从参加气象节的电视台中，评出优秀的气象节目、气象小姐和气象先生。",
                    "到了2000年时，就已经有65个国家的130个电视台参加国际气象节了。",
                    "我国最早的气象台是1872年建成的上海徐家汇观象台，和即时系统预报。"
                ]
            },
            {
                "title": "气象知识",
                "detail": [
                    "“天上钩钩云，地下雨淋淋”，钩钩云就是钩卷云，它实际上是一个天气系统过来最开始出现的云，所以一旦天上出现钩钩云的时候，说明天气系统快接近这个地方，就要下雨了。",
                    "",
                    "“燕子低飞蛇过道，大雨就来到”，在夏天下大雨之前，一般气压要降低，而燕子、蛇这些小动物，对气压降低反应很敏感，乱飞乱跑，这就意味着要下大雨了。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "情人节",
        "short_desc": "愿得一人心，白首不相离",
        "area": 0,
        "type": 1,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "英文名",
                        "value": "Valentine‘s Day"
                    },
                    {
                        "title": "节日时间",
                        "value": "每年2月14日"
                    },
                    {
                        "title": "节日活动",
                        "value": "赠送贺卡和玫瑰花"
                    },
                    {
                        "title": "节日意义",
                        "value": "有情人终成眷属"
                    }
                ],
                "detail": [
                    "男女在这一天互送礼物用以表达爱意或友好。现已成为欧美各国青年人喜爱的节日，其他国家也已开始流行。于能表达共同的人类情怀，各国各地纷纷发掘了自身的“情人节”。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "270年——罗马圣教徒瓦伦丁被处死，此日被后人定为“情人节”瓦伦丁节，又称情人节，是欧美和大洋洲的一些国家的民族节日。此节日的来源甚多，但一般是以罗马圣教徒瓦伦丁被处死，后被定为“情人节”较为普通。公元3世纪，罗马帝国出现全面危机，经济凋敝，统治阶级腐败，社会动荡不安，人民纷纷反抗。贵族阶级为维护其统治，残暴镇压民众和基督教徒。是时有一位教徒瓦伦丁，被捕入狱。在狱中，他以坦诚之心打动了典狱长的女儿。他们相互爱慕，并得到典狱长女儿的照顾。统治阶级下令将他执行死刑。在临刑前，他给典狱长女儿写了一封长长的遗书，表明自己是无罪的。表明他光明磊落的心迹和对典狱长女儿深深眷恋。",
                    "公元270年2月14日，他被处死刑，后来，基督教徒为了纪念瓦伦丁为正义、为纯洁的爱而牺牲自己，将临刑的这一天定为“圣瓦伦节”，后人又改成“情人节”。 "
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "在情人节的习俗中，鲜花和巧克力是庆祝时必不可少的。这是男性送女性最经典的礼物，表明专一、情感和活力。玫瑰代表爱情是众所周知的，但不同颜色、朵数的玫瑰还另有吉意。",
                    "",
                    "不同数量",
                    "花有灵性有花语，数目不同代表意思不一样：",
                    "",
                    "0朵玫瑰代表——爱你在心口难开！",
                    "1朵玫瑰代表——我的心中只有你 ONLY YOU！",
                    "2朵玫瑰代表—— 这世界只有我俩！",
                    "3朵玫瑰代表——我爱你I LOVE YOU！",
                    "4朵玫瑰代表—— 至死不渝！",
                    "5朵玫瑰代表—— 由衷欣赏！",
                    "6朵玫瑰代表—— 互敬 互爱 互谅！",
                    "7朵玫瑰代表—— 我偷偷地爱著你！",
                    "8朵玫瑰代表—— 感谢你的关怀扶持及鼓励！",
                    "9朵玫瑰代表—— 长久 ALWAYS！",
                    "10朵玫瑰代表—— 十全十美 无懈可击！",
                    "11朵玫瑰代表—— 一生一世只爱你一个！",
                    "12朵玫瑰代表—— 对你的爱与日俱增！",
                    "13朵玫瑰代表—— 友谊长存！",
                    "14 朵玫瑰代表—— 骄傲！",
                    "15 朵玫瑰代表—— 对你感到歉意IM SORRY！",
                    "16 朵玫瑰代表—— 多变不安的爱情！",
                    "17 朵玫瑰代表—— 绝望无可挽回的爱！",
                    "18 朵玫瑰代表—— 真诚与坦白！",
                    "19 朵玫瑰代表—— 忍耐与期待！",
                    "20 朵玫瑰代表—— 我仅一颗赤诚的心！",
                    "21 朵玫瑰象徵—— 真诚的爱！",
                    "22 朵玫瑰象徵—— 祝你好运！",
                    "25 朵玫瑰象徵—— 祝你幸福！",
                    "30 朵玫瑰象徵—— 信是有缘！",
                    "36 朵玫瑰象徵—— 浪漫！",
                    "40 朵玫瑰象徵—— 誓死不渝的爱情！",
                    "50 朵玫瑰象徵—— 邂逅不期而遇！",
                    "99 朵玫瑰象徵—— 天长地久 FOREVER！",
                    "100 朵玫瑰象徵—— 百分之百的爱 100 % LOVE！",
                    "101 朵玫瑰象徵—— 最…… 最爱！",
                    "108 朵玫瑰象徵—— 求婚！ "
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "元宵节",
        "short_desc": "月上柳梢头，花市灯如昼",
        "area": 0,
        "type": 1,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "别称",
                        "value": "上元节、上元佳节、春灯节、灯节、小正月、元夕"
                    },
                    {
                        "title": "节日时间",
                        "value": "农历正月十五"
                    },
                    {
                        "title": "节日意义",
                        "value": "农历新年的第一个月圆之夜"
                    },
                    {
                        "title": "设定时间",
                        "value": "汉朝"
                    }
                ],
                "detail": [
                    "中国古俗中，上元节（元宵节）﹑中元节（盂兰盆节）﹑下元节（水官节）合称三元。元宵节始于2000多年前的秦朝。汉文帝时下令将正月十五定为元宵节。汉武帝时，“太一神”祭祀活动定在正月十五（太一：主宰宇宙一切之神）。司马迁创建“太初历”时，就已将元宵节确定为重大节日。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "元宵节是中国的传统节日，早在2000多年前的西汉时期就有了。据资料与民俗传说，正月十五在西汉已经受到重视，汉武帝正月上辛夜在甘泉宫祭祀“太一”的活动，（太一：主宰宇宙一切的神），被后人视作正月十五祭祀天神的先声。",
                    "",
                    "元宵，原意为“上元节的晚上”，因正月十五“上元节”主要活动是晚上的吃汤圆赏月，后来节日名称演化为“元宵节”。元宵之夜，大街小巷张灯结彩，人们赏灯，猜灯谜，吃元宵，将从除夕开始延续的庆祝活动推向又一个高潮，成为世代相沿的习俗。元宵在早期节庆形成过程之时，只称正月十五日、正月半或月望，隋以后称元夕或元夜。唐初受了道教的影响，又称上元，唐末才偶称元宵。但自宋以后也称灯夕。到了清朝，就另称灯节。在国外，元宵也以The Lantern Festiva而为人所知。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "猜灯谜",
                    "猜灯谜”又叫“打灯谜\",是元宵节后增的一项活动，灯谜最早是由谜语发展而来的，起源于春秋战国时期。它是一种富有讥谏、规戒、诙谐、笑谑的文艺游戏。谜语悬之于灯，供人猜射，开始于南宋。",
                    "",
                    "舞狮子",
                    "舞狮子是中国优秀的民间艺术，每逢元宵佳节或集会庆典，民间都以狮舞前来助兴。这一习俗起源于三国时期，南北朝时开始流行，至今已有一千多年的历史。",
                    "",
                    "耍龙灯",
                    "耍龙灯，也称舞龙灯或龙舞。它的起源可以追溯上古时代。传说，早在黄帝时期，在一种《清角》的大型歌舞中，就出现过由人扮演的龙头鸟身的形象，其后又编排了六条蛟龙互相穿插的舞蹈场面。中华民族崇尚龙，把龙作为吉祥的象征，龙舞流行于中国很多地方。",
                    "",
                    "送孩儿灯",
                    "简称“送灯”，也称“送花灯”等，即在元宵节前，娘家送花灯给新嫁女儿家，或一般亲友送给新婚不育之家，以求添丁吉兆，“灯”与“丁”谐音。",
                    "",
                    "吃元宵",
                    "“元宵”作为食品，在中国也由来已久。宋代，民间即流行一种元宵节吃的新奇食品。这种食品，最早叫“浮元子”后称“元宵”，生意人还美其名曰“元宝”。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "爱耳日",
        "short_desc": "爱护双耳，尽情聆听万物美妙的旋律",
        "area": 1,
        "type": 1,
        "cards": [{
                "title": "简介",
                "tags": [{
                    "title": "节日时间",
                    "value": "每年3月3日"
                }],
                "detail": [
                    "中国有听力语言障碍的残疾人2057万，其中7岁以下聋儿可达80万，老年性聋949万；由于药物、遗传、感染、疾病、环境噪声污染、意外事故等原因每年约新生聋儿3万余名。听力障碍严重影响着这一人群的生活、学习和社会交往。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "1998年3月，在政协第九届全国委员会第一次会议上，社会福利组15名委员针对我国耳聋发病率高、数量多、危害大，预防薄弱这一现实，提出了《关于建议确立爱耳日宣传活动》的第2330号提案。这一提案引起了有关部门的高度重视，经中国残疾人联合会、卫生部等10个部门共同商定，确定每年3月3日为全国爱耳日。",
                    "",
                    "曾有专家论证，如果“爱耳日”活动能够实施，每年仅7岁以下儿童药物中毒性耳聋至少可以减少1〜2万名。自2000年确立全国“爱耳日”，耳聋预防的公众宣传、全民的爱耳意识都有所提高。"
                ]
            },
            {
                "title": "听力保养",
                "detail": [
                    "避噪音",
                    "人倘若长时间接触机器轰鸣、车辆喧闹、人声喧哗等各种噪音，会使原本开始衰退的听觉更容易疲劳，听力急剧减退，甚至引发噪音性耳聋。因此，尽量避免或减少噪声的干扰，是老年人保护听力的首要一条。",
                    "",
                    "戒挖掏",
                    "经常用耳勺、火柴棒掏耳朵，容易碰伤耳道，引起感染、发炎，还可能弄坏耳膜。耳道奇痒难受时，可以用棉签沾少许酒精或甘油轻擦耳道，亦可内服维生素B、C和鱼肝油。",
                    "",
                    "慎用药",
                    "尽量避免应用耳毒性药物，如庆大霉素、链霉素、卡那霉素、新霉素等，因为老年人解毒排泄功能低，应用这些药物容易引起耳中毒而损害听力。",
                    "",
                    "常按摩",
                    "按摩耳垂前后的翳风穴(在耳垂与耳后高骨之间的凹陷处)，和听会穴(在耳屏前下方，下颌关节突后缘之凹陷处)，可以增加内耳的血液循环，有保护听力的作用。宜每日早晚各按摩一次，每次5~10分钟，长期坚持下去即可见效。",
                    "",
                    "熄肝火",
                    "老年人如经常处于急躁、恼怒的状态中，会导致植物神经失去正常的调节功能，使内耳器官发生缺血、水肿和听觉神经营养障碍，这样就可能出现听力锐减或暴发耳聋。因此，老年人要尽量使自己保持轻松愉快的良好心境。",
                    "",
                    "多补肾",
                    "中医认为，肾开窍于耳，听力的衰退与肾虚有着密切的关系。故老年人可在医生指导下服用一些补肾的药物，如六味地黄丸、金匮肾气丸、龟龄丸等，也可常喝核桃粥、芝麻粥、花生粥、猪肾粥等，对于保护听力颇有裨益。(请在医生指导下使用)"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "妇女节",
        "short_desc": "愿每一位女性都成熟着，自信着，美丽着，幸福着",
        "area": 0,
        "type": 1,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "别称",
                        "value": "联合国妇女权益和国际和平日、国际妇女节"
                    },
                    {
                        "title": "节日时间",
                        "value": "每年3月8日"
                    },
                    {
                        "title": "节日类型",
                        "value": "国际节日"
                    },
                    {
                        "title": "节日活动",
                        "value": "举行各类妇女权益活动"
                    },
                    {
                        "title": "设定时间",
                        "value": "1975年"
                    }
                ],
                "detail": [
                    "“三八”国际妇女节是全世界女性共同的节日。它是各国妇女争取和平、平等、发展的节日，也是庆祝各国妇女在经济、政治和社会等领域作出的贡献和取得的成就的节日。而这一节日得以确立并广泛推行的背后，是各国妇女长达一个世纪的不懈努力和艰苦斗争。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "在联合国介绍国际妇女节的网页上，把“三八”国际妇女节的起源归因于20世纪初期一系列的妇女运动大事，这些事件包括：美国社会党人在1909年将2月28日定为全国妇女日；1910年，第二国际哥本哈根会议上以蔡特金为首的来自17个国家的100余名妇女代表筹划设立国际妇女节，但未规定确切的日期；1911年3月19日，奥地利、丹麦、德国和瑞士等国有超过100万妇女集会庆祝国际妇女节；1913年2月的最后一个周日，俄罗斯妇女以举行反对第一次世界大战示威游行的方式庆祝了她们的国际妇女节；1914年3月8日，欧洲多国妇女举行反战示威游行；为纪念在一战中丧生的近200万俄罗斯妇女，1917年3月8日（俄历2月23日）俄罗斯妇女举行罢工，拉开了“二月革命”的序幕，4天后，沙皇被迫退位，临时政府宣布赋予妇女选举权。可以说，正是上世纪初这一系列发生在欧洲和美洲的女权运动共同促成了“三八”国际妇女节的诞生，而不是人们想当然的“国际妇女节”只是国际共产主义运动的遗产。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "1.面包加玫瑰之歌",
                    "美国堪萨斯州罗伦斯市的纺织女工罢工打出的标语就有“我们也要面包加玫瑰”。这表明她们不仅要求温饱，而且要过像样人的生活。受其影响，诗人詹姆士·奥朋汉（James Oppenheim）写了《面包加玫瑰》之歌。",
                    "",
                    "2.母亲节取缔妇女节",
                    "从1911年欧洲超过100万人举行各种活动庆祝国际妇女节以来，国际妇女节已经走过近100年的历史。就如同女性为争取自己权利所做的斗争一样，妇女节也曾遭到抵制。上世纪30年代的德国，希特勒就曾禁止庆祝妇女节。不过，这也引来一些纳粹女性的不满。为了拉拢妇女，纳粹党调整策略，宣扬“男人是生活的组织者，女人是他的帮手和执行者”。在取消妇女节的同时，设立了母亲节。母亲节也是希特勒为纪念自己母亲想出来的点子。他想用母亲节鼓励年轻女子生产报国。计划出来后，得到纳粹党上下的大力支持。希特勒随后公布，所有德国男人和孩子，必须在母亲节那天，给母亲和妻子赠送鲜花和夹心巧克力作为赞赏、包容和爱的象征。",
                    "",
                    "3.消费主义的异军突起",
                    "在这一天，来自不同民族，年龄也不尽相同的各个城市街头的男性，为女性——妻子、女朋友、女儿、同事、母亲——准备礼物。但随着消费主义取代了妇女节的女权主义政治立场，妇女节在蔓延的商业狂潮中失去了其独特的性别政治意味，民众开始对节日意义存在争议。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "国际警察日",
        "short_desc": "",
        "area": 1,
        "type": 1,
        "cards": [{
                "title": "简介",
                "tags": [{
                    "title": "节日时间",
                    "value": "每年3月14日"
                }],
                "detail": [
                    "3月14日是“国际警察日”，我国的“警察日”是公安部在2004年确定的。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "3月14日是“国际警察日”，是国际刑警组织于1995年确定的。今天也是我国公安部于2004年确立“警察日”之后的第七个“警察日”。",
                    "",
                    "国际刑警组织（International Criminal Police Organization – TERPOL）成立于1923年，最初名为国际刑警委员会，总部设在奥地利首都维也纳。二战期间，该组织迁到德国首都柏林，一度受纳粹组织控制，二战后，该组织恢复正常运转，总部迁到法国巴黎。1956年，该组织更名为国际刑事警察组织，简称国际刑警组织。1989年，该组织总部迁到法国里昂。",
                    "",
                    "国际刑警组织的宗旨是保证和促进各成员国刑事警察部门在预防和打击刑事犯罪方面的合作。它的主要任务是汇集、审核国际犯罪资料，研究犯罪对策；负责同成员国之间的情报交换；搜集各种刑事犯罪案件及犯罪指纹、照片、档案；通报重要案犯线索、通缉追捕重要罪犯和引渡重要犯罪分子；编写有关刑事犯罪方面的资料等。",
                    "国际刑警组织每年召开一次全体大会，并经常举行各种国际性或地区性研讨会。该组织日常与各国国家中心局保持密切关系，组织国际追捕。“红色通缉令”是该组织在打击国际犯罪活动中使用的一种紧急快速通缉令。",
                    "",
                    "中国于1984年加入国际刑警组织，同年组建国际刑警组织中国国家中心局。1995年，国际刑警组织第64届大会在北京举行。多年来，中国始终与国际刑警组织之间保持着密切的合作关系。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "气象日",
        "short_desc": "保护蓝天白云，还一片绿水青山",
        "area": 1,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "别称",
                        "value": "国际气象日"
                    },
                    {
                        "title": "节日时间",
                        "value": "每年的3月23日"
                    },
                    {
                        "title": "节日活动",
                        "value": "要求各国成员在这一天举行庆祝活动"
                    },
                    {
                        "title": "节日意义",
                        "value": "宣传气象工作的重要作用"
                    }
                ],
                "detail": [
                    "气象是指大气的状态和现象，如冷、热、干、湿、风、云、雨、雪等。为了加强国际间的气象研究与合作，更好地把气象观测结果应用于人类的各项实践活动，1878年国际气象组织在维也纳成立，该组织1947年9月召开气象局长会议，审议并通过了《世界气象组织公约》，公约于1950年3月23日正式生效，国际气象组织由此改名为世界气象组织，并成为联合国的一个专门机构。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "为纪念世界气象组织的成立，每年的3月23日被定为“世界气象日（World Meteorological Day）”，又称“国际气象日”。",
                    "",
                    "国际气象组织原为非官方性国际气象合作机构，在其各成员国代表签订的世界气象组织公约生效一周年之日，即1951年3月23日，改组为世界气象组织（World Meteorological Organization,WMO），成为政府间的国际气象合作机构，并与联合国建立关系。",
                    "",
                    "1960年世界气象组织执行委员会决定把每年3月23日定为世界性纪念日，要求各成员国每年在这一天举行庆祝活动，并广泛宣传气象工作的重要作用。每年世界气象日都有一个中心活动内容，各成员国在这一天可根据当年的中心内容，开展多种形式的宣传和纪念活动，如组织群众到气象台站参观访问，举行有政府领导人参加的群众庆祝仪式，举办气象仪表装备、照片、图表和资料的展览，举行记者招待会，由报刊、广播电台、电视台报道特写文章和讲话，放映气象科学电影，发行纪念邮票等。",
                    "",
                    "中国是世界气象组织的创始国之一。"
                ]
            },
            {
                "title": "历年主题",
                "detail": [
                    "1961 年 气象",
                    "1962 年 气象对农业和粮食生产的贡献",
                    "1963 年交通和气象(特别是气象应用于航空)",
                    "1964 年 气象— 经济发展的一个因素",
                    "1965 年 国际气象合作",
                    "1966 年 世界天气监测网",
                    "1967 年 天气和水",
                    "1968 年 气象与农业",
                    "1969 年 气象服务的经济效益",
                    "1970 年 气象教育和训练",
                    "1971 年 气象与人类环境",
                    "1972 年 气象与人类环境",
                    "1973 年 国际气象合作100年",
                    "1974 年 气象与旅游",
                    "1975 年 气象与电讯",
                    "1976 年 天气与粮食",
                    "1977 年 天气与水",
                    "1978 年 未来气象与研究",
                    "1979 年 气象与能源",
                    "1980 年 人与气候变迁",
                    "1981 年 世界天气监测网",
                    "1982 年 空间气象观测",
                    "1983 年 气象观测员",
                    "1984 年 气象增加粮食生产",
                    "1985 年 气象与公众安全",
                    "1986 年 气候变迁， 干旱和沙漠化",
                    "1987 年 气象与国际合作的典范",
                    "1988 年 气象与宣传媒介",
                    "1989 年 气象为航空服务",
                    "1990 年 气象和水文部门为减少自然灾害服务",
                    "1991 年 地球大气",
                    "1992 年 天气和气候为稳定发展服务",
                    "1993 年 气象与技术转让",
                    "1994 年 观测天气与气候",
                    "1995 年 公众与天气服务",
                    "1996 年 气象与体育服务",
                    "1997 年 天气与城市水问题",
                    "1998 年 天气、 海洋与人类活动",
                    "1999 年 天气、 气候与健康",
                    "2000 年气象服务五十年",
                    "2001 年 天气、 气候和水的志愿者",
                    "2002 年 降低对天气和气候极端事件的脆弱性",
                    "2003 年 关注我们未来的气候",
                    "2004 年 信息时代的天气、 气候和水",
                    "2005 年 天气、 气候、 水和可持续发展",
                    "2006 年 预防和减轻自然灾害",
                    "2007 年 极地气象： 认识全球影响",
                    "2008 年 观测我们的星球， 共创更美好的未来（ OBSERVING OUR PLANET FOR A BETTER FUTURE）",
                    "2009 年 天气、 气候和我们呼吸的空气(Weather, climate and the air we breathe)",
                    "2010 年 世界气象组织—— 致力于人类安全和福祉的六十年",
                    "2011 年 人与气候",
                    "2012 年 天气、 气候和水为未来增添动力",
                    "2013 年 监视天气， 保护生命和财产",
                    "2014 年 天气和气候： 青年人的参与",
                    "2015 年 气候知识服务气候行动",
                    "2016 年 直面更热、 更旱、 更涝的未来"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "消费者权益日",
        "short_desc": "净化消费环境，让消费者更有力量",
        "area": 1,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "每年3月15日"
                    },
                    {
                        "title": "节日意义",
                        "value": "宣传维护消费者权益"
                    },
                    {
                        "title": "设定时间",
                        "value": "1983年"
                    },
                    {
                        "title": "设立机构",
                        "value": "国际消费者联盟组织"
                    }
                ],
                "detail": [
                    "1983年、国际消费者联盟组织确定每年的3月15日为“国际消费者权益日”。这是基于美国前总统约翰·肯尼迪于1962年3月15日在美国国会发表了《关于保护消费者利益的总统特别咨文》，首次提出了著名的消费者的“四项权利”，即：有权获得安全保障；有权获得正确资料；有权自由决定选择；有权提出消费意见。肯尼迪提出的这四项权利，以后逐渐为世界各国消费者组织所公认，并作为最基本的工作目标。"
                ]
            },
            {
                "title": "发展历史",
                "detail": [
                    "1898年，全世界第一个消费者组织在美国成立，1936年，建立了全美的消费者联盟。第二次世界大战后，各种反映消费者利益和要求的组织，在一些发达国家相继出现。在此基础上，1960年，国际消费者联盟组织宣告成立。之后，消费者运动更加活跃，许多发展中,有些国家也建立了消费者组织，使消费者运动成为一种全球性的社会现象，全世界已有90多个国家共300 多个消费者组织在开展活动。",
                    "",
                    "1962年3月15日，肯尼迪在美国国会发表了有关保护消费者利益的总统特别咨寻文，首次提出了著名的消费者的4项权利，即安全消费的权利、消费时被告知基本事实的权利、选择的权利和呼吁的权利。随着消费者权利保护工作的开展，肯尼迪提出的4项权利和国际消费者协会确定的另外4项权利，即满足基本需求的权利、公正解决纠纷的权利、掌握消费基本知识的权利和在健康环境中生活工作的权利，一并成为全世界保护消费者权益工作的8条准则。",
                    "",
                    "1983年，国际消费者协会把每年的3月15日定为国际消费者权益日。此后，每年3月15日，世界各地的消费者及有关组织都要举行各种活动，推动保护消费者权益运动进一步发展。"
                ]
            },
            {
                "title": "维权方式",
                "detail": [
                    "1、自行协商交涉是首选。",
                    "发生问题可先与商家交涉，自行协商，同时注意收集相关证据。如能协商解决，省时省力。但如果对方态度一直良好，但久拖不决，建议不要一直等待，及时采取其他措施。",
                    "",
                    "2、向工商、卫生、税务、房管等行政主管部门举报要求处理。",
                    "发生侵害消费者权益的事件，消费者可以视不同的违法行为向商家的主管行政机关投诉举报，比如都可以向工商局投诉。",
                    "",
                    "3、寻求消费者协会的帮助，进行调解。",
                    "消费者权益受到侵害，可以向当地消费者协会反映，寻求帮助，消协也可介入进行调解，也可由消协向相关主管单位反映。",
                    "",
                    "4、向法院起诉。",
                    "如果通过前述程序解决不了，消费者可依法向有管辖权的法院提起诉讼，法院可依法判决，如任何一方不自动履行判决，另一方可申请法院强制执行。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "植树节",
        "short_desc": "种一方绿色，植一片希望",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "3月12日"
                    },
                    {
                        "title": "节日活动",
                        "value": "以宣传森林效益，并动员群众参加造林为活动内容的节日"
                    },
                    {
                        "title": "设定时间",
                        "value": "1979年2月23日"
                    },
                    {
                        "title": "节日起源",
                        "value": "中国陕西西安"
                    }
                ],
                "detail": [
                    "植树节是一些国家以法律规定宣传保护树木，并动员群众参加以植树造林为活动内容的节日。按时间长短可分为植树日，植树周或植树月，总称国际植树节。通过这种活动，激发人们爱林，造林的热情。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "中国古代在清明节时节就有插柳植树的传统，中国历史上最早在路旁植树是由一位叫韦孝宽的人于1400多年前从陕西首创的。",
                    "",
                    "而近代植树节则最早由美国的内布拉斯加州发起。19世纪以前，内布拉斯加州是一片光秃秃的荒原，树木稀少，大风一起，黄沙漫天，人民深受其苦。1872年，美国著名农学家朱利叶斯·斯特林·莫尔顿提议在内布拉斯加州规定植树节，动员人民有计划地植树造林。当时州农业局通过决议采纳了这一提议，并由州长亲自规定今后每年4月份的第三个星期三为植树节。",
                    "在美国，植树节是一个州定节日，没有全国统一规定的日期。但是每年4、5月间，美国各州都要组织植树节活动。例如，罗德艾兰州规定每年5月份的第二个星期五为植树节，并放假一天。其他各州有的是固定日期，也有的是每年由州长或州的其他政府部门临时决定植树节日期。每当植树节到来，以学生为主的社会各界群众组成浩浩荡荡的植树大军，投入植树活动。"
                ]
            },
            {
                "title": "植树趣闻",
                "detail": [
                    "几百年前，南斯拉夫就制定了一项法律，规定每对新婚夫妇，必须先种植油橄榄树70株。日本鹿儿岛等一些地区也有新婚夫妇要植树的规定，树旁立碑写明姓名和婚期，植后50年方能砍伐，届时植树夫妇可举行结婚50周年庆祝活动。",
                    "",
                    "印度尼西亚爪哇岛法令条文规定，第一次结婚要种树2棵，离婚的要种5棵；第二次结婚必须种树3棵，否则不予登记。",
                    "添车种树，在日本有一项特殊的规定，凡是私人增添一辆汽车，必须植一棵树。这是因为，每辆汽车每年要排出大量有毒的碳氢化合物，还要发出噪音，而树木则是天然的“消毒员”和“除音器”，所以必须种树。",
                    "添丁种树，在非洲坦桑尼亚的许多地方，有一种“添丁植树”的风俗，即谁家生了孩子，便把胎盘埋在门外的土地里，并在那里种上一棵树，表示希望孩子像树一样茁壮成长。",
                    "家庭树，波兰的一些地方规定：凡是生了小孩子的家庭均要植树3株，称之为“家庭树”。",
                    "树木银行，为了防止建筑工程毁坏树木，日本开办了“树木银行”。凡施工单位，必须把清理场地挖出来的带根树木及时存入“树木银行”，在工程结束后，该单位必须及时把树木取出来栽上，以保持原有的绿化面积。",
                    "求爱树，在德国的波恩市，每年的植树季节，小伙子要送给姑娘一棵精心挑选的白桦树苗，亲手把它栽好，以表达爱慕之情，人们称之为“求爱树”。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "澳门回归纪念日",
        "short_desc": "澳门回归纪念日，骨肉兄弟不分离，共祝祖国能富强，中华儿女把头昂",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "别称",
                        "value": "澳门回归节、澳门回归日"
                    },
                    {
                        "title": "节日时间",
                        "value": "每年12月20日"
                    },
                    {
                        "title": "节日意义",
                        "value": "庆祝澳门回归中国"
                    }
                ],
                "detail": [
                    "澳门自1999年12月20日回归后，成为中华人民共和国的一个特别行政区，依据澳门基本法实行高度自治。在\"一国两制\"政策的指引下，澳门的社会和经济方面的特色会予以保留并得以延续。澳门的面积很小，是世界上人口最稠密的地方之一，也是亚洲人均收入比较高的地区。"
                ]
            },
            {
                "title": "名称来源",
                "detail": [
                    "澳门以前是个小渔村。她的本名为濠镜或濠镜澳,因为当时泊口可称为\"澳\".澳门及其附近盛产蚝(即牡蛎),蚝壳内壁光亮如镜,澳门因此被称为蚝镜(蚝的繁体字为\"蚝\"）。后人把这个名称改为较文雅的“濠镜”。清乾隆年间出版的《澳门纪略》中说：“濠镜之名，著于《明史》。东西五六里、南北半之，有南北二湾，可以泊船。或曰南北二湾，规圆如镜，故曰濠镜。”从这个名称中，又引申出濠江、海镜、镜海等一连串澳门的别名。澳门的名字源于渔民非常敬仰的中国女神 天后，又名娘妈。根据传说，一艘渔船在一个天气晴朗，风平浪静的日子里航行，突然刮起狂风雷暴，渔民们处於危急关头。这时，一位少女站了起来，下令风暴停止。风竟然停止了，大海也恢复了平静，渔船平安地到达了海镜港。上岸后，少女朝妈阁山走去，忽然一轮光环照耀，少女化做一缕青烟。后来，人们在她登岸的地方，建了一座庙宇供奉这位娘妈。",
                    "十六世纪中叶，第一批葡萄牙人抵澳时，询问居民当地的名称，居民误以为指庙宇，答称\"妈阁\"。葡萄牙人以其音而译成\"MACAU\"，成为澳门葡文名称的由来。"
                ]
            },
            {
                "title": "日期由来",
                "detail": [
                    "公元1999年12月20日，在距离千禧年还有11天的时候，中国政府将对澳门恢复行使主权。收回澳门的时间，中方虽曾有过早于香港、与香港同时、晚于香港三种考虑，但前提是在20世纪之内，也就是说，最晚也不迟于1999年。谈判中，中方立场趋于一致，倾向在香港回归后的1998年收回澳门。葡方出于自身的利益或者面子问题，希望越晚越好，其提出的一个理由是要给澳门一个比较长的过渡期",
                    "就在1986年6月30日中葡第一轮会谈前，葡国就不断有官员放出试探性气球，暗示葡萄牙不会将澳门拱手相让，而会讨价还价，以使澳门保留“葡韵”，长期充当葡文化在东方的桥头堡。",
                    "",
                    "为打破僵局，1986年11月，时任外交部副部长、中方谈判团团长的周南飞抵里斯本，与葡国家领导人举行会晤。双方就澳门回归的时间展开激烈争论。周南一改昔日的豪爽健谈，以“疲倦”为由取消了原定的一些参访活动，匆匆回国。在离开里斯本机场时，周南向记者表示：“在本世纪末以前完成香港、澳门回归祖国的大业，是亿万中国人民的意愿，也是中国政府的决心。”",
                    "同年12月的最后一天，我外交部发言人发表谈话，郑重指出：“在2000年前收回澳门，是中国政府和包括澳门同胞在内的十亿中国人民不可动摇的坚定立场和强烈愿望，任何超越2000年后交还澳门的主张，都是不能接受的。”",
                    "中方的这些措辞强硬的表态，引起葡萄牙方面的高度重视，实际上也堵死了任何延期交还的企图。孰料，这时发生了耐人寻味的“秘密协议失踪事件”。葡国总理等人声称1979年中葡建交时的秘密协议不见了，澳门总督也附和说从来没有看过这份文件。经过一阵折腾，文件找到了，同时传来葡方从原定立场后退的消息。1987年1月6日，葡萄牙召开所有领导人出席的国务会议，经四个多小时激烈辩论，最终原则上同意在1999年将澳门的治权交还中国。中葡谈判“柳暗花明”，进入最后一轮。",
                    "“这时，葡方向我们提出，希望在1999年的最后一天即12月31日交还澳门。我方就表示，这太靠近新年了，何况在香港、澳门，当地居民都有过圣诞节的习惯，葡萄牙国内不是也过圣诞节吗？政权交接还是与圣诞节和新年的假期错开来为好。就这样，为了让澳门同胞过一个安安稳稳的假期，葡方最后同意了我们的主张：中国政府在圣诞节前的12月20日对澳门恢复行使主权。”"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "南京大屠杀死难者国家公祭日",
        "short_desc": "愿悲剧不再重演，愿祖国繁荣昌盛",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "别称",
                        "value": "南京大屠杀公祭日"
                    },
                    {
                        "title": "节日时间",
                        "value": "每年的12月13日"
                    },
                    {
                        "title": "节日意义",
                        "value": "牢记历史、勿忘国耻"
                    },
                    {
                        "title": "节日类型",
                        "value": "国家公祭日、纪念日"
                    }
                ],
                "detail": [
                    "南京大屠杀是中国抗日战争初期侵华日军在当时的中国南京犯下的大规模屠杀、强奸以及纵火、抢劫等战争罪行与反人类罪行。日军暴行的高潮从1937年12月13日攻占南京开始持续了6周，直到1938年2月南京的秩序才开始好转。据第二次世界大战结束后远东国际军事法庭和南京军事法庭的有关判决和调查，在大屠杀中有20万以上乃至30万以上中国平民和战俘被日军杀害，约2万中国妇女遭日军奸淫，南京城的三分之一被日军纵火烧毁，财产损失不计其数。"
                ]
            },
            {
                "title": "历史背景",
                "detail": [
                    "南京大屠杀是中国抗日战争初期侵华日军在当时的中国南京犯下的大规模屠杀、强奸以及纵火、抢劫等战争罪行与反人类罪行。日军暴行的高潮从1937年12月13日攻占南京开始持续了6周，直到1938年2月南京的秩序才开始好转。据第二次世界大战结束后远东国际军事法庭和南京军事法庭的有关判决和调查，在大屠杀中有20万以上乃至30万以上中国平民和战俘被日军杀害，约2万中国妇女遭日军奸淫，南京城的三分之一被日军纵火烧毁，财产损失不计其数。",
                    "在中国，自1947年南京军事法庭审判以来，普遍认为约有30万人在大屠杀中遇难。在日本，日本政府承认“发生过杀害非战斗人员和掠夺等行为”，但对遇难人数则暧昧不清，也有认为南京大屠杀不存在的“否认派”。“否认派”的论调得到部分日本政商势力的支持，在华人世界引起强烈反感，这也使得南京大屠杀成为严重影响中日关系的主要历史问题之一。"
                ]
            },
            {
                "title": "重大意义",
                "detail": [
                    "以中国国家名义进行正式纪念与公祭，其世界意义在于，促使人类历史记忆长久保持唤醒状态，而避免出现哪怕是片刻的忘却与麻木，共同以史为鉴、开创未来，一起维护世界和平及正义良知，促进共同发展和时代进步。",
                    "中国人民抗日战争的胜利为世界反法西斯战争的胜利作出了不可磨灭的历史贡献，共同消除了威胁世界和平的法西斯邪恶势力，壮大了人类的进步力量，促进了全球的民族解放运动，对世界和平事业产生了深远影响。",
                    "",
                    "“国家公祭日”古已有之，在古代称为“国祀”，在中国，向来是先人们精神生活中的大事，《左传》谓：“国之大事，在祀与戎”。历朝历代，在复杂的礼仪中，始终凝结成一个不变的核心，一个最根本的认识。如果说过去的祭天、祭地、祭祖的祭祀文化，反映的是一个文明成熟程度的话，大家对一段历史的复原以及回忆，乃至将其定格、固化为一个“公祭日”，反映的则是整个民族的成熟与强大。",
                    "",
                    "任何历史都是现代史，都对现实有着巨大的警鉴价值，而对历史铭记，就是树起了一面镜子，无论是对企图颠覆历史的人，还是渴望和平的人，都需要这个镜子随时折射提示，从这个角度上说，中国的“国家公祭”，小而言之是中国人对自己同胞的记忆、缅怀，是对国人应有历史价值观的培养；大而言之，是中国人在替世界保留的一份珍贵遗产，是中国人就此确立与国际社会相处的尊严方式。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "平安夜",
        "short_desc": "愿时时平安，事事平安",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "英文名",
                        "value": "Christmas Eve"
                    },
                    {
                        "title": "流行区域",
                        "value": "全球"
                    },
                    {
                        "title": "节日活动",
                        "value": "聚餐、舞会、互换礼物"
                    }
                ],
                "detail": [
                    "平安夜（Silent Night），即圣诞前夕，在大部分基督教国家是圣诞节节日之一，但现在，由于中西文化的融合，已成为世界性的一个节日。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "平安夜传统上是摆设圣诞树的日子，但随着圣诞节的庆祝活动提早开始进行，例如美国在感恩节后，不少圣诞树早在圣诞节前数星期已被摆设。延伸发展至今平安夜不仅是指12月24日晚了，指的是圣诞前夕，特指12月24日全天，但由于一般节日氛围在晚上容易调动起来，大型活动都集中在晚上，固被称作平安夜，更加贴切。",
                    "",
                    "圣诞节是指12月25日，平安夜是指12月24日是圣诞前夕，称为圣诞夜，也称为平安夜。届时，千千万万的欧美人风尘仆仆地赶回家中团聚。圣诞之夜必不可少的节目是Party或聚会。大多数欧美家庭成员团聚在家中，共进丰盛的晚餐，然后围坐在熊熊燃烧的火炉旁，弹琴唱歌，共叙天伦之乐；或者举办一个别开生面的化妆舞会，通宵达旦地庆祝圣诞夜是一个幸福、祥和、狂欢的平安夜、团圆夜。圣诞之夜，父母们会悄悄地给孩子们准备礼物放在长统袜里。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "平安夜亦被认为是圣诞老人及其他地区类似人物四处向好儿童派礼物的时间。在意大利，礼物在平安夜当日早上已开启，而在英国、爱尔兰、瑞典、丹麦、挪威、芬兰、葡萄牙及波兰，圣诞礼物通常在平安夜晚上或圣诞日早上开启。",
                    "在德国大部分地区，圣诞礼物则在平安夜晚上（德语 Bescherung）开启。在冰岛圣诞节在平安夜下午6时开始，教堂钟声会被敲响，人们会与家人一起享用节日晚餐，之后便会拆礼物，一起渡过晚上。在北美洲，大部分家庭会在圣诞日早上拆礼物。对于已离婚的家庭，孩子可能分开两日与父亲或母亲其中一方庆祝。在西班牙礼物则要到1月6日显现日（Día de reyes）早上才会被拆。不少传统圣诞故事都在平安夜晚上发生。",
                    "在日本，大多数人都信奉传统的神道教以及佛教，圣诞节的宗教气息淡薄，一般人鲜少注意圣诞节或平安夜的宗教意义。大多是年轻人受到商业炒作的影响，将圣诞节等同于礼物交换、狂欢节或是情人节的延伸，年轻人开派对，情侣往往在圣诞夜约会、吃大餐，造成餐厅与旅馆人潮远多于教堂的现象。",
                    "",
                    "平安夜送平安果，这个习俗据说是中国才有的。因为中国人比较注重谐音，比如洞房花烛夜，把花生红枣和莲子放在被子下面，寓意\"早(枣)生贵子\"。平安夜就是圣诞节前夜，圣诞节是12月25日，平安夜就是12月24日夜晚。苹果的\"苹\"与平安的\"平\"同音，于是中国人寓以苹果\"平安\"的吉祥含义。于是就有了平安夜送苹果的习俗。送苹果代表送的人祝福接受平安果的人新的一年平平安安。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "人权日",
        "short_desc": "不忘初心，不负光阴",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "英文名",
                        "value": "Human Rights Day"
                    },
                    {
                        "title": "发布机构",
                        "value": "联合国"
                    },
                    {
                        "title": "节日时间",
                        "value": "每年的12月10日"
                    }
                ],
                "detail": [
                    "人权日始于1950年，国际社会在这一天共同纪念《宣言》倡导的基本自由，向积极促进和捍卫这些权利的人们致敬。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "人权日和第二次世界大战有着极其密切的关系。二战是世界上的资本主义国家为了重新瓜分全球的资源，如原料和土地等，而发动的大规模战争。对全人类来说，战争是非常残酷的。二战期间，到处生灵涂炭，民不聊生，人类遭到空前的浩刧。在战争年代，根本没有什么人权可谈。",
                    "人类吸取了这惨痛的历史经验教训过后，为了避免这样的悲惨历史重演，通过联合国在1948年12月10日发表《世界人权宣言》。值得注意的是，《世界人权宣言》是联合国大会在没有任何国家反对情况下通过的。",
                    "为了纪念这个重大的日子，联合国把通过《世界人权宣言》的日子（也就是12月10日）定名为人权日。这就是人权日的由来。"
                ]
            },
            {
                "title": "人权内容",
                "detail": [
                    "生命权。",
                    "生命权是最基本，最重要的人权，如果无法充分保障人的生命权，那么一切其它权利都是空中楼阁。无端剥夺人的生命，或者肆意对人施加恐吓、虐待和折磨，就是用一种非人权的待人方式。任由这种情况发生，个人权利就无从谈起。所以一般各国的刑法都将侵害他人生命权的罪行量刑最重。“生命权是一个人之所以被当作人类伙伴所必须享有的权利。”",
                    "",
                    "自由权",
                    "自由权这个混合体词语不应存在，\"权\"已经是个包含了具体的规范，有容许和禁止的条文，而\"自由\"(Free)是含有无限制无约束性的主观感性概念性形容词，把无限(Free)局限于规范性\"权\"之内是符合逻辑的表达。",
                    "",
                    "财产权",
                    "财产权是生命权和自由权的延伸。如果一个人要生存下去、要有能力选择他喜欢的方式生存下去，一定要有物质作为支持，那么，对自我劳动的所得进行排他性的占有，就是生命权与自由权必不可少的保障。“人能够工作，能够靠自己的劳动成果生活，并把生活剩余的钱存起来留给子女或者自己的晚年，这都是人尊严的一部分。”[6]财产权看似是一种物权，但其实质为人支配物，即支配自己正当所得的权利。",
                    "",
                    "尊严权",
                    "尊严也是生命权和自由权的合理延伸。如果一个人若无尊严，那么他的生命至多是一种无人格的形式。作为一种基本的人权，尊严的价值早在古代就得到普遍的认同，如陶渊明，不为五斗米折腰等。尊严权主要要求人们在社会交往中互敬互爱，文明礼貌。如果一个人的尊严权被否认，就意味者人们可以肆无忌惮的羞辱，威胁，骚扰，中伤他，那显然他就失去了“作为人类”的资格，这无疑是和人权所不容的。",
                    "",
                    "获助权",
                    "获助权常常和“人道主义”联系在一起，出现于天灾、人祸之后。由于种种不可预知的灾祸，人的生命权无时不刻受到威胁。在危难关头得到伙伴的帮助，是生命权的必要保障。在现代社会中，突发性的灾难有时会造成很大的危害，这种时候个体的获助权就需要一个强大的组织，一般是政府的倾力帮助，这是政府一项重要的公共服务职能。",
                    "",
                    "公正权",
                    "人权的普适性必然的要求每一个人都受到公平合理的对待，但现实生活中，经济权力、政治权力、种族、国籍等，都会不同程度将人划到不同的等级，那么人权就变成的有限的，有条件的，甚至成为特权阶级的奢侈品了。而公正权是为了将人权平等的扩展到每一个人身上。公正权不仅是人权的一部分，更重要的是它也是人权中其它部分的必要条件。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "圣诞节",
        "short_desc": "庆圣诞、迎新年",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "英文名",
                        "value": "Christmas"
                    },
                    {
                        "title": "节日类型",
                        "value": "西方传统节日、宗教节日"
                    },
                    {
                        "title": "流行地区",
                        "value": "欧洲、美洲"
                    }
                ],
                "detail": [
                    "圣诞节（Christmas）又称耶诞节，译名为“基督弥撒”，西方传统节日，在每年12月25日。弥撒是教会的一种礼拜仪式。圣诞节是一个宗教节，因为把它当作耶稣的诞辰来庆祝，故名“耶诞节”。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "1.纪念耶稣出生",
                    "",
                    "据说耶稣是因着圣灵成孕，由童女马利亚所生的。神更派遣使者加伯列在梦中晓谕约瑟，叫他不要因为马利亚未婚怀孕而不要她，反而要与她成亲，把那孩子起名为“耶稣”，意思是要他把百姓从罪恶中救出来。",
                    "当马利亚快要临盆的时候，罗马政府下了命令，全部人民到伯利恒务必申报户籍。约瑟和马利亚只好遵命。他们到达伯利恒时，天色已昏，无奈两人未能找到旅馆渡宿，只有一个马棚可以暂住。就在这时，耶稣要出生了。于是马利亚唯有在马槽上，生下耶稣。后人为纪念耶稣的诞生，便定十二月二十五为圣诞节，年年望弥撒，纪念耶稣的出世。",
                    "",
                    "2.罗马教会设立",
                    "12月25日本是波斯太阳神（即光明之神）密特拉（Mithra）的诞辰，是一个异教徒节日，同时太阳神也是罗马国教众神之一。这一天又是罗马历书的冬至节，崇拜太阳神的异教徒把这一天当作春天的希望，万物复苏的开始。基于此原因，罗马教会选择这一天作为圣诞节。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "礼物：圣诞卡",
                    "装饰：圣诞袜、圣诞帽、圣诞树、圣诞节橱窗、圣诞节环",
                    "饮食：火鸡、沙滩宴、煮肉、玉米粥、海鲜、姜饼、glogi酒等"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "艾滋病日",
        "short_desc": "正视艾滋，呵护生命，抵制歧视",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "每年12月1日"
                    },
                    {
                        "title": "标志",
                        "value": "红绸带"
                    },
                    {
                        "title": "设立机构",
                        "value": "世界卫生组织"
                    },
                    {
                        "title": "节日意义",
                        "value": "增强人们对艾滋病的认知"
                    }
                ],
                "detail": [
                    "在地球上，平均每分钟都有一个孩子死于艾滋病，有超过1500万的儿童因为艾滋病而失去父母。中国艾滋病病毒感染人数在全球居第十四位，更以每年百分之四十的速度递增。防治艾滋病任重道远，需要全社会共同努力。面对因艾滋酿成的一桩桩悲剧，我们不需要旁观者，我们要用自己的力量来扭转这场恶性流行病的传播态势——团结全社会的力量，以我们所能做到的各种方式，共同抗击艾滋！"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "艾滋病日的概念来源于1988年，全球卫生部大臣关于艾滋病预防计划的高峰会议上自1981年世界第一例艾滋病病毒感染者发现至今，短短30多年间，艾滋病在全球肆虐流行，已成为重大的公共卫生问题和社会问题，引起世界卫生组织及各国政府的高度重视。为号召全世界人民行动起来，团结一致共同对抗艾滋病，1988年1月，世界卫生组织在伦敦召开了一个有100多个国家参加的“全球预防艾滋病”部长级高级会议，会上宣布每年的12月1日为“世界艾滋病日” (World Aids Day) ；1996年1月，联合国艾滋病规划署（UNAIDS）在日内瓦成立；1997年联合国艾滋病规划署将“世界艾滋病日”更名为“世界艾滋病防治宣传运动”，使艾滋病防治宣传贯穿全年。"
                ]
            },
            {
                "title": "历届主题",
                "detail": [
                    "世界艾滋病日自设立以来，每年都有一个明确的宣传主题。围绕主题，联合国艾滋病规划署、世界卫生组织及其成员国都要开展各种形式的宣传教育活动。",
                    "1988年：“全球共讨，征服有期”(Join the Worldwide Effort)",
                    "1989年：“我们的生活，我们的世界——让我们相互关照”（Our Lives,Our World----Lets Take Care of Each Other)",
                    "1990年：“妇女和艾滋病”（Women and AIDS)",
                    "1991年：“共同迎接艾滋病的挑战”（Sharing the Challenge)",
                    "1992年：“预防艾滋病，全社会的责任”（A Community Commitment)",
                    "1993年：“时不我待，行动起来”（Time to Act)",
                    "1994年：“家庭与艾滋病”（AIDS and the Family)",
                    "1995年：“共享权益，同担责任”（Shared Right,Shared Responsibilites)",
                    "1996年：“一个世界，一个希望”（One World,One Hope)",
                    "1997年：“艾滋病与儿童”（Children Living in a World with AIDS)",
                    "1998年：“青少年--迎战艾滋病的生力军”（Force for Change: World AIDS Campaign with Young People)",
                    "1999年：“倾听、学习、尊重”（Listen,Learn,Live！）",
                    "2000年：“男士责无旁贷”（Men Make a Difference)",
                    "2001年：“你我同参与”（I care,and You?)",
                    "2002年：“相互关爱，共享生命”（Live,let Live)",
                    "2004年：“关注妇女，抗击艾滋”（Women,Girls,HⅣ and AIDS)",
                    "2005 年：“遏制艾滋 履行承诺”（stop AIDS,keep the promise",
                    "2006年：12月1日是第19个世界艾滋病日。“遏制艾滋，履行承诺”",
                    "2007年：12月1日是第20个世界艾滋病日。宣传主题仍然是遏制艾滋，履行承诺",
                    "2008年：世界艾滋病日主题仍为“遏制艾滋，履行承诺”（Stop AIDS,Keep the promise）",
                    "2009年：世界艾滋病日主题为“普遍可及和人权”（Universal Access and Human Rights）",
                    "2010年：世界艾滋病日主题为“正视艾滋，重视权益，点亮反歧视之光”（Keep the light on HⅣ and human rights）",
                    "2011年：世界艾滋病日主题为“行动起来，向‘零’艾滋迈进”（Getting to Zero）",
                    "2012年：与2011年相同，仍为“行动起来，向‘零’艾滋迈进”（英文主题Getting to Zero），副标题是“全民参与，全力投入，全面预防”。中国确定的副题是：全民参与，全力投入，全面预防",
                    "2013年：与2012年相同，副标题是“共抗艾滋，共担责任，共享未来”。旨在号召全社会积极投身于艾滋病防治工作，共同承担防治责任和义务，群策群力，各展所长，遏制艾滋病的流行",
                    "2014年：2014年活动主题为“行动起来，向‘零’艾滋迈进”(英文主题Getting to Zero)，副标题是“共抗艾滋，共担责任，共享未来”",
                    "2015年：2015年活动主题是“行动起来，向‘零’艾滋迈进”（英文主题为GettingtoZero），副标题为“合力抗艾，共担责任，共享未来”。",
                    "2016年：“携手抗艾,重在预防”"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "大学生节",
        "short_desc": "今朝梦圆挥笔墨，明日驰聘舞乾坤",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日别称",
                        "value": "世界大学生节、世界学生日、国际学生日"
                    },
                    {
                        "title": "节日时间",
                        "value": "11月17日"
                    },
                    {
                        "title": "节日意义",
                        "value": "纪念反法西斯的大学生运动"
                    }
                ],
                "detail": [
                    "1946年，世界各国学生代表于布拉格召开全世界学生大会，宣布把每年的11月17日定为“世界大学生节”，以加强全世界大学生的团结和友谊。节日的设立是为了控诉暴徒血腥的罪恶行径，为了纪念反法西斯的大学生运动，为了在大学生中倡导追求和平、民主、自由和进步。"
                ]
            },
            {
                "title": "起源",
                "detail": [
                    "1939年11月17日，侵入捷克斯洛伐克的德国法西斯匪徒屠杀了布拉格大学9名学生和2位教授，其他各校的男女学生也遭到机枪扫射、殴辱和监禁。",
                    "",
                    "1946年11月17日，世界各国学生代表于布拉格召开全世界学生大会，为了在大学生中倡导追求和平、民主和自由，也为了纪念反法西斯的大学生运动，将每年的11月17日定为世界学生日，以激励全世界学生共同为建设一个和平、民主、进步的世界而努力。",
                    "",
                    "截至2006年11月17日，国际大学生节已是第60届。"
                ]
            },
            {
                "title": "活动",
                "detail": [
                    "世界",
                    "",
                    "每年11月17日，世界各地的大学生会组织各种各样的活动形式予以纪念，如在校内举办文化节、图片展览等，组织讲座宣传、歌舞晚会等，到校外慰问困难儿童，展示当代大学生的精神风采。",
                    "",
                    "我国",
                    "",
                    "为迎接、庆祝第六十八个国际大学生节，2013年11月12日，徐州发起万名大学生在《永久和平赋》书法长卷上签名并诵读赋文活动，为世界祈以新年之福。",
                    "",
                    "“信鸽咕咕，尽沐熙风细雨；海鸟嗷嗷，悠乐泛桅浮樯。全普天之永乐，乃万古之向往。然枭雄弓张，长有战事之繁；鼠盗齿啮，擅行不劳之享。遂有万古血海漂杵，千秋家园毁伤。然军功既往，难掩烦冤之泪；烟土久旱，不改云霓之望。”（摘自《永久和平赋》）"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "光棍节",
        "short_desc": "但愿人长久，光棍不再有",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日别称",
                        "value": "单身节"
                    },
                    {
                        "title": "节日时间",
                        "value": "11月11日"
                    },
                    {
                        "title": "节日活动",
                        "value": "购物、单身趴等"
                    }
                ],
                "detail": [
                    "11月11日，光棍节，源于这一天日期里有四个阿拉伯数字“1“形似四根光滑的棍子，而光棍在中文有单身的意思，所以光棍节是单身一族的一个另类节日，这个日子便被定为“光棍节”。",
                    "",
                    "光棍节产生于校园，并通过网络等媒介传播，逐渐形成了一种光棍节的文化。如今越来越多的人选在光棍节结婚，于此同时，也是各大商家以脱光为由打折促销的时期。"
                ]
            },
            {
                "title": "来源",
                "detail": [
                    "光棍节的来历，一直是个迷，有各种各样的猜测和说法。其中光棍节产生于校园文化的说法是比较可信的，但由谁最先提出已不可考了。众多说法中，光棍节起源于南京高校的校园文化是被广泛接受的观点。（同时期诞生在不同地区，后续在南京地区高校开展比较活跃，继而通过群体活动和媒体传播，光棍节在社会上流行开来。）"
                ]
            },
            {
                "title": "意义",
                "detail": [
                    "光棍增多并非是坏事，相反是人们追求高质量婚姻生活的结果。光棍节从网络走向社会，从小众活动变成公众活动，也是社会开放度、包容度提升的一种表现。20岁到30岁之间的单身人士往往是承受社会压力最大的人群，除了工作、竞争之外，还有来自家庭催婚的压力。",
                    "",
                    "光棍节无非是让光棍们在调侃中轻松面对，有一个机会互相交流，不失为一个解压的方法。同时，在这种舆论下，社会给予光棍们的尖锐度也在下降，让单身人士能够有更大的空间选择自己的真爱。",
                    "",
                    "如今光棍节已经成为都市年轻人一个特别的日子。在这天，几个单身朋友在一起或喝酒谈心，或K歌泡吧，或疯狂购物，尽情狂欢，体会“只羡光棍不羡仙”的感觉。光棍节的热闹聚会，是年轻人渴望爱情的一种热烈表达。据悉，社会上也有年轻人特意将婚礼定在这一天，就是为了赶上4个“1”的吉利，希望这“一心一意的爱，一生一世的情”能带给他们幸福。"
                ]
            },
            {
                "title": "差异",
                "detail": [
                    "校园篇",
                    "",
                    "光棍节到来之时有关“光棍节”聚会的消息渐渐增多，虽然主体活动依旧是饭局，但强调“AA制”似乎成为这个节日个性独立、彼此尊重的一个标志。",
                    "",
                    "年龄篇",
                    "",
                    "光棍没有确切的年龄限制，只要自己愿意，只要是单身都可以把自己称为是光棍，并不局限年龄，确切的说光棍与单身是符合实际情况后自己心里的一种感觉。",
                    "",
                    "性别篇",
                    "",
                    "光棍节并不是男性独有的节日，女性也有很多很多单身光棍的，男女平等，一视同仁，光棍节是单身男女共同的节日。",
                    "",
                    "网络篇",
                    "",
                    "让接受新事物的网民更清楚的了解这个节日，并且因为网络快捷方便的传播方式让更多的单身族加入过光棍节的队伍来，部分单身网友为此节日聚会等等。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "记者节",
        "short_desc": "你宏亮的声音，击碎了丑恶，铸就了美善，回荡在天南地北",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日别称",
                        "value": "中国记者节"
                    },
                    {
                        "title": "节日时间",
                        "value": "11月8日"
                    },
                    {
                        "title": "节日类型",
                        "value": "行业节日"
                    }
                ],
                "detail": [
                    "2000年，国务院正式批复中国记协《关于确定“记者节”具体日期的请示》，同意将中国记协的成立日11月8日定为记者节。记者节像护士节、教师节一样，是我国仅有的三个行业性节日之一。按照国务院的规定，记者节是一个不放假的工作节日。"
                ]
            },
            {
                "title": "来源",
                "detail": [
                    "新中国成立前就有记者节。从1933年到1949年，每年的11月8日，新闻从业人员都举行各种仪式纪念这一节日。",
                    "",
                    "1949年12月23日，中华人民共和国政务院颁布的《全国年节及纪念日放假办法》中明确规定了“记者节”，《办法》对年节和纪念日放假规定这样表述：“其他各种纪念节日如：二七纪念、五卅纪念、七七抗战纪念、八一五抗战胜利纪念、九一八纪念、护士节、教师节、记者节等，均不必放假。”但因为当时没有确定具体日期，因此长期以来中国新闻从业人员一直未过记者节。",
                    "",
                    "1999年9月18日颁布的《全国年节及纪念日放假办法》，再一次明确列入了记者节。2000年8月，国务院正式批复中国记协的请示，同意将11月8日定为记者节。也是中国记协的成立日。1937年11月8日，以范长江为首的左翼新闻工作者在上海成立中国青年记者协会，这是中国记协的前身。"
                ]
            },
            {
                "title": "各国节日",
                "detail": [
                    "记者节，有些国家又称新闻节、出版节。",
                    "",
                    "前苏联的出版节是5月5日。1922年3月俄共（布）第十一次代表大会作出决议，以《真理报》创刊日（5月5日）为全俄出版节。该报于俄历1912年4月12日创刊，俄历与公历相差13天，换算为公历，便是5月5日。苏维埃俄国从1913年起改用公历纪元。",
                    "",
                    "韩国的新闻节是4月7日。1896年4月7日，朝鲜医生徐弼博士在汉城创办朝鲜第一家民营报纸《独立新闻》，初为周三刊，两年后改为日报。为纪念朝鲜第一家民营报纸的诞生，韩国建国后将4月7日这一天定为韩国的新闻节。",
                    "",
                    "1990年前的匈牙利，其记者节是12月7日。这是为了纪念1918年的这一天诞生了匈牙利共产党的第一家报纸《红色权利报》。",
                    "",
                    "旧中国的记者节是9月1日，1934年杭州市记者公会通电全国，要求定9月1日为记者节，以纪念国民政府1933年9月1日颁布《保护新闻工作人员及维护舆论机关》的命令一事。由于得到了全国新闻界的赞同，国民政府承认9月1日为中国记者节。陕甘宁边区建立后，延安的新闻工作者每年都在这一天举行纪念活动。1943年9月1日，重庆《新华日报》为纪念记者节发表社论《记者节谈记者作风》；为纪念记者节，陆定一同志这一天在延安《解放日报》发表文章《我们对于新闻学的基本观点》。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "国庆节",
        "short_desc": "举国上下齐欢畅，家和国盛万事兴",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "10月1日"
                    },
                    {
                        "title": "节日意义",
                        "value": "纪念国家本身"
                    },
                    {
                        "title": "节日象征",
                        "value": "国家独立"
                    },
                    {
                        "title": "纪念方式",
                        "value": "大规模、全民性庆典活动"
                    }
                ],
                "detail": [
                    "1949年12月3日，中央人民政府委员会第四次会议接受全国政协的建议，通过了《关于中华人民共和国国庆日的决议》，决定每年10月1日为中华人民共和国宣告成立的伟大日子，为中华人民共和国国庆日。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "国庆节是由一个国家制定的用来纪念国家本身的法定假日。",
                    "它们通常是这个国家的独立、宪法的签署、元首诞辰或其他有重大纪念意义的周年纪念日；也有些是这个国家守护神的圣人节。",
                    "",
                    "虽然绝大部分国家都有类似的纪念日，但是由于复杂的政治关系，部分国家的这一节日不能够称为国庆日，比如美国只有独立日，没有国庆日，但是两者意义相同。而中国古代把皇帝即位、诞辰称为“国庆”。"
                ]
            },
            {
                "title": "活动",
                "detail": [
                    "假期安排",
                    "",
                    "1999年起国庆节是中国大陆的“黄金周”假期。国庆的法定休假时间为3天，再将前后两个周末调整为一起休假共计7天；中国大陆海外机构及企业则为3-7日；澳门特别行政区为2日，香港特别行政区为1日。",
                    "",
                    "截至2014年，中国国务院办公厅节假日安排的通知10月1日至7日放假调休，共7天。9月28日（星期日）、10月11日（星期六）上班。",
                    "",
                    "阅兵仪式",
                    "",
                    "每逢五、十周年会有不同规模的庆典和阅兵，历史上影响较大且最具代表意义的是开国大典、建国5周年、10周年、35周年和50周年、60周年的六次大阅兵。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "国庆日，各国都要举行不同形式的庆祝活动，以加强本国人民的爱国意识，增强国家的凝聚力。各国之间也都要相互表示祝贺。逢五逢十的国庆日，有的还要扩大庆祝规模。",
                    "",
                    "为庆祝国庆日，各国政府通常要举行一次国庆招待会，由国家元首、政府首脑或外交部长出面主持，邀请驻在当地的各国使节和其他重要外宾参加。但也有的国家不举行招待会，如美国、英国均不举行招待会。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "粮食日",
        "short_desc": "珍惜一菜一饭，深知来之不易",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "10月16日"
                    },
                    {
                        "title": "起源时间",
                        "value": "1979年11月"
                    },
                    {
                        "title": "节日活动",
                        "value": "黎巴嫩厅发布“食物权方法工具箱”等"
                    }
                ],
                "detail": [
                    "世界粮食日（World Food Day，缩写为WFD），是世界各国政府每年在10月16日围绕发展粮食和农业生产举行纪念活动的日子。1979年11月，第20届联合国粮农组织（Food and Agricultural Organization of the United Nations，FAO)大会决议确定，1981年10月16日是首届世界粮食日，此后每年的这一天都作为“世界粮食日”。"
                ]
            },
            {
                "title": "来源",
                "detail": [
                    "1972年，由于连续两年气候异常造成的世界性粮食歉收，加上苏联大量抢购谷物，出现了世界性粮食危机。联合国粮农组织于1973年和1974年相继召开了第一次和第二次粮食会议，以唤起世界，特别是第三世界注意粮食及农业生产问题。但是，问题并没有得到解决，世界粮食形势更趋严重。关于“世界粮食日”的决议正是在这种背景下做出的。",
                    "",
                    "选定10月16日作为世界粮食日因为联合国粮农组织创建于1945年10月16日。联合国粮农组织在关于世界粮食日的决议中要求，各国政府在每年10月16日要组织举办各种多样、生动活泼的庆祝活动。"
                ]
            },
            {
                "title": "宗旨",
                "detail": [
                    "促进人们重视农业粮食生产，为此激励国家、双边、多边及非政府各方作出努力；",
                    "鼓励发展中国家开展经济和技术合作；",
                    "鼓励农村人民，尤其是妇女和最不利群体参与影响其生活条件的决定和活动；",
                    "增强公众对于世界饥饿问题的意识；",
                    "促进向发展中国家转让技术；",
                    "加强国际和国家对战胜饥饿、营养不良和贫困的声援，关注粮食和农业发展方面的成就。"
                ]
            },
            {
                "title": "意义",
                "detail": [
                    "在人类居住的地球上，不仅动植物，而且农作物的品种也在日益减少。古代先农们种植过多达数千种的农作物，而只有大约150种被广泛种植，成为人们主要的食物来源。其中，玉米、小麦、水稻约占60%，而大多数其它农作物品种已处于灭绝的边缘。随着农作物品种日趋单一和世界人口爆炸性增长，全世界粮食供应正变得日益脆弱。",
                    "",
                    "自马尔萨斯于1798年发表《人口论》，提出人口增长将超过生活资料生产的观点之后，人们对他的预言持不同观点。1968年，保罗.爱赫利奇发表了《人口炸弹》；1972年，罗马俱乐部发表了《增长的极限》。这两部著作都进一步表示担心说，无限制的人口增长将导致大规模的饥荒。对这种观点也有人持不同观点，认为：人不仅仅消费，而且还能生产出比消费多得多的东西。",
                    "",
                    "70年代末，美国华盛顿世界观察研究所的来斯特.布朗争辩说，世界各地的农场主和农民已经用尽了能够提高产量的办法，但稻谷和小麦的产量正开始下降。在亚洲的其它地区，水稻研究人员20多年来也未能大幅度地提高作物产量。",
                    "",
                    "2009年，世界人口正以每年9100万的速度增长，地球提供给人们“足够”粮食的局面还能维持多久，许多人正以焦虑的心情在进行研究。许多国家政府对于举办“世界粮食日”活动都很重视。有的国家首脑在这一天发表演讲，有的国家举行纪念会或发表纪念文章，有的国家科研机构发表粮食和农业科研成果，举办科学讨论会等，以提高人们对粮食以及粮食引发的一系列问题的重视和研究。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "万圣夜",
        "short_desc": "即使被命运捉弄，但请相信，糖果必会到来",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日别名",
                        "value": "万圣节前夜"
                    },
                    {
                        "title": "节日时间",
                        "value": "每年10月31日"
                    },
                    {
                        "title": "节日意义",
                        "value": "赞美秋天，祭祀亡魂，祈福平安"
                    }
                ],
                "detail": [
                    "万圣节前夜（英语：Halloween，为“All Hallow's Eve”的缩写），简称“万圣夜”，万圣节（All Hallow's Day）的前一天，即每年的10月31日，是西方世界的传统节日，庆祝活动一般在晚上。主要流行于撒克逊人后裔云集的美国、不列颠群岛、澳大利亚、加拿大和新西兰等西方国家。当晚小孩会穿上化妆服，戴上面具，挨家挨户收集糖果等。"
                ]
            },
            {
                "title": "来源",
                "detail": [
                    "两千多年前，欧洲的基督教会把11月1日定为“天下圣徒之日”（All Hallows’ Day）。“Hallow”即圣徒之意。传说自公元前五百年，居住在爱尔兰、苏格兰等地的凯尔特人（Celts）把这节日往前移了一天，即10月31日。他们认为该日是夏天正式结束的日子，也就是新年伊始，严酷的冬天开始的一天。",
                    "",
                    "那时人们相信，故人的亡魂会在这一天回到故居地在活人身上找寻生灵，借此再生，而且这是人在死后能获得再生的唯一希望。而活人则惧怕死人的魂灵来夺生，于是人们就在这一天熄掉炉火、烛光，让死人的魂灵无法找到活人，又把自己打扮成妖魔鬼怪把死人的魂灵吓走。之后，他们又会把火种、烛光重新燃起，开始新的一年的生活。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "万圣节前夜是一年中最“闹鬼”的时候，各种妖魔鬼怪、海盗、外星来客和巫婆们纷纷出动。在基督纪元以前，凯尔特人在夏末举行仪式感激上苍和太阳的恩惠。当时的占卜者点燃并施巫术以驱赶据说在四周游荡的妖魔鬼怪。",
                    "",
                    "后来罗马人用果仁和苹果来庆祝的丰收节与凯尔特人的10月31日融合了。在中世纪，人们穿上动物造型的服饰、戴上可怕的面具是想在万圣节前夜驱赶黑夜中的鬼怪。尽管后来基督教代替了凯尔特和罗马的宗教活动，早期的习俗还是保留下来了。孩子们带着开玩笑的心理穿戴上各种服饰和面具参加万圣夜舞会，这些舞会四周的墙上往往悬挂着用纸糊的巫婆、黑猫、鬼怪和尸骨，窗前和门口则吊着龇牙咧嘴或是面目可憎的南瓜灯笼。",
                    "",
                    "万圣节前夕，孩子们会提着南瓜灯，穿着各式各样的稀奇古怪的服装，挨家挨户地去索要糖果，不停地说：“trick or treat”（意思是：“给不给，不给就捣蛋。”）要是你不肯给糖果的话，孩子们就会很生气，用各种方法去惩罚你，例如：把垃圾倒在你家里等等的方法去惩罚你，直到你肯给他们糖果为止。",
                    "",
                    "而对传统而言人们会扮成精灵，然后逐家逐户去恳求食物。他们的信念是要给予精灵崇拜及食物来讨好它们，否则这些精灵就会捉弄他们，诸如：烟囱堵塞，牛羊失丧﹑被掷黄芽白等等。另一个途径去愚弄或吓跑这些恶灵，就是扮成它们的样子，他们相信这样那些恶灵就不会伤害他们。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "辛亥革命",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "爆发时间",
                        "value": "1911年（辛亥年）10月10日"
                    },
                    {
                        "title": "结束时间",
                        "value": "1912年2月12日"
                    },
                    {
                        "title": "主要人物",
                        "value": "孙文、黄兴 、蒋翊武、黎元洪、袁世凯"
                    }
                ],
                "detail": [
                    "辛亥革命，是指发生于中国农历辛亥年（清宣统三年），即公元1911年至1912年初，旨在推翻清朝专制帝制、建立共和政体的全国性革命。"
                ]
            },
            {
                "title": "结果",
                "detail": [
                    "清帝退位",
                    "",
                    "1912年2月2日，清廷举行御前会议，会上决定退位，以取得革命党人的优待条件。",
                    "",
                    "袁世凯上台",
                    "",
                    "1912年2月13日，孙中山提出辞呈，向临时参议院推荐袁世凯接任。2月15日临时参议院选袁世凯任临时大总统，议决临时政府仍设在南京，并电袁前来受职；未受任前，政务仍由孙中山继续执行。"
                ]
            },
            {
                "title": "影响",
                "detail": [
                    "废除帝制",
                    "",
                    "辛亥革命以反对君主专制制度、建立资产阶级共和国为目标，是一次比较完全意义上的资产阶级民主革命。正如毛泽东指出的:“中国反帝反封建的资产阶级民主革命，正规地说起来，是从孙中山开始的。”辛亥革命的领袖们，则认识到要救国必须革命，要想救亡图存，只能是推翻封建皇权。",
                    "",
                    "思想解放",
                    "",
                    "辛亥革命冲破了封建思想的牢笼。自汉代董仲舒以来的中国思想文化，君臣关系是“三纲五常”中三纲之首。皇帝不仅是政治上的权威，也是文化中诸多价值观念的重要依据与合法性的来源。辛亥革命不但从行动上坚决地打倒皇帝，并从舆论上对君权神授观念和皇权思想进行鞭挞和批判，极大地促进了人民的思想解放，为探索救国兴邦的道路打开了新的思想境界。",
                    "",
                    "除旧布新",
                    "",
                    "辛亥革命促进了社会习俗的除旧布新。辛亥革命的实际政治影响远远超出了人们的预料。政治体制的革故鼎新带来了民国初年的中国社会面貌为之一新。正是这些新的变化，打开了中国通向现代化的闸门。",
                    "",
                    "经济发展",
                    "",
                    "辛亥革命为民族资本主义的发展创造了有利的条件。民国建立以后，国内实业集团纷纷成立，开工厂、设银行成为风气。民族资本主义的经济力量在短短的几年内就有了显著的增长，无产阶级队伍也迅速壮大起来。",
                    "",
                    "此外，从辛亥革命各省独立响应开始，中国进入长期的分裂混乱之中，除了袁世凯曾在二次革命之后曾短暂大致统一全国之外，其他中华民国的政权都未能直接统治整个中国(如号称统一的国民政府只能收到五个省的税收)，一直要到1950年代，才由中国共产党使中国大陆再度统一。长期的分裂及战乱，对于中国的经济发展及现代化建设造成很大的阻碍。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "重阳节",
        "short_desc": "九九重阳节，浓浓敬老情",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日别称",
                        "value": "登高节、晒秋节、重九节、九九重阳等"
                    },
                    {
                        "title": "节日时间",
                        "value": "农历九月初九日"
                    },
                    {
                        "title": "节日起源",
                        "value": "祭祀、自然崇拜"
                    },
                    {
                        "title": "节日饮食",
                        "value": "重阳糕、菊花酒"
                    }
                ],
                "detail": [
                    "重阳节早在战国时期就已经形成，自魏晋重阳气氛日渐浓郁，倍受历代文人墨客吟咏，到了唐代被正式定为民间的节日，此后历朝历代沿袭至今。1989年农历九月九日被定为老人节，倡导全社会树立尊老、敬老、爱老、助老的风气。2006年5月20日，重阳节被国务院列入首批国家级非物质文化遗产名录。"
                ]
            },
            {
                "title": "起源",
                "detail": [
                    "重阳的源头，可追溯到先秦之前。《吕氏春秋》之中《季秋纪》载：“（九月）命家宰，农事备收，举五种之要。藏帝籍之收于神仓，祗敬必饬。”“是日也，大飨帝，尝牺牲，告备于天子。”可见当时已有在秋九月农作物丰收之时祭飨天帝、祭祖，以谢天帝、祖先恩德的活动。",
                    "",
                    "汉代，《西京杂记》中记西汉时的宫人贾佩兰称：“九月九日，佩茱萸，食蓬饵，饮菊花酒，云令人长寿。”相传自此时起，有了重阳节求寿之俗。这是受古代巫师（后为道士）追求长生，采集药物服用的影响。同时还有大型饮宴活动，是由先秦时庆丰收之宴饮发展而来的。《荆楚岁时记》云：“九月九日，四民并籍野饮宴。”隋杜公瞻注云：“九月九日宴会，未知起于何代，然自驻至宋未改。”求长寿及饮宴，构成了重阳节的基础。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "登高",
                    "",
                    "重阳节首先有登高的习俗。金秋九月，天高气爽，这个季节登高远望可达到心旷神怡、健身祛病的目的。早在西汉，《长安志》中就有汉代京城九月九日时人们游玩观景之记载。在东晋时，有著名的“龙山落帽”故事。",
                    "",
                    "吃重阳糕",
                    "",
                    "据史料记载，重阳糕又称花糕、菊糕、五色糕，制无定法，较为随意。 九月九日天明时，以片糕搭儿女头额，口中念念有词，祝愿子女百事俱高，乃古人九月作糕的本意。讲究的重阳糕要作成九层，像座宝塔，上面还作成两只小羊，以符合重阳（羊）之义。有的还在重阳糕上插一小红纸旗，并点蜡烛灯。这大概是用“点灯”、“吃糕”代替“登高”的意思，用小红纸旗代替茱萸。当今的重阳糕，仍无固定品种，各地在重阳节吃的松软糕类都称之为重阳糕。",
                    "",
                    "赏菊",
                    "",
                    "重阳日，历来就有赏菊花的风俗，所以古来又称菊花节。农历九月俗称菊月，节日举办菊花大会，倾城的人潮赴会赏菊。从三国魏晋以来，重阳聚会饮酒、赏菊赋诗已成时尚。在中国古俗中，菊花象征长寿。",
                    "",
                    "饮菊花酒",
                    "",
                    "菊花含有养生成分，晋代葛洪《抱朴子》有南阳山中人家饮用遍生菊花的甘谷水而益寿的记载。重阳佳节饮菊花酒，是中国的传统习俗。菊花酒，在古代被看作是重阳必饮、祛灾祈福的“吉祥酒”。",
                    "",
                    "菊花酒汉代已见。其后仍有赠菊祝寿和采菊酿酒的故事，如魏文帝曹丕曾在重阳日赠菊给钟繇（祝他长寿），梁简文帝《采菊篇》有“相呼提筐采菊珠，朝起露湿沾罗懦”之句，是采菊酿酒的事例。直到明清，菊花酒仍然盛行，在明代高濂的《遵生八笺》中仍有记载，是盛行的健身饮料。",
                    "",
                    "佩茱萸",
                    "",
                    "古代还风行九九插茱萸的习俗，所以又叫做茱萸节。茱萸入药，可制酒养身祛病。插茱萸和簪菊花在唐代就已经很普遍。茱萸香味浓，有驱虫去湿、逐风邪的作用，并能消积食，治寒热。民间认为九月初九也是逢凶之日，多灾多难，所以在重阳节人们喜欢佩带茱萸以辟邪求吉。茱萸因此还被人们称为“辟邪翁”。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "爱牙日",
        "short_desc": "爱牙护齿保健康，生命质量有保障",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "每年的9月20日"
                    },
                    {
                        "title": "设立机构",
                        "value": "卫生部、教委等部"
                    },
                    {
                        "title": "节日宗旨",
                        "value": "提高全民族的口腔健康水平"
                    }
                ],
                "detail": [
                    "1989年，由卫生部、教委等部委联合签署，确定每年的9月20日为全国的爱牙日。 宗旨是通过爱牙日活动，来广泛动员社会的力量，在群众中进行牙病防治知识的普及教育，增强口腔健康观念和自我口腔保健的意识，建立口腔保健行为，从而提高全民族的口腔健康水平。 建立爱牙日是为了加强口腔预防工作，落实预防为主方针的重要举措！健康每一天，从爱牙开始！"
                ]
            },
            {
                "title": "历年主题",
                "detail": [
                    "1989年：人人刷牙，早晚刷牙，正确刷牙！用保健牙刷和含氟牙膏刷牙。",
                    "1990年：爱牙健齿强身。",
                    "1991年：爱护牙齿从小做起。",
                    "1992年：爱护牙齿，从小做起，从我做起。",
                    "1993年：天天刷牙，定期检查。",
                    "1994年：健康的生活需要口腔卫生。",
                    "1995年：适量用氟，预防龋齿。",
                    "1996年：少吃含糖食品，有益口腔健康。",
                    "1997年：爱牙健齿强身，预防龋病。牙周疾病，健康的牙齿伴你一生。",
                    "1998年：健康的牙齿，美好的微笑。",
                    "1999年：老年人的口腔保健。",
                    "2000年：善待牙齿。",
                    "2001年：吸烟与口腔健康 。",
                    "2002年：预防牙周疾病 维护口腔健康 。",
                    "2003年：有效刷牙 预防牙周疾病。",
                    "2004年：口腔健康与生命质量。",
                    "2005年：关注孕妇口腔健康。",
                    "2006年：婴幼儿口腔保健 。",
                    "2007年：面向西部，面向儿童 。",
                    "2009年：维护口腔健康，提高生命质量。",
                    "2010年：窝沟封闭，保护牙齿。",
                    "2011年：健康口腔，幸福家庭。",
                    "2012年：健康口腔，幸福家庭；关爱自己，保护牙周。",
                    "2013年：健康口腔，幸福家庭；关爱老人，修复失牙。",
                    "2014年：健康每一天，从爱牙开始。",
                    "2015年：定期口腔检查，远离口腔疾病。",
                    "2016年：口腔健康，全身健康。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "教师节",
        "short_desc": "一方黑板朝朝暮暮绘宏图，三尺讲台岁岁年年育英才",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "英文名",
                        "value": "Teachers’ Day"
                    },
                    {
                        "title": "节日时间",
                        "value": "每年的9月10日"
                    },
                    {
                        "title": "节日意义",
                        "value": "肯定教师为教育事业所做的贡献"
                    }
                ],
                "detail": [
                    "在中国近现代史上，多次以不同的日期作为过教师节。直至1985年，第六届全国人大常委会第九次会议通过了国务院关于建立教师节的议案，才真正确定了1985年9月10日为中国第一个教师节。"
                ]
            },
            {
                "title": "演变历史",
                "detail": [
                    "1931年，教育家邰爽秋、程其保等联络京、沪教育界人士，在南京中央大学集会，发表要求“改善教师待遇，保障教师工作和增进教师修养”的宣言，并议定6月6日为教师节，也称双六节。这个教师节没有被当时的国民党政府承认，但在中国各地产生了一定影响。",
                    "",
                    "1939年国民党政府决定立孔子诞辰日8月27日为教师节，并颁发了《教师节纪念暂行办法》，但当时未能在全国推行",
                    "",
                    "建国后，中央人民政府曾恢复6月6日为教师节，教育部通告各地教育工作者，可以根据实际情况自行组织庆祝活动。",
                    "",
                    "1951年，中华人民共和国教育部和中华全国总工会共同商定，把5月1日国际劳动节作为中国教师节。但由于这一天缺少教师的特点，执行的结果并不理想。特别是1957年以后，在左倾思想影响下，教师不受重视，教师节实际上已不再存在。",
                    "",
                    "1981年3月，中国人民政治协商会议第五届全国委员会第四次会议上，中国民主促进会的17位政协委员联名提交了一份提案：建议确定全国教师节日期及活动内容案。提案指出，教师担负着培养四化建设人才的重任，应当享有崇高的社会地位。",
                    "",
                    "1982年4月，教育部党组和全国教育工会分党组联合，由张承先和方明共同签发的“关于恢复‘教师节’的请示报告”送中央书记处，报告中并建议以马克思的诞辰日5月5日为教师节。",
                    "",
                    "1983年3月全国政协六届一次会议上，方明和民进18位政协委员联名再次提出“为提高教师的社会地位，造成尊师重教的社会风尚，建议恢复教师节案”。9月，中宣部办公厅致函教育部办公厅，经研究政协一次会议方明等同志的提案，同意恢复教师节。12月，由教育部何东昌部长和方明共同签发的教育部党组和全国教育工会分党组“关于恢复‘教师节’的请示”送中央宣传部。",
                    "",
                    "1984年12月15日，北京师范大学钟敬文、启功、王梓坤、陶大镛、朱智贤、黄济、赵擎寰联名，正式提议设立教师节。教育部党组和全国教育工会分党组“关于建立‘教师节’的报告”送中央书记处并报国务院。",
                    "",
                    "1985年1月，全国人大常委会通过了这一议案，确定每年的9月10日为教师节。"
                ]
            },
            {
                "title": "庆祝方式",
                "detail": [
                    "由于教师节并非中国传统节日，所以各地每年都会有不同的庆祝活动，没有统一、固定的形式。",
                    "政府、学校方面，有举行教师节庆祝表彰大会，为教师颁发奖金、证书；有组织学校学生、歌舞团等，为教师献上歌舞表演；有走访、慰问教师代表，还有组织新入职教师进行集体宣誓等活动。",
                    "学生方面，有自发通过原创参与、将祝福写在板报、贺卡、绘画上；有将合影照及活动感言晒至个人空间、微博上，来表达对教师的真挚祝福及衷心问候。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "九一八纪念日",
        "short_desc": "九一八事变需牢记，振兴中华从我做起",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                    "title": "别称",
                    "value": "沈阳事变、满洲事变"
                }],
                "detail": [
                    "九一八事变——1931年9月18日，日本驻中国东北地区的关东军突然袭击沈阳，以武力侵占东北的事件。"
                ]
            },
            {
                "title": "背景",
                "detail": [
                    "19世纪末至20世纪前半叶，日本逐步确定了征服世界必先征服中国，征服中国必先征服“满蒙”的战略方针。1930年，世界资本主义经济危机波及日本，为了转移日益激化的国内阶级矛盾，日本加快了武力侵华的步伐，于1931年7月和8月在东北制造了“万宝山事件”和“中村事件”。9月18日，日本又制造“柳条湖事件”，发动了侵略中国东北的战争。",
                    "",
                    "当晚10时许，日本关东军岛本大队川岛中队河本末守中尉率部下数人，在沈阳北大营南约800米的柳条湖附近，将南满铁路一段路轨炸毁。日军在此布置了一个假现场，摆了3具身穿中国士兵服的尸体，反诬是中国军队破坏铁路。日军独立守备队第二大队即向中国东北军驻地北大营发动进攻。次日晨4时许，日军独立守备队第五大队由铁岭到达北大营加入战斗。5时半，东北军第七旅退到沈阳东山嘴子，日军占领北大营。战斗中东北军伤亡300余人，日军伤亡24人。这就是震惊中外的九一八事变。"
                ]
            },
            {
                "title": "影响",
                "detail": [
                    "1931年的9月18日，炮声轰隆，炸毁了南满铁路，炸开了沈阳城。这一炸，在中国人心里烙上了一道永恒的伤疤。这一天，成为中国人民14年艰苦卓绝的抗日战争的历史起点。81年前的今天，日本关东军制造沈阳事变，拉开了其漫长侵华战争的序幕，国人痛失东北，三千万父老，数千里江山，沦陷于异族的铁蹄之下。“九一八”，就此成为国人的“国耻日”。",
                    "",
                    "九一八事变是日本军国主义发动了蓄谋已久的侵华战争。这场侵略战争给中国人民带来了空前的灾难和损失，也给后人留下了深刻的历史血鉴。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "抗日战争胜利纪念日",
        "short_desc": "铭记历史，珍视和平，愿祖国繁荣",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "9月3日"
                    },
                    {
                        "title": "节日起源",
                        "value": "世界反法西斯战争胜利"
                    },
                    {
                        "title": "节日活动",
                        "value": "反法西斯战争胜利70周年阅兵式"
                    },
                    {
                        "title": "设立机构",
                        "value": "十二届全国人大常委会第七次会议"
                    }
                ],
                "detail": [
                    "1945年9月2日，日本向盟军投降仪式在东京湾密苏里号军舰上举行。在包括中国在内的9个受降国代表注视下，日本在投降书上签字。这是中国近代以来反侵略历史上的第一次全面胜利，也为世界反法西斯战争的胜利做出了巨大贡献。之后每年的9月3日，被确定为中国人民抗日战争胜利纪念日。"
                ]
            },
            {
                "title": "背景",
                "detail": [
                    "1931年9月18日，日本侵略者发动“九一八事变”，进而侵占中国东北。1937年7月7日，日本侵略者炮轰宛平县城、进攻卢沟桥，发动全面侵华战争。中国人民毅然奋起，英勇抵抗。在中国共产党倡导建立的抗日民族统一战线的旗帜下，以国共合作为基础，全国各族人民包括港澳台同胞、海外侨胞同日本侵略者进行了历时十四年的艰苦卓绝的斗争。",
                    "1945年9月2日上午9时（东京时间），同盟国联合受降典礼在停泊于东京湾的美国军舰“密苏里”号上隆重举行。日本政府全权代表和大本营全权代表分别在投降书上签字，美国、中国、英国、苏联、法国和荷兰、澳大利亚、加拿大、新西兰的全权代表(中国代表为国民政府军事委员会军令部部长徐永昌将军)在日本投降书上签字确认，接受日本投降，投降书即刻宣告生效。至此，中国人民抗日战争胜利结束，宣告日本帝国主义彻底失败，世界反法西斯战争取得了完全胜利。",
                    "1946年4月，中国国民党中常会决议将庆祝胜利的9月3日，定为抗战胜利纪念日（“国定纪念日”）。 中华人民共和国成立后，1949年12月23日，中央人民政府政务院即公布了《全国年节及纪念日放假办法》，规定了“八一五抗战胜利纪念日”。1951年8月13日，中央人民政府政务院发布通告，将抗战胜利纪念日改定为9月3日。1999年9月18日，国务院对《全国年节及纪念日放假办法》进行修订，延续了9月3日为抗战胜利纪念日的规定。"
                ]
            },
            {
                "title": "意义",
                "detail": [
                    "全国人大常委会以立法形式确立纪念日，集中反映了中国人民的共同意志，表明了中国人民坚决维护国家主权和领土完整的坚定立场，表明了中国人民反对侵略战争、捍卫人类尊严、维护世界和平的坚定立场。依照全国人大常委会的决定，隆重地举行法定的、国家层面的纪念和悼念活动，目的是要牢记中国人民抗日战争的伟大意义，充分认识中国人民抗日战争在世界反法西斯战争中的重要地位和巨大贡献，充分认识中国人民抗日战争胜利为实现民族独立和人民解放奠定的重要基础，永远铭记中国人民反抗日本帝国主义侵略的艰苦卓绝斗争；牢记日本帝国主义侵略给中国人民和世界人民造成的深重灾难，警醒全世界人民时刻警惕日本为军国主义侵略历史翻案，维护第二次世界大战胜利成果和确立的战后国际秩序；弘扬以爱国主义为核心的伟大民族精神，激励全国各族人民为实现中华民族伟大复兴的中国梦、促进人类和平与发展的崇高事业而共同奋斗。",
                    "",
                    "在中华民族的复兴征程上，这是两个具有特殊意义的日子。一个是1945年9月2日日本政府正式签订投降书，9月3日举国同庆，中国人民取得抗日战争的伟大胜利，也标志着世界反法西斯战争的完全胜利。一个是1937年12月13日，三十多万同胞惨遭日本侵略者杀戮。全国人大常委会首次以立法形式将这两个日子分别确定和设立为国家层面的纪念日。这一将重大纪念活动制度化、规范化的国家行动，集中反映了全体中国人民的共同意愿和心声。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "中国烈士纪念日",
        "short_desc": "铭记烈士，感恩烈士",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "英文名",
                        "value": "Chinese Martyrs Day"
                    },
                    {
                        "title": "节日时间",
                        "value": "9月30日"
                    },
                    {
                        "title": "节日意义",
                        "value": "纪念中国烈士"
                    },
                    {
                        "title": "设定时间",
                        "value": "2014年8月25日"
                    }
                ],
                "detail": [
                    "2014年8月25日下午，中国十二届全国人大常委会第十次会议听取国务院关于提请审议《关于设立烈士纪念日的决定(草案)》的议案。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "近代以来，为了争取民族独立和人民自由幸福，为了国家繁荣富强，无数的英雄献出了生命，烈士的功勋彪炳史册，烈士的精神永垂不朽。为了弘扬烈士精神，缅怀烈士功绩，培养公民的爱国主义、集体主义精神和社会主义道德风尚，培育和践行社会主义核心价值观，增强中华民族的凝聚力，激发实现中华民族伟大复兴中国梦的强大精神力量，第十二届全国人民代表大会常务委员会第十次会议决定：将9月30日设立为烈士纪念日。每年9月30日国家举行纪念烈士活动。"
                ]
            },
            {
                "title": "意义",
                "detail": [
                    "缅怀烈士功绩、弘扬烈士精神，培养公民的爱国主义、集体主义精神和社会主义道德风尚，传承中华民族气节血脉，培育和践行社会主义核心价值观，增强中华民族的凝聚力，激发实现中华民族伟大复兴中国梦的强大精神力量。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "中秋节",
        "short_desc": "海上生明月，天涯共此时",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "别称",
                        "value": "团圆节，秋夕，八月节等"
                    },
                    {
                        "title": "节日时间",
                        "value": "农历八月十五日"
                    },
                    {
                        "title": "节日习俗",
                        "value": "赏月、祭月、吃月饼等"
                    },
                    {
                        "title": "流传地区",
                        "value": "中国众多民族与汉字文化圈诸国"
                    }
                ],
                "detail": [
                    "中秋节始于唐朝初年，盛行于宋朝，至明清时，已成为与春节齐名的中国主要节日之一。受中华文化的影响，中秋节也是东亚和东南亚一些国家尤其是当地的华人华侨的传统节日。自2008年起中秋节被列为国家法定节假日。2006年5月20日，国务院列入首批国家级非物质文化遗产名录。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "关于中秋节的起源，说法较多。中秋一词，最早见于《周礼》，《礼记·月令》上说：“仲秋之月养衰老，行糜粥饮食。”",
                    "",
                    "一说它起源于古代帝王的祭祀活动。《礼记》上记载：“天子春朝日，秋夕月”，夕月就是祭月亮，说明早在春秋时代，帝王就已开始祭月、拜月了。后来贵族官吏和文人学士也相继仿效，逐步传到民间。",
                    "",
                    "二是中秋节的起源和农业生产有关。秋天是收获的季节。“秋”字的解释是：“庄稼成熟曰秋”。八月中秋，农作物和各种果品陆续成熟，农民为了庆祝丰收，表达喜悦的心情，就以“中秋”这天作为节日。“中秋”就是秋天中间的意思，农历的八月是秋季中间的一个月，十五日又是这个月中间的一天，所以中秋节可能是古人“秋报”遗传下来的习俗。",
                    "",
                    "也有历史学家研究指出，中秋节起源应为隋末唐军于大业十三年八月十五日，唐军裴寂以圆月作为构思，成功发明月饼，并广发军中作为军饷，成功解决因大量吸收反隋义军而衍生之军粮问题。",
                    "",
                    "中秋节是中国的传统佳节。根据史籍的记载，“中秋”一词最早出现在《周礼》一书中。到魏晋时，有“谕尚书镇牛淆，中秋夕与左右微服泛江”的记载。直到唐朝初年，中秋节才成为固定的节日。《唐书·太宗记》记载有“八月十五中秋节”。中秋节的盛行始于宋朝，至明清时，已与元旦齐名，成为中国的主要节日之一。这也是中国仅次于春节的第二大传统节日。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "1.祭月、赏月、拜月",
                    "",
                    "2.猜谜",
                    "",
                    "中秋月圆夜在公共场所挂着许多灯笼，人们都聚集在一起，猜灯笼身上写的谜语，因为是大多数年轻男女喜爱的活动，同时在这些活动上也传出爱情佳话，因此中秋猜灯谜也被衍生了一种男女相恋的形式。",
                    "",
                    "3.吃月饼",
                    "",
                    "中秋节赏月赏月和吃月饼是中国各地过中秋节的必备习俗，俗话说：“八月十五月正圆，中秋月饼香又甜”。月饼一词，源于南宋吴自牧的《梦梁录》，那时仅是一种点心食品。到后来人们逐渐把赏月与月饼结合在一起，寓意家人团圆，寄托思念。同时，月饼也是中秋时节朋友间用来联络感情的重要礼物。",
                    "",
                    "4.赏桂花、饮桂花酒",
                    "",
                    "人们经常在中秋时吃月饼赏桂花，食用桂花制作的各种食品，以糕点、糖果最为多见。",
                    "中秋之夜，仰望着月中丹桂，闻着阵阵桂香，喝一杯桂花蜜酒，欢庆合家甜甜蜜蜜，已成为节日一种美的享受。到了现代，人们多是拿红酒代替。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "地球日",
        "short_desc": "地球是我们唯一的家园，爱护地球，让蓝天永驻，青山常在",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "每年的4月22日"
                    },
                    {
                        "title": "流行地区",
                        "value": "全球"
                    },
                    {
                        "title": "节日意义",
                        "value": "关爱地球"
                    }
                ],
                "detail": [
                    "中国从20世纪90年代起，每年都会在4月22日举办世界地球日活动。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "1969年美国民主党参议员盖洛德·尼尔森在美国各大学举行演讲会，筹划在次年的4月22日组织以反对越战为主题的校园运动，但是在1969年西雅图召开的筹备会议上，活动的组织者之一，哈佛大学法学院学生丹尼斯·海斯提出将运动定位在于全美国的，以环境保护为主题的草根运动。",
                    "",
                    "1969年盖洛·尼尔森提议，在全国各大学校园内举办环保问题讲演会，海斯听到这个建议后，就设想在剑桥市举办一次环保的演讲会。于是，他前往首都华盛顿去会见了尼尔森。年轻的海斯谈了自己的设想，尼尔森喜出望外，立即表示愿意任用海斯，甚至鼓动他暂时停止学业，专心从事环保运动。于是，海斯毅然办理了停学手续。不久，他就把尼尔森的构想扩大，办起了一个在美国各地展开的大规模的社区性活动。他选定1970年4月22日（ 星期三） 为第一个“ 地球日”。 就在那年的4月22日， 美国各地大约有2000万人参加了游行示威和演讲会。 ",
                    "",
                    "2009年4月22日，第63届联合国大会一致通过决议，决定将今后每年的4月22日定为“世界地球日”。"
                ]
            },
            {
                "title": "历年主题",
                "detail": [
                    "世界地球日没有国际统一的特定主题，它的总主题始终是“只有一个地球”。",
                    "2016 年 节约利用资源， 倡导绿色简约生活",
                    "2015 年 珍惜地球资源 转变发展方式—— 提高资源利用效益",
                    "2014 年 珍惜地球资源 转变发展方式—— 节约集约利用国土资源共同保护自然生态空间",
                    "2013 年 珍惜地球资源 转变发展方式—— 促进生态文明 共建美丽中国",
                    "2012 年 珍惜地球资源 转变发展方式—— 推进找矿突破， 保障科学发展",
                    "2011 年 珍惜地球资源 转变发展方式—— 倡导低碳生活",
                    "2010 年 珍惜地球资源， 转变发展方式， 倡导低碳生活。",
                    "2009 年 绿色世纪（ Green Generation）",
                    "2008 年 善待地球—— 从身边的小事做起",
                    "2007 年 善待地球—— 从节约资源做起",
                    "2006 年 善待地球—— 珍惜资源， 持续发展",
                    "2005 年 善待地球—— 科学发展， 构建和谐",
                    "2004 年 善待地球， 科学发展",
                    "2003 年 善待地球， 保护环境",
                    "2002 年 让地球充满生机",
                    "2001 年 世间万物， 生命之网",
                    "2000 年 2000 环境千年—— 行动起来吧！",
                    "1999 年 拯救地球， 就是拯救未来",
                    "1998 年 为了地球上的生命—— 拯救我们的海洋",
                    "1997 年 为了地球上的生命",
                    "1996 年 我们的地球、 居住地、 家园",
                    "1995 年 各国人民联合起来， 创造更加美好的世界",
                    "1994 年 一个地球， 一个家庭",
                    "1993 年 贫穷与环境—— 摆脱恶性循环",
                    "1992 年 只有一个地球—— 一齐关心， 共同分享",
                    "1991 年 气候变化—— 需要全球合作",
                    "1990 年 儿童与环境",
                    "1989 年 警惕， 全球变暖！",
                    "1988 年 保护环境、 持续发展、 公众参与",
                    "1987 年 环境与居住",
                    "1986 年 环境与和平",
                    "1985 年 青年、 人口、 环境",
                    "1984 年 沙漠化",
                    "1983 年 管理和处置有害废弃物； 防治酸雨破坏和提高能源利用率",
                    "1982 年 纪念斯德哥尔摩人类环境会议10周年—— 提高环境意识",
                    "1981 年 保护地下水和人类食物链； 防治有毒化学品污染",
                    "1980 年 新的10年， 新的挑战—— 没有破坏的发展",
                    "1979 年 为了儿童和未来—— 没有破坏的发展",
                    "1978 年 没有破坏的发展",
                    "1977 年 关注臭氧层破坏、 水土流失、 土壤退化和滥伐森林",
                    "1976 年 水： 生命的重要源泉",
                    "1975 年 人类居住",
                    "1974 年 只有一个地球"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "清明节",
        "short_desc": "清明时节雨纷纷，路上行人欲断魂",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "别称",
                        "value": "踏青节"
                    },
                    {
                        "title": "节日时间",
                        "value": "一般是在公历4月5号前后"
                    },
                    {
                        "title": "节日类型",
                        "value": "中国传统节日"
                    },
                    {
                        "title": "节日意义",
                        "value": "以扫墓、祭拜等形式纪念祖先"
                    }
                ],
                "detail": [
                    "清明节是中国三大鬼节之一。“鬼节”即是悼念亡人之节，是和祭祀天神、地神的节日相对而言的。中华民族传统的清明节大约始于周代，距今已有二千五百多年的历史。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "清明节的起源，据传始于古代帝王将相“墓祭”之礼，后来民间亦相仿效，于此日祭祖扫墓，历代沿袭而成为中华民族一种固定的风俗。",
                    "",
                    "清明最早只是一种节气的名称，其变成纪念祖先的节日与寒食节有关。寒食节是中国古代较早的节日，传说是在春秋时代为纪念晋国的忠义之臣介子推而设立的。",
                    "",
                    "秦汉时代，祭扫坟墓的风气更盛。据《汉书》记载，大臣严延年即使离京千里，也要定期还乡祭扫墓地。在唐代，不论士人还是平民，都将寒食节扫墓视为返本追宗的仪节，由于清明距寒食节很近，人们还常常将扫墓延至清明。",
                    "",
                    "宋元时期，清明节逐渐由附属于寒食节的地位，上升到取代寒食节的地位。这不仅表上坟扫墓等仪式多在清明举行，就连寒食节原有的风俗活动如冷食、蹴鞠、荡秋千等，也都被清明节收归所有了。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "清明节的习俗除了讲究禁火、扫墓，还有踏青、荡秋千、蹴鞠、打马球、插柳等一系列风俗体育活动。相传这是因为寒食节要寒食禁火，为了防止寒食冷餐伤身，所以大家来参加一些体育活动，来锻炼身体。清明节，民间忌使针，忌洗衣，大部分地区妇女忌行路。傍晚以前，要在大门前洒一条灰线，据说可以阻止鬼魂进宅。 因此，这个节日中既有祭扫新坟生离死别的悲酸泪，又有踏青游玩的欢笑声，是一个富有特色的节日。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "愚人节",
        "short_desc": "笑一笑十年少，“愚”人同乐",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "别称",
                        "value": "万愚节、幽默节"
                    },
                    {
                        "title": "节日时间",
                        "value": "公历4月1日"
                    },
                    {
                        "title": "节日意义",
                        "value": "对人生的一种幽默"
                    },
                    {
                        "title": "流行地区",
                        "value": "欧美地区"
                    }
                ],
                "detail": [
                    "在这一天人们以各种方式互相欺骗和捉弄，往往在玩笑的最后才揭穿并宣告捉弄对象为“愚人”。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "对于它的起源众说纷纭：一种说法认为这一习俗源自印度的“诠俚节”。该节规定，每年三月三十一日的节日这天，情侣之间互相愚弄欺骗来娱乐。较普遍的说法是起源于法国。1564年，法国首先采用新改革的纪年法——格里历(即通用的阳历)，以一月一日为一年的开端，改变了过去以四月一日作为新年开端的历法。新历法推行过程中，一些因循守旧的人反对这种改革仍沿袭旧历，拒绝更新。",
                    "",
                    "他们依旧在四月一日这天互赠礼物，组织庆祝新年的活动。主张改革的人对这些守旧者的做法大加嘲弄。聪明滑稽的人在四月一日这天给顽固派赠送假礼物，邀请他们参加假庆祝会，并把这些受愚弄的人称为“四月傻瓜”或“上钩之鱼”。以后，他们在这天互相愚弄，日久天长便成为法国流行的一种风俗。该节在十八世纪流传到英国，后来又被英国早期移民带到了美国。",
                    "",
                    "起初，任何美国人都可以炮制骇人听闻的消息，而且不负丝毫的道德和法律责任，政府和司法部门也不会追究。相反，谁编造的谎言最离奇、最能骗取人们相信，谁还会荣膺桂冠。这种做法给社会带来不少混乱，因而引起人们的不满。人们节日期间的愚弄欺骗已不再像过去那样离谱，而是以轻松欢乐为目的。",
                    "",
                    "愚人节这天玩笑只能开到中午12点之前，这是约定俗成的严格规矩。过了中午还找乐子的人是一个比被他取笑的人还大的傻瓜。过了钟点还开玩笑的人会立刻碰钉子，自找没趣儿。有一首小诗是这样描述的：愚人节已过十二点，你这个大傻瓜来得晚。待到来年愚人节，你将是最大个的大笨蛋。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "愚人节时，人们常常组织家庭聚会，用水仙花和雏菊把房间装饰一新。典型的传统做法是布置假的环境，可以把房间布置得像过圣诞节一样．也可以布置得像过新年一样，待客人来时，则祝贺他们“圣诞快乐”或“新年快乐”，令人感到别致有趣。",
                    "",
                    "在愚人节的聚会上，还有一种做假菜的风俗。有人曾经描述过一个典型的愚人节菜谱：先是一道“色拉”，莴苣叶上撒满了绿胡椒，但是把叶子揭开后，才发现下面原来是牡蛎鸡尾酒；第二道菜是\"烤土豆\"，其实下面是甜面包屑和鲜蘑；此后上的菜还有用蟹肉作伪装的烧鸡和埋藏在西红柿色拉下面的覆盆子冰淇淋。饭后，客人还可以从药丸盒里取食糖果。",
                    "",
                    "不过愚人节最典型的活动还是大家互相开玩笑，用假话捉弄对方。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "端午节",
        "short_desc": "棕子香，香厨房，艾叶香，香满堂，吃了端午粽，还要冻三冻",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "别称",
                        "value": "端阳节、午日节、龙舟节等"
                    },
                    {
                        "title": "节日时间",
                        "value": "五月初五（农历）"
                    },
                    {
                        "title": "节日饮食",
                        "value": "粽子、雄黄酒、五黄等"
                    },
                    {
                        "title": "节日意义",
                        "value": "非物质文化遗产传承"
                    }
                ],
                "detail": [
                    "端午节与春节、清明节、中秋节并称为中国民间的四大传统节日。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "“端午”一词最早出现于西晋名臣周处的《风土记》，这本文献成了现代人们查考端午节等传统节日习俗的重要参考。端午节起源于何时──长期以来众说纷纭，说法不一，争论不休。",
                    "关于端午节起源的主要观点有四种：",
                    "1.流传甚广的“历史人物纪念”观点，依据南朝梁人吴均的《续齐谐记》及宗檩《荆楚岁时记》，认为端午节起源于纪念屈原。还有认为是纪念伍子胥、纪念曹娥等。",
                    "2.闻一多先生认为端午节是古百越族举行龙图腾崇拜活动的节日，他在《端午考》及《端午节的历史教育》（见《闻一多全集》）两篇论文中详细论证了他的观点。",
                    "3.认为端午节起源于恶日（见张心勤《端午节非因屈原考》）。",
                    "4.说端午节源于夏至（详见刘德谦《“端午”始源又一说》）。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "划龙舟",
                    "",
                    "挂艾草与菖蒲",
                    "",
                    "端午食粽",
                    "",
                    "栓五色丝线",
                    "",
                    "饮蒲酒、雄黄酒、朱砂酒"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "国际家庭日",
        "short_desc": "有爱的地方就是家的方向",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "每年的5月15日"
                    },
                    {
                        "title": "起源时间",
                        "value": "1994年"
                    }
                ],
                "detail": [
                    "在许多国家，这一日旨在强调对家庭有利害关系和重要意义的各种领域的机会。活动包括强调有关主题的讲习班和会议、无线电和电视节目、报纸专论和文艺节目。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "家庭问题在20世纪80年代是联合国日益关注的对象。 1983年，根据社会发展委员会在其第二十八届会议上的建议，经济和社会理事会在其关于家庭在发展进程中作用的决议（1983\/ 23）中，请秘书长，“增强决策者和公众对家庭需要问题的认识，以及为满足这些需求的有效途径”。在其1985年5月29日通过的第1985\/29号决议，经社理事会请大会审议包括题为“在发展过程中的家庭”，以期考虑可能请秘书长向大会提出针对所涉问题的全球意识的发展过程。",
                    "",
                    "随后，根据社会发展委员会在其第三十届会议的建议，及经社理事会1987年第一届常会（1987年5月28日的1987\/42决议），大会在1987年12月7日的42\/134决议中，邀请“所有国家，尽可能提供他们关于国际家庭年的意见和建议”。它还要求“秘书长根据会员国的意见和建议向大会第四十三届会议提出一份全面报告，对设立国际家庭年的可能性，以及改善家庭福祉，加强作为全球努力促进社会进步和发展一部分的国际合作”。",
                    "",
                    "联合国大会在1989年12月9日的第44\/82号中决议宣布，1994年为国际家庭年。"
                ]
            },
            {
                "title": "家庭现状",
                "detail": [
                    "许多社会学专家和教育家认为：家庭在建立精神安宁，巩固文化和社会关系，以及对后代的成长中，产生有重要影响。此外，家庭作为社会的主要成员，可以保护青年和未来工代免遭社会的不良现象的腐蚀。家庭在过去半个世纪， 特别在近20年， 在各国社会中都发生了重大变化， 其主要原因是由于工作、 迁移、 信息发展、 社会、 经济等关系， 以及战争和社会不平等因素造成家庭基础受到动摇。 ",
                    "",
                    "当然家庭基础的动摇，不但导致离婚率上升，而且给社会带来诸多不利，如年青人离开家庭，进入社会，由于心理不平衡开始在社会上制造暴力事件，以示发自内心的不满或以此得以安慰。",
                    "调查显示：在90年代，美国的传统家庭基础已完全崩溃。继而出现的是“同居”，“单亲家庭”和“单身汉”。一个圆满家庭生活的比率从1990年的25.6%下降到2000年的23.3%。据美国家庭联合会统计：美国30%的孩子是私生子。另外对美国社会的调查显示：77%的青年因为家庭原因而走上偷盗、吸毒、打架斗殴等歧途。统计还显示：从1960年至1995年期间，无父母照管的青少年采取的暴力犯罪活动，增加了七倍。此外，在世界其它地方发生的冲突和战争中，家庭成了首当其冲的牺牲品，家庭遭战争破坏后当然对社会带来的诸多麻烦，是不言而喻的。所以说，如果我们现在还不采取一系列正确的方案和措施，来巩固家庭基础，恢复社会精神与文化价值，那么我们将无法解决一系列社会问题。",
                    "",
                    "当今，包括，联合国、联合国教科文组织、联合国儿童基金会以及其它许多政府和非政府组织在内的国际组织做出种种努力，力求提高世界各国的文化和教育水平。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "护士节",
        "short_desc": "谢谢你像天使一样，为我们赶走病痛",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "每年的5月12日"
                    },
                    {
                        "title": "节日类型",
                        "value": "世界节日"
                    },
                    {
                        "title": "节日意义",
                        "value": "弘扬救死扶伤精神"
                    },
                    {
                        "title": "纪念人物",
                        "value": "弗劳伦斯·南丁格尔"
                    },
                    {
                        "title": "设定地点",
                        "value": "华盛顿"
                    }
                ],
                "detail": [
                    "国际护士节是每年的5月12日，是为纪念现代护理学科的创始人弗洛伦斯·南丁格尔于1912年设立的节日。其基本宗旨是倡导、继承和弘扬南丁格尔不畏艰险、甘于奉献、救死扶伤、勇于献身的人道主义精神。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "1854年至1856年间，英法联军与沙俄发生激战。在英国一家医院任护士主任的南丁格尔，带领38名护士奔走前线，参加护理伤病员的工作。因当时医疗管理混乱，护理质量很差，伤病员死亡率高达42%。在这种情况下，南丁格尔下定决心潜心改善病室的卫生条件，并加强对病人的护理和营养。半年之后，医院的伤病员死亡率下降到了2.2%。这一事迹传遍全欧。1860年，她在英国伦敦创办了世界上第一所正规护士学校。她的护士工作专著，成了医院管理、护士教育的基础教材。鉴于南丁格尔推动了世界各地护理工作和护士教育的发展，因此被誉为“近代护理创始人”。南丁格尔1910年在逝世后，国际护士理事会为纪念南丁格尔为护理事业做出的贡献，于1912年将她的生日5月12日定为“国际护士节”，最初称“医院日”，也称“南丁格尔日”。"
                ]
            },
            {
                "title": "节日活动",
                "detail": [
                    "授帽仪式",
                    "每逢5月12日国际护士节到来之际，医院、护士学校等都会举行庄严的护士授帽仪式，授帽仪式是护生成为护士的重要时刻，在护理学创始人南丁格尔像前，护生直跪在护理前辈面前，前辈为护生戴上象征着圣洁的天使的洁白燕帽，护生接过前辈手中象征着“燃烧自己，照亮他人”的蜡烛，站在南丁格尔像前宣读誓言。“我宣誓：以救死扶伤、防病治病，实行社会主义的人道主义，全心全意为人民服务为宗旨，履行护士的天职；我宣誓：以自己的真心、爱心、责任心对待我所护理的每一位病人；我宣誓：我将牢记今天的决心和誓言，接过前辈手中的蜡烛，把毕生精力奉献给护理事业。”"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "劳动节",
        "short_desc": "劳动者用勤恳创造世界",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "别称",
                        "value": "国际劳动节、国际示威游行日"
                    },
                    {
                        "title": "节日时间",
                        "value": "每年五月一日"
                    },
                    {
                        "title": "节日类型",
                        "value": "国际节日"
                    },
                    {
                        "title": "节日意义",
                        "value": "劳动者争取到了合法权益"
                    }
                ],
                "detail": [
                    "国际劳动节又称“五一国际劳动节”、“国际示威游行日”（International Workers`Day或者May Day）， 是世界上80多个国家的全国性节日。 定在每年的五月一日。 它是全世界劳动人民共同拥有的节日。 "
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "十八世纪末，美国和欧洲等许多国家，逐步由资本主义发展到帝国主义阶段，为了刺激经济的高速发展，榨取更多的剩余价值，以维护这个高速运转的资本主义机器，资本家不断采取增加劳动时间和劳动强度的办法来残酷地剥削工人。",
                    "",
                    "1877年，美国历史上第一次全国罢工开始了。工人阶级走向街头游行示威，向政府提出改善劳动与生活条件，要求缩短工时，实行八小时工作制。罢工不久，队伍日渐扩大，工会会员人数激增，各地工人也纷纷参加了罢工运动。",
                    "",
                    "1886年5月1日，以美国芝加哥为中心，在美国举行了约35万人参加的大规模罢工和示威游行，示威者要求改善劳动条件，实行八小时工作制。1886年5月3日芝加哥政府出动警察进行镇压，开枪打死两人，事态扩大，5月4日罢工工人在干草市场广场举行抗议，由于不明身份者向警察投掷炸弹，最终警察开枪，先后共有4位工人、7位警察死亡，史称“干草市场暴乱”（Haymarket Riot）或“干草市场屠杀”（Haymarket Massacre）。在随后的宣判中有8位无政府主义者以谋杀罪被起诉，4位无政府主义者被绞死，1位在牢中自杀。",
                    "",
                    "为纪念这次伟大的工人运动及抗议随后的宣判，在世界范围内举行了工人的抗议活动。这些活动成为了“国际劳动节”的前身。",
                    "1889年7月，在恩格斯组织召开的第二国际成立大会上宣布将每年的五月一日定为国际劳动节。"
                ]
            },
            {
                "title": "各地活动",
                "detail": [
                    "世界各国一般都会举行相应的庆祝活动。不过，并不是所有国家都将5月1日定为劳动节，那些同过“五一”的国家，具体的庆祝方式和习惯也大不相同。",
                    "",
                    "欧洲",
                    "欧洲仍以示威庆祝“五一”　在英国和德国，当局担心示威恐怕会有无政府主义者闹事捣乱。在德国柏林，警方和无政府主义者半夜发生冲突，有多人被捕。不过，数千名工会活跃分子却和平地在市内游行。在法国和奥地利，反对退休金改革的人也上街参与游行。从土耳其国家电视台的新闻画面完全可以看见，警方用警棍打示威者，并扯着其中一人的头发把他拉走。",
                    "",
                    "意大利：不庆祝，不放假。",
                    "英国、法国等欧洲国家都将“五一”确定为劳动节，不少国家都放假一天，还有的国家则根据情况将公共假期放在5月的第一个星期一。不过，和世界大多数国家不太一样的是，意大利尽管承认“五一”国际劳动节，政府也表示尊重劳工，但一般人并不举行专门的庆祝活动，也没有全国性的“五一”假期。",
                    "美国：劳动节发源地不过“五一”，美国政府后来在设立劳动节时，自行规定每年9月的第一个星期一为劳动节，所以美国人的劳动节不在5月，而在9月。每逢9月的劳动节，美国人可以放假一天，全美各地的民众一般都会举行游行、集会等各种庆祝活动，以示对劳工的尊重。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "青年节",
        "short_desc": "恰同学少年，风华正茂；书生意气，挥斥方遒",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "每年5月4日"
                    },
                    {
                        "title": "流行地区",
                        "value": "中国"
                    },
                    {
                        "title": "节日活动",
                        "value": "爱国、进步、民主、科学"
                    }
                ],
                "detail": [
                    "五四青年节源于中国1919年反帝爱国的“五四运动”，五四爱国运动是一次彻底的反对帝国主义和封建主义的爱国运动，也是中国新民主主义革命的开始。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "五四青年节是为纪念1919年5月4日爆发的五四运动而设立的。",
                    "",
                    "五四运动是1919年5月4日发生在北京以青年学生为主的一场学生运动，广大群众、市民、工商人士等中下阶层广泛参与的一次示威游行、请愿、罢工、暴力对抗政府等多形式的爱国运动。是中国人民彻底的反对帝国主义、封建主义的爱国运动。五四运动是中国新民主主义革命的开端，是中国革命史上划时代的事件，是中国旧民主主义革命到新民主主义革命的转折点。"
                ]
            },
            {
                "title": "五四精神",
                "detail": [
                    "五四精神的核心内容为“爱国、进步、民主、科学”。",
                    "",
                    "爱国主义是五四精神的源泉，民主与科学是五四精神的核心，勇于探索、敢于创新、解放思想、实行变革是民主与科学提出和实现的途径，理性精神、个性解放、反帝反封建是民主与科学的内容。而所有这些，最终目的都是为了振兴中华民族。因此，纪念五四运动，发扬五四精神，应该把这些方面结合起来，为振兴中华民族而努力奋斗。",
                    "五四精神代表着诚实的，进步的，积极的，自由的，平等的，创造的，美的，善的，和平的，相爱互助的，劳动而愉快的，全社会幸福的统一体现。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "无烟日",
        "short_desc": "为了健康，为了孩子们更为了明天，今天无烟",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "5月31日"
                    },
                    {
                        "title": "设立机构",
                        "value": "世界卫生组织"
                    }
                ],
                "detail": [
                    "我国作为世界上最大的烟草受害国，在控制吸烟问题与先进国家存在明显差距，主要原因之一即在于公众对于吸烟危害健康这一重要的科学事实缺乏深刻的认识，有效控烟仍需要社会各界的广泛参与。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "为引起国际社会对烟草危害人类健康的重视，世界卫生组织1987年11月将每年的4月7日定为“世界无烟日”，1988年开始执行。1989年起世界无烟日为每年的5月31日。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "1988 年： 要烟草还是要健康， 请您选择",
                    "1989 年： 妇女与烟草",
                    "1990 年： 青少年不要吸烟",
                    "1991 年： 在公共场所和公共交通工具上不吸烟",
                    "1992 年： 工作场所不吸烟",
                    "1993 年： 卫生部门和卫生工作者反对吸烟",
                    "1994 年： 大众传播媒介宣传反对吸烟",
                    "1995 年： 烟草与经济",
                    "1996 年： 无烟的文体活动",
                    "1997 年： 联合国和有关机构反对吸烟",
                    "1998 年： 在无烟草环境中",
                    "1999 年： 戒烟。 口号是“ 放弃成长香烟”",
                    "2000 年： 不要利用文体活动促销烟草 口号“ 吸烟有害勿受诱惑”",
                    "2001 年： 清洁空气， 拒吸二手烟",
                    "2002 年： 无烟体育— 清洁的比赛",
                    "2003 年： 无烟草影视及时尚行动",
                    "2004 年： 控制吸烟， 减少贫困",
                    "2005 年： 卫生工作者与控烟",
                    "2006 年： 烟草吞噬生命",
                    "2007 年： 创建无烟环境",
                    "2008 年： 无烟青少年。 口号： 禁止烟草广告和促销， 确保无烟青春好年华。",
                    "2009 年： 烟草健康警示。 口号： 图形警示揭露烟害真相",
                    "2010 年： 性别与烟草。 口号： 抵制针对女性的市场营销",
                    "2011 年： 世界卫生组织《 烟草控制框架公约》。 口号： 烟草致命如水火无情， 控烟履约可挽救生命",
                    "2012 年： 烟草业干扰控烟。 口号： 生命与烟草的对抗",
                    "2013 年： 禁止烟草广告、 促销和赞助",
                    "2014 年： 提高烟草税",
                    "2015 年： 制止烟草制品非法贸易"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "中国旅游日",
        "short_desc": "走出去，让眼界拓宽，让心灵遨游",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "每年的5月19日"
                    },
                    {
                        "title": "节日意义",
                        "value": "强化旅游宣传，培养国民旅游休闲意识"
                    }
                ],
                "detail": [
                    "设立“中国旅游日”旨在强化旅游宣传，培养国民旅游休闲意识，鼓励人民群众广泛参与旅游活动，提升国民生活质量，推动旅游业发展。旅游日具体日期的选定，将体现下列原则：与旅游有一定的纪念、宣传等关联意义；时间上适于全民外出旅游休闲；有利于加强国民的旅游意识，发挥旅游的教育功能。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "中国旅游日源自天台山 《徐霞客游记》开篇《游天台山记》：“癸丑之三月晦（公元1613年5月19日），自宁海出西门，云散日朗，人意山光，俱有喜态。”《徐霞客游记》开篇的短短24个字，为后人留下了文化旅游的瑰宝。",
                    "2011年3月30日，国务院常务会议通过决议，中国正式设立国家旅游日，时间确定为5月19日。这个日子，就是《徐霞客游记》的开篇日，也是宁海县每年举行“中国宁海徐霞客开游节”的日子。把5月19日确定为“中国旅游日”，宁海已经整整呼吁了10年。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "浙江天台山： 国有景点半价开放",
                    "山西： 全省景区门票半价迎客",
                    "上海： 部分景点免票或半价",
                    "山东： 11 城市推出惠民方案",
                    "浙江宁海： 景区每年免费游3天",
                    "江苏周庄： 首推“ 免费游”",
                    "云南： 多个景区半价迎客"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "爱眼日",
        "short_desc": "世界爱眼日,“视”界因你而美",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "外文名",
                        "value": "Sight Day"
                    },
                    {
                        "title": "节日时间",
                        "value": "每年6月6日"
                    },
                    {
                        "title": "发起人",
                        "value": "王延华，耿贯一"
                    },
                    {
                        "title": "流行范围",
                        "value": "中国大陆"
                    }
                ],
                "detail": [
                    "加强全民爱眼意识，提高民族健康素质。眼睛的健康有赖于人们的爱眼意识，全民眼保健工作，需要社会各界人士的支持和参与。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "1992年9月25日，天津医科大学眼科教授王延华与流行病学教授耿贯一首次倡议在国内设立爱眼日。1996年，国家卫生部、国家教育部、团中央、中国残联等12个部委联合发出通知，将爱眼日活动列为国家节日之一，并重新确定每年6月6日为“全国爱眼日”。"
                ]
            },
            {
                "title": "历年主题",
                "detail": [
                    "1996 年 \"保护儿童和青少年视力\"",
                    "1997 年 \"老年人眼保健\"",
                    "1998 年 \"预防眼外伤\"",
                    "1999 年 \"保护老年人视力，提高生活质量\"",
                    "2000 年 \"动员起来，让白内障盲见光明\"",
                    "2001 年 \"早期干预，减少可避免的儿童盲症\"",
                    "2002 年 \"关爱老年人的眼睛,享有看见的权利\"",
                    "2003 年 \"爱护眼睛、为消除可避免盲而努力\"",
                    "2004 年 \"防治屈光不正及低视力，提高儿童和青少年眼保健水平\"",
                    "2005 年“ 预防近视， 珍爱光明”",
                    "2006 年“ 防盲治盲， 共同参与”",
                    "2007 年“ 防盲进社区， 关注眼健康”",
                    "2008 年“ 明亮眼睛迎奥运”",
                    "2009 年“ 关注青少年眼健康”",
                    "2009 年“ 珍爱视觉之窗”",
                    "2010 年“ 关注贫困人口眼健康， 百万工程送光明”",
                    "2011 年“ 关爱低视力患者， 提高康复质量”",
                    "2012 年“ 情系白内障患者共享和谐新视界”",
                    "2013 年“ 汇聚中国梦， 2016 年前消灭致盲性沙眼”",
                    "2014 年“ 关注眼健康， 预防糖尿病致盲”",
                    "2015 年“ 告别沙眼盲， 关注眼健康”",
                    "2016 年“ 呵护眼睛， 从小做起”"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "儿童节",
        "short_desc": "愿你我童心常在，快乐常留，让童年的纸飞机飞回你手里",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "每年6月1日"
                    },
                    {
                        "title": "节日意义",
                        "value": "保障世界各国儿童的权益"
                    },
                    {
                        "title": "设立机构",
                        "value": "国际民主妇女联合会"
                    },
                    {
                        "title": "设定时间",
                        "value": "1949年11月"
                    }
                ],
                "detail": [
                    "儿童节是为了保障世界各国儿童的生存权、保健权和受教育权，抚养权，为了改善儿童的生活，为了反对虐杀儿童和毒害儿童而设立的节日。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "国际儿童节的设立，和发生在二战期间一次屠杀--利迪策惨案有关。1942年6月10日，德国法西斯枪杀了捷克利迪策村16岁以上的男性公民140余人和全部婴儿，并把妇女和90名儿童押往集中营。村里的房舍、建筑物均被烧毁，好端端的一个村庄就这样被德国法西斯给毁了。第二次世界大战结束后，世界各地经济萧条，成千上万的工人失业，过着饥寒交迫的生活。儿童的处境更糟，有的得了传染病，一批批地死去；有的则被迫当童工，受尽折磨，生活和生命得不到保障。为了悼念利迪策惨案和全世界所有在战争中死难的儿童，反对虐杀和毒害儿童，以及保障儿童权利，1949年11月，国际民主妇女联合会在莫斯科举行理事会议，各国代表愤怒地揭露了帝国主义分子和各国反动派残杀、毒害儿童的罪行。为了保障世界各国儿童的生存权、保健权和受教育权，为了改善儿童的生活，会议决定以每年的6月1日为国际儿童节。当时的很多国家表示赞同，特别是社会主义国家。"
                ]
            },
            {
                "title": "各国习俗",
                "detail": [
                    "韩国",
                    "",
                    "韩国的儿童节于1923年， 从“ 男孩节” 演变过来的。 也是韩国的公众假日， 每年的5月5日。 这一天， 孩子们可以尽情享受欢乐， 父母要给孩子准备他们最想要的礼物。 很多孩子也会在这天穿上韩服， 体验传统的韩国文化。",
                    "",
                    "哥伦比亚",
                    "",
                    "哥伦比亚将每年的7月4日定为儿童节。 在这个节日里， 全国的学校都要举行各种生动活泼的庆祝活动， 儿童们还常常戴上各式各样的假面具， 扮成小丑的样子在街头玩耍， 十分开心。",
                    "",
                    "巴西",
                    "",
                    "巴西的儿童节在8月15日， 这一天正好也是巴西的“ 全国防疫日”。 所以， 每到这个日子， 各地的医生们都要为孩子们看病， 还要给5岁以下的儿童注射预防小儿麻痹症的疫苗， 表明政府十分关心儿童的健康。 另外， 巴西的“ 圣母显灵日” 10 月12日也往往作为儿童节， 有一些庆祝活动。",
                    "",
                    "非洲国家",
                    "",
                    "非洲西部的国家大都有专门的“ 儿童狂欢节”， 一般持续一个月。 非洲人能歌善舞，“ 儿童狂欢节”， 尽管人们的生活条件不相同， 所有孩子都会尽情欢乐， 热闹非凡。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "反毒品日",
        "short_desc": "珍爱生命，远离毒品",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "每年6月26日"
                    },
                    {
                        "title": "节日意义",
                        "value": "引起各国、各地区对毒品问题的重视"
                    }
                ],
                "detail": [
                    "中国是世界上禁毒最坚决的国家之一，在新中国成立之前，吸毒者约为200O万，以当时人口总数为5亿计，平均每25个人当中就有一个是瘾君子。解放后由于党和政府的重视，全国人民的共同努力，只用了短短几年的时间就在全国范围内禁绝了毒品的犯罪活动，1958年成为举世闻名的“无毒国”。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "1987年6月12日至26日，在维也纳召开了由138个国家和地区的3000名代表参加的“麻醉品滥用和非法贩运问题”部长级会议。这次会议提出了“爱生命，不吸毒”的口号。同时，为了进一步引起各国、各地区对毒品问题的重视，号召全世界人民共同抵御毒品的侵袭，与毒品犯罪活动作坚决的斗争，也为了纪念这次意义重大的国际禁毒会议，大会结束时，与会代表一致建议，将每年的6月26日定为“国际反毒品日”。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "从1992年起，国际禁毒日都确定有一个主题口号，以达到国际社会关注和共同参与的效果。",
                    "1992年：“毒品，全球问题，需要全球解决”；",
                    "1998年：“无毒世界我们能做到”；",
                    "1999年：“亲近音乐，远离毒品”；",
                    "2000年：Facing Reality: Denial, Corruption and Violence；",
                    "2001年：“体育拒绝毒品”；",
                    "2002年：“吸毒与艾滋病”；",
                    "2003年：“让我们讨论毒品问题” （2003年中国“6·26”国际禁毒日的宣传主题为 “远离毒品，关爱未来”）；",
                    "2004年：“抵制毒品，参与禁毒”；",
                    "2005年：“珍惜自我，健康选择”；",
                    "2006年：“毒品不是儿戏”；",
                    "2007年：“控制毒品”；",
                    "2008年：“依法禁毒，构建和谐”；",
                    "2009年：“毒品控制了你的生活吗?你的生活，你的社区，拒绝毒品。”",
                    "2010年：“参与禁毒斗争，构建和谐社会”；",
                    "2011年： “青少年与合成毒品”；",
                    "2012年：“全球行动共建无毒品安全社区”；",
                    "2013年：“抵制毒品，参与禁毒”；",
                    "2014年：“远离毒品、健康生活、拥抱美好人生。”"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "国际奥林匹克日",
        "short_desc": "和平，友谊，进步；更高，更快，更强",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "6月23日"
                    },
                    {
                        "title": "设立机构",
                        "value": "国际奥林匹克委员会"
                    },
                    {
                        "title": "设定时间",
                        "value": "1948年6月23日"
                    },
                    {
                        "title": "节日意义",
                        "value": "纪念国际奥委会在巴黎正式成立"
                    }
                ],
                "detail": [
                    "纪念日的宗旨是鼓励世界上所有的人，不分性别、年龄或体育技能的高低，都能参与到体育活动中来。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "1894年6月23日，国际奥委会 在巴黎正式成立，为了纪念这一具有历史意义的一日，经过国际奥林匹克委员会的赞同，把这一日称为“奥林匹克日”或运动日。国际奥委会从1948年起将每年的6月23日定为国际奥林匹克日。当年6月23日举行了首次奥林匹克日活动，参加的国家有葡萄牙、希腊、奥地利、加拿大、瑞士、英国、乌拉圭、委内瑞拉和比利时。此后，在每年的6月17日至24日之间，各个国家或地区奥委会都要组织各种庆祝活动。现在世界上参加此项活动的国家、地区已由首届的9个增至100多个，参加者十分踊跃，表达了人们对奥林匹克精神的崇尚！经过国际奥林匹克委员会的赞同，把这一日称为“奥林匹克日”或“运动日”。",
                    "现代奥林匹克运动会不分种族、肤色、宗教信仰、意识形态、语言文化，全世界人民相聚在五环旗下，以团结、和平与友谊为宗旨进行公平竞技，具有国际性的特点。"
                ]
            },
            {
                "title": "组织机构",
                "detail": [
                    "国际奥林匹克委员会，简称国际奥委会。1894年成立后，总部设在巴黎。1914年第一次世界大战爆发，为了避免战火的洗劫,1915年4月10日总部迁入这个有“国际文化城”之称的瑞士洛桑。这里有奥林匹克博物馆，奥林匹克研究中心，还有以顾拜旦名字命名的大街，体育场，等等。数十年来，洛桑为奥林匹克运动的发展，作出了重大贡献，无怪乎有人将洛桑称为\"奥林匹克之都\"。国际奥委会也是在这个美好的城市逐渐发展、壮大而闻名于世的。",
                    "",
                    "国际奥委会，是奥林匹克运动的领导机构，是一个不以营利为目的、具有法律地位和永久继承权的法人团体。根据现代奥林匹克运动创始人顾拜旦的理想，恢复奥林匹克运动的目的，在于增强各国运动员之间的友谊与团结，促进世界和平以及各国人民之间的相互了解，发展世界体育运动。《奥林匹克宪章》明文规定，国际奥委会的宗旨是：鼓励组织和发展体育运动和组织竞赛；在奥林匹克理想指导下，鼓舞和领导体育运动，从而促进和加强各国运动员之间的友谊；迄今已有近百年历史的国际奥委会，为之作出了积极努力和重大贡献。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "建党节",
        "short_desc": "花沐春雨艳，福依党恩生",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "每年7月1号"
                    },
                    {
                        "title": "节日起源",
                        "value": "党的诞生纪念日"
                    },
                    {
                        "title": "设定时间",
                        "value": "1938年5月"
                    },
                    {
                        "title": "设立机构",
                        "value": "中国共产党"
                    }
                ],
                "detail": [
                    "中国共产党于1921年7月23日成立后，在反动军阀政府的残暴统治之下，只能处于秘密状态，没有公开进行活动的环境。在大革命时期，党忙于国共合作、开展工农运动和支援北伐战争，没有条件对党的诞生进行纪念。把7月1日作为中国共产党的诞辰纪念日，是毛泽东同志于1938年5月提出来的。"
                ]
            },
            {
                "title": "由来",
                "detail": [
                    "中国共产党成立纪念日，每年公历七月一日。1917年俄国十月革命胜利后，马克思主义迅速传遍我国，经过“五四”爱国运动，最早接受马列主义的革命知识分子李大钊，陈独秀，毛泽东，董必武等人，相继在各地成立共产主义小组，宣传马列主义，从事工人运动。在列宁领导的共产国际的积极帮助下，1921年7月各地共产主义小组派出代表到上海召开了中国共产党第一次代表大会。后因被帝国主义密探发觉，会议又转移到浙江嘉兴南湖的一只游船上继续进行。参加会议的有李达，李汉俊，张国焘，刘仁静，董必武，陈潭秋，毛泽东，何叔衡，王尽美，邓恩铭，陈公博，周佛海，共十二名。代表全国各界五十七名党员出席了会议。包惠僧受陈独秀派遣，出席了会议。参加会议的还有共产国际代表马林，赤色职工国际代表尼柯尔斯基。大会通过了党的章程，选举陈独秀为总书记，宣告中国共产党成立。从此，在中国出现了完全新式的以共产主义为目的，以马列主义为行动指南，统一的工人阶级政党。中国共产党的诞生，开辟了中国历史发展的新时代，使中国革命的面貌焕然一新。抗日战争时期，由于环境困难，不能查记“一大”召开的准确日期（后经过考证，得出“一大”召开的时间为1921年7月23日），因此1941年党中央决定召开“四大”，确定1921年7月的首日即7月1日作为党的生日和纪念日。"
                ]
            },
            {
                "title": "历史",
                "detail": [
                    "中国共产党于1921年7月成立后，在反动派的残暴统治之下，只能处于秘密状态，没有公开进行活动的环境。在大革命时期，党忙于国共合作、开展工农运动和支援北伐战争，没有条件对党的诞生进行纪念。在土地革命战争时期，党处在更加艰苦的农村战争环境或者在白色恐怖下进行斗争，仍没有条件组织统一的活动来纪念党的生日。直至1936年当中国共产党成立十五周年的时候，党的一大代表陈潭秋同志在莫斯科出版的《共产国际》杂志第七卷上，发表了《第一次代表大会的回忆》一文，以表示对党的诞生的纪念。这是纪念中国共产党诞生最早的一篇文章。文章中提到一大是1921年7月底开幕的，但没有具体的日期。这篇纪念文章是以一大开会的月份为依据的，而不是以一大开幕的具体日期为依据的。当时，还没有举行过群众性的纪念党的生日的活动。",
                    "",
                    "当时，在延安的曾经参加过一大的党的创始人只有毛泽东和董必武两人。他们回忆一大是七月份召开的，但记不清楚确切的开会日期。因为缺乏档案资料，一时无法查证，所以就把7月1日确定为党的诞生纪念日。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "中国航海日",
        "short_desc": "让我们朝着梦的的方向，扬帆起航",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "每年的7月11日"
                    },
                    {
                        "title": "节日意义",
                        "value": "宣传普及航海及海洋知识等"
                    },
                    {
                        "title": "设定地点",
                        "value": "宁波"
                    },
                    {
                        "title": "设定时间",
                        "value": "2005年4月25日"
                    }
                ],
                "detail": [
                    "“航海日”是由政府主导、全民参与的全国性的法定活动日,既是所有涉及航海、海洋、渔业、船舶工业、航海科研教育等有关行业及其从业人员和海军官兵的共同节日,也是宣传普及航海及海洋知识,增强海防意识,促进社会和谐团结的全民族文化活动。地球上的水域面积占了三分之二，一个国家的兴盛与航海事业密不可分。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "2005年7月11日，是中国伟大航海家郑和下西洋600周年纪念日。2005年4月25日，经国务院批准，将每年的7月11日确立为中国“航海日”，作为国家的重要节日固定下来。”同时也作为“世界海事日3月17日”在中国的实施日期。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "节日期间，相关部门统一张贴活动主题和宣传口号，举办多种形式的庆祝、纪念、学术、竞赛、教育、表彰、展览等文化宣传活动。节日当天，中国籍民用船舶、中国航运企业拥有或经营的非中国籍船舶都要挂满旗，我国航运、港口、船舶代理、海事、救助、水运工程等涉海管理机关、企事业单位和科研院校，也要悬挂旗帜以示庆祝航海日，除在限制鸣笛特殊水域或在港作业的船舶外，中国籍民用船舶、中国航运企业拥有或经营的非中国籍船舶还要统一鸣笛1分钟。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "建军节",
        "short_desc": "致敬！中国军人！",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "英文名称",
                        "value": "Army Day"
                    },
                    {
                        "title": "节日时间",
                        "value": "每年的8月1日"
                    },
                    {
                        "title": "节日意义",
                        "value": "纪念中国工农红军成立"
                    },
                    {
                        "title": "设立机构",
                        "value": "中国人民革命军事委员会"
                    }
                ],
                "detail": [
                    "每年的八月一日是中国人民解放军建军纪念日，因此也叫“八一”建军节。1933年7月11日，中华苏维埃共和国临时中央政府根据中央革命军事委员会6月30日的建议，决定8月1日为中国工农红军成立纪念日。1949年6月15日，中国人民革命军事委员会发布命令，以“八一”两字作为中国人民解放军军旗和军徽的主要标志。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "1927年国民革命军正处于北伐中，当时的国民党两大集团——武汉的汪精卫集团和南京的蒋介石集团并不团结，4月，蒋介石在上海发动“四一二”政变，大批逮捕、处决共产党人，三个月后，武汉的汪精卫集团也发动“七一五”政变，大肆清党，政府、军队中的共产党人遭到驱逐。在这种情况下，中国共产党为了表明革命到底的立场，唤醒广大民众，反抗国民党反动派的屠杀，决定在南京和武汉之间的江西南昌发动武装起义，打响了武装反抗国民党反动统治的第一枪。",
                    "",
                    "1933年7月11日，中华苏维埃共和国临时中央政府决定将8月1日作为中国工农红军成立纪念日。从此，每年8月1日就成为中国工农红军和后来中国人民解放军的建军节。当年8月1日，在瑞金叶坪红军广场举行了历史上第一个“八一”纪念活动并且当日傍晚在瑞金城南竹马岗举行了红军阅兵式和分列式。从此，8月1日正式成为人民军队的建军节。因此，可以说南昌是军旗升起的地方，而瑞金是八一建军节诞生的地方。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "禁止核试验国际日",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "每年的8月29日"
                    },
                    {
                        "title": "设立机构",
                        "value": "联合国大会"
                    },
                    {
                        "title": "节日意义",
                        "value": "宣传、教育和倡导必须将禁止核试验作为走向更安全的世界的可贵的一步"
                    }
                ],
                "detail": [
                    "禁止核试验国际日是2009年12月2日，联合国大会第六十四届会议一致通过第64\/35号决议，宣布8月29日为禁止核试验国际日。该决议由哈萨克斯坦连同很多提案国和联合提案国发起，旨在纪念1991年8月29日塞米巴拉金斯克核武器试验场关闭。设立这个纪念日是对联合国、其会员国、政府间和非政府组织、学术机构、青年网络及媒体的激励，也是在宣传、教育和倡导必须将禁止核试验作为走向更安全的世界的可贵的一步。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "自20世纪中期核武器试验开始以来（首次是在1945年），世界各地进行了近2000次核试验。很少有人考虑到试验对人类生命的毁灭性影响，更不用说了解大气层试验的放射性尘降物了。在初期，拥有核武器是衡量科学精细程度或军事力量的一种标准。事后的分析和历史向我们表明了核武器试验令人恐惧的悲剧性影响，特别是在控制条件空缺以及鉴于今天的核武器更为强大、更具破坏性的情况下。之后在世界各地发生的事故提供了令人信服的理由，有必要纪念禁止核试验国际日——在这一天举行的教育事项、举办的活动和发布的讯息都是力争引起世界的注意，而且强调必须统一尝试阻止进一步的核武器试验。",
                    "",
                    "1996年通过的《全面禁止核试验条约》（《禁核试条约》）是终止一切形式的核试验的国际文书，但是，该条约尚未生效。2009年12月2日，联合国大会第六十四届会议一致通过第64\/35号决议，宣布8月29日为禁止核试验国际日。该决议的前言强调，“应该尽一切努力终止核试验，以避免给人的生命和健康带来毁灭性和有害的后果”，并且“终止核试验是实现无核武器世界目标的关键途径之一”。"
                ]
            },
            {
                "title": "活动",
                "detail": [
                    "2010年首次纪念了禁止核试验国际日。自此，全世界每年都通过各种活动庆祝这个日子，如专题研讨会、会议、展览、竞赛、出版物、学术机构的教学指导、媒体广播和其他活动。联合国总部也开展了一些活动。为2012年的纪念活动已规划了类似项目。",
                    "自禁止核试验国际日设立以来，很多双边和多边政府层面的最新事态发展、民间社会的广泛运动和联合国秘书长本人的努力，均有助于推动禁止核试验这一事业。联合国秘书长潘基文十分明确地表示，“ 一个无核武器的世界将是全球最高级别的公益”。 2010 年5月，《 不扩散核武器条约》 的所有缔约国承诺努力“ 实现无核武器世界的和平与安全”， 而且这些国家将禁止核试验称为“ 生死攸关之举”。 "
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "七夕",
        "short_desc": "拱桥下，喜鹊架，随爱走天涯",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "别称",
                        "value": "乞巧节、七巧节、七姐诞"
                    },
                    {
                        "title": "节日时间",
                        "value": "农历七月初七"
                    },
                    {
                        "title": "节日意义",
                        "value": "中国古代的妇女节"
                    },
                    {
                        "title": "流行地区",
                        "value": "中国与汉字文化圈诸国"
                    }
                ],
                "detail": [
                    "七夕节始于汉朝，是流行于中国及汉字文化圈诸国的传统节日，起源于对自然的崇拜及妇女穿针乞巧，后被赋予牛郎织女的传说，极具浪漫色彩。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "唐宋诗词中，妇女乞巧也被屡屡提及，唐朝王建有诗说“阑珊星斗缀珠光，七夕宫娥乞巧忙”。据《开元天宝遗事》载：唐太宗与妃子每逢七夕在清宫夜宴，宫女们各自乞巧，这一习俗在民间也经久不衰，代代延续。",
                    "",
                    "后牛郎织女的爱情故事融入乞巧节，民间姑娘信以为真，于是每到农历七月初七，在牛郎织女“鹊桥会”时，姑娘们就会来到花前月下，抬头仰望星空，寻找银河两边的牛郎星和织女星，希望能看到他们一年一度的相会，乞求上天让自己也能像织女那样心灵手巧，祈祷自己也能有个称心如意的美满婚姻，久而久之便形成了七夕节。",
                    "",
                    "2006年5月20日，七夕节被国务院列入第一批国家非物质文化遗产名录。现在又被认为是“中国情人节”。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "穿针乞巧、兰夜斗巧、种生求子、为牛庆生、晒书晒衣、拜织女、拜魁星、染指甲、妇女洗发、结扎巧姑等"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "全国律师咨询日",
        "short_desc": "律师是法律最理想、最无可指责的解释者",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "每年的8月26日"
                    },
                    {
                        "title": "节日起源",
                        "value": "通过《中华人民共和国律师暂行条例》的日子"
                    },
                    {
                        "title": "设定时间",
                        "value": "1980年8月26日"
                    }
                ],
                "detail": [
                    "全国律师咨询日，1980年8月26日，第五届全国人民代表大会常务委员会第15次会议通过《中华人民共和国律师暂行条例》。这是中华人民共和国关于律师制度的第一部法律，也标志着我国律师制度的恢复和重建。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "根据《中华人民共和国律师暂行条例》（1980年颁布），中华全国律师协会成立于1986年7月，是社会团体法人，是全国性的律师行业自律性组织，依法对律师实行行业管理。中华全国律师协会成立后，将通过《中华人民共和国律师暂行条例》这个特别的日子被定为“全国律师咨询日”。"
                ]
            },
            {
                "title": "宗旨",
                "detail": [
                    "团结和教育会员维护宪法和法律的尊严，忠实于律师事业，恪守律师职业道德和执业纪律；维护会员的合法权益；提高会员的执业素质；加强行业自律，促进律师事业的健康发展，为依法治国，建设社会主义法治国家，促进社会的文明和进步而奋斗。",
                    "凡中华人民共和国律师均为中华全国律师协会会员，地方律师协会为本会团体会员。中华全国律师协会现有团体会员31个（即各省、自治区、直辖市律师协会），个人会员近11万人。《中华人民共和国律师法》于1996年5月15日第八届全国人民代表大会常务委员会第十九次会议通过，2001年12月29日第九届全国人民代表大会常务委员会第二十五次会议通过《关于修改〈中华人民共和国律师法〉的决定》。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "日本投降纪念日",
        "short_desc": "八年抗战八年泪，一寸山河一寸血",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "每年8月15日"
                    },
                    {
                        "title": "节日意义",
                        "value": "铭记历史、缅怀先烈"
                    },
                    {
                        "title": "节日类型",
                        "value": "纪念日"
                    }
                ],
                "detail": [
                    "1945年8月14日，日本政府照会美、中、英、苏四国政府，接受《波茨坦公告》，无条件投降。重庆(战时首都)时间15日晨7时，四国政府在各自首都同时宣布日本政府已正式无条件投降。"
                ]
            },
            {
                "title": "背景",
                "detail": [
                    "1945年8月15日，日本电台播出了裕仁天皇亲自宣读的《终战诏书》，宣布330万日军放下武器无条件投降。中国经过艰苦卓绝的8年抗战,终于取得胜利。"
                ]
            },
            {
                "title": "历史意义",
                "detail": [
                    "象征着第二次世界大战的结束。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "中元节",
        "short_desc": "点一盏河灯，载一世祈福",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "别称",
                        "value": "盂兰盆节、鬼节、七月半"
                    },
                    {
                        "title": "节日时间",
                        "value": "农历七月十五日，部分在七月十四日"
                    },
                    {
                        "title": "流行地区",
                        "value": "中国以及汉字文化圈诸国"
                    },
                    {
                        "title": "节日意义",
                        "value": "敬祖"
                    }
                ],
                "detail": [
                    "中元节就是我们俗称的鬼节，传言在这一天会地府会放出全部的鬼魂。中元节是祀鬼为中心的节日，是中国民间最大的祭祀节日之一。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "古人于中元节放河灯，道士建醮祈祷，乃是中国传统民俗，佛教与道教对这个节日的意义各有不同的解释，道教强调孝道；佛教则着重于为那些从阴间放出来的无主孤魂做“普渡”。中元节的源头，应与中国古代流行的土地祭祖有关。据说天官生日在正月十五日，称上元节。其主要职责是为人间赐福。地官生日在七月十五日，称中元节。其主要职责是为人间赦罪。水官生日在十月十五日，称为下元节。其主要职责是为人间解厄。",
                    "",
                    "中元节，在农历七月十五日，俗称七月半。传说该日地府放出全部鬼魂，民间普遍进行祭祀鬼魂的活动。凡有新丧的人家，例要上新坟，而一般在地方上都要祭孤魂野鬼，所以，它整个儿是以祀鬼为中心的节日，成了中国民间最大的鬼节。",
                    "七月原是小秋，有若干农作物成熟，民间按例要祀祖，用新米等祭供，向祖先报告秋成。宋孟元老《东京梦华录》卷一说：“中元前一日，即买练叶，享祀时铺衬桌面，又买麻谷巢儿，亦是系在桌子脚上，乃告先祖秋成之意。”七月小秋作物成熟，讲究孝道的中国人，例要向先祖报告，并且请老祖宗尝新，所以七月例行祭祀祖先。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "1.祭祀活动",
                    "",
                    "中元普渡",
                    "中元是相当重要的民俗节日，不少人会在旧历的七月初一到七月卅日之间，择日以酒肉、糖饼、水果等祭品举办祭祀活动，以慰在人世间游玩的众家鬼魂，并祈求自己全年的平安顺利。",
                    "",
                    "祭祖",
                    "祭祖节在阴历七月十五日，所以简称为“七月半”祭祖。此时盛夏已经过去，秋凉刚刚开始。民间相信祖先也会在此时返家探望子孙，故需祭祖。",
                    "",
                    "祭祀土地",
                    "七月十五，民间还盛行祭祀土地和庄稼。将供品撒进田地。烧纸以后，再用剪成碎条的五色纸，缠绕在农作物的穗子上。传说可以避免冰雹袭击，获得大秋丰收。一些地方同时还要到后土庙进行祭祀。定襄县民俗将麻、谷悬挂门首。",
                    "",
                    "2.放河灯",
                    "",
                    "人们认为，中元节是鬼节，也应该张灯，为鬼庆祝节日。不过人鬼有别，所以中元张灯和上元张灯不一样。",
                    "",
                    "3.送羊节",
                    "",
                    "农历七月十五，还被称为“送羊节”。汉许慎《说文解字》中说：“羊，祥也。”甲骨文《卜辞》中也称羊通祥。可见在古代，羊这种动物，一向代表吉祥之意。旧京及华北地区的农村，民间流行七月十五由外祖父、舅舅给小外甥送活羊的习俗。传说此风俗与沉香劈山救母的传说有关。沉香劈山救母后，要追杀虐待其母的舅舅二郎神，二郎神为重修兄妹之好和舅甥之谊，每年的七月十五都要给沉香送一对活羊，据说这是取二郎神和沉香之母“杨”姓的谐音，以重结两家之好。从此民间留下了舅舅送活羊的习俗，后来逐渐演变为送一对面羊。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "佛诞",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "农历四月初八"
                    },
                    {
                        "title": "节日别称",
                        "value": "佛诞节、佛诞日"
                    },
                    {
                        "title": "节日意义",
                        "value": "纪念佛祖诞辰"
                    }
                ],
                "detail": [
                    "佛诞节，也就是说是佛教佛祖释迦牟尼诞辰，而且也是在不同国家中都有着不同的姓氏来去纪念佛祖的诞生。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "公元前1027年，农历四月初八，佛祖诞生在北印度，有天上九龙吐出香水为太子洗浴。因此典故，便有了庆祝的重要内容之一：以香水沐浴佛身。所以，浴佛节又名佛诞节。",
                    "中国东汉时仅限于寺院举行，到魏晋南北朝时流传至民间。浴佛时间在史籍中有不同记载。蒙古族、藏族地区以四月十五日为佛诞日，即佛成道日、佛涅盘日，故在这天举行浴佛仪式。汉族地区佛教在北朝时多在农历四月初八举行，后不断变更、发展，北方改在农历十二月初八(腊八节)举行 ，南方则仍为农历四月初八举行 ，相沿至今（俗称“四月八”）。傣族的泼水节在傣历六月（夏历四月）举行 ，清晨，男女老幼沐浴更衣，到佛门寺院礼佛，就带有浴佛的性质。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "1.中国大陆",
                    "",
                    "信众会到寺院，参与浴佛、献花、献果、供僧、供舍利、演戏等等节目。",
                    "有些地方传统会将佛像请到街上游行，置于大象上，或以花车乘载。寺院会开放让信徒进入炷香、礼拜佛像、供养僧众。",
                    "今日的华人地区，常会引入世间庆祝的习惯，如舞龙舞狮，张灯挂彩，甚至燃放炮竹。",
                    "2.台湾地区",
                    "",
                    "台湾佛教徒普遍于农历四月初八举行庆祝及浴佛典礼。经佛教徒之争取，1999年起，中华民国内政部在国定纪念日中正式纳入佛陀诞辰纪念日，日期为农历四月八日，由有关机关、团体举行纪念活动。由于日期接近“母亲节”（五月第二个星期日），一些庆祝活动也会于母亲节举行。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "国父诞辰纪念日",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "每年的11月12日"
                    },
                    {
                        "title": "节日别称",
                        "value": "孙中山诞辰纪念日"
                    },
                    {
                        "title": "节日起源",
                        "value": "国家机构设定"
                    },
                    {
                        "title": "节日意义",
                        "value": "纪念孙中山诞辰"
                    }
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "孙中山，本名孙文，谱名德明，字载之，号日新，又号逸仙，幼名帝象，广东中山人，中国近代民主主义革命先驱，中华民国和中国国民党创始人，三民主义的倡导者。首举彻底反封建的旗帜，“起共和而终帝制”。1905年成立中国同盟会。",
                    "1911年辛亥革命后被推举为中华民国临时大总统。1929年6月1日，根据其生前遗愿，陵墓永久迁葬于南京钟山中山陵。",
                    "1940年，国民政府通令全国，尊称其为“中华民国国父”。他是一位在海峡两岸都受到敬重的革命家，中国国民党尊其为总理，中国共产党尊称孙中山为“中国近代民主革命的伟大先行者”。每年的11月12日便是孙中山先生诞辰纪念日。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "每年的孙中山先生诞辰日，全国各地都会开展各种活动纪念孙中山诞辰。南京建有孙中山纪念馆。",
                    "2013年11月12日，全国政协在北京中山公园中山堂举行仪式，纪念中国民主革命的伟大先行者孙中山先生诞辰147周年。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "复活节",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "英文名称",
                        "value": "Easter Day"
                    },
                    {
                        "title": "节日时间",
                        "value": "每年过春分月圆后的第一个星期天"
                    },
                    {
                        "title": "节日类型",
                        "value": "宗教节日"
                    },
                    {
                        "title": "节日意义",
                        "value": "为纪念耶稣基督"
                    }
                ],
                "detail": [
                    "基督徒认为，复活节象征着重生与希望，为纪念耶稣基督于公元30到33年之间被钉死在十字架之后第三天复活的日子。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "《圣经.新约全书》记载，耶稣被钉死在十字架上，第三天身体复活，复活节因此得名。复活节是基督宗教最重大的节日，重要性超过圣诞节，宗教起源与节期在以色列。按《圣经.马太福音》的说法，耶稣基督在十字架上受刑死后三天复活，因而设立此节。",
                    "历史学家根据《圣经》和先进以色列人逾越节的日期，推算出在春分日（3月21日）之后月满后的第一个星期天就是《圣经》中讲到耶稣复活的日子。由于每年的春分日都不固定，所以每年的复活节的具体日期也是不确定的。但节期大致在3月22日至4月25日之间。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "1.复活节彩蛋",
                    "节日期间，人们按照传统习俗把鸡蛋煮熟后涂上红色，代表天鹅泣血，也表示生命女神降生后的快乐；大人孩子三五成群地聚在一处，用彩蛋作游戏；他们把彩蛋放在地上或土坡上滚，最后破裂者即为获胜，胜利者可以得到所有游戏者的彩蛋。",
                    "2.宗教游行",
                    "过去，在多数西方国家里，复活节一般要举行盛大的宗教游行。游行者身穿长袍，手持十字架，赤足前进。他们打扮成基督教历史人物，唱着颂歌欢庆耶稣复活。如今节日游行已失去往日浓厚的宗教色彩。节日游行洋溢着喜庆的气氛，具有浓烈的民间特色和地方特色。在美国，游行队伍中即有身穿牛仔服踩高跷的小丑，也有活泼可爱的卡通人物米老鼠。在英国，游行多以介绍当地的历史和风土人情为主，游行者化装成为苏格兰风笛乐队以及皇宫卫士，吸引了众多的游客。",
                    "3.穿新衣",
                    "复活节的到来还使人们纷纷换上新衣。过去基督教教徒会在节前去教堂行洗礼，然后穿上自己的新袍，庆祝基督的新生。穿戴一新的习俗保留至今，因为人们认为节日里不穿新衣是要倒运的。",
                    "4.打扫",
                    "复活节期间，人们还喜欢彻底打扫自己的住处，表示新生活从此开始。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "农历新年",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "农历正月初一至初三"
                    },
                    {
                        "title": "节日意义",
                        "value": "农历新年的开始"
                    }
                ],
                "detail": [
                    "农历新年的历史悠久，据传起源于殷商时期。按照农历，正月初一称元日、元辰、元正、元朔、元旦等，俗称大年初一。农历节气非常准，新年到，意味着春天将要来临，万象更新草木复苏，新一轮播种和收获季节又要开始。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "关于春节的起源有说法诸多，其中有几种较具代表性的说法，春节源于腊祭、源于巫术仪式说、源于鬼节说等，其中最被普遍接受的说法是春节由虞舜时期兴起。",
                    "公元前2000多年的一天，舜即天子位，带领着部下人员，祭拜天地。从此，人们就把这一天当作岁首。据说这就是农历新年的由来，后来叫春节。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "1.谢灶",
                    "腊月二十三日送灶神，澳门人谓之“谢灶”。澳门人给灶神按中国传统也用灶糖，说是用糖糊灶神之嘴，免得其到玉帝面前说坏话。",
                    "2.守岁",
                    "守岁是打麻将，看电视，叙旧聊天，共享天伦之乐；大概受西方圣诞节和情人节的影响，澳门人还争相购买一些吉祥的花木迎接新春，现今已成了一个澳门年俗。",
                    "3.逛花市",
                    "澳门在年宵兴办花市，多是桃花，水仙、盆竹、盆桔，花开富贵，祝报平安，鲜花瑞木兆示着新年的美好前程。澳门的花市办三天，这三天给奔波一年的澳门人无穷的慰藉。",
                    "4.利市",
                    "春节这天，澳门人讲究“利市”，“利市”就是红包，这天老板见到员工，长辈见到晚辈，甚至已婚人见到未婚人都得“利市”。“利市”纯脆是以示吉利。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "圣母无原罪瞻礼",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "每年的12月8日"
                    },
                    {
                        "title": "宗教",
                        "value": "天主教"
                    },
                    {
                        "title": "节日意义",
                        "value": "庆祝圣母玛利亚获得无原罪的恩赐的一个瞻礼"
                    }
                ],
                "detail": [
                    "在奥地利、哥斯达黎加、智利、西班牙、意大利、爱尔兰、马耳他和澳门等地都将此节日立为假期。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "节日可追溯自8世纪的“安纳怀孕玛丽亚”，庆祝日是12月9日。庆祝的对象是安纳老年怀孕，也庆祝圣母玛丽亚为完全圣洁，没有受原罪所玷染。后来节日传到意大利南部、英国及法国，节日的重点对象改变为圣母玛丽亚，强调玛丽亚从生命开始，巳不受原罪玷染。",
                    "由于此思想没有《圣经》根据，故在12世纪时曾受到一些神学家的反对，其中著名的有：圣纳德。到13世纪时，由于方济各会支持此为教义思想，圣母始胎无原罪的神学思想逐渐被接受。在1477年，教宗西斯笃四世批准此节日在罗马教区举行。到1708年，教宗克莱孟十一世把节日定为整个教会应举行的庆节。最后，教宗良十三世提升此节日为第一等节日，有8日庆期。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "耶稣受难日",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "复活节的前第三天"
                    },
                    {
                        "title": "节日类型",
                        "value": "基督教节日"
                    },
                    {
                        "title": "节日意义",
                        "value": "纪念耶稣之“死"
                    },
                    {
                        "title": "设定时间",
                        "value": "公元4世纪"
                    }
                ],
                "detail": [
                    "耶稣受难日是纪念耶稣生命中最高潮的一周(即“圣周”，又称“受难周”)中最重大的日子。这一周是从复活节前的一个星期日（棕榈主日——耶稣进入耶路撒城，民众手持棕榈枝欢迎他）开始，经复活节前的星期四(立圣餐日——纪念耶稣与门徒进“最后的晚餐”时设立圣餐礼)和星期五（受难日——纪念耶稣为世人的罪被钉十字架而死）到复活节（星期日）结束。“圣周”的主题也是《新约圣经》——耶稣基督的受死和复活。"
                ]
            },
            {
                "title": "人物简介",
                "detail": [
                    "耶稣，也称为基督，意思是王或弥赛亚，2000年前生于以色列。现代文明把时间分为公元前（即基督前）和公元（即主年）的纪年方式来纪念耶稣的诞生。耶稣30岁以前是个木匠，过着犹太人的传统生活。耶稣三十岁以后开始教导众人，行神迹，并被记载下来。但他从来都没有远行到距离出生地200英里（大约320公里）以外的地方。耶稣在三年的时间里一直尽力保持低调，但他的名声还是传遍了全国，引起了设在以色列各省执政掌权的罗马官员和犹太领袖（宗教律法师）的注意。",
                    "耶稣最受争议的就是他一直声称自己就是神，直接干犯了宗教律法，因而宗教领袖要求罗马政府处死他。罗马当局几次审讯都没发现耶稣触犯了罗马的法律。就连犹太人的领袖也承认，耶稣除了自称为神之外，完好地遵行了犹太人的律法。但他们还是以对政府不利为由，说服以色列南省的罗马总督彼拉多下令将耶稣处决。",
                    "耶稣残忍地遭到严刑拷打，然后双手被人挂起来，钉在一根水平的木梁（十字架）上。这种行刑方法使得空气无法吸入肺部，三小时以后他就死了。然而，有五百多人却见证说，他三天以后从死里复活了，而且此后的40天里在以色列的南北两省走动。很多人认为这就足以证明耶稣自称为神是真实的。后来，耶稣返回了自己不久前遇害的城市耶路撒冷，见证人说他从那里离开了地球，升到天上去了。"
                ]
            },
            {
                "title": "纪念活动",
                "detail": [
                    "1.受难日",
                    "“受难日”是纪念耶稣之“死”。这一天信徒们身穿深色服装，参加礼拜仪式时言容肃穆。天主教徒在祷告时还要将蜡烛顺次熄灭，使教堂暗黑。仪式上要诵读《旧约·以赛亚书》里的几节经文。以赛亚是古代的希伯来先知，他似乎是预言了基督受难，天主教会称这段文字为“受苦仆人”经文。",
                    "2.圣餐礼",
                    "圣餐礼，是为纪念耶稣受难日而举行的一种仪式。这个仪式是耶稣在和门徒们共进“最后的晚餐”时创立的，当时他已经知道门徒中有人出卖了他。",
                    "3.拜十字架",
                    "天主教的仪式上还要奉行崇拜十字架的礼仪。该礼仪最早出现在耶路撒冷。十字架先被遮盖，随后逐渐显露，然后被安放在祭坛前，信徒们要对十字架进行跪拜。",
                    "4.重走耶稣受难",
                    "对耶稣受难日这个节日，天主教、东正教以及基督教圣公会、信义会和其他一些派别都有礼拜仪式。在许多地方，不同教派在这一天联合举行礼拜，表示团结合一。",
                    "除了圣餐礼、晨祷、晚崇拜之外，受难日的游行活动在天主教徒聚居的社区也很常见。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "追思节",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "英文名称",
                        "value": "All Soul's Day"
                    },
                    {
                        "title": "节日别称",
                        "value": "万灵节、追思已亡节"
                    },
                    {
                        "title": "节日时间",
                        "value": "11月2日，逢星期日移至11月3日"
                    },
                    {
                        "title": "节日类型",
                        "value": "宗教节日"
                    }
                ],
                "detail": [
                    "澳门11月2日是追思节,天主教会和圣公会的炼灵月的第二天，俗称“天主教的清明节”，是用作纪念亲人、炼灵等的瞻礼日。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "天主教纪念已去世教徒的节日。按其教义，在世信徒的代祷行为可以帮助那些在炼狱中涤罪的信徒亡灵，使之尽早涤尽罪恶、升入天堂。早期教会就有为亡者祈祷的风尚。6世纪时本笃会开始在圣灵降临节举行追思已亡修道者的活动。998年，克吕尼大修道院的修士圣奥迪劳（St.Odilo）将万圣节（11月1日）的后一天（11月2日）定为追思已亡教友之日，即“追思节”。如逢星期日，则移至11月3日。"
                ]
            },
            {
                "title": "节日活动",
                "detail": [
                    "圣味基坟场会在当天举行追思弥撒。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "和平纪念日",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日别称",
                        "value": "二二八和平纪念日"
                    },
                    {
                        "title": "节日时间",
                        "value": "每年2月28日"
                    },
                    {
                        "title": "节日意义",
                        "value": "纪念发生于1947年2月28日的二二八事件"
                    }
                ],
                "detail": [
                    "二二八事件及当时政府事后的清乡工作，造成许多无辜台湾人民的死伤，也加深了台湾本省人和外省人间的族群及文化隔阂。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "在戒严时期，二二八事件向来是政府相当忌讳的话题，直到1980年代后期，才逐渐有要求政府为二二八事件平反及道歉的声音出现。1995年2月28日，当时的台湾“总统”李登辉代表台湾政府首次为二二八事件，向受难者家属及全体人民道歉。1996年，台北市长陈水扁宣布将2月28日定为纪念假日，并将台北新公园更名为“二二八和平公园”，台湾“行政院”亦于同年订定2月28日为“和平纪念日”，并为台湾假日。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "2006年起，每年二二八和平纪念日当天，全台各机关学校、团体都要降半旗，以表示对二二八事件死难者最深的哀悼之意。在纪念日当天放假一日。"
                ]
            }
        ]
    },
    {
        "disp_name": "中国农民丰收节",
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "每年农历秋分"
                    },
                    {
                        "title": "设定时间",
                        "value": "2018年6月21日"
                    },
                    {
                        "title": "节日意义",
                        "value": "党中央对“三农”工作的高度重视，对广大农民的深切关怀"
                    }
                ],
                "detail": [
                    "2018年是中国改革开放40周年，中国的改革是从农村发端，举办“中国农民丰收节”可以展示农村改革发展的巨大成就，展示中国农民的伟大创造，增强加快建设社会主义现代化农业强国的决心和信心。"
                ]
            },
            {
                "title": "背景",
                "detail": [
                    "农民是中国人口的最大多数，是中国共产党执政的基础，广大农民在革命、建设、改革等各个历史时期都做出了重大贡献。习近平总书记强调，任何时候都不能忽视农业、忘记农民、淡漠农村。中央决定，在脱贫攻坚的关键时期、全面建成小康社会的决胜阶段、实施乡村振兴战略的开局之年，设立“中国农民丰收节”，顺应了新时代的新要求、新期待，将极大调动起亿万农民的积极性、主动性、创造性，提升亿万农民的荣誉感、幸福感、获得感，汇聚起脱贫攻坚、全面建成小康社会、实施乡村振兴战略，加快推进农业农村现代化的磅礴力量。",
                ]
            },
            {
                "title": "意义",
                "detail": [
                    "这个节日的设立，是习近平总书记主持召开中央政治局常委会会议审议通过，由国务院批复同意的。这是第一个在国家层面专门为农民设立的节日。设立一个节日，由中央政治局常委会专门审议，这是不多见的，充分体现了以习近平同志为核心的党中央对“三农”工作的高度重视，对广大农民的深切关怀。",
                ]
            },
        ]
    },

    {
        "id": 1,
        "disp_name": "孔子诞辰纪念日",
        "short_desc": "三人行，必有我师焉",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "每年9月28日"
                    },
                    {
                        "title": "节日意义",
                        "value": "纪念孔子诞辰"
                    },
                    {
                        "title": "人物成就",
                        "value": "开创儒家学派、编纂《春秋》、修订“五经”、创办私学"
                    }
                ],
                "detail": [
                    "孔子是春秋时期的著名教育家，被尊为“至圣先师”。孔子被称为圣人，所以孔子诞辰日又被称为“中国的圣诞节”。中国古代祭孔，都以每年农历八月二十七作为孔子诞辰。民国期间，政府曾定夏历8月27日作为教师节，后又把它换算为西元9月28日。这已为传统习惯，人们从心理上已经认可它。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "关于孔子诞辰的早期记载，见于《公羊传》、《谷梁传》和《史记》。",
                    "孔丘（公元前551年9月28日），字仲尼。排行老二，汉族人，春秋时期鲁国人。孔子是我国古代伟大的思想家和教育家，儒家学派创始人，世界最著名的文化名人之一。孔子创立的儒家学说以及在此基础上发展起来的儒家思想，对中华文明产生了深刻影响，是中国传统文化的重要组成部分。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "在孔子诞辰日，中国大陆、香港、澳门、台湾、韩国、日本等地都有纪念活动。在文庙举行“祭孔典礼”是常见的正式纪念活动。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "台湾光复节",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日别称",
                        "value": "光复节、光复日"
                    },
                    {
                        "title": "节日时间",
                        "value": "10月25日"
                    },
                    {
                        "title": "节日类型",
                        "value": "纪念历史"
                    },
                    {
                        "title": "设定地点",
                        "value": "中国台湾省"
                    }
                ],
                "detail": [
                    "中日甲午战争后，日本对台湾实行殖民统治。第二次世界大战日本战败投降后，于1945年10月25日中国政府在台北市举行中国战区台湾省对日本的受降典礼。翌年8月，台湾省行政长官公署颁布命令，明订10月25日为“台湾光复节”以为纪念。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "台湾于甲午战争失败后，割让给日本，民国三十四年（即西元1945年）对日抗战胜利，台湾才告光复。同年九月九日在南京接受日本投降，台湾地区的受降典礼则于同年的十月二十五日在台北举行。后遂定每年的十月二十五日为台湾光复节。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "依据台湾地区《纪念日及节日实施办法》，台湾光复节属于不放假的节日，而由相关机关，团体，学校举行庆祝活动。每年的台湾光复节，全省都会张灯结彩，扩大庆祝。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "天穿日",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "一般为正月二十"
                    },
                    {
                        "title": "节日意义",
                        "value": "纪念女娲补天拯救人类的纪念日"
                    },
                    {
                        "title": "流行地区",
                        "value": "广东、福建、台湾等客家人聚居地"
                    }
                ],
                "detail": [
                    "天穿日是元宵之后的第一个汉族传统节日，时间有正月初十、二十、二十三、二十五等几种说法，以正月二十为多。天穿节又叫补天节，源于女娲补天的神话故事。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "关于天穿节的由来，东晋以降的资料多说是源自女娲补天的传说。女娲之名，始见战国成书的《楚辞·天问》：“女娲有体，孰制匠之？”而女娲补天的神话，出自西汉淮南王刘安与其门客共撰《淮南子》。",
                    "天穿节的日期与女娲补天神话主旨是一致的。叶春声指出：“考‘天穿’日即二十四节气中的‘雨水’日，一般在每年阳历二月十九日，阴历正月十九至二十三日左右，是日‘天一生水’，多半有雨，故谓之‘天穿’。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "1、煎饼“补天穿”",
                    "“二十日天穿，二十一日地穿”。天穿日与雨水相近，汉族民间为祈祷苍天保佑“雨水之日，屋无穿漏”，每逢天穿节便用红丝线系上煎饼，将其掷于屋顶上方，寓意为“补天漏”或“补天穿”。有祈风调雨顺之意，也是对女娲补天的一种效仿。有些地方在此日还将煎饼撕成小块，抛向天空，意为补天，再撒些于地，意为补地。",
                    "2、天穿射",
                    "古时还有在节日那天到郊外举行射箭活动的习俗，有人认为这也隐含着以武功继承女娲杀黑龙、断鳌足、拯生民于水火之伟绩的意思。",
                    "3、甜畸扎针",
                    "岭南、福建、台湾等地的客家妇女在此日把甜畸做成大圆块，以油煎后，上面扎针，也是“补天穿”之意。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "辛亥革命纪念日",
        "short_desc": "以史为鉴，展望未来",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "每年公历10月10日"
                    },
                    {
                        "title": "设定时间",
                        "value": "1912年9月（民国初年)"
                    },
                    {
                        "title": "流行地区",
                        "value": "中国及海外华侨聚居区"
                    },
                    {
                        "title": "节日意义",
                        "value": "纪念推翻满清封建帝制和中华民国成立"
                    }
                ],
                "detail": [
                    "辛亥革命，是指发生于中国农历辛亥年（清宣统三年），即公元1911年至1912年初，旨在推翻清朝专制帝制、建立共和政体的全国性革命。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "清帝退位",
                    "1912年2月2日，清廷举行御前会议，会上决定退位，以取得革命党人的优待条件。",
                    "袁世凯上台",
                    "1912年2月13日，孙中山提出辞呈，向临时参议院推荐袁世凯接任。2月15日临时参议院选袁世凯任临时大总统，议决临时政府仍设在南京，并电袁前来受职；未受任前，政务仍由孙中山继续执行。"
                ]
            },
            {
                "title": "影响",
                "detail": [
                    "废除帝制",
                    "辛亥革命以反对君主专制制度、建立资产阶级共和国为目标，是一次比较完全意义上的资产阶级民主革命。正如毛泽东指出的:“中国反帝反封建的资产阶级民主革命，正规地说起来，是从孙中山开始的。”辛亥革命的领袖们，则认识到要救国必须革命，要想救亡图存，只能是推翻封建皇权。",
                    "思想解放",
                    "辛亥革命冲破了封建思想的牢笼。自汉代董仲舒以来的中国思想文化，君臣关系是“三纲五常”中三纲之首。皇帝不仅是政治上的权威，也是文化中诸多价值观念的重要依据与合法性的来源。辛亥革命不但从行动上坚决地打倒皇帝，并从舆论上对君权神授观念和皇权思想进行鞭挞和批判，极大地促进了人民的思想解放，为探索救国兴邦的道路打开了新的思想境界。",
                    "除旧布新",
                    "辛亥革命促进了社会习俗的除旧布新。辛亥革命的实际政治影响远远超出了人们的预料。政治体制的革故鼎新带来了民国初年的中国社会面貌为之一新。正是这些新的变化，打开了中国通向现代化的闸门。",
                    "经济发展",
                    "辛亥革命为民族资本主义的发展创造了有利的条件。民国建立以后，国内实业集团纷纷成立，开工厂、设银行成为风气。民族资本主义的经济力量在短短的几年内就有了显著的增长，无产阶级队伍也迅速壮大起来。",
                    "此外，从辛亥革命各省独立响应开始，中国进入长期的分裂混乱之中，除了袁世凯曾在二次革命之后曾短暂大致统一全国之外，其他中华民国的政权都未能直接统治整个中国(如号称统一的国民政府只能收到五个省的税收)，一直要到1950年代，才由中国共产党使中国大陆再度统一。长期的分裂及战乱，对于中国的经济发展及现代化建设造成很大的阻碍。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "祖父母节",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "8月第4个星期日"
                    },
                    {
                        "title": "节日起源",
                        "value": "世界有许多国家及地区订有“祖父母节”"
                    },
                    {
                        "title": "设立机构",
                        "value": "台“教育部”"
                    }
                ],
                "detail": [
                    "台“教育部”公布调查显示，台湾超过6成孙子孙女不记得祖父母生日，超过1成孙子女不知道祖父母名字。“教育部”将首度发起“祖父母节”，盼能唤起晚辈对长者的重视。"
                ]
            },
            {
                "title": "对象",
                "detail": [
                    "“祖父母节”对象不仅是“内祖父母”，也包括“外祖父母”，或敬若为祖父母的长者。"
                ]
            },
            {
                "title": "相关数据",
                "detail": [
                    "中正大学成人及继续教育学系副教授李蔼慈发表的“2010跨世代间互动关系”调查指出，80%祖父母能完全说出孙子女的名字，但只有36.7%的孙子女能完全说出祖父母的名字，10.3%孙子女则完全不知道祖父母的名字。调查中显示，近4成的祖父母完全记得孙子女的生日，但仅有5.8%的孙子女完全记得祖父母的生日，且超过6成的孙子女完全不记得祖父母生日。",
                    "而据香港《大公报》报道，香港一项调查发现，虽然年轻人有意识认为应多与祖父母相处，但却欠缺主动和实际行动。当中，78%表示应多抽时间陪伴他们，但有85%称不会与他们同住，逾70%更不会主动约会或致电给他们。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "香港特别行政区成立纪念日",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "7月1日"
                    },
                    {
                        "title": "流行地区",
                        "value": "香港"
                    },
                    {
                        "title": "节日性质",
                        "value": "纪念日"
                    }
                ],
                "detail": [
                    "1997年7月1日，中华人民共和国主席江泽民在香港向全世界郑重宣告：中华人民共和国香港特别行政区政府成立。因此这一天也成为香港回归纪念日。"
                ]
            },
            {
                "title": "背景",
                "detail": [
                    "香港是中国的领土，为了完成祖国统一的大业，中国人民收回香港的主权不可非议。但是，采取什么样的方式收回香港主权才是最有利的。“一个国家，两种制度”是邓小平同志集中党中央的集体智慧，经过深思熟虑提出来的，以和平方式实现国家统一的伟大战略构想，是中外历史上史无前例的伟大创举。",
                    "1984年12月19日,中国政府和英国首相撒切尔夫人在北京正式签订《中英关于香港问题的联合声明》，规定中华人民共和国政府1997年7月1日对香港恢复行使主权。",
                    "1997 年7月1日香港回归变成现实，但是这一伟大创举的设计师——我们敬爱的邓小平同志却离开了我们，他老人家看不到自己亲自设计的“和平统一，一国两制”愿望的实现。至此，我们更加缅怀邓小平同志的丰功伟绩，我相信，他老人家会同我们一道庆祝这一举世瞩目的日子——1997年7月1日香港回归。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "佛诞",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "农历四月初八"
                    },
                    {
                        "title": "节日别称",
                        "value": "佛诞节、佛诞日"
                    },
                    {
                        "title": "节日意义",
                        "value": "纪念佛祖诞辰"
                    }
                ],
                "detail": [
                    "佛诞节，也就是说是佛教佛祖释迦牟尼诞辰，而且也是在不同国家中都有着不同的姓氏来去纪念佛祖的诞生。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "公元前1027年，农历四月初八，佛祖诞生在北印度，有天上九龙吐出香水为太子洗浴。因此典故，便有了庆祝的重要内容之一：以香水沐浴佛身。所以，浴佛节又名佛诞节。",
                    "中国东汉时仅限于寺院举行，到魏晋南北朝时流传至民间。浴佛时间在史籍中有不同记载。蒙古族、藏族地区以四月十五日为佛诞日，即佛成道日、佛涅盘日，故在这天举行浴佛仪式。汉族地区佛教在北朝时多在农历四月初八举行，后不断变更、发展，北方改在农历十二月初八(腊八节)举行 ，南方则仍为农历四月初八举行 ，相沿至今（俗称“四月八”）。傣族的泼水节在傣历六月（夏历四月）举行 ，清晨，男女老幼沐浴更衣，到佛门寺院礼佛，就带有浴佛的性质。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "1.中国大陆",
                    "",
                    "信众会到寺院，参与浴佛、献花、献果、供僧、供舍利、演戏等等节目。",
                    "有些地方传统会将佛像请到街上游行，置于大象上，或以花车乘载。寺院会开放让信徒进入炷香、礼拜佛像、供养僧众。",
                    "今日的华人地区，常会引入世间庆祝的习惯，如舞龙舞狮，张灯挂彩，甚至燃放炮竹。",
                    "2.台湾地区",
                    "",
                    "台湾佛教徒普遍于农历四月初八举行庆祝及浴佛典礼。经佛教徒之争取，1999年起，中华民国内政部在国定纪念日中正式纳入佛陀诞辰纪念日，日期为农历四月八日，由有关机关、团体举行纪念活动。由于日期接近“母亲节”（五月第二个星期日），一些庆祝活动也会于母亲节举行。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "香港回归纪念日",
        "short_desc": "香港回归，祖国万岁",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "7月1日"
                    },
                    {
                        "title": "流行地区",
                        "value": "香港"
                    },
                    {
                        "title": "节日性质",
                        "value": "纪念日"
                    }
                ],
                "detail": [
                    "1997年7月1日，中华人民共和国主席江泽民在香港向全世界郑重宣告：中华人民共和国香港特别行政区政府成立。因此这一天也成为香港回归纪念日。"
                ]
            },
            {
                "title": "背景",
                "detail": [
                    "香港是中国的领土，为了完成祖国统一的大业，中国人民收回香港的主权不可非议。但是，采取什么样的方式收回香港主权才是最有利的。“一个国家，两种制度”是邓小平同志集中党中央的集体智慧，经过深思熟虑提出来的，以和平方式实现国家统一的伟大战略构想，是中外历史上史无前例的伟大创举。",
                    "1984年12月19日,中国政府和英国首相撒切尔夫人在北京正式签订《中英关于香港问题的联合声明》，规定中华人民共和国政府1997年7月1日对香港恢复行使主权。",
                    "1997 年7月1日香港回归变成现实，但是这一伟大创举的设计师——我们敬爱的邓小平同志却离开了我们，他老人家看不到自己亲自设计的“和平统一，一国两制”愿望的实现。至此，我们更加缅怀邓小平同志的丰功伟绩，我相信，他老人家会同我们一道庆祝这一举世瞩目的日子——1997年7月1日香港回归。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "七七事变纪念日",
        "short_desc": "硝烟已经散去，血与泪的国耻不容遗忘",
        "area": 1,
        "type": 1,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "每年的七月七日"
                    },
                    {
                        "title": "节日意义",
                        "value": "纪念卢沟桥事变"
                    }
                ],
                "detail": [
                    "1937年7月7日夜，日军在北平西南卢沟桥附近演习时，借口一名士兵“失踪”，要求进入宛平县城搜查，遭到中国守军第29军严辞拒绝。日军遂向中国守军开枪射击，又炮轰宛平城。第29军奋起抗战。这就是震惊中外的七七事变，又称卢沟桥事变。七七事变是日本帝国主义全面侵华战争的开始，也是中华民族进行全面抗战的起点。"
                ]
            },
            {
                "title": "历史背景",
                "detail": [
                    "1931年9月18日，日军挑起九一八事变，占领中国东北，并一手炮制了伪“满洲国”。日军占领东北后，将魔爪伸向华北，阴谋策动“华北自治”。1936年6月，日本天皇批准了新的《帝国国防方针》及《用兵纲领》，公然宣称要实现控制东亚大陆和西太平洋，最后称霸世界的野心。8月7日，日本五相会议通过了《国策基准》，具体地规定了侵略中国，进犯苏联，待机南进的战略方案。同时，还根据1936年度侵华计划，制定了1937年侵华计划。从1936年5月起，日本陆续增兵华北，不断制造事端，频繁进行军事演习，华北局势日益严峻。"
                ]
            },
            {
                "title": "事件影响",
                "detail": [
                    "“卢沟桥事变”标志着中华全民族抗日战争的开始。 中国人民经过流血牺牲、艰苦抗战，终于在1945年8月15日以日本宣布无条件投降赢得了民族解放战争的伟大胜利。”"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "中国医师节",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "每年8月19日"
                    },
                    {
                        "title": "设定时间",
                        "value": "2017年11月3日"
                    },
                    {
                        "title": "设立机构",
                        "value": "国务院"
                    },
                    {
                        "title": "节日意义",
                        "value": "国家设立“中国医师节”，对于加强医师职业规范、加强行业自律，更好地改善医患关系，其积极意义不容忽视。"
                    }
                ],
                "detail": [
                    "2017年11月3日，国务院通过了卫计委（今卫健委）关于“设立中国医师节”的申请，同意自2018年起，将每年的8月19日设立为“中国医师节”。"
                ]
            },
            {
                "title": "背景",
                "detail": [
                    "2016年8月19日，全国卫生与健康大会明确了卫生与健康工作在党和国家事业全局中的重要位置和新时代卫生与健康工作方针。大会提出把人民健康放在优先发展的战略地位，努力全方位全周期保障人民健康。以此为标志设立“中国医师节”，体现了党和国家对1100多万卫生与健康工作者的关怀和肯定。"
                ]
            },
            {
                "title": "历程",
                "detail": [
                    "2017年11月3日，国务院同意设立“中国医师节”。国务院批复同意自2018年起，将每年8月19日设立为“中国医师节”，明确具体工作由国家卫生计生委（今国家卫健委）商有关部门组织实施。国家卫计委相关负责人表示，将会同有关部门组织好“中国医师节”的各项活动，激励广大卫生与健康工作者大力弘扬“敬佑生命、救死扶伤、甘于奉献、大爱无疆”的崇高精神，进一步推动全社会形成尊医重卫的良好氛围，加快推进健康中国战略深入实施。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "中国航天日",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "中文名称",
                        "value": "中国航天日"
                    },
                    {
                        "title": "外文名称",
                        "value": "Space Day of China"
                    },
                    {
                        "title": "节日时间",
                        "value": "每年4月24日"
                    },
                    {
                        "title": "首届时间",
                        "value": "2016年"
                    },
                    {
                        "title": "设立机构",
                        "value": "中华人民共和国国务院"
                    },
                    {
                        "title": "目的",
                        "value": "铭记历史，传承精神"
                    }
                ],
                "detail": [
                    "中国航天日，是为了纪念中国航天事业成就，发扬中国航天精神而计划设立的一个纪念日。首个航天日，习主席要求科学家帮助实现中国成为全球天空巨头的梦想。设立“中国航天日”，就是要铭记历史、传承精神，激发全民尤其是青少年崇尚科学、探索未知、敢于创新的热情。"
                ]
            },
            {
                "title": "由来",
                "detail": [
                    "1970年4月24日，中国第1颗人造地球卫星东方红1号发射成功，拉开了中国人探索宇宙奥秘、和平利用太空、造福人类的序幕。以此为标志设立“中国航天日”，旨在宣传我国和平利用外层空间的一贯宗旨，大力弘扬航天精神，科学普及航天知识，激发全民族探索创新热情，唱响“探索浩瀚宇宙、发展航天事业、建设航天强国”的主旋律，凝聚实现中国梦航天梦的强大力量。"
                ]
            },
            {
                "title": "意义",
                "detail": [
                    "设立“中国航天日”是大力弘扬以爱国主义为核心的中华民族精神，构建社会主义核心价值体系，激励中华儿女热爱祖国、崇尚科学，增强中华民族凝聚力和向心力的迫切需要。",
                    "设立“中国航天日”，使航天人及所有科技工作者能够拥有自己的纪念日，可有效激励航天人以及全国的科技工作者，以中国航天创业史为教材，以航天英雄为榜样，在航天精神、“两弹一星”精神、载人航天精神的鼓舞激励下，献身航天，献身科学，不断攀登新的科学高峰，再创新的辉煌。同时也引导全国人民铭记航天人为中国科技发展和综合国力增强所做出的牺牲和奉献，关心和支持祖国航天事业的未来发展。",
                    "设立“中国航天日”，可有效地激发全国青少年热爱祖国，崇尚科学，崇尚科学家的热情，引导青少年树立正确的价值观，学习航天人自主创新、自强不息、艰苦奋斗、勇攀高峰的民族精神，积极投身科技事业，努力探索与利用外层空间，促进人类进步和社会发展，造福全人类，以所学的知识报效祖国。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "全国儿童预防接种宣传日",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "中文名称",
                        "value": "全国儿童预防接种宣传日"
                    },
                    {
                        "title": "节日时间",
                        "value": "每年4月25日"
                    },
                    {
                        "title": "目的",
                        "value": "促进儿童预防接种"
                    }
                ],
                "detail": [
                    "计划免疫宣传日就是“4.25”全国儿童预防接种宣传日，每年的这一天，既是广大儿童的节日，也是全国计划免疫工作者的节日。 1986年6月20日，经国务院批准，由卫生部、国家教育委员会、全国妇联、广播电影电视部、经济贸易部、国家民委联合发布通知，成立全国儿童计划免疫工作协调小组和每年4月25五日开展全国儿童预防接种日活动，其近期目标是实现国家“七五”计划提出的1990年争取“使全国免疫接种率达到85%以上”的目标。"
                ]
            },
            {
                "title": "宣传简介",
                "detail": [
                    "九十年代以来，每年选定一个重点内容作为当年全国儿童预防接种日活动的主题，尤其是紧紧围绕WHO“全球消灭脊髓灰质炎”的行动纲领的要求，将消灭脊髓灰质炎作为“巩固和发展我国计划免疫工作成果，保护儿童健康”的一项重要目标。全国儿童预防接种日活动每一年主题的确立对全国各级政府、卫生行政部门和各级各类卫生防疫机构普及计划免疫和提高儿童免疫接种率起到了积极的推动作用。通过全国儿童预防接种宣传日活动的开展，使广大市民免疫预防的自觉性不断提高，也使每个儿童家长了解并深深体会到“计划免疫是每个孩子都应享有的权利”。“全国儿童预防接种日活动”使全国儿童获得高水平的免疫种率成为现实，为保护儿童健康、造福子孙后代，提高我国的人口素质做出了重要的贡献。"
                ]
            },
            {
                "title": "由来",
                "detail": [
                    "儿童免疫预防接种，关系到下一代的健康成长，涉及千家万户。",
                    "80年代后，我国政府积响应世界卫生组织提出的扩大计划免疫规划（EPI，Expanded Programme on Immunization）并积极开展工作。",
                    "为了加强对该工作的组织实施，进一步提高影响力度，促进社会各界人士积极参与，保证免疫接种率，有效地防止相应传染病的发生和流行，达到最终消灭疾病的目的，1986年经国务院批准确定，成立了全国计划免疫协调领导小组，并确定每年4月25日为全国儿童预防接种宣传日。",
                    "至此，我国已建立了完善的免疫接种程序：在婴儿出生24小时内，即进行乙肝疫苗接种，并在婴儿1周岁之前，完成程序中的所有基础免疫。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "世界舞蹈日",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "中文名称",
                        "value": "世界舞蹈日"
                    },
                    {
                        "title": "节日时间",
                        "value": "每年4月29日"
                    },
                    {
                        "title": "设定时间",
                        "value": "1982年"
                    }
                ],
                "detail": [
                    "世界舞蹈日是4月29日，由联合国教科文组织下的国际舞蹈委员会（CID）推广，1982年国际舞蹈委员会最先提出。"
                ]
            },
            {
                "title": "简介",
                "detail": [
                    "这个日期是为了纪念现代芭蕾舞之父Jean-Georges Noverre。"
                ]
            },
            {
                "title": "目标",
                "detail": [
                    "世界舞蹈日的目标是增加大众对舞蹈的重要性的注意，同时希望各国政府在从初等教育到高等教育中均为舞蹈提供空间。尽管舞蹈是人类文化不可缺少的一部分，它不获全球的官方机构重视。CID的主席Alkis Raftis教授在2003年世界舞蹈日讯息说：“在全球约二百个国家中，过半数的国家的法律文件（不论有关好还是坏的）没有提及舞蹈。州的财政预算没有资金来支持这种艺术形式。无论是私人还是公共，都不存在舞蹈教育这回事。”"
                ]
            },
            {
                "title": "纪念活动",
                "detail": [
                    "2005年的世界舞蹈日以初等教育为重心。CID鼓励舞蹈团体联络所在地的教育部门，向它们提出所有学校书写有关舞蹈的文章、绘画舞蹈的情形和在街上跳舞等以庆祝此日。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "国际不打小孩日",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "别名",
                        "value": "绝体罚日、无巴掌日"
                    },
                    {
                        "title": "英文名",
                        "value": "International Spankout Day"
                    },
                    {
                        "title": "节日时间",
                        "value": "每年4月30日"
                    },
                    {
                        "title": "节日意义",
                        "value": "宣导反体罚的儿童人权观念"
                    },
                    {
                        "title": "设立机构",
                        "value": "Center For Effective Discipline"
                    }
                ],
                "detail": [
                    "国际不打小孩日（每年4月30日），由美国反体罚组织“有效管教中心（Center For Effective Discipline）”1998年发起的。节日通过各种活动，宣导反体罚的儿童人权观念。全世界已有上百个国家及民间组织参与此盛事。"
                ]
            },
            {
                "title": "背景",
                "detail": [
                    "在美国，尤其是在一些拉美、亚裔移民家庭以及是一些收入比较低，受教育程度比较低的黑人家庭，打孩子的现象相对于白人家庭来说还是比较普遍的，甚至存在严重的虐待、性侵等情况。正是因为美国的确存在虐待孩子的情况，美国儿童人权组织有效管理中心在发起了反对体罚儿童的运动。"
                ]
            },
            {
                "title": "节日文化",
                "detail": [
                    "设立这一“节日”的目的是为了宣传反体罚的儿童人权观念，引起社会的重视。",
                    "请来试试看，至少在今天不要打小孩，或者你将会发现，今天过后的每一天，你都不需要打小孩了。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "世界新闻自由日",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "别名",
                        "value": "新闻自由日"
                    },
                    {
                        "title": "英文名",
                        "value": "World Press Freedom Day"
                    },
                    {
                        "title": "节日时间",
                        "value": "每年5月3日"
                    },
                    {
                        "title": "设立机构",
                        "value": "联合国"
                    }
                ],
                "detail": [
                    "5月3日是世界新闻自由日（World Press Freedom Day）。世界新闻自由日是由联合国创建，旨在提高新闻自由的意识，并提醒政府尊重和提升言论自由的权利，该权利铭记在世界人权宣言第19条中。2013年5月3日是“世界新闻自由日”设立20周年。联合国5月2日举行特别活动纪念。"
                ]
            },
            {
                "title": "节日概述",
                "detail": [
                    "联合国大会于1993年12月20日作出决定，宣布5月3日为世界新闻自由日。这一行动源于联合国教科文组织大会。\n5月3日是世界新闻自由日，在这一天，全世界的人们都被告知：言论自由权被侵犯的现象仍然存在；他们被提醒：有许多新闻工作者，因为提供每日新闻而被捕入狱，甚至英勇牺牲。\n世界新闻自由日让世界再次重温《世界人权宣言》第19条所阐述的保护言论自由和新闻自由基本权利的重要意义。没有这些权利，就无法实现民主和发展。独立、自由和多元化的媒体能够确保透明度和落实问责制，促进社会参与和法制并有助于消除贫困，因此对民主社会的良政发挥着至关重要的作用。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "世界红十字日",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "别名",
                        "value": "红十字日"
                    },
                    {
                        "title": "英文名",
                        "value": "World Red Cross"
                    },
                    {
                        "title": "节日时间",
                        "value": "每年5月8日"
                    },
                    {
                        "title": "设立机构",
                        "value": "国际联合会执行委员会"
                    }
                ],
                "detail": [
                    "世界红十字与红新月日（World Red Cross and Red Crescent Day），是由红十字会与红新月会国际联合会（International Federation of Red Cross and Red Crescent Sieties）、红十字国际委员会 (International Committee of the Red Cross )以及190个国家红十字会和红新月会共同举办的纪念日，1948年，经国际联合会执行委员会同意，红十字创始人亨利·杜南先生的生日5月8日被定为红十字日。在这一天，红十字国际委员会、红十字会与红新月会国际联合会及各国红十字会和红新月会都以各种形式纪念这一日子，以表示红十字运动的国际性以及红十字人道工作不分种族、宗教及政治见解的特性。"
                ]
            },
            {
                "title": "发展过程",
                "detail": [
                    "红十字国际委员会（International Committee of The Red Cross）创建于1863年，是个独立的人道主义机构，也是红十字会的创始团体。在武装冲突或动乱场合，红十字国际委员会作为中立者，主动地根据日内瓦公约，努力保护和救助战争和内乱的受害者，从而对世界和平做出贡献。红十字会与红新月会国际联合会创建于1919年，前身是红十字会与红新月会协会。它的职责是促进各国红会开展人道工作、协调各国红会救济自然灾害者的行动，以及救助武装冲突地区以外的难民。国际联合会通过这些工作来推动世界和平。",
                    "1864年8月，红十字会在日内瓦再次举行会议，签署了第一个《改善战地武装部队伤者病者境遇之日内瓦公约》，即红十字会公约。从此，红十字会正式得到国际公约的承认和保护。由于宗教和历史等原因，一些伊斯兰国家类似的组织采用红新月或红狮和太阳作为标志。",
                    "红十字会或红新月会都是同一性质的志愿救护、救济团体，在世界上被认为是一个超越国界、超越时空的非政治、非宗教的人道主义团体。初创时旨在在战时照顾伤员，后成为一般地预防灾难、救济难民的机构。世界上许多国家都成立了红十字会或红新月会。在国际上，它们的联合组织是红十字会与红新月会国际联合会。"
                ]
            },
            {
                "title": "基本原则",
                "detail": [
                    "人道、公正、中立、独立、志愿服务、普遍。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "碘缺乏病防治日",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "每年5月15日"
                    },
                    {
                        "title": "设立机构",
                        "value": "国务院"
                    },
                    {
                        "title": "流行地区",
                        "value": "中国"
                    },
                    {
                        "title": "设定时间",
                        "value": "1993年9月"
                    },
                    {
                        "title": "节日意义",
                        "value": "消除碘缺乏病"
                    }
                ],
                "detail": [
                    "1993年9月国务院召开“中国2000年消除碘缺乏病动员会”，会议并提出：5月5日为全国碘缺乏病防治日，借以加大力宣传，提高人们对防治碘缺乏病的认识。1994年起每年的5月5日定为全国碘缺乏病宣传日。国家设立五一长假后，碘缺乏病防治日（5月5日）包含在了五一长假里，不便于宣传、教育工作的开展。经过卫生部与碘缺乏病防治相关部委的协调，防治碘缺乏病日自2000年起改为5月15日。"
                ]
            },
            {
                "title": "宣传口号",
                "detail": [
                    "一、坚持食用碘盐，预防出生缺陷\n二、坚持食用碘盐，提高人口素质\n三、防治碘缺乏病，保护母婴健康\n四、食用碘盐是最好的补碘方式\n五、抵制非碘盐，保护群众健康\n六、净化食盐市场，保护群众利益\n七、扶贫先增智，增智先补碘\n八、依法普及碘盐，构建和谐社会\n九、预防碘缺乏，建设新农村。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "世界电信日",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "每年5月17日"
                    },
                    {
                        "title": "设定时间",
                        "value": "1969年5月17"
                    },
                    {
                        "title": "特点",
                        "value": "每年世界电信日都有一个主题"
                    }
                ],
                "detail": [
                    "世界电信日（World Telecommunications Day），1969年5月17日，国际电信联盟第二十四届行政理事会正式通过决议，决定把国际电信联盟的成立日——5月17日定为“世界电信日”，并要求各会员国从1969年起，每年5月17日都要开展纪念活动。2006年11月，国际电信联盟把世界电信日和世界信息社会日合并为世界电信和信息社会日。"
                ]
            },
            {
                "title": "设立宗旨",
                "detail": [
                    "1969年5月17日，国际电信联盟第二十四届行政理事会正式通过决议，决定把国际电信联盟的成立日——5月17日定为“世界电信日”，并要求各会员国从1969年起，在每年5月17日开展纪念活动。\n1973年国际电信联盟再次通过决议，要求各会员国继续开展各种纪念活动，活动方式可以多种多样。为了使纪念活动更有系统性，每年的世界电信日都有一个主题。我国每年由工业和信息化部代表中国政府主办、中国通信学会承办纪念活动。各地通信管理局和运营商也会举办相应的纪念活动。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "国际博物馆日",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "每年5月18日"
                    },
                    {
                        "title": "设定时间",
                        "value": "1977年"
                    },
                    {
                        "title": "发起创办",
                        "value": "国际博物馆协会(ICOM)"
                    }
                ],
                "detail": [
                    "国际博物馆日定于每年的5月18日，是由国际博物馆协会（ICOM）发起并创立的。1977年国际博物馆协会为促进全球博物馆事业的健康发展，吸引全社会公众对博物馆事业的了解、参与和关注，向全世界宣告1977年5月18日为第一个国际博物馆日，并每年为国际博物馆日确定活动主题。"
                ]
            },
            {
                "title": "节日来历",
                "detail": [
                    "约在公元前5世纪，在希腊的特尔费·奥林帕斯神殿里，有一座收藏各种雕塑和战利品的宝库，它被博物馆界视为博物馆的开端。在其后相当长的时间里，博物馆只是供皇室或少数富人观赏奇珍异物的收藏室。到18世纪末，西欧一些国家博物馆相继建立，并向公众开放，博物馆的功能才有了新的发展，人们对博物馆的认识也发生了变化。1880年，英国博物馆学者鲁金斯发表了《博物馆之功能》的论文，强调博物馆应成为一般公众受教育的场所。美国学者顾迪在《将来的博物馆》和《博物馆行政管理的原则》两篇专论中，进一步强调博物馆必须致力于革新教育，开展积极的活动，使之不仅成为专家学者从事研究的场所，而且要成为教育机构的补充设施，校外教学园地。\n1946年11月，国际博物馆协会在法国巴黎成立。1974年6月，国际博物馆协会于哥本哈根召开第11届会议，将博物馆定义为“是一个不追求营利，为社会和社会发展服务的公开的永久机构。它把收集、保存、研究有关人类及其环境见证物当做自己的基本职责，以便展出，公诸于众，提供学习、教育、欣赏的机会。”\n1971年国际博物馆协会在法国召开大会，针对当今世界的发展，探讨了博物馆的文化教育功能与人类未来的关系。1977年，国际博物馆协会为促进全球博物馆事业的健康发展，吸引全社会公众对博物馆事业的了解、参与和关注，向全世界宣告：1977年5月18日为第一个国际博物馆日并每年为国际博物馆日确定活动主题。"
                ]
            },
            {
                "title": "节日成立",
                "detail": [
                    "中国博物馆学会于1983年正式加入国际博物馆协会，并成立了国际博物馆协会中国国家委员会。每年5月18日在全国各省市区举办形式多样的纪念活动。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "全国学生营养日",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "每年5月20日"
                    },
                    {
                        "title": "设定时间",
                        "value": "2001年"
                    },
                    {
                        "title": "流行地区",
                        "value": "中国、美国"
                    },
                    {
                        "title": "节日意义",
                        "value": "宣传学生时期营养重要性，普及营养知识"
                    },
                    {
                        "title": "设立机构",
                        "value": "中国学生营养促进会"
                    }
                ],
                "detail": [
                    "1989年成立的中国学生营养促进会在营养学家于若木的主持下，结合世界卫生组织2000年人人享有卫生保健的战略目标，制定了1991年至2000年十年学生营养工作计划。这一计划命名为“护苗系统工程”，其中确定每年5月20日为中国学生营养日。其目的在于广泛、深入宣传学生时期营养的重要性，大力普及营养知识。2001年5月，教育部、卫生部以（卫疾控发【2001】120号）文联合颁布文件将“中国学生营养日”法定下来。"
                ]
            },
            {
                "title": "设立背景",
                "detail": [
                    "中国学生营养状况不容乐观，虽然膳食热量供给基本达到标准，但蛋白质供给量偏低，优质蛋白比例小，钙、锌、铁、维生素A等营养素明显不足。为了青少年茁壮成长，应大力普及学生营养知识，为学生提供合理的饮食结构。中小学校、托幼机构应根据不同食物所含的营养素和不同年龄段学生的营养标准，合理调配膳食，做到荤素、粗细搭配，平衡健康。"
                ]
            },
            {
                "title": "设立宗旨",
                "detail": [
                    "营养学专家在首届中国学生营养健康节新闻发布会上呼吁，尽管城乡生活日益富裕，但城乡中小学生的营养与健康问题仍应引起全社会的重视关注。专家认为，大力宣传普及营养科学与食品卫生知识，扫除“营养盲”在我国城乡已成当务之急。1989年5月20日全国启动了“中国学生营养日”活动。在此基础上，为进一步促进改善学生营养状况和营养成份，提高人口健康素质，中国学生营养与健康促进会、中国关心下一代工作委员会、中华慈善总会、中国营养学会、教育部关心下一代工作委员会等决定举办中国学生营养健康节，向社会各界全面宣传学生的营养、食品安全、健康等相关知识，“把关心下一代从我做起”变成每位家长和全社会的自觉行动。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "全国母乳喂养宣传日",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "每年5月20日"
                    },
                    {
                        "title": "设定时间",
                        "value": "1990年"
                    },
                    {
                        "title": "节日目的",
                        "value": "强化人们母乳喂养意识"
                    },
                    {
                        "title": "设立机构",
                        "value": "卫生部"
                    }
                ],
                "detail": [
                    "每年的5月20日是全国母乳喂养宣传日。1990年5月10日卫生部决定，将每年的5月20日作为全国母乳喂养宣传日，广泛开展宣传、咨询活动，以强化人们母乳喂养意识，从而更好地实行计划生育和优生优育。"
                ]
            },
            {
                "title": "节日起源",
                "detail": [
                    "儿童问题是当代人类面临的最严重的挑战之一。儿童的天性是纯洁、脆弱和需要依靠的；他们充满着生机，代表着未来；应该在快乐、和平、学习的和谐气氛中长大成人。但是，由于战争、贫困和经济危机，使得当代世界的许多儿童至今还面临着生存的危险，其生存状况恶劣和身心健康的损害程度令人发指。",
                    "为此，1990年召开的世界儿童问题首脑会议向全世界发出了紧急呼吁：让每个儿童有更好的未来！通过了《儿童生存、保护和发展世界宣言》和《执行九十年代儿童生存、保护和发展世界宣言行动计划》。1991年3月，李鹏总理代表中国政府签署了这两个文件，并对国际社会作出了庄严的承诺。1992年2月，国务院制定和颁发了《九十年代中国儿童发展规划纲要》，显示了中国政府重视和关怀儿童事业的严肃态度。",
                    "1990年5月10日，卫生部在北京举行了母乳喂养新闻发布会，确定5月20日为“全国母乳喂养宣传日”。这是由国家卫生部为保护、促进和支持母乳喂养而设立的一项重要活动，也是献给所有哺乳母亲与她们孩子的节日。呼吁全社会都来关注和支持“母乳喂养”的观念，让母亲和宝宝建立更紧密的关系！母乳含有婴儿头6个月需要的“所有营养”，而且容易消化。母乳干净且没有细菌感染，所以不会使婴儿生病……“母乳最好”的观念已经深入人心。 除特殊情况外，产妇在住院期间，95%以上的妈妈自愿对宝宝进行母乳喂养。”世界卫生组织提倡母乳喂养24个月。产妇应在生产后30分钟内早开奶，早吸吮，这是提高母乳喂养率的关键。母乳喂养是最经济、最安全、最营养的，对新生儿成长发育也最有益。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "世界预防中风日",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "5月25日"
                    },
                    {
                        "title": "节日目的",
                        "value": "让百姓识别早期症状，预防中风"
                    }
                ],
                "detail": [
                    "世界预防中风日是在2004年6月24日在加拿大温哥华举行的第五届“世界中风研讨会”，订立每年5月25日为世界预防中风日，加强人们认识中风及相关问题，预防中风。中风是脑血管出现问题，令脑细胞失去血液、氧气和养分供应，最终令脑细胞受损或死亡，继而影响到该部分脑细胞所控制的功能，例如活动及语言机能，妨碍病患者自我照顾能力。2009年5月25日“世界中风日”的主题就是“让百姓识别早期症状，预防中风。"
                ]
            },
            {
                "title": "中风分类",
                "detail": [
                    "出血性中风：俗称“爆血管”或“脑溢血”，由于高血压或脑血管疾病，例如脑血管肿瘤而导致脑血管破裂。\n缺血性中风：因脑血管硬化导致血管狭窄及闭塞或因血凝块流到脑血管造成闭塞。"
                ]
            },
            {
                "title": "中风征状",
                "detail": [
                    "中风征状视乎发生病变的位置及其损害程度，常见病征有：\n局部感觉麻木，例如一边脸、单边肢体或身躯\n肢体无力，尤其是身躯单侧、单肢\n身体突然失去平衡能力\n言语不清、流口水、吞咽困难、嘴歪\n视力范围减小，或眼睛肌肉失调、出现复视\n神志不清、昏迷\n其他征状：例如突发性的剧烈头痛、持续的晕眩"
                ]
            },
            {
                "title": "预防中风",
                "detail": [
                    "戒烟。\n经常做运动，保持心境平和。\n均衡饮食，避免进食高胆固醇及高动物脂肪食物。\n避免酗酒。\n如患有高血压、糖尿病、心脏病或曾患有中风者，必须定期检查和接受适当治疗，确保血糖或血压处于正常水平。\n曾患有缺血性中风或心脏病，而又由医生处方给予预防药物的人士，应定期覆诊和服药。"
                ]
            },
            {
                "title": "中风护理",
                "detail": [
                    "改善患者的生活质素：重视患者的生理和心理健康。\n增强患者的自我照顾能力：如中风病患者重获日常生活所需要的技巧，减少倚赖别人，这样既可减慢机能退化，亦可减轻家人及照顾者的负担。\n保持肢体的功能，防止并发病如褥疮、肺部感染、哽咽及肩周炎发生。\n中风能导致半身不遂，但凭著意志及通过适当治疗，不少病人亦可重新恢复部份机能，可向专业人士求助。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "世界牛奶日",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "中文名",
                        "value": "世界牛奶日"
                    },
                    {
                        "title": "英文名",
                        "value": "World Milk Day"
                    },
                    {
                        "title": "节日时间",
                        "value": "每年的6月1日"
                    },
                    {
                        "title": "节日意义",
                        "value": "改善国民营养状况，增强人民体质"
                    },
                    {
                        "title": "设立机构",
                        "value": "中国乳制品工业协会"
                    },
                    {
                        "title": "设定时间",
                        "value": "2000年"
                    }
                ],
                "detail": [
                    "2000年，中国乳制品工业协会向联合国粮农组织建议，经征求其他国家的意见，联合国粮农组织（FAO）倡导，将每年的6月1日定为“世界牛奶日”。主题改善国民营养状况，增强人民体质。"
                ]
            },
            {
                "title": "设立宗旨",
                "detail": [
                    "2000年，中国乳制品工业协会向联合国粮农组织建议，经征求其他国家的意见，联合国粮农组织（FAO）倡导，将每年的6月1日定为“世界牛奶日”。主题改善国民营养状况，增强人民体质。",
                    "2000年联合国粮农组织提议，并征得了世界700多位乳业界人士的意见后，把每年的6月1日确定为“世界牛奶日”。“世界牛奶日”活动的目的为以多种形式向广大消费者介绍牛奶的生产情况，宣传牛奶营养价值和对人体健康的重要性，了解广大消费者对牛奶与奶制品的意见和要求。",
                    "世界牛奶日”活动的目的是以多种形式向广大消费者介绍牛奶的生产情况，宣传牛奶的营养价值和对人体健康的重要性，了解广大消费者对牛奶和奶制品的意见和要求。这是双向的，又是互动的牛奶科普宣传活动，也是牛奶和奶制品的促销活动。"
                ]
            },
            {
                "title": "发展历史",
                "detail": [
                    "上个世纪50年代,法国的促进牛奶消费协会提出了庆祝“牛奶日“的设想,这个设想在1961年被国际牛奶联合会(IDF)所采纳，并做出了每年5月第三周的周为“国际牛奶日“的决定，这一天在每年都不是固定不变的。",
                    "中国开展这一活动最早的城市是江苏省的南京市，最早的企业是南京“卫岗乳业“。早在1997年就开始了这一活动。全国其他城市和企业也陆续开展了这一活动，并且取得了良好的效果。中国奶业之所以能够持续、稳定和快速地发展，同“世界牛奶日“活动的开展也是有联系的。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "世界海洋日",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "中文名",
                        "value": "世界海洋日"
                    },
                    {
                        "title": "英文名",
                        "value": "World Oceans Day"
                    },
                    {
                        "title": "节日时间",
                        "value": "每年的6月8日"
                    },
                    {
                        "title": "设立机构",
                        "value": "联合国大会"
                    },
                    {
                        "title": "设定时间",
                        "value": "2009年"
                    }
                ],
                "detail": [
                    "联合国于第63届联合国大会上将每年的6月8日确定为“世界海洋日”（World Oceans Day）。联合国秘书长潘基文就此发表致辞时指出，人类活动正在使海洋世界付出可怕的代价，个人和团体都有义务保护海洋环境，认真管理海洋资源。"
                ]
            },
            {
                "title": "设立起源",
                "detail": [
                    "2008年12月5日第63届联合国大会通过第111号决议，决定自2009年起，每年的6月8日为“世界海洋日”。早在1992年，加拿大就已经在当年的里约热内卢联合国环境与发展会议上发出这一提议，每一年都有一些国家在这一天举办与保护海洋环境有关的非官方纪念活动。但直至2009年联合国才正式确立其为官方纪念日。",
                    "2009年是联合国首次正式确定的“世界海洋日”，联合国希望世界各国都能借此机会关注人类赖以生存的海洋，体味海洋自身所蕴含的丰富价值，同时也审视全球性污染和鱼类资源过度消耗等问题给海洋环境和海洋生物带来的不利影响，而如今“世界海洋日”的主题被确定为“我们的海洋，我们的责任”。",
                    "世界上很多海洋国家和地区都有自己的海洋日，如欧盟的海洋日为5月20日，日本则将7月份的第三个星期一确定为“海之日”。"
                ]
            },
            {
                "title": "设立目的",
                "detail": [
                    "联合国秘书长潘基文呼吁世界各国进一步认识海洋对调节全球气候的能力，采取切实措施保护海洋环境，维护健康的海洋生态系统，确保国际航运的安全。人类活动正在使世界海洋付出可怕的代价。过度开发，非法的、未经授权和无管制的捕捞活动、破坏性的捕捞方法、外来入侵物种以及海洋污染，特别是来自陆地的污染等，正在使珊瑚等一些脆弱的海洋生态系统和重要的渔场遭到破坏。海洋温度升高和海平面上升及气候变化造成的海洋酸化，进一步对海洋生命、沿海和海岛社区及国家的经济造成威胁。在联合国的支持下，国际社会通过一系列关于保护海洋环境的国际性法律文件，其中核心的文件为1982年通过的《联合国海洋法公约》。该公约为人类在海洋上的所有活动提供了法律框架，同时也为各国开展各种国际合作提供了基础。"
                ]
            },
            {
                "title": "设立意义",
                "detail": [
                    "“世界海洋日”的确立，为国际社会应对海洋挑战搭建了平台，也为在中国进一步宣传海洋的重要性、提高公众海洋意识提供了新的机会。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "世界无童工日",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "中文名",
                        "value": "世界无童工日"
                    },
                    {
                        "title": "英文名",
                        "value": "The World Day Against Child Labor"
                    },
                    {
                        "title": "节日时间",
                        "value": "每年的6月12日"
                    },
                    {
                        "title": "提出者",
                        "value": "国际劳工组织"
                    },
                    {
                        "title": "设定时间",
                        "value": "2002年"
                    }
                ],
                "detail": [
                    "世界无童工日，为关注日益严重的童工问题，2002年6月，在日内瓦召开的第90届国际劳工大会决定每年的6月12日定为“世界无童工日（the World Day Against Child Labor）”，呼吁世界各国密切关注童工问题，并采取切实有效的措施解决这一问题，每年世界无童工日都确定一个主题，以切实体现关切的童工问题的各个方面，从而引导各国政府的解决。"
                ]
            },
            {
                "title": "设立起源",
                "detail": [
                    "国际劳工组织于2002年设立了世界无童工日，将重点放在对全球范围童工的关注和需要消除这种现象的行动。每年6月12日，来自世界各地的政府，雇主和工人组织，民间社会，以及亿万人民群众聚集起来，突显出童工的困境，探讨人们怎样来帮助他们。",
                    "国际劳工组织第182号公约的通过巩固了消除童工劳动的全球共识。已有数百万童工受益于该公约，但仍有许多工作要做。据最新数字估计，仍有2.15亿儿童在被迫当童工，而其中的1.15亿儿童在从事危险工作。国际劳工组织会员国已将2016年定为消除最恶劣形式的童工劳动的期限。为了实现这一目标需要更大的努力和承诺。"
                ]
            },
            {
                "title": "设立目的",
                "detail": [
                    "2002年6月，在日内瓦召开的第90届国际劳工大会决定每年的6月12日定为“世界无童工日（the World Day Against Child Labor）”，同时呼吁世界各国密切关注日益严重的童工问题，并采取切实有效的措施解决这一问题。",
                    "日益严重的童工问题已引起许多国家和地区的高度重视。一些国家已采取措施，严格禁止在家务劳动中雇佣15岁以下的儿童。有些国家还成立专门机构，负责维护儿童的合法权益，遏制贩卖儿童和使用童工等丑恶社会现象的蔓延。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "世界献血者日",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "中文名",
                        "value": "世界献血者日"
                    },
                    {
                        "title": "英文名",
                        "value": "World Blood Donor Day(WBDD)"
                    },
                    {
                        "title": "节日时间",
                        "value": "每年的6月14日"
                    },
                    {
                        "title": "设定时间",
                        "value": "2005年5月24日"
                    }
                ],
                "detail": [
                    "世界献血者日（World Blood Donor Day, WBDD）是每年的6月14日。为了鼓励更多的人无偿献血，宣传和促进全球血液安全规划的实施，世界卫生组织、红十字会与红新月会国际联合会、国际献血组织联合会、国际输血协会将2004年6月14日定为第一个世界献血者日。"
                ]
            },
            {
                "title": "历史来源",
                "detail": [
                    "为鼓励更多的人无偿献血，宣传和促进全球血液安全规划的实施，世界卫生组织（WHO）、红十字会与红新月会国际联合会（IFRC）、献血者组织国际联合会（IFBDO）、国际输血协会（ISBT） [2]  将2004年6月14日定为第一个世界献血日。之所以选中这一天，是因为6月14日是发现ABO血型系统的诺贝尔奖获得者卡尔·兰德斯坦纳的生日。"
                ]
            },
            {
                "title": "献血标准",
                "detail": [
                    "1． 年龄：18-55周岁（可申请延续献血年限）\n2． 体重：男≥50千克，女≥45千克。\n3． 血压：12-20/8-12kpa，脉压差：≥4kpa或90mmhg-140mmhg/60mmhg-90mmhg，脉压差：≥30mmhg。\n4． 脉搏：60-100次/分，高度耐力的运动员≥50次/分。\n5． 体温正常。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "世界防治荒漠化和干旱日",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "中文名",
                        "value": "世界防治荒漠化和干旱日"
                    },
                    {
                        "title": "英文名",
                        "value": "World Day to Combat Desertification"
                    },
                    {
                        "title": "节日时间",
                        "value": "每年的6月17日"
                    },
                    {
                        "title": "设定时间",
                        "value": "1994年12月19日"
                    },
                    {
                        "title": "设立机构",
                        "value": "联合国"
                    }
                ],
                "detail": [
                    "世界防治荒漠化与干旱日（World Day to combat desertification），1994年12月19日第49届联合国大会根据联大第二委员会（经济和财政）的建议，通过了49/115号决议，从 1995年起把每年的6月17日定为“世界防治荒漠化与干旱日”，旨在进一步提高世界各国人民对防治荒漠化重要性的认识，唤起人们防治荒漠化的责任心和紧迫感。"
                ]
            },
            {
                "title": "荒漠化概念",
                "detail": [
                    "按联合国多次关于荒漠化定义的讨论，特别是1992年联合国环境与发展大会所提出的定义是：“荒漠化是由于气候变化和人类不合理的经济活动等因素使干旱、半干旱和具有干旱灾害的半湿润地区的土地发生了退化”。",
                    "这个荒漠化定义已得到联合国多次荒漠化国际公约政府间谈判会议的确认，重申在国际公约中采取这一定义，并将这这个定义列入《21世纪议程》的第12章中，还进一步补充了定义释文中出现的“土地退化”含义：“由于一种或多种营力结合以及不合理土地利用，导致旱农地、灌溉农地、牧场和林地生物或经济生产力和复杂性下降及丧失，其中包括人类活动和居住方式所造成的土地生产力下降，例如土地的风蚀、水蚀，土壤的物理化学和生物特性的退化和自然植被的长期丧失”。"
                ]
            },
            {
                "title": "发展历史",
                "detail": [
                    "1977年联合国荒漠化会议正式提出了土地荒漠化这个世界上最严重的环境问题。1992年6月,包括中国总理李鹏在内的100多个国家元首和政府首脑与会、170多个国家派代表参加的巴西里约环境与发展大会上,荒漠化被列为国际社会优先采取行动的领域。之后，联合国通过了47/188号决议，成立了《联合国关于在发生严重干旱和/或荒漠化的国家特别是在非洲防治荒漠的公约》政府间谈判委员会。公约谈判从1993年5月开始,历经5次谈判，于1994年6月17日完成。“6.17即为”国际社会对防治荒漠化公约达成共识的日子。在1994年10月14日至15日于巴黎举行的公约签字仪式上，林业部副部长祝光耀代表我国政府签署了公约。为了有效地提高世界各地公众对执行与自己和后代密切相关的“防治荒漠化公约”重要性的认识，加强国际联合防治荒漠化行动，迎合国际社会对执行公约及其附件的强烈愿望，以及纪念国际社会达成防治荒漠化公约共识的日子，1994年12月19日第49届联合国大会根据联大第二委员会（经济和财政）的建议，通过了49/115号决议，决定从1995年起把每年的6月17日定为“世界防治荒漠化与干旱日”，旨在进一步提高世界各国人民对防治荒漠化重要性的认识，唤起人们防治荒漠化的责任心和紧迫感。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "全国土地日",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "中文名",
                        "value": "全国土地日"
                    },
                    {
                        "title": "别名",
                        "value": "土地日"
                    },
                    {
                        "title": "节日时间",
                        "value": "每年的6月25日"
                    },
                    {
                        "title": "设定时间",
                        "value": "1991年5月24日"
                    },
                    {
                        "title": "设立机构",
                        "value": "国务院"
                    },
                    {
                        "title": "节日起源",
                        "value": "国务院第83次常务会议决定"
                    },
                    {
                        "title": "节日活动",
                        "value": "土地政策宣传"
                    },
                    {
                        "title": "节日意义",
                        "value": "珍惜土地资源，合理利用土地资源"
                    }
                ],
                "detail": [
                    "全国土地日，是每年6月25日。1986年6月25日，第六届全国人民代表大会常务委员会第十六次会议通过并颁布我国第一部专门调整土地关系的大法——《中华人民共和国土地管理法》。",
                    "为纪念这一天，1991年5月24日国务院第83次常务会议决定，从1991年起，把每年的6月25日，即《土地管理法》颁布的日期确定为全国土地日。“土地日”是国务院确定的第一个全国纪念宣传日。中国是世界上第一个为保护土地而设立专门纪念日的国家。"
                ]
            },
            {
                "title": "基本国情",
                "detail": [
                    "我国土地资源的特点是“一多三少”，即总量多，人均耕地少，高质量的耕地少，可开发后备资源少。虽然我国现有土地面积居世界第三位，但人均仅及世界人均1/3；耕地面积列世界第二位，而人均排在世界第六十七位。在这有限的耕地中，缺乏水源保证、干旱退化、水土流失、污染严重的耕地占了相当大的比例。"
                ]
            },
            {
                "title": "基本国策",
                "detail": [
                    "十分珍惜、合理利用土地和切实保护耕地是我国的基本国策。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "国际禁毒日",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "中文名",
                        "value": "国际禁毒日"
                    },
                    {
                        "title": "外文名",
                        "value": "International Day Against Drug Abuse and Illicit Trafficking"
                    },
                    {
                        "title": "节日时间",
                        "value": "每年的6月26日"
                    },
                    {
                        "title": "设定时间",
                        "value": "1987"
                    },
                    {
                        "title": "通过机构",
                        "value": "第42届联合国大会"
                    },
                    {
                        "title": "规范称谓",
                        "value": "禁止药物滥用和非法贩运国际日"
                    }
                ],
                "detail": [
                    "国际禁毒日即国际反毒品日，是每年的6月26日。1987年6月12日至26日，联合国在维也纳召开由138个国家的3000多名代表参加的麻醉品滥用和非法贩运问题部长级会议，会议提出了“爱生命，不吸毒”的口号，并与会代表一致同意6月26日定为“国际禁毒日”，以引起世界各国对毒品问题的重视，同时号召全球人民共同来解决毒品问题。"
                ]
            },
            {
                "title": "内容简介",
                "detail": [
                    "国际禁毒日（International Day Against Drug Abuse and Illicit Trafficking），全称是禁止药物滥用和非法贩运国际日。1987年6月，在奥地利首都维也纳举行了联合国部长级禁毒国际会议，有138个国家的3000多名代表参加了这次国际禁毒会议。这次会议通过了禁毒活动的《综合性多学科纲要》。26日会议结束时，参与会议的代表一致通过决议，从1988年开始将每年的6月26日定为“国际禁毒日”，以引起世界全国各地对毒品问题的重视，同时号召全球人民共同来解决毒品问题。"
                ]
            },
            {
                "title": "设立宗旨",
                "detail": [
                    "世界范围的毒品蔓延泛滥，已成为严重的国际人民的威胁。据联合国统计，全世界每年毒品交易额高达5000亿美元以上，是仅次于军火交易的世界第二大宗买卖。20世纪80年代，全世界因吸毒造成了10万人死亡。毒品不仅严重摧残人类健康，危害民族素质，助长暴力和犯罪，而且吞噬巨额社会财富。对于发展中的国家来说，毒品造成的损失和扫毒所需要的巨额经费更是沉重的负担。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "国际体育记者日",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "中文名",
                        "value": "国际体育记者日"
                    },
                    {
                        "title": "节日时间",
                        "value": "每年的7月2日"
                    },
                    {
                        "title": "设定时间",
                        "value": "1995"
                    }
                ],
                "detail": [
                    "7月2日为国际体育记者日。国际体育记者协会1995年在加拿大举行代表大会，确定7月2日为“国际体育记者日”。中国体育记者协会于1978年成为国际体育记者协会的正式会员。"
                ]
            },
            {
                "title": "发展历史",
                "detail": [
                    "1995年在加拿大举行的第58届国际体育记者协会（简称AIPS）代表大会上，并将7月2日定为“国际体育记者日”，号召所有会员国协会在7月2日这天举行各种形式的庆祝活动。AIPS于1924年7月2日在法国巴黎成立，截止2015年有125个会员国协会。中国体育记者协会在1978年AIPS莫斯科代表大会上被接纳为该组织正式会员。"
                ]
            },
            {
                "title": "组织机构",
                "detail": [
                    "国际体育记者协会是由各国体育记者协会组成的国际性组织。国际体育记者协会自成立以来，为加强各国体育记者之间的沟通与合作,促进体育运动及体育新闻事业在全世界的发展，弘扬奥林匹克精神做了大量卓有成效的工作。 国际体育记者协会是唯一被国际奥委会和各国际单项体育联合会广泛认可的全球性体育记者组织。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "国际接吻日",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "中文名",
                        "value": "国际接吻日"
                    },
                    {
                        "title": "外文名",
                        "value": "International Kissing Day"
                    },
                    {
                        "title": "节日时间",
                        "value": "每年的7月6日"
                    },
                    {
                        "title": "设定时间",
                        "value": "1991"
                    },
                    {
                        "title": "设计机构",
                        "value": "联合国"
                    }
                ],
                "detail": [
                    "国际接吻日，也称为世界接吻日，节日定在每年的7月6日。又名国际亲吻节(International Kissing Day)。这个节日由英国人率先发起，1991年得到联合国的承认。每年的这一天世界上的许多城市都举行各种接吻比赛，成为一道温馨的风景线。"
                ]
            },
            {
                "title": "简介",
                "detail": [
                    "国际接吻日，大家也叫为世界接吻日，为每年的7月6日。又名「国际亲吻节」（InternationalKissingDay）。这个节日首先由英国人发起，二十年前得到联合国的批准。接吻，是一种古老而风行的示爱方式，也是一种甜蜜的享受，现代心理学告诉人们，93%的女子都盼望情人吻她，而男性也喜欢去吻自己所钟爱的女子。每年的这一天许多城市都举行各种接吻比赛，以俄罗斯城市莫斯科最负盛名，比赛参加的男女有机会赢得各种奖品和礼品。"
                ]
            },
            {
                "title": "接吻礼俗",
                "detail": [
                    "接吻的历史始于大约公元前3000年，古人们在膜拜众神时都要亲吻他们。古罗马帝国时期，不仅与朋友和家庭成员接吻，还要与商人和路人接吻，以此表示对他们的欢迎。中世纪这种见面礼不仅要受到人际关系的约束，还要受到社会阶层的约束。社会地位越低，亲吻距离脸部就越远。对于与自己地位相等的人吻嘴唇，比自己地位高一等的人要吻手，再者则要吻其膝盖，对于自己在其面前微不足道的人（首先是指神职人员）要吻其脚背，甚至他脚边的土地。在意大利，男人一定要娶当众亲吻的姑娘为妻。"
                ]
            },
            {
                "title": "吻的含义",
                "detail": [
                    "吻额头=疼爱你；\n吻眼睛=想你吻他；\n吻鼻子=希望再见你；\n吻唇=只爱你一人；\n吻舌=想和你过一辈子；\n吻耳朵=逗你玩；\n吻脸=想和你做朋友；\n吻脖子=要你一人；\n吻手=崇拜你；\n随便吻=不想你离开。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "世界人口日",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "中文名",
                        "value": "世界人口日"
                    },
                    {
                        "title": "外文名",
                        "value": "World Population Day"
                    },
                    {
                        "title": "节日时间",
                        "value": "每年的7月11日"
                    },
                    {
                        "title": "设定时间",
                        "value": "1989"
                    },
                    {
                        "title": "设立机构",
                        "value": "联合国大会"
                    },
                    {
                        "title": "节日意义",
                        "value": "引起国际社会对人口问题的重视。"
                    }
                ],
                "detail": [
                    "世界人口日是每年的7月11日。1987年7月11日，地球人口达到50亿。为纪念这个特殊的日子，1989年，在联合国发展规划署理事会在其第89/46号决定中建议国际社会把每年的7月11日定为世界人口日（World Population Day），以便把重点放在紧迫性的人口总体发展计划和解决这些问题的方案。",
                    "1990年联合国根据其开发计划署理事会第36届会议的建议，决定将每年7月11日定为“世界人口日”，以唤起人们对人口问题的关注。据此，1990年7月11日遂成为第一个“世界人口日”。2011年10月31日，世界人口已达到70亿。"
                ]
            },
            {
                "title": "由来",
                "detail": [
                    "1987年7月11日，前南斯拉夫的一个婴儿降生，被联合国象征性地认定为是地球上第50亿个人，并宣布地球人口突破50亿大关。联合国人口活动基金会(UNFPA)倡议将这一天定为”世界50亿人口日“。1990年，联合国决定将每年的七月十一日定为”世界人口日“，以唤起人们对人口问题的关注。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "国际冰壶日",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "中文名",
                        "value": "国际冰壶日"
                    },
                    {
                        "title": "节日时间",
                        "value": "每年的7月16日"
                    }
                ],
                "detail": [
                    "每年的7月16日为国际冰壶日。冰壶（curlings）又称掷冰壶、冰上溜石，是以队为单位在冰上进行的一种投掷性竞赛项目。"
                ]
            },
            {
                "title": "冰壶历史",
                "detail": [
                    "冰上溜石14世纪起源于苏格兰，至今在苏格兰还保存刻有1511年份的砥石（即冰壶）。1795年，第一个冰上溜石俱乐部在苏格兰创立，1838年苏格兰冰上溜石俱乐部制定第一个正式的比赛规则。",
                    "1807年冰上溜石活动传入欧洲和北美加拿大，1820年起在美国等地流行。从此，冰上溜石作为一项冬季运动在逐渐开展起来。",
                    "20世纪初，通过加拿大冰上溜石爱好者的努力，使这项运动的比赛规则和方法更加完美，并由室外逐渐移入室内，并于1927年举行首次全国冰上溜石比赛。首届世界冰上溜石锦标赛始于1959年，最初称为苏格兰威士忌杯赛，1968年改称加拿大银扫帚锦标赛，1986年正式定名为世界冰上溜石锦标赛。",
                    "1955年冰上溜石传入亚洲地区，目前日本、韩国等地非常流行。",
                    "1924年，冰壶首次以表演项目的形式在奥运会上亮相。1966年国际冰上溜石联全会成立，1991年改为世界冰上溜石联合会，同时获得了国际奥委会的承认。冰上溜石曾于1924年、1932年、1936年、1964年、1968年、1992年6次被列为冬奥会表演项目。1993年国际奥委会决定，从1998年开始，冰上溜石列为冬奥会正式比赛项目。在亚洲，冰上溜石从第五届亚冬会开始列为正式比赛项目。冬奥会比赛项目，设男、女、混合双人3个小项。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "世界肝炎日",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "中文名",
                        "value": "世界肝炎日"
                    },
                    {
                        "title": "节日时间",
                        "value": "每年的7月28日"
                    }
                ],
                "detail": [
                    "7月28日是已故诺贝尔奖得主巴鲁克·布隆伯格的诞辰日，为纪念这位乙肝病毒发现者，世界卫生组织2010年5月决定，从2011年开始将每年的世界肝炎日从5月19日变更为7月28日。"
                ]
            },
            {
                "title": "社会背景",
                "detail": [
                    "肝炎（学名：Hepatitis)是肝脏的炎症。肝炎的原因不同，最常见的是病毒造成的，此外还有自身免疫造成的。酗酒也可以导致肝炎。肝炎分急性和慢性肝炎。由病毒造成的肝炎按照其病毒系列不同分为甲型肝炎、乙型肝炎、丙型肝炎、丁型肝炎、戊型肝炎和庚型肝炎共六种类型病毒性肝炎。",
                    "肝炎的早期症状及表现，如：食欲减退，消化功能差，进食后腹胀，没有饥饿感；厌吃油腻食物等。",
                    "肝炎暂无有效预防措施，早发现早诊断是本病防治的关键。脂肪肝是引起肝炎的重要原因，近年来明显增多的肝脏病，常与肥胖症、糖尿病共存。要远离脂肪肝，应从调节饮食入手。控制脂肪饮食当然是必需的。预防脂肪肝还要提倡运动，运动可以消耗掉体内多余的脂肪。",
                    "肝炎是肝脏的炎症，最常见的原因是病毒感染。病毒性肝炎分为甲、乙、丙、丁和戊型，虽然病毒种类不同，但都足以对人构成严重危害，其中乙型和丙型肝炎可以导致肝硬化和肝癌的发生，给全球带来严重的疾病负担。",
                    "世界卫生大会首次就病毒性肝炎通过一项决议，审视了疾病的现状，要求世卫组织制定一项有时间目标限定的全面战略，加强疾病监测、提高预防手段，扩大筛查范围并向感染者提供治疗，及向发展中国家提供切实的帮助。",
                    "全世界约有20 亿人已感染乙型肝炎病毒，其中3.5 亿以上的人患有慢性感染，每年有50 至70 万人死于乙型肝炎病毒感染。约有1.3 至1.7 亿人为慢性丙型肝炎病毒感染，估计每年有35 万人因与丙型肝炎相关的肝脏疾病死亡。"
                ]
            },
            {
                "title": "治疗误区",
                "detail": [
                    "误区一：症状和一般疾病混同\n通常对于乙肝病毒携带者来说，如果病毒在肝细胞内复制活跃，肝功能受到损伤，会表现出一些症状。全身疲乏无力、口干、口苦、食欲减退、恶心、厌油腻、右上腹隐隐作痛、腹泻、小便黄如浓茶色等都是病症的反应。",
                    "误区二：不发病就不会传染\n大部分携带者几年、甚至几十年都不会发病，所以人们就认为其不具有传染性，也无须检查。其实不然。建国医院肝病科吴医师介绍，所谓病毒携带者，是肝功能和B超检测都正常，但表面抗原呈阳性，也就是澳抗阳性的人。这些人的肝脏并没有损伤，但只要检测出澳抗阳性，就具有传染性。",
                    "误区三：不做定期体检\n及时、准确地检测病情，对治疗是非常有利的。很多人在乙肝病毒感染的早期，或感染后相当长的时期内，都不会出现任何症状，要早发现乙肝最有效的方法，就是每一年或半年体检一次，检查肝功能和乙肝五项指标。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "世界爱虎日",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "中文名",
                        "value": "世界爱虎日"
                    },
                    {
                        "title": "节日时间",
                        "value": "每年的7月29日"
                    },
                    {
                        "title": "节日意义",
                        "value": "树立公众的野生虎保护意识"
                    },
                    {
                        "title": "设定时间",
                        "value": "2010年11月"
                    }
                ],
                "detail": [
                    "2010年1月，泰国召开的老虎保护亚洲部长级会议提出将每年的7月29日设为“全球老虎日”。",
                    "2010年11月，于俄罗斯圣彼得堡召开的“保护老虎国际论坛”（老虎峰会）通过了“全球野生虎种群恢复计划”并发表了《全球野生虎分布国政府首脑宣言》，倡议共同努力促进野生虎及栖息地的保护，并确定每年的7月29日为“全球老虎日”。"
                ]
            },
            {
                "title": "节日由来",
                "detail": [
                    "2010年11月23日，来自孟加拉人民共和国、不丹王国、柬埔寨王国、中华人民共和国、印度共和国、印度尼西亚共和国、老挝人民民主共和国、马来西亚、缅甸联邦、尼泊尔王国、俄罗斯联邦、泰王国和越南社会主义共和国等13个全球野生虎分布国的政府首脑，参加“保护老虎国际论坛”（即老虎峰会）。峰会上，各国政府代表意识到野生虎的生存已经到了濒临灭绝的紧要关头。在过去的一百年里，全球野生虎的数量从100000只锐减到不到3500只。野生虎是健康生态系统的重要标志之一，如果不扭转这样的趋势，结果不只是野生虎数量上的锐减，人类自身也将遭受由于野生虎栖息地丧失及其种群消亡给整个亚洲的生物多样性及我们共同赖以生存的生态系统带来的巨大损失。同时，为了树立公众的野生虎保护意识，这次峰会将每年的7月29日定为“全球老虎日”。"
                ]
            },
            {
                "title": "物种渊源",
                "detail": [
                    "约200万年前，虎起源于中国中部。\n17世纪早期，共有8个虎的亚种，它们是:里海虎、孟加拉虎、印支虎、东北虎、华南虎、爪哇虎、苏门达腊虎以及巴厘虎。\n19世纪末期，老虎皮毛的不断增值导致了中亚以及前苏联远东地区老虎数量的锐减。\n1906年，巴基斯坦最后一只老虎被射杀。\n20世纪30年代，最后一只巴厘虎于1937年9月27日被射杀。\n20世纪40年代，高强度伐木活动使东北虎栖息地遭严重破坏。\n20世纪50年代，朝鲜最后一只老虎死亡。\n1952年，前苏联成为第一个明令禁止猎杀老虎的国家。\n1970年，土耳其最后一只老虎被射杀。\n20世纪70年代，里海虎绝种。\n1980年，最后一只爪哇虎在雅加达的动物园去世。\n2004年，马来虎被确认为新的老虎亚种，此前被归为印支虎的一个种群。\n2005年，俄罗斯宣布国内东北虎数量达到近500头，比50年前增加10倍，成为老虎保护的一个成功典范。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "非洲妇女节",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "中文名",
                        "value": "非洲妇女节"
                    },
                    {
                        "title": "节日时间",
                        "value": "每年的7月31日"
                    },
                    {
                        "title": "设定时间",
                        "value": "1962年7月"
                    }
                ],
                "detail": [
                    "非洲妇女节，一个1962年7月设定于达累斯萨拉姆有关妇女的节日。"
                ]
            },
            {
                "title": "节日由来",
                "detail": [
                    "1962年7月，全非妇女第一次代表大会在达累斯萨拉姆召开，成立了“全非妇女会议”（今名“泛非妇女组织”），确定每年的7月31日为“非洲妇女日”。这个组织的代表大会以及重大庆祝活动都在这天举行。",
                    "泛非妇女组织是非洲统一组织下属的全非性妇女组织，享有“非统”观察员地位。原名为全非妇女会议，在加纳、几内亚、坦桑等国的倡议下，于1962年7月在达累斯萨拉姆召开成立大会。1974年在第四次代表大会上改为现名。"
                ]
            },
            {
                "title": "节日意义",
                "detail": [
                    "联合国的四次全球性会议加强了国际妇女运动，随着国际妇女运动的成长，妇女节取得了全球性的意义。这些进展使国际妇女节成为团结一致、协调努力要求妇女权利和妇女参与政治、经济和社会生活的日子。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "中国男子节",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "中文名",
                        "value": "中国男子节"
                    },
                    {
                        "title": "别名",
                        "value": "爸爸节"
                    },
                    {
                        "title": "节日时间",
                        "value": "每年的8月8日"
                    },
                    {
                        "title": "节日意义",
                        "value": "其目的是为了纪念在战场上为国捐躯的父亲们"
                    },
                    {
                        "title": "流行地区",
                        "value": "上海"
                    }
                ],
                "detail": [
                    "每年的8月8日为中国男子节，也有爸爸节之称。是在1945年8月8日，由上海的部分爱国人士发起的，其目的是为了纪念在战场上为国捐躯的父亲们。8月8日的爸爸节与父亲节不同，是中国特有的男子节，原因很简单：“八八”与“爸爸”是同音。其由来据说与抗战有关，1945年8月8日，上海部分爱国人士发起“父亲节”，纪念在战场上为国捐躯的父亲们。抗战胜利后，就把每年8月8日定为爸爸节。"
                ]
            },
            {
                "title": "节日由来",
                "detail": [
                    "每年8月8日为中国的男子节！也是所说的88（爸爸）节！ 八月八日爸爸节，这个节日的由来和对日抗战有关。 民国三十四年，当时抗战打得正火热，上海的爱国之士为了纪念在战争中为国捐躯的爸爸们，特地提出父亲节的构想，还举办庆祝活动。抗战胜利后，上海仕绅名流们觉得庆祝父亲节实在太有意义啦！积极争取之下，八月八日就被当时的中央政府订为爸爸们的专属节日。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "国际左撇子日",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "中文名",
                        "value": "国际左撇子日"
                    },
                    {
                        "title": "外文名",
                        "value": "International Left-Handers Day"
                    },
                    {
                        "title": "节日时间",
                        "value": "每年的8月13日"
                    },
                    {
                        "title": "起源时间",
                        "value": "1975年8月13日"
                    }
                ],
                "detail": [
                    "国际左撇子日是8月13日，是为了提醒大家注意左撇子在生活中遭遇的种种不便，希望能促进在教育、日常生活工具的设计上，重视左撇子的权益并发起对左撇子的相关研究而设立的节日。"
                ]
            },
            {
                "title": "节日起源",
                "detail": [
                    "1975年8月13日，美国堪萨斯州托佩卡市的一群左撇子建立了名叫左撇子国际的组织，他们设想把全世界的左撇子联合起来，共同争取左撇子的权益。1976年，该组织举行庆祝活动。并将这一天确定为国际左撇子日，希望有朝一日全世界的左撇子一起庆祝自己的节日。1976年的8月13日就成为第一个国际左撇子日。"
                ]
            },
            {
                "title": "节日意义",
                "detail": [
                    "这个节日的目在于提醒人们注意到左撇子在以右手为主的世界中，遭遇的种种不便，希望能促进在教育、日常生活、工具的设计上重视惯用左手者的权益，并发起对左撇子的相关研究。"
                ]
            },
            {
                "title": "相关名人",
                "detail": [
                    "左撇子——这个所谓“另类”群体之中，是一个天才云集之地：拿破仑、歌德、尼采、毕加索、达芬奇、鲍勃·迪伦、列维·卡洛、爱因斯坦、拉斐尔、米开朗琪罗、卓别林、玛丽莲·梦露、甘地、贾斯汀比伯、休·杰克曼、亚里士多德 [5-6]  等等。左撇子们宣言，建立国际左撇子日的目的在于团结世界各地的左撇子为争取自身的利益而斗争，唤起全社会对左撇子问题的关注，提醒人们在一个以右撇子为主的社会中改进产品的设计并更多考虑左撇子的方便与安全，消释数千年来在各种文化中都存在而今天也还在不断制造着的对左撇子的偏见。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "火把节",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "中文名",
                        "value": "火把节"
                    },
                    {
                        "title": "别名",
                        "value": "星回节"
                    },
                    {
                        "title": "节日时间",
                        "value": "农历六月二十四"
                    },
                    {
                        "title": "流行地区",
                        "value": "彝族、白族、纳西族、拉祜族"
                    }
                ],
                "detail": [
                    "火把节是彝族、白族、纳西族、基诺族、拉祜族等民族的古老传统节日，有着深厚的民俗文化内涵，被称为“东方的狂欢节”。不同的民族举行火把节的时间也不同，大多是在农历的六月二十四，主要活动有斗牛、斗羊、斗鸡、赛马、摔跤、歌舞表演、选美等。在新时代，火把节被赋予了新的民俗功能，产生了新的形式。"
                ]
            },
            {
                "title": "由来传说",
                "detail": [
                    "云南各民族关于火把节的传说，各有各的特色内容。彝族关于火把节的传说，实际上是以游牧为主转为以农耕为主之后，新的文化形态对原有文化形态的扬弃。这个传说最大限度地保留了火把节来源的原始信息，也是最接近火把节源头的一个传说。纳西族关于火把节传说的谚语中保留了火把节来历的重大信息，与《玉溪编事》、《禄劝县志》、《峒溪县志》中的记载共同对探究火把节的来历提供了重要线索。大理和昆明一带关于火把节的传说，虽然遮掩了火把节的来源，但其“星回节”的说法，一样露出了火把节的源头活水及深厚文化。"
                ]
            },
            {
                "title": "举办时间",
                "detail": [
                    "彝族、纳西族、基诺族在农历六月二十四举行，白族在六月二十五举行，拉祜族在六月二十举行，节期二三天。彝族认为过火把节是要长出的谷穗像火把一样粗壮。后人以此祭火驱家中田中鬼邪，以保人畜平安。如今人们还利用集会欢聚之机，进行社交或情人相会，并在节日开展商贸活动。",
                    "“火把节”也是白族的一个盛大节日，每年夏历六月二十五日，白族人民就会举行预祝五谷丰登、人畜兴旺的活动。",
                    "江苏省南通地区是整个汉族中可以说是唯一进行火把节的地区，每年的冬至那一天，当地的精壮男子都会拿着一大把由稻秆作成的火把，在房前屋后浇上油，在田间地头绕上几圈，以乞求来年的风调雨顺，五谷丰登。"
                ]
            },
            {
                "title": "庆祝活动",
                "detail": [
                    "彝族火把节一般历时三天三夜，分为迎火、玩火、送火三个阶段。火把节第一天：祭火。这一天，村村寨寨都会宰牛杀羊，集体分享，并备酒肉祭祖。火把节第二天：传火。这一天，家家户户都聚集在祭台圣火下，举行各式各样的传统节日活动。火把节第三天：送火。这是整个凉山彝族国际火把节的高潮。这一天夜幕降临时，人人都会手持火把，竞相奔走。最后人们将手中的火把聚在一起，形成一堆堆巨大的篝火，欢乐的人们会聚在篝火四周尽情地歌唱、舞蹈。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "全国中小学开学日",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "中文名",
                        "value": "全国中小学开学日"
                    },
                    {
                        "title": "外文名",
                        "value": "The first day of classes of primary and secondary schools"
                    },
                    {
                        "title": "节日时间",
                        "value": "9月1日"
                    }
                ],
                "detail": [
                    "全国中小学开学日，中小学秋季开学都约定俗成地定在9月1日。"
                ]
            },
            {
                "title": "节日背景",
                "detail": [
                    "2008年9月1日，中央电视台和教育部首次联合推出《开学第一课》“知识守护生命”大型公益行动，节目以生命意识教育和应急避险教育为主题，将奥运精神与抗震精神相结合，通过奥运冠军和孩子们的互动，寓教于乐，凸显了以人为本，尊重生命，守护生命。"
                ]
            },
            {
                "title": "节日由来",
                "detail": [
                    "教育部于2008年8月26日专门发文，通知全国各地学校组织学生和家长一起收看此节目，让全国2.2亿中小学生同上这一堂课，被媒体称作是“史上最牛一堂课”。《开学第一课》播出后，收到了良好的社会效果，社会反响强烈，节目在中央一套及二套重播多次，《开学第一课》也成为一个知名品牌。因此9月1日自然而然地被定为全国开学日。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "国际扫盲日",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "中文名",
                        "value": "国际扫盲日"
                    },
                    {
                        "title": "外文名",
                        "value": "International Literacy Day"
                    },
                    {
                        "title": "节日时间",
                        "value": "每年9月8日"
                    },
                    {
                        "title": "节日意义",
                        "value": "扫盲"
                    },
                    {
                        "title": "设立机构",
                        "value": "联合国教科文组织"
                    },
                    {
                        "title": "设定时间",
                        "value": "1965年11月17日"
                    }
                ],
                "detail": [
                    "国际扫盲日（International Literacy Day）是联合国教科文组织在1965年11月17日所召开的第14届代表大会上所设立的。日期为每年的9月8日，旨在动员世界各国以及相关国际机构重视文盲现象，与文盲现象作斗争，并促进世界各国普及初等教育，提高初等教育的水平，使适龄儿童都能上学，达到能够识字的目标。最终达到增进人际沟通，消除歧视，促进文化传播和社会发展的目标。"
                ]
            },
            {
                "title": "发展历史",
                "detail": [
                    "1966年，联合国教科文组织第十四次大会决定，每年9月8日为国际扫盲日。其目的在于各成员国和有关国际机构同文盲现象作斗争。从此以后，各成员国每年这一天都组织各种活动。"
                ]
            },
            {
                "title": "设立宗旨",
                "detail": [
                    "文盲现象是一个全球性的社会问题，严重阻碍了社会的发展。为了消灭文盲现象，联合国教科文组织早在1966年就决定，把每年的9月8日定为国际扫盲日，目的是动员世界各国和有关国际机构同文盲现象作斗争，并希望通过国际扫盲日活动推动扫盲工作的开展，使各国适龄儿童都能上学、在校学生不过早辍学、成年文盲有受教育机会。"
                ]
            },
            {
                "title": "产生影响",
                "detail": [
                    "人类在扫盲上取得的巨大成就值得庆祝，全世界已有近四十亿的识字人口。但是，在所有儿童、青年和成人中实现全民扫盲依然是一个未完成的目标，而且这个目标时时在变化。之所以会这样，其原因很可能是：过高的目标，不充足的和重复的工作，政策和资源的缺乏，以及持续对任务重要性和复杂性的低估。不管怎样，我们从这几十年获得的经验说明：达到普及识字的目标，不仅需要更多、更好的努力，还需要更新政治理念，并且针对地方、国家和国际等不同等级做不同的工作。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "国际新闻工作者日",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "中文名",
                        "value": "国际新闻工作者日"
                    },
                    {
                        "title": "别名",
                        "value": "国际新闻工作者团结日"
                    },
                    {
                        "title": "节日时间",
                        "value": "每年9月8日"
                    },
                    {
                        "title": "节日目的",
                        "value": "唤起全世界的新闻工作者加强团结"
                    }
                ],
                "detail": [
                    "每年9月8日是“世界新闻记者日”或“国际新闻工作者团结日”。其目的是唤起全世界的新闻工作者加强团结、努力工作、认真采写真实报导、揭露战争贩子的反动面目、及时报导事件发生的真象、打击坏人坏事，保卫世界和平。9月8日为纪念捷克斯洛克氏族英雄、共产党员、新闻记者尤利乌斯·伏契克于1943年9月8日被德国法西斯杀害的纪念日。"
                ]
            },
            {
                "title": "简介",
                "detail": [
                    "新闻记者，是指专职于新闻工作机构中从事采访报导社会上的新闻的专业从业人员。包括编辑、采访、评论人员等。中国的新闻机构最初是采编合一的。19世纪70年代开始设立专职的采访人员，最初时不叫“记者”，而称为“访员”、“访事”、“报事人”等。19世纪90年代后才开始称采访人员为“记者”。"
                ]
            },
            {
                "title": "相关来历",
                "detail": [
                    "国际新闻工作者协会，简称“国际记协”，英文缩写“IOJ”。1946年6月由前苏联、波兰等国在哥本哈根成立。其创建任务是：帮助自由、真实地交换新闻、维护和平，增进各国人民之间的友谊和国际谅解，捍卫人民获得真实准确新闻的权利，保护记者的权益。1947年记协二大后，11个主要西方国家退出记协，并于1952年成立了国际新闻工作者联合会（“国际记联”），分裂后的记协将总部迁到布拉格。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "国际臭氧层保护日",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "中文名",
                        "value": "国际臭氧层保护日"
                    },
                    {
                        "title": "别名",
                        "value": "国际保护臭氧层日"
                    },
                    {
                        "title": "节日时间",
                        "value": "每年9月16日"
                    },
                    {
                        "title": "节日意义",
                        "value": "倡导人们保护空气、保护地球，少用破坏臭氧、污染环境的东西"
                    },
                    {
                        "title": "设立机构",
                        "value": "联合国大会"
                    }
                ],
                "detail": [
                    "每年的9月16日是国际臭氧层保护日。随着人类活动的加剧，地球表面的臭氧层出现了严重的空洞，1974年被美国加利福尼亚大学的教授罗兰(F.Sherwood Rowland)和穆连(Mario Molina)发现。 保护臭氧层就是保护蓝天，保护地球生命。 1995 年1月23日联合国大会决定，每年的9月16日为国际保护臭氧层日，要求所有缔约国按照《关于消耗臭氧层物质的蒙特利尔议定书》及其修正案的目标，采取具体行动纪念这个日子。"
                ]
            },
            {
                "title": "设立宗旨",
                "detail": [
                    "臭氧层是指距离地球25-30公里处臭氧分子相对富集的大气平流层。它能吸收99%以上对人类有害的太阳紫外线，保护地球上的生命免遭短波紫外线的伤害。所以，臭氧层被誉为地球上生物生存繁衍的保护伞。",
                    "保护臭氧层就是保护蓝天，保护地球生命。为了唤起公众环境保护意识，1995年1月23日联合国大会决定，每年的9月16日为国际保护臭氧层日(International Day for the Preservation of the Ozone Layer)，要求所有缔约国按照《关于消耗臭氧层物质的蒙特利尔议定书》及其修正案的目标，采取具体行动纪念这个日子。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "中国脑健康日",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "中文名",
                        "value": "中国脑健康日"
                    },
                    {
                        "title": "节日时间",
                        "value": "每年9月16日"
                    },
                    {
                        "title": "建立时间",
                        "value": "2000年9月16日"
                    },
                    {
                        "title": "发起人",
                        "value": "中国医促会脑健康专业委员会"
                    }
                ],
                "detail": [
                    "2000年9月16日，我国把每年9月命名为“脑健康月”。中国医促会脑健康专业委员会的王忠诚等百余位著名专家学者发出倡议，将每年9月16日定为“脑健康日”。"
                ]
            },
            {
                "title": "节日概述",
                "detail": [
                    "国际脑研究组织第四届神经科学大会，把21世纪称为“脑的世纪”。近年来，我国神经系统和精神疾病发病率呈逐年上升之势，这已引起了社会各界的广泛关注。2000年9月16日，我国把每年9月命名为“脑健康月”。中国医促会脑健康专业委员会的王忠诚等百余位著名专家学者发出倡议，将每年9月16日定为“脑健康日”。"
                ]
            },
            {
                "title": "节日简介",
                "detail": [
                    "9月是中国脑健康月，9月16日是第18个“中国脑健康日”，而9月21日又是一年一度的“世界老年痴呆日”。两个“日”的设立凸显“脑健康”的重要性以及其受关注程度。据了解，目前我国脑血管病患者近700万，每年新发病人都超过200万。而中国在医学界对于脑健康的研究重点是集中在脑血管、老年痴呆症、癫痫、精神发育迟滞等典型病症上。在所有的疾病中，脑血管病的致残率是最高的，其中70%以上都留下各种各样的后遗症。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "国际和平日",
        "short_desc": "",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "中文名",
                        "value": "国际和平日"
                    },
                    {
                        "title": "外文名",
                        "value": "International Day of peace"
                    },
                    {
                        "title": "节日时间",
                        "value": "每年9月21日"
                    },
                    {
                        "title": "纪念意义",
                        "value": "呼吁全球停火和非暴力"
                    }
                ],
                "detail": [
                    "2001年9月7日，联合国大会通过决议，决定自2002年起，国际和平日为9月21日。决议中提到：“宣布此后，国际和平日应成为全球停火和非暴力日，并邀请所有国家和人民在这一天停止敌对行动。”55/282号决议还邀请所有会员国、联合国系统各组织、区域组织和非政府组织以及个人以各种适当方式（包括教育和公众宣传）庆祝国际和平日并同联合国合作实现全球停火。"
                ]
            },
            {
                "title": "设定宗旨",
                "detail": [
                    "2001年9月，联合国大会在其通过的第55/282号决议中决定，从2002年开始，每年9月21日为“国际和平日”。",
                    "国际和平日定于每年9月第三个星期二举行。这个节日恰逢联合国大会在纽约举行的常会开幕。联合国在1981年宣布国际和平日，并邀请全体会员国，非政府组织和各界人士以一种方式庆祝这个节日，借以表现联合国承诺维持各个民族和各个国家之间的和平。联合国网络空间校车是全球教学与学习计划所包括的现象……",
                    "自国际和平日设立以来，联合国每年都会在这一天举行仪式并敲响和平钟，邀请艺术家和教育家等作为和平使者出席纪念活动，联合国都提醒所有人关注、庆祝和纪念这一日子。同时，各国政府、非政府机构、民间社会和宗教团体也会举办纪念活动庆祝这个日子。国际和平日已在世界范围内得到2000多个组织机构的支持。",
                    "联合国将举办活动提请所有人民关注、庆祝和纪念这个日子。大会宣布国际和平日为全球停火和非暴力日，并邀请所有国家和人民在这一天停止敌对行动。大会邀请所有会员国、联合国系统各组织、区域组织和非政府组织以及个人以适当方式，包括通过教育和公众宣传庆祝国际和平日并同联合国合作实现全球停火。"
                ]
            },
            {
                "title": "发展历史",
                "detail": [
                    "联合国大会于1981年通过决议，将每年9月的第三个星期二联大开幕的日子定为国际和平日。1997年3月，中国人钟文芳先生向联合国安理会提出“世界息战周”的构想，主张“息战周内一切战乱国家和地区都应无条件息战”，“以给战乱地区以停战思考，提供缓和矛盾的契机，有效地推动世界和平”（据《世界名人与和平报》1997年3月18日报道）。2001年9月7日，联大通过第55/282号决议，决定从2002年开始，将每年的9月21日定为国际和平日。大会宣布，国际和平日为全球停火和非暴力日，并呼吁所有国家和人民在这一天停止敌对行动。大会还号召所有会员国、联合国系统各组织、区域组织和非政府组织以及个人，通过教育和公众宣传等适当方式庆祝国际和平日并同联合国合作实现全球停火。这正是钟文芳先生提出的“世界息战周”具体体现，并且，9月21日为钟文芳先生的生日。",
                    "自国际和平日设立以来，每年的这一天，联合国都举行仪式，提请所有人关注、庆祝和纪念这一日子。同时，全世界的政府和非政府机构、民间社会和宗教团体也纷纷举行各种活动纪念国际和平日。在这一天，会进行“根与芽”的活动，人们还要放飞和平鸽。",
                    "多年来，包括联合国在内的国际社会为实现地区稳定和世界和平作出了不懈努力。然而在全世界范围内，战争、恐怖袭击、地区冲突等多种形式的暴力敌对活动依然存在，人类实现真正和平的理想任重而道远。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "世界保健日",
        "short_desc": "一身健康，一世幸福",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "10月13日"
                    },
                    {
                        "title": "设定时间",
                        "value": "1950年"
                    },
                    {
                        "title": "设立机构",
                        "value": "联合国"
                    },
                    {
                        "title": "流行地区",
                        "value": "全球"
                    }
                ],
                "detail": [
                    "每年的10月13日是世界保健日。每年都要选择一个与公共卫生领域相关的主题，旨在提高全世界人对这一保健领域的认识，借此激发起一项长期宣传活动，促使该领域工作的开展。“世界保健日”期间，包括中国在内的世界卫生组织各会员国，都举行纪念活动，推广和普及有关健康知识，以提高人民健康水平。"
                ]
            },
            {
                "title": "发展历史",
                "detail": [
                    "1946年2月，联合国经社理事会决定召开卫生方面的国际会议，同年6—7月召开第一届世界卫生大会，世界卫生组织正式成立，总部设在日内瓦，并于7月22日正式批准了由61个国家签署的世界卫生组织《世界卫生组织法》。为了纪念《世界卫生组织组织法》批准日，第一届世界卫生大会决定，每年10月13日为“世界保健日”，倡议各国举行各种纪念活动，第二届世界卫生大会决定自1950年起，依《世界卫生组织组织法》正式确定每年10月13日“世界保健日”。"
                ]
            },
            {
                "title": "保健窍门",
                "detail": [
                    "1.放松精神：可以让肌肉、骨骼、器官自然地进行恢复和修整。",
                    "2.科学饮食：吃饭讲究营养，决不按口味挑食。",
                    "3.下蹲通络：具体做法是——两脚并拢，周身中正，两肩微微前扣，两臂自然下垂，百会上领，下颏回收，两眼轻轻闭合。",
                    "4.动养方式：通俗的讲动养就是根据每个人的具体情况通过运动、营养有机结合的处方达到保健的目的。比如说：每周固定2-3次运动，每次持续2.5个小时，以持续性的有氧运动（慢跑、快走、瑜伽等），饮食最基本的是根据身体和四次的变化去吃食物，讲究荤素、干湿、红白搭配。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "联合国日",
        "short_desc": "愿和平鸽在宇宙中翱翔",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "10月24日"
                    },
                    {
                        "title": "发起时间",
                        "value": "1942年"
                    },
                    {
                        "title": "发起者",
                        "value": "中国在内50个国家"
                    },
                    {
                        "title": "发起机构",
                        "value": "联合国大会"
                    }
                ],
                "detail": [
                    "联合国日是1947年联合国大会为纪念《联合国宪章》正式生效和联合国正式成立而确定的节日。节日日期为每年10月24日，主题为维持国际和平与安全，发展国际间友好关系，促进经济、社会、文化及人类福利等方面的国际合作。"
                ]
            },
            {
                "title": "发展历史",
                "detail": [
                    "1943年10月30日，中、美、英、苏四国在莫斯科发表《普遍安全宣言》，提出有必要建立一个普遍性的国际组织。",
                    "1944年8月21日至10月7日，苏、英、美三国和中、英、美三国先后在华盛顿敦巴顿橡树园举行会谈，根据《普遍安全宣言》的精神，草拟了建立新的国际组织的章程，并通过了《关于建立普遍性国际组织的建议案》。在谈到这个国际组织的名称时，三国都同意沿用1942年“共同宣言”所用过的”联合国”一词，把未来的国际组织命名为“联合国”，这个提议最后获得了旧金山制宪会议批准。",
                    "1945年2月，由罗斯福、丘吉尔和斯大林参加的雅尔塔会议，又进一步讨论了成立联合国的问题。会议公报宣布，为了维护世界和平与安全，反法西斯同盟国将尽快建立一个普遍性的国际组织，并决定同年4月25日在美国旧金山召开联合国制宪会议。根据这一决定，由美、英、中、苏、法5国发起，并邀请《联合国家共同宣言》各签字国参加的联合国制宪大会，1945年4月25日，来自50个国家(波兰因故未参加)280多名代表和1700多名顾问、专家及记者聚集一堂，在美国旧金山召开联合国国际组织会议。中国代表团由10人组成，其中包括中国共产党的代表董必武。会议以敦巴顿橡胶园会议的建议为基础，经过两个多月的讨论，起草了《联合国宪章》。6月25日，代表们在旧金山歌剧院一致通过了《联合国宪章》。6月26日，制宪会议在旧金山退伍军人纪念堂礼堂进行最后一项，也是此次大会最庄重的议程——与会代表在宪章上签字。按照大会商定的程序，中国代表团第一个签字。正午时分，董必武用毛笔在宪章上写下了自己的名字。各国代表都签署了这个宪章，其后波兰也在宪章上补签，签署宪章的51个国家成为联合国的创始会员国。1945年10月24日，中、法、苏、英、美和其他多数签字国递交批准书后，宪章开始生效，联合国正式成立。",
                    "1947年，联合国大会决定，10月24日为“联合国日”。",
                    "1971年，大会建议会员国把这一天作为公共假日。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "寒衣节",
        "short_desc": "洒一杯酒，让哀思化作祝福",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "农历十月初一"
                    },
                    {
                        "title": "节日别称",
                        "value": "十月朝，祭祖节，冥阴节等"
                    },
                    {
                        "title": "节日活动",
                        "value": "祭奠先人"
                    }
                ],
                "detail": [
                    "寒衣节，每年农历十月初一，又称“十月朝”、“祭祖节”、“冥阴节”，民众称为鬼头日，是我国传统的祭祀节日，相传起源于周代。这一天，特别注重祭奠先亡之人，谓之送寒衣。寒衣节与春季的清明节，秋季的中元节，并称为一年之中的三大“鬼节”。同时，这一天也标志着严冬的到来，所以也是为父母爱人等所关心的人送御寒衣物的日子。"
                ]
            },
            {
                "title": "节日传说",
                "detail": [
                    "关于祭祖节还有这样一个蔡伦造纸和蔡莫烧纸的故事。",
                    "中国民间传说蔡伦刚发明出纸时，买卖很好。蔡伦的嫂子慧娘就让丈夫蔡莫向蔡伦学习造纸。回来开了一家造纸厂，但蔡莫造的纸，质量不好，卖不出去，二人很着急。后来，慧娘想了一个办法，摆脱了困境。",
                    "某天的半夜，慧娘假装因急病而死。蔡莫伤心欲绝，在她的棺材前悲哀不已，他边烧纸边哭诉：“我跟弟弟学造纸，不用心，造的纸质量太差了，竟把你气病了。我要把这纸烧成灰，来解心头之恨。”他烧完了又抱来纸烧，烧了一阵之后，只听见慧娘在棺材里喊：“把门开开，我回来了。”这可把人们吓呆了，人们把棺材打开，慧娘装腔作势地唱道：阳间钱能行四海，阴间纸在做买卖。不是丈夫把纸烧，谁肯放我回家来？",
                    "慧娘唱了很多遍说：“刚才我是鬼，现在我是人，大家不要害怕。我到了阴间，阎王就让我推磨受苦，丈夫送了钱，就有许多小鬼帮我，真是有钱能使鬼推磨。三曹官也向我要钱，我把全部的钱都送了他，他就开了地府后门，放我回来了。”蔡莫装作糊涂：“我并没有送钱给你啊？”慧娘说：“你烧的纸就是阴间的钱。”这样一说，蔡莫又抱了几捆纸，烧给他的父母。",
                    "在场的人们一听，便以为烧纸有很大的好处，都向蔡莫买纸。慧娘慷慨地送给乡亲，这事一传十，十传百，远近的乡亲都来买蔡家的纸，烧给自己死去的亲人。不到两天，积压的纸被抢购一空。慧娘“还阳”那一天正是农历十月初一，因此，后人都在十月初一祭祀祖先，上坟烧纸，以示对祖先的怀念。",
                    "十月初一，有的地方还有祭牛王的习俗。相传这一习俗起源于春秋秦国。某日，秦文公命人砍倒一棵梓树，梓树忽然化为一头牛。秦文公令人追杀。牛一下跳入水中，再也没有上来。人们就立“怒特祠”祭祀此牛神。怒特，是高大健壮、威风凛凛的公牛。这样的公牛，在人们的心目中，有着驱疫辟邪、保护牛类的功能。"
                ]
            },
            {
                "title": "风俗习惯",
                "detail": [
                    "农历十月初一，谓之“十月朝“。我国自古以来就有新收时祭奠祖宗的习俗，以示孝敬，不忘本。古人们也在农历十月初一用黍矐祭祀祖先。农历十月初一祭祀祖先，有家祭、也有墓祭，南北方都是如此，今天江南的许多地区，还有十月初一祭新坟的习俗。",
                    "农历十月初一，也是冬天的第一天，此后天气渐渐寒冷，人们怕在冥间的祖先灵魂缺衣少穿，因此，祭祀时除了食物、香烛、纸钱等一般供物外，还有一种不可缺少的供物-冥衣。在祭祀时，人们把冥衣焚化给祖先，叫作“送寒衣“。",
                    "后来，有的地方，“烧寒衣”的习俗，就有了一些变迁，不再烧寒衣，而是“烧包袱”人们把许多冥纸封在一个纸袋之中，写上收者和送者的名字以及相应称呼，这就叫“包袱”。有寒衣之名，而无寒衣之实。人们认为冥间和阳间一样，有钱就可以买到许多东西。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "尾牙",
        "short_desc": "奋斗的汗水刚刚拭去，胜利的笑容正在蔓延",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "农历腊月十六"
                    },
                    {
                        "title": "节日起源",
                        "value": "闽南商人"
                    },
                    {
                        "title": "流行区域",
                        "value": "台湾及福建地区"
                    },
                    {
                        "title": "相关人物",
                        "value": "福德正神（即土地公）"
                    }
                ],
                "detail": [
                    "尾牙是闽南地区的民间传统节日，商家一年活动的“尾声”，也是普通百姓春节活动的“先声”。每月的初二、十六，是闽南商人祭拜土地公神的日子，称为“做牙”。二月二日为最初的做牙，叫做“头牙”；十二月十六日的做牙是最后一个做牙，所以叫“尾牙”。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "尾牙节这一节日主要流行于东南沿海，尤其是闽台地区。但是它却的的确确是土生土长的华夏传统节日，这一节日与中国人的土地神崇拜有关。",
                    "「牙」是中国民间祭拜土地公的仪式。传统习俗中，作生意的人在农历每月初二及十六日，都必须准备一些三牲四果、香枝与纸钱等、祭拜地基主（土地最早的主人）以及土地公，在牲礼当中不可少的是一只雄鸡拔毛煮熟的白斩鸡，这是用来象徵生意兴隆的。",
                    "农历十二月十六日为一年中最後一次的祭祀活动俗称「尾牙」。古时有一诗句「一年夥计酬杯酒，万户香烟谢土神」，这是旧时诗人述的尾牙情景。民间在二月初二土地公诞辰祭拜之後，东家即宴请伙计，到了十二月十六日「尾牙」这天，商家们为了感谢土地公一年的照顾，都会准备丰富的祭品来酬谢土地公，而祭拜完的东西就会犒赏给员工们。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "（1）福建地区把做“尾牙”之后的日子，即农历十二月十七日到二十二日作为赶工结帐时间。所以，也称二十二日为“尾期”。“尾期”前可以向各处收凑新旧账，延后则就要等到新年以后才收帐了。",
                    "所以“尾牙”的饭吃完后，就有几天好忙。过了“尾期”，即使是身为债主的硬去收账，也可能会被对方痛骂一场，说不定还会被揍，也不能有分毫怨言。",
                    "（2）商人和农人在“尾牙”这一天，除了供奉神明，也要招待自家的雇工与仆婢，对于来年是否继续雇用一个人，也要在尾牙作最后的决定。所以，这算是慰劳日，又是礼貌相送日。",
                    "（3）薄饼是“尾牙”的必备食物。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "龙抬头",
        "short_desc": "二月二，龙抬头，剃龙头，精神抖",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "农历二月初二"
                    },
                    {
                        "title": "流行地区",
                        "value": "中国"
                    },
                    {
                        "title": "节日类型",
                        "value": "传统节日"
                    }
                ],
                "detail": [
                    "龙抬头节又称春龙节、春花节。民间传说在唐朝武则天当政时期，因得罪玉皇大帝而被罚三年无雨，人间河干沟竭，树枯苗死，人们难以过活。司管天河的龙王心中不忍，在阴历二月初二这天私自给人间降雨，因而受到责罚。人们用炒熟的玉米花、豆子供献龙王，报答其救命之恩，因此感动了玉皇大帝，允许龙王继续为人间降雨。以后二月二祭龙王，相沿下来成为节日。"
                ]
            },
            {
                "title": "民间传说",
                "detail": [
                    "传说每逢农历二月初二，是天上主管云雨的龙王抬头的日子；从此以后，雨水会逐渐增多起来。因此，这天就叫“春龙节”。 中国北方广泛的流传着“二月二，龙抬头；大仓满，小仓流。”的民谚。",
                    "每当春龙节到来，中国北方大部分地区在这天早晨家家户户打着灯笼到井边或河边挑水，回到家里便点灯、烧香、上供。旧时，人们把这种仪式叫做“引田龙”。这一天，家家户户还要吃面条、炸油糕、爆玉米花，比作为“挑龙头”、“吃龙胆”、“金豆开花，龙王升天，兴云布雨，五谷丰登”，以示吉庆。",
                    "农谚“二月二，龙抬头”，传说古时候关中地区久旱不雨，玉皇大帝命令东海小龙前去播雨。小龙贪玩，一头钻进河里不再出来。有个小伙子，到悬崖上采来了“降龙水”，搅浑河水。小龙从河中露出头来与小伙子较量，小龙被击败，只好播雨。其实，所谓“龙抬头”指的是经过冬眠，百虫开始苏醒。所以俗话说“二月二，龙抬头，蝎子、蜈蚣都露头。”"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "各地龙抬头节的民俗活动很多，内容十分丰富，主要有：",
                    "1.祭龙",
                    "在过去，龙抬头节是祭祀龙神的日子，每年的这一天，人们都要到龙神庙或水畔焚香上供祭祀龙神，祈求龙神兴云化雨，保佑一年五谷丰登。不过，在很多地区，特别是南方，人们也把二月初二作为“土地公生日”，举行社祭，祭祀土地神。",
                    "2.撒灰",
                    "这一节俗，由来已久，直到今天，许多乡村地区仍然流行。撒灰所用之灰，一般是柴灰，也有用石灰或用糠的。具体做法，各地不同，大致为：将灰撒在门前，谓之“拦门辟灾”；将灰撒在墙角，意在“辟除百虫”；将灰撒在院中，作大小不等的圆圈，并象征性地放置一些五谷杂粮，称做“围仓”或“打灰囤”，以祝丰年；将灰撒在井边，呼曰“引龙回”，以求风调雨顺。撒法各地不尽相同，一般是从井边开始，一路逶迤撒来，步入宅厨，环绕水缸，灰线蜿蜒不断，甚为有趣。",
                    "3.熏虫",
                    "进入农历二月，天气渐暖，各种昆虫开始活动，有些昆虫对人的健康是有害的，所以二月二这一天，人们纷纷摊烙煎饼、燃烧熏香，希望凭借烟气驱走毒虫。",
                    "4.击房梁",
                    "就是用木棍或者竹竿敲击房梁，以惊走蛇、蝎等毒虫，毋使为害。有的地方流行敲击炕沿，目的与敲击房梁相同。",
                    "5.理发",
                    "传统民间有种说法，曰：正月剃头（理发）死舅舅。此说虽无根据，也很荒谬，但影响却极深，因此，在春节前无论多忙，人们都要抽出空来理一次发，然后就要一直等到 “龙抬头”的日子了。二月二理发，俗称“剃龙头”，据说可以带来一年的好运，因此，“是日，多剃发”。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "圆周率日",
        "short_desc": "探索与求知就像π，无限但不循环",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "3月14日"
                    },
                    {
                        "title": "英文名称",
                        "value": "Pi Day"
                    },
                    {
                        "title": "节日活动",
                        "value": "派对"
                    },
                    {
                        "title": "节日意义",
                        "value": "庆祝圆周率π"
                    }
                ],
                "detail": [
                    "圆周率日是一年一度的庆祝数学常数π的节日，时间被定在3月14日。通常是在下午1时59分庆祝，以象征圆周率的六位近似值3.14159，有时甚至精确到26秒，以象征圆周率的八位近似值3.1415926；习惯24小时记时的人在凌晨1时59分或者下午3时9分（15时9分）庆祝。全球各地的一些大学数学系在这天举办派对。"
                ]
            },
            {
                "title": "发展历史",
                "detail": [
                    "目前已知最早的大型以π为主题的庆祝活动是Larry Shaw 组织，1988年3月14日在旧金山科学博物馆举办的。Larry Shaw 是旧金山科学博物馆的一名物理学家，那一天他带着博物馆的员工和参与者一起围绕这博物馆纪念碑做3又1/7圈（22/7，π的近似值之一）的圆周运动，并一起吃水果派，分享有关π的知识。之后，旧金山科学博物馆继承了这个传统，在每年的这一天都举办庆祝活动。",
                    "美国麻省理工学院首先倡议将3日14日定为国家圆周率日。2009年美国众议院正式通过一项无约束力决议（Non-binding resolution）（HRES 224），将每年的3月14号设定为“圆周率日”（National Pi day）。",
                    "2010年的圆周率日，谷歌为表庆祝，推出了π的Google Doodle，图中元素颇丰，不仅包含了圆周率的定义，π值范围，圆周周长与面积公式，甚至还包含了球体积公式以及圆周的外切和内切多边形示意图。"
                ]
            },
            {
                "title": "庆祝方式",
                "detail": [
                    "庆祝圆周率日的方式有很多，比如吃派，喝一种名字中含有“pi”的鸡尾酒（pi?a colada），玩和pi 发音相近的彩罐游戏（pi?ata）。 ",
                    "这一天常见的庆祝方式包括：",
                    "（1）阅读π的悠久历史，学习有关π的数学知识。",
                    "（2）背诵π。π是无理数，很多人通过背诵π小数点后面的数字来表现记忆力。日本人Akira Haraguchi在2005年将π背到了小数点后第 83431 位。",
                    "（3）计算圆周率。2009年，法国著名程序员Fabr ice Bellard用个人PC，耗时116天，计算到了PI的小数点后第2.7万亿位打破了由超级计算机保持的圆周率运算记录。同时Fabrice Bellard在圆周率算法方面也有着惊人的成就，1997年他提出了最快圆周率算法公式。",
                    "（4）观看电影《死亡密码 π》（1998年讲述一个偏执数学家故事的惊悚电影）、《少年派的奇幻漂流》（一个名为pi的少年的冒险故事）。",
                    "（5）做一个以π为主题的派。",
                    "（6）欣赏以π为主题的音乐，例如圆周率之歌。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "世界水日",
        "short_desc": "不要让你的眼泪成为世界最后一滴水",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "3月22日"
                    },
                    {
                        "title": "节日类型",
                        "value": "公益性节日"
                    },
                    {
                        "title": "设立时间",
                        "value": "1993年1月18日"
                    },
                    {
                        "title": "节日意义",
                        "value": "唤起公众的节水意识，加强水资源保护"
                    }
                ],
                "detail": [
                    "世界水日宗旨是唤起公众的节水意识，加强水资源保护。为满足人们日常生活、商业和农业对水资源的需求，联合国长期以来致力于解决因水资源需求上升而引起的全球性水危机。1977年召开的“联合国水事会议”，向全世界发出严重警告：水不久将成为一个深刻的社会危机，石油危机之后的下一个危机便是水。1993年1月18日，第四十七届联合国大会作出决议，确定每年的3月22日为“世界水日”。"
                ]
            },
            {
                "title": "背景",
                "detail": [
                    "“世界水日”的确立，是有背景的：一切社会和经济活动都极大地依赖淡水的供应量和质量，随着人口增长和经济发展，许多国家将陷入缺水的困境，经济发展将受到限制；推动水的保护和持续性管理需要地方一级、全国一级、地区间、国际间的公众意识。",
                    "世界水日是为了节省地球水资源。解除我们国家以及全世界缺水的状态。"
                ]
            },
            {
                "title": "目的",
                "detail": [
                    "为了唤起公众的水意识，建立一种更为全面的水资源可持续利用的体制和相应的运行机制，1993年1月18日，第47届联合国大会根据联合国环境与发展大会制定的《21世纪行动议程》中提出的建议，通过了第193号决议，确定自1993年起，将每年的3月22日定为“世界水日”，以推动对水资源进行综合性统筹规划和管理，加强水资源保护，解决日益严峻的缺水问题。同时，通过开展广泛的宣传教育活动，增强公众对开发和保护水资源的意识。让我们节约用水，不要让最后一滴水成为我们的眼泪！"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "上巳节",
        "short_desc": "三月三，春风暖，明媚三月一起看",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "农历三月三日"
                    },
                    {
                        "title": "节日别称",
                        "value": "祓禊、修禊、禊祭，或者单称禊。"
                    }
                ],
                "detail": [
                    "上巳(sì)节，俗称三月三，汉民族传统节日，该节日在汉代以前定为三月上旬的巳日，后来固定在夏历三月初三。传统的上巳节在农历三月的第一个巳日，也是祓禊的日子，即春浴日。上巳节又称女儿节。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "上巳节可追溯到追念伏羲氏。伏羲和其妹女娲抟土造人，繁衍后代，豫东一带尊称伏羲为“人祖爷”，在淮阳（伏羲建都地）建起太昊陵古庙，由农历二月二到三月三为太昊陵庙会，善男信女，南船北马，都云集陵区，朝拜人祖。农历三月三，还是传说中王母娘娘开蟠桃会的日子。有一首北京竹枝词是这样描述蟠桃宫庙会盛况的：“三月初三春正长，蟠桃宫里看烧香；沿河一带风微起，十丈红尘匝地。”传说西王母原是中国西部一个原始部落的保护神。她有两个法宝：一是吃了可以长生不老的仙丹，二是吃了能延年益寿的仙桃——蟠桃。神话传说中的嫦娥，就是偷吃了丈夫后羿弄来的西王母仙丹后飞上月宫的。此后，在一些志怪小说中，又把西王母说成是福寿之神。",
                    "古时以三月第一个巳日为“上巳”，汉代定为节日。“是月上巳，官民皆絜(洁)于东流水上，曰洗濯祓除，去宿垢疢(病)，为大絜”(《后汉书?礼仪志上》)。后又增加了临水宴宾、踏青的内容。魏晋以后，上巳节改为三月三，后代沿袭，遂成汉族水边饮宴、郊外游春的节日。"
                ]
            },
            {
                "title": "节日活动",
                "detail": [
                    "（一）高禖",
                    "在上巳节活动中，最主要的活动是祭祀高禖，即管理婚姻和生育之神。",
                    "（二）祓禊",
                    "上巳节还有祓禊、修禊或沐浴活动。沐浴是为了去灾。",
                    "（三）曲水流觞",
                    "在上巳节中还有临水浮卵、水上浮枣和曲水流觞三种活动。在上述三种水上活动中，以临水浮卵最为古老，它是将煮熟的鸡蛋放在河水中，任其浮移，谁拾到谁食之。",
                    "（四）会男女",
                    "在上巳节中有一种奇特的风俗，即“会男女”。这种节日中的野合，由来已久，本来自氏族时期的季节性婚配——野合群婚，后来也有残存，如广西左江崖画、成都汉墓画像砖上都有男女野合图。后来的记载也多见此俗。",
                    "（五）蟠桃会",
                    "道教兴起后，认为农历三月三为西王母蟠桃会之日。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "世界知识产权日",
        "short_desc": "知识是一切力量的源泉",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "4月26日"
                    },
                    {
                        "title": "起源时间",
                        "value": "2001年"
                    },
                    {
                        "title": "流行地区",
                        "value": "世界"
                    }
                ],
                "detail": [
                    "世界知识产权日是由世界知识产权组织设立，定在每年的4月26日，旨在促进各界树立尊重知识、崇尚科学，保护知识产权的意识，营造鼓励知识创新和保护知识产权的法律环境。每年世界知识产权组织会确定一个主题，各成员国应围绕当年主题在世界知识产权日期间举办各种宣传活动，以达到设立该主题日的目的。"
                ]
            },
            {
                "title": "宣传标志",
                "detail": [
                    "世界知识产权日宣传标志主体字母为“I”、“P”、“R”，是知识产权英文表达法Intellectual Property Rights的首写字母，三个字母变形组成一个活跃的人体形状，意为“知识以人为本，保护知识产权，维护权利人的利益”。标志包括主体字母、“宣传周”日期及中英文名称三部分，体现与世界知识产权日的联系。"
                ]
            },
            {
                "title": "节日活动",
                "detail": [
                    "世界知识产权组织鼓励各成员国和组织发来关于其本国为庆祝世界知识产权日所举行的各大事件和活动的简短报告。每年的知识产权日都给大家提供一个机会，与世界各地的人们共同铭记、讨论和展示知识产权制度怎样为音乐和艺术的繁荣作出贡献，怎样驱动技术创新，帮助改变世界。",
                    "中国活动",
                    "自2001年起，4月26日“世界知识产权日”期间，中国有关部门都会组织活动，以增强全社会的知识产权保护意识。从2007年开始，全国整规办、知识产权局等九部门决定把每年的4月19日至26日作为“保护知识产权宣传周”，在全国范围内联合开展活动，共同推动知识产权的宣传和保护工作。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "世界微笑日",
        "short_desc": "用微笑和世界问好",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "5月8日"
                    },
                    {
                        "title": "节日别称",
                        "value": "国际微笑日"
                    },
                    {
                        "title": "设立机构",
                        "value": "世界精神卫生组织"
                    }
                ],
                "detail": [
                    "世界微笑日，让我们嘴角上翘，我们用微笑对抗地心引力带来的面容衰老，也用微笑释放善意，与世界和睦相处。在你对别人的微笑里，也会看到世界对你微笑。这样美好的特权，何必吝啬！"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "1948年，国际红十字会规定将国际红十字会创始人亨利·杜南的生日—5月8日为世界红十字日，也即“世界微笑日”。从1948年起，每年的5月8日，世界精神卫生组织把这天订立为“世界微笑日”，希望通过微笑促进人类身心健康，同时在人与人之间传递愉悦与友善，增进社会和谐。",
                    "世界微笑日，是唯一一个庆祝人类行为表情的节日，这一天会变得特别温馨，在对别人的微笑中，你也会看到世界对自己微笑起来。"
                ]
            },
            {
                "title": "有关微笑",
                "detail": [
                    "作用",
                    "1.微笑不仅仅使人心情舒畅、精神振奋，而且能够消除忧虑、稳定情绪，可以使动脉弛缓，加快血液循环，起到与胸部、肠胃、肩膀周围的上体肌肉运动一样的效果。研究证明，笑有强心健脑、促进呼吸、有助美容、改善消化、缓解疼痛、降压健身和防治疾病等多种保健功能。",
                    "2.微笑，不单是种表情，更是一种感情，是拉近人们之间距离的法宝，是融洽人际关系的催化剂.微笑是无声的问候，它是心灵相通的阳光，传递着亲切与尊重的讯息，为深入沟通与交往创造温馨和谐的氛围。",
                    "3.微笑，传递给人的是愉快和友善的情感信息，沟通着人们的情感，化解着人际之间的矛盾，代表着和美的道德指引。真正甜美的微笑，是和蔼的体现、亲切的象征，往往比言语更真实、更富魅力，也是一个人良好综合素养的自然流露。面露平和欢愉的微笑，说明心情愉快，充实满足，乐观向上，善待人生，这样的人才会产生吸引别人的魅力。",
                    "4.经调查研究，皱一下眉头需要牵动20块肌肉，而笑一笑只需要牵动13块肌肉，由此可见，笑一笑可以延缓衰老，同时，也给人一整天的愉悦心情。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "世界环境保护日",
        "short_desc": "保护水资源，保护生态环境",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "6月5日"
                    },
                    {
                        "title": "设定时间",
                        "value": "1972年6月5日"
                    },
                    {
                        "title": "设定地点",
                        "value": "斯德哥尔摩"
                    },
                    {
                        "title": "设立机构",
                        "value": "联合国环境规划署"
                    }
                ]
            },
            {
                "title": "设立宗旨",
                "detail": [
                    "1972年6月5日联合国在瑞典首都斯德哥尔摩召开了联合国人类环境会议，会议通过了《人类环境宣言》，并提出将每年的6月5日定为“世界环境日”。同年10月，第27届联合国大会通过决议接受了该建议。世界环境日，是联合国促进全球环境意识、提高政府对环境问题的注意并采取行动的主要媒介之一。联合国系统和各国政府，每年都在6月5日的这一天开展各项活动来宣传与强调保护和改善人类环境的重要性。",
                    "地球是人类和其他物种的共同家园，然而由于人类常常采取乱砍滥伐、竭泽而渔等不良发展方式，地球上物种灭绝的速度大大加快。生物多样性丧失的趋势正使生态系统滑向不可恢复的临界点，如果地球生态系统最终发生不可挽回的恶化，人类文明所赖以存在的相对稳定的环境条件将不复存在。",
                    "世界环境日的意义在于提醒全世界注意地球状况和人类活动对环境的危害。要求联合国系统和各国政府在这一天开展各种活动来强调保护和改善人类环境的重要性。"
                ]
            },
            {
                "title": "当前的十大环境问题",
                "detail": [
                    "当前，威胁人类生存的十大环境问题是：",
                    "（一）全球气候变暖",
                    "由于人口的增加和人类生产活动的规模越来越大，向大气释放的二氧化碳（CO2）、甲烷（CH4）、一氧化二氮（N2O）等温室气体不断增加，导致大气的组成发生变化。大气质量受到影响，气候有逐渐变暖的趋势。",
                    "（二）臭氧层的耗损与破坏",
                    "臭氧层被破坏，将使地面受到紫外线辐射的强度增加，给地球上的生命带来很大的危害。研究表明，紫外线辐射能破坏生物蛋白质和基因物质脱氧核糖核酸，造成细胞死亡；使人类皮肤癌发病率增高；伤害眼睛，导致白内障而使眼睛失明；抑制植物如大豆、瓜类、蔬菜等的生长，并穿透10米深的水层，杀死浮游生物和微生物，从而危及水中生物的食物链和自由氧的来源，影响生态平衡和水体的自净能力。",
                    "（三）生物多样性减少",
                    "（四）酸雨蔓延",
                    "（五）森林锐减",
                    "（六）土地荒漠化",
                    "（七）大气污染",
                    "（八）水污染",
                    "（九）海洋污染",
                    "（十）危险性废物越境转移"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "国家扶贫日",
        "short_desc": "关爱贫困人口，关心扶贫工作",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "10月17日"
                    },
                    {
                        "title": "节日意义",
                        "value": "消除绝对贫困"
                    },
                    {
                        "title": "设立机构",
                        "value": "国务院扶贫办"
                    },
                    {
                        "title": "设定时间",
                        "value": "2014年8月1日"
                    }
                ],
                "detail": [
                    "2014年8月29日，国务院扶贫办召开会议，专题研究部署全国首次“扶贫日”系列活动工作，听取各地的意见建议。会议指出，《国务院关于同意设立“扶贫日”的批复》同意自2014年起，将每年的10月17日 设立为“扶贫日”，具体工作由国务院扶贫办商有关部门组织实施。会议强调，全国扶贫系统要组织动员各有关方面，按照依法合规、节俭务实、注重实效的原则，精心组织开展全国首次“扶贫日”活动，使之成为在国内外有较高知名度、较大影响力和较强公信力的公益活动。"
                ]
            },
            {
                "title": "目的",
                "detail": [
                    "设立扶贫日的主要目的就是引导社会各界关注贫困问题，关爱贫困人口，关心扶贫工作，核心内容是学习身边榜样，宣传凡人善举，动员广泛参与，培育良好风尚。"
                ]
            },
            {
                "title": "意义",
                "detail": [
                    "设立“扶贫日”充分体现了党中央、国务院对扶贫开发工作的高度重视，对贫困地区贫困群众的格外关心，对于宣传党和政府扶贫开发方针政策、弘扬中华民族扶贫济困传统美德和友善互助核心价值观、加强扶贫理论政策研究交流，对于构建政府市场社会协同 推进扶贫开发的工作格局，动员全社会力量继续向贫困宣战，必将发挥重要作用。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "中国人民警察节",
        "short_desc": "英勇无畏，除暴安良",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日时间",
                        "value": "1月10日"
                    },
                    {
                        "title": "设定时间",
                        "value": "2020年7月21日"
                    }
                ],
                "detail": [
                    "“中国人民警察节”是在国家层面专门为人民警察队伍设立的节日，是对人民警察队伍为党和人民利益英勇奋斗的充分肯定。\n2020年7月21日，《国务院关于同意设立“中国人民警察节”的批复》发布，同意自2021年起，将每年1月10日设立为“中国人民警察节”。"
                ]
            },
            {
                "title": "节日来源",
                "detail": [
                    "据了解，1986年1月10日，广东省广州市公安局率先建立我国第一个110报警服务台。1996年，公安部在福建漳州召开全国城市110报警服务台建设工作现场会，全面推广“漳州110”经验。经过三十多年的实践与探索，110已经成为人民警察队伍的标志性品牌，被誉为“人民的保护神”。近年来，每年1月10日公安部及各地公安机关都组织开展多种形式的110宣传日活动，这一日期具有极高的社会知晓度和群众认可度。将这一日期确立为“中国人民警察节”，体现了鲜明的政治性、广泛的人民性和警察职业的标志性。"
                ]
            },
            {
                "title": "节日意义",
                "detail": [
                    "经党中央批准、国务院批复，自2021年起，将每年1月10日设立为“中国人民警察节”。“中国人民警察节”的设立，充分体现了以习近平同志为核心的党中央对人民警察队伍的高度重视和关心关怀，是进一步健全完善人民警察荣誉制度和标志体系的重要举措，对于推动人民警察队伍革命化正规化专业化职业化建设，增强广大民警职业荣誉感自豪感归属感，激励全警以强烈的担当精神履行好党和人民赋予的新时代使命任务具有重大意义。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "母亲节",
        "short_desc": "摘一束美丽的鲜花，采一束清晨的阳光。送给你，亲爱的妈妈",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "英文名",
                        "value": "Mother`s Day"
                    },
                    {
                        "title": "节日时间",
                        "value": "每年5月的第二个星期日"
                    },
                    {
                        "title": "母亲花",
                        "value": "康乃馨、萱草花"
                    }
                ],
                "detail": [
                    "20世纪80年代，母亲节逐渐被中国内地的民众所接受。从1988年开始，中国南方的广州等一些城市开始举办母亲节的庆祝活动，并把评选“好母亲”作为内容之一。20世纪末，随着中国与国际的日益接轨，母亲节这一节日在中国大陆各地日益推广开来，越来越多的人开始接受母亲节概念，在每年5月的第二个星期日，中国人和全世界其他国家的人们一道以各种各样的方式表达对母亲养育之恩的感谢。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "传统起源",
                    "",
                    "母亲节的传统起源于古希腊。在一月八日这一天，古希腊人向希腊众神之母瑞亚（英文：Rhea、Cybele，希腊文：宙斯、波塞冬、哈德斯、得墨忒耳、赫拉和赫斯提亚的母亲，故称众神之母）致敬。到古罗马时，这些活动的规模就变得更大，庆祝盛况往往持续达三天之久。当然，古时人们对女神的崇拜只不过是一种迷信，它同今天人们对母性的尊敬是大不相同的。",
                    "",
                    "现代起源",
                    "",
                    "1913年5月，美利坚合众国众议院一致通过决议，号召总统以及内阁、参众两院和联邦政府的一切官员一律在母亲节佩戴白色石竹花。1914年，美利坚合众国国会正式命名5月的第二个星期日为母亲节，并要求总统发布宣言，号召政府官员在所有的公共建筑物上悬挂国旗。紧接着，威尔逊总统昭告全国公民也在自己的住宅上挂国旗以表达人们对美利坚合众国全体母亲的热爱和尊敬。此后美利坚合众国总统每年都要发表一篇内容相同的宣言。此后，母亲节便在各国开展。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "中国： 五月的第二个星期日， 会用贺卡和康乃馨来表示爱， 也有人建议以忘忧草来表达母爱",
                    "挪威： 母亲节定于二月的第二个星期天。",
                    "阿根廷： 则在十月的第二个星期天庆祝母亲节。",
                    "黎巴嫩： 在春天的第一天庆祝母亲节。",
                    "南非： 母亲节则定于五月的第一个星期天。",
                    "南斯拉夫： 南斯拉夫的塞尔维亚人称母亲节为Materice， 于每年圣诞节的前两个礼拜庆祝。 庆祝的习俗是： 小孩子们在母亲节的清晨溜进母亲房间， 将母亲绑在床上， 母亲醒来时发现自己被五花大绑， 便哀求孩子们放开她， 并以她预先藏在枕头下的小礼物作为交换。",
                    "瑞典在五月的最后一个星期日庆祝母亲节。 在母亲节前夕， 瑞典红十字会会举办塑料制的母亲花的义卖， 义卖所得将作为赞助育有许多小孩的母亲的度假经费。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "父亲节",
        "short_desc": "愿天下父亲身体无恙，幸福安康",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "英文名",
                        "value": "Father`s Day"
                    },
                    {
                        "title": "节日时间",
                        "value": "6月的第3个星期日"
                    },
                    {
                        "title": "节日意义",
                        "value": "感恩父亲"
                    },
                    {
                        "title": "流行地区",
                        "value": "全球"
                    }
                ],
                "detail": [
                    "父亲节（Father`s Day），顾名思义是感恩父亲的节日。约始于二十世纪初，起源于美国，现已广泛流传于世界各地，节日日期因地域而存在差异。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "世界上的第一个父亲节，1910年诞生在美国，是由住在美国华盛顿州斯波坎的布鲁斯多德夫人倡导的，多德夫人的母亲在生育第六个孩子时，因难产而死，多德夫人的父亲威廉斯马特先生曾参加过南北战争，他在妻子过世后，独自一人在华盛顿州东部的一个乡下农场，承担起抚养、教育六个孩子的重任。",
                    "",
                    "1909年斯马特先生辞世之年，当多德夫人参加完教会的母亲节感恩礼拜后，她特别想念父亲，多德夫人心中明白，她的父亲在养育儿女过程中所付出的爱和艰辛，并不亚于任何一个母亲，多德夫人将她的感受告诉给教会的瑞马士牧师，希望能有一个特别的日子，纪念全天下伟大的父亲，她的这一想法得到了牧师的赞许，同时得到了各教会组织的支持，多德夫人随即写信向市长与州政府表达了自己的想法，并建议以她父亲的生日——每年的6月5日作为父亲节，斯波坎市市长与华盛顿州州长公开表示赞成，州政府采纳这一建议的同时，把节期改在6月的第三个星期日，1910年6月19日多德夫人所在的华盛顿州斯波坎市，举行了全世界的第一次父亲节庆祝活动，在差不多的时间里，美国各地其它城镇的人们也开始庆祝“父亲节”。"
                ]
            },
            {
                "title": "节日活动",
                "detail": [
                    "美国为父母做早餐",
                    "",
                    "在美国，父亲节当天早餐一般是由子女们来做，父母可以继续睡觉，不必早起。由子女们做好早餐拿到床前给父母亲食用。此外，在美国儿女也会给父亲寄贺卡，买领带、袜子之类的小礼品送给父亲，以表达对父亲的敬重。",
                    "",
                    "日本给父亲念感恩信、搓背",
                    "",
                    "在日本父亲节时，女儿一般会念感谢信给父亲。她们会和父亲团聚，给父亲送上礼物和祝福。在日本，不管是已经出嫁的还是待字闺中的女儿，一般要给父亲写一封挚爱和祝福的信，将这封信捧到父亲面前，大声念给父亲听，感谢父亲的生身和养育之恩。",
                    "",
                    "德国父亲喝啤酒庆祝",
                    "",
                    "在德国的父亲节这一天，嗜酒的德国父亲们可以想喝多醉就喝多醉，而且回家之后媳妇不许管。一些小镇上的男人一早推着载满大木桶装的啤酒小车出门，碰上谁就和谁喝，直至醉倒在街上。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "感恩节",
        "short_desc": "怀揣感恩心，感谢每一天，感谢所有人",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节日别称",
                        "value": "火鸡节"
                    },
                    {
                        "title": "节日时间",
                        "value": "每年11月第四个星期四"
                    },
                    {
                        "title": "节日起源地",
                        "value": "美国"
                    }
                ],
                "detail": [
                    "感恩节（Thanksgiving Day）是美国人民独创的一个古老节日，也是美国人合家欢聚的节日。初时感恩节没有固定日期，由美国各州临时决定。直到美国独立后的1863年，林肯总统宣布感恩节为全国性节日。"
                ]
            },
            {
                "title": "起源",
                "detail": [
                    "当我们再次计划庆祝“美国人”（指征服美洲的欧洲人后裔）所说的“感恩节”之时，让我们反思一下过去。其实，感恩节的起源不过是个传说，而这个传说并不被印第安人认可，真实的情况是，感恩节是白人入侵美洲后，为庆祝对印第安人的大屠杀取得胜利而设的节日。",
                    "",
                    "在欧洲人入侵美洲129年之后的1621年，据说有了第一个感恩节。传说中，当年，普利茅斯殖民地的英国移民首次庆祝了感恩节。这批来自英格兰的清教徒，1620年乘“五月花”号船来到马萨诸塞州的普利茅斯。他们在当地印第安人的帮助下，学会了种植玉米、狩猎、捕鱼等本领。在第二年欢庆丰收之时，英国移民邀请印第安人一起感谢上帝施恩，因此有了第一个感恩节。传说中，首次庆祝盛宴延续了三天，清教徒们和印第安人一同分享了丰收后的美食。",
                    "",
                    "但那三天盛宴没有留下什么历史记录，存活下来的印第安人不认可这个传说。真实的情况是，由于欧洲人带来的瘟疫，印第安人像苍蝇一样死去，现在康涅狄格州的佩科特部落（印第安人的一个分支），在欧洲人来到时有8000人，到1637年只剩下1500人，而那年才是白人官方首次宣布庆祝感恩节，白人当时是为了庆祝他们在康涅狄格山谷对佩科特人的屠杀。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "美国",
                    "",
                    "感恩节这一天。美国举国上下热闹非凡，有许多戏剧表演、化装游行、体育比赛等等。除了美国拥有自己的感恩节，加拿大、巴西、埃及、希腊等地方也拥有属于自己的感恩节，不同国家庆祝感恩节的习俗各有不同。",
                    "",
                    "每逢感恩节这一天，美国家家户户都要吃火鸡，他们通常还吃一些传统的菜肴，例如西葫芦、奶油洋葱、土豆泥、番瓜派等等。家庭成员无论在哪都会赶回家过节。",
                    "",
                    "埃及",
                    "",
                    "埃及人边“哭”边庆祝收包谷更多。古埃及的感恩节又叫收获节，是为了纪念他们的庄稼神明而举行的，所以这个节日在每年春天举行。这一天他们常常假装哭泣而且悲痛欲绝，他们害怕收割仍在生长的玉米会使玉米发怒。",
                    "",
                    "除了美国、加拿大，世界上还有埃及、希腊等国家有自己独特的感恩节，但英国、法国等欧洲国家却与感恩节绝缘[3]  ，也有学者倡议设立“中华感恩节”，以弘扬传统文化。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "立春",
        "short_desc": "春回大地，万木生芽",
        "area": 0,
        "type": 1,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "别称",
                        "value": "打春、立春节"
                    },
                    {
                        "title": "节气时间",
                        "value": "每年2月3日或4日"
                    },
                    {
                        "title": "代表寓意",
                        "value": "春季的开始"
                    },
                    {
                        "title": "气候特点",
                        "value": "气温回升，风和日暖"
                    },
                    {
                        "title": "节气养生",
                        "value": "保护阳气、戒暴怒，情怀忧郁"
                    },
                    {
                        "title": "节气三候",
                        "value": "东风解冻；蜇虫始振；鱼陟负冰"
                    }
                ],
                "detail": [
                    "立春是汉族民间重要的传统节日之一。“立”是“开始”的意思，自秦代以来，中国就一直以立春作为孟春时节的开始。所谓“一年之计在于春”，春是温暖，鸟语花香；春是生长，耕耘播种。"
                ]
            },
            {
                "title": "养生",
                "detail": [
                    "《黄帝内经·素问·四气调神大论篇》：“春三月，此谓发陈，天地俱生，万物以荣，夜卧早起，广步于庭，被发缓形，以使志生。”立春是人体肝阳亢盛之时，所以人的情绪容易急躁。初春气压低，天气多变，常使人的情绪产生较大波动。春季要防躁动，避免过度劳累，保证睡眠情绪豁达。同时，经历冬季的收敛藏伏，春季也正是运动养生的最佳时机。",
                    "立春养生方面主要是护肝、调节心情为主。心情舒畅，既可防止肝火上越，又有利于阳气生长。要力戒暴怒，更忌心情忧郁，做到心胸开阔、乐观向上，保持心境恬愉的好心态。所以立春情绪不宜过于激动、兴奋，要保持愉悦、平和，这样有利于疏肝理气。立春过后，春游、踏青、放风筝等都可以舒缓紧张情绪。"
                ]
            },
            {
                "title": "饮食",
                "detail": [
                    "立春为肝气旺的时候，肝气旺则会影响到脾，因此立春饮食调养宜清淡可口，忌油腻辛辣，应选择一些养肝、疏肝、健脾、理气的食物和药材，宜多吃小白菜、青椒、西红柿、胡萝卜、芹菜、卷心菜等新鲜蔬菜和柑橘、柠檬等水果；宜多喝水，防止毒素损害肝脏。",
                    "立春应少吃酸味、多吃甘淡、性温、微辛食物，以养肝健脾和胃，抗御外邪对人体侵袭，还应增加黄绿色蔬菜与时令水果的摄取，补充维生素和无机盐的不足。谷豆类的黑米、豆豉、大豆及其制品；禽鱼类的鸽子、鹌鹑、鲫鱼、泥鳅；蔬菜类的芥菜、菠菜、油菜、胡萝卜、春笋；果品类的栗子、红枣、枸杞、菠萝、甘蔗、橄榄等。将这些食品加以科学搭配食用，就能从中摄取丰富的营养，尤其是蔬果中的多种维生素可充分满足肝脏的需求。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "立春不仅是二十四节气中的第一个节气，而且还是一个重要的节日。在天文意义上它标志着春季的开始。每年2月4日或5日太阳到达黄经315°时为立春。",
                    "对立春的理解，古籍《群芳谱》中这样解释的：“立，始建也。春气始而建立也。”立春期间，气温、日照、降雨，开始趋于上升、增多。但这一切对全国大多数地方来说仅仅是春天的前奏，春天的序幕还没有真正地拉开。",
                    "自秦代以来，我国就一直以立春作为春季的开始。立春是从天文上来划分的，而在自然界、在人们的心目中，春是温暖，鸟语花香；春是生长，耕耘播种。在气候学中，春季是指候（5天为一候）平均气温10℃至22℃的时段。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "风俗活动：",
                    "迎春、春游、鞭春牛、咬春等",
                    "",
                    "风俗食物：",
                    "春饼、萝卜、春卷等"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "雨水",
        "short_desc": "春雨贵如油，滋润天地万物",
        "area": 0,
        "type": 1,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节气时间",
                        "value": "每年2月18日或19日"
                    },
                    {
                        "title": "代表寓意",
                        "value": "降雨开始，雨量渐增"
                    },
                    {
                        "title": "气候特点",
                        "value": "降水增多，气温回升快"
                    },
                    {
                        "title": "节气养生",
                        "value": "养护脾脏、锻炼身体"
                    },
                    {
                        "title": "节气三候",
                        "value": "獭祭鱼；鸿雁来；草木萌动"
                    }
                ],
                "detail": [
                    "雨水节气中，地湿之气渐升，且早晨时有露、霜出现。"
                ]
            },
            {
                "title": "养生",
                "detail": [
                    "雨水期间天气变化无常，容易引起人们的情绪波动，乃至心神不安，影响人的身心健康，对高血压、心脏病、哮喘患者极为不利。春季人的肝气旺盛，怒气伤肝，因而应当采取积极的修身养生方法，陶冶性情，保持情绪稳定。",
                    "",
                    "雨水之后空气中水分增加，导致气温不仅低，而且寒中有湿。这种湿寒的气候对人体内脏和关节有一定的影响。此时养生应注意下列事宜: ",
                    "一、勿过早减外衣。“燥寒冻肉，湿寒入骨。”由于春气涌动，身体偏热的人从感觉上欲减外衣。体热外泄，湿寒交换于内入骨，易伤骨关节致病。",
                    "二、年老体弱者勿用冷水。按中国五行学说，水对应肾，肾主骨。雨水时节年老体弱者用冷水洗脸、洗手，湿寒易侵入关节，又无充足的阳气驱寒于外，湿寒滞留于手，指易酸痛，重则变形；湿寒滞留于头，头易疼痛。",
                    "三、洗头及时吹干。洗头之后应及时用热风机吹干。否则，水湿留于发际中变凉，“同气相求”使湿寒聚于头，由表及里深入颅内，导致头痛。如毛发未干又被冷风吹过，易出现“偏头风”之症。"
                ]
            },
            {
                "title": "饮食",
                "detail": [
                    "吃食物以平性为宜。“倒春寒”容易使人内脏郁热壅阻，因此不易吃燥热食物“火上浇油”。郁热令人“贪凉”，过于食凉，又会“同气相求”使湿寒伤及脏腑，引起胃寒、胃凉、腹泻之类的失衡症状。所以，饮食保持中庸，吃热饭热菜，但不吃慎吃辣椒、不喝少喝白酒等性温、性热的食物为宜。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "雨水是24节气中的第2个节气。每年的正月十五前后（公历2月18-20日），太阳黄经达330度时，是二十四节气的雨水。此时，气温回升、冰雪融化、降水增多，故取名为雨水。雨水节气时段一般从公历2月18日或19日开始，到3月4日或5日结束。雨水和谷雨、小雪、大雪一样，都是反映降水现象的节气。",
                    "",
                    "《月令七十二候集解》：“正月中，天一生水。春始属木，然生木者必水也，故立春后继之雨水。且东风既解冻，则散而为雨矣。”意思是说，雨水节气前后，万物开始萌动，春天就要到了。如在《逸周书》中就有雨水节后“鸿雁来”“草木萌动”等物候记载。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "雨水这天在民间有一项特具风趣的活动叫“拉保保”。(保保则是干爹）以前人们都有一个为自己儿女求神问卦的习惯， 看看自己儿女命相如何， 需不需要找个干爹。 而找干爹的目的， 则是为了让儿子或女儿顺利， 健康的成长。（ 这可能也与以前的医疗条件有关， 因为医疗条件不好， 好多孩子生病根本无法医治。 所以需要借助干爹的福气将孩子带大） 于是便有了雨水节拉保保的活动。 此举一年复一年， 久而成为一方之俗。 ",
                    "",
                    "雨水节的另一个主要习俗则是女婿，女婿去给岳父岳母送节。送节的礼品则通常是两把藤椅，上面缠着一丈二尺长的红带，这称为“接寿”，意思是祝岳父岳母长命百岁。送节的另外一个典型礼品就是“罐罐肉”：用沙锅炖了猪脚和雪山大豆，海带，再用红纸，红绳封了罐口，给岳父岳母送去。这是对辛辛苦苦将女儿养育成人的岳父岳母表示感谢和敬意。如果是新婚女婿送节，岳父岳母还要回赠雨伞，让女婿出门奔波，能遮风挡雨，也有祝愿女婿人生旅途顺利平安的意思。",
                    "",
                    "雨水节回娘屋是流行于川西一带的另一项风俗。民间到了雨水节，出嫁的女儿纷纷带上礼物回娘家拜望父母。生育了孩子的妇女，须带上罐罐肉、椅子等礼物，感谢父母的养育之恩。久不怀孕的妇女，则由母亲为其缝制一条红裤子，穿到贴身处，据说，这样可使其尽快怀孕生子。此项风俗现仍在农村流行。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "惊蛰",
        "short_desc": "历经蛰伏的生命，迎来全新的世界",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "别名",
                        "value": "启蛰"
                    },
                    {
                        "title": "节气时间",
                        "value": "每年3月5日或6日"
                    },
                    {
                        "title": "代表寓意",
                        "value": "天气回暖，春雷乍动"
                    },
                    {
                        "title": "气候特点",
                        "value": "春雷乍动，雨水增多"
                    },
                    {
                        "title": "节气养生",
                        "value": "应顺肝之性，助益脾气"
                    },
                    {
                        "title": "节气三候",
                        "value": "桃始华；黄鹂鸣；鹰化为鸠"
                    }
                ],
                "detail": [
                    "惊蛰时，蛰虫惊醒，天气转暖，渐有春雷，中国大部分地区进入春耕季节。"
                ]
            },
            {
                "title": "养生",
                "detail": [
                    "惊蛰过后万物复苏，是春暖花开的季节，同时却也是各种病毒和细菌活跃的季节。惊蛰时节人体的肝阳之气渐升，阴血相对不足，养生应顺乎阳气的升发、万物始生的特点，使自身的精神、情志、气血也如春日一样舒展畅达，生机盎然。",
                    "惊蛰期间气温回升，且天气变化较大，正是流感病毒高发期。普通感冒和流感共同的病因均源于病毒，症状以上呼吸道感染为主，同时伴有脚软无力、发热、鼻塞流涕等，个别体质较弱者还可因感冒引发气管炎、肺炎、肾炎、心肌炎等病，因此不可小视感冒。预防为主，应适量增减衣着，体弱者少去公共场所，保持室内通风，中午时可多晒晒太阳，夜间娱乐要适度。易感冒者可每日早晚两次用盐水漱口;用姜末加红糖，开水冲泡晚闻服用。",
                    "起居方面，惊蛰过后，气温逐渐升高，气候变暖，人们也就越来越会感到困乏，这就是俗称的“春困”。所以只有保证良好的睡眠，才能有健康和精力充沛的工作生活。应该做到：",
                    "1、在睡前半小时摒弃杂念，心情平静，有利于入睡；",
                    "2、稍微活动一下身体，有利于身体的舒展和放松；",
                    "3、睡前洗脸、洗脚，按摩面部和搓脚心。可推动血气运行，温补脏腑，安神宁心，消除一天的疲劳，利于入睡。"
                ]
            },
            {
                "title": "饮食",
                "detail": [
                    "惊蛰时节饮食起居应顺肝之性，助益脾气，令五脏和平。宜多吃富含植物蛋白质、维生素的清淡食物，少食动物脂肪类食物。可多食鸭血、菠菜、芦荟、水萝卜、苦瓜、木耳菜、芹菜、油菜、山药、莲子、银耳等食物。",
                    "惊蛰天气明显变暖，饮食应清温平淡，多食用一些新鲜蔬菜及蛋白质丰富的食物，如春笋、菠菜、芹菜、鸡、蛋、牛奶等，增强体质抵御病菌的侵袭。惊蛰时节，乍暖还寒，气候比较干燥，很容易使人口干舌燥、外感咳嗽。生梨性寒味甘，有润肺止咳、滋阴清热的功效，民间素有惊蛰吃梨的习俗。梨的吃法很多，比如生食、蒸、榨汁、烤或者煮水，特别是冰糖蒸梨对咳嗽具有很好的疗效，而且制作简单方便，平时不妨把其当作甜点食用。另外，咳嗽患者还可食用莲子、枇杷、罗汉果等食物缓解病痛，饮食宜清淡，油腻的食物最好不吃，刺激性的食物，如辣椒、葱蒜、胡椒也应少吃。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "惊蛰，是24节气中的第三个节气。农历每年二月初一前后（公历3月5-6日之间），太阳到达黄经345度时为“惊蛰”。惊蛰的意思是天气回暖，春雷始鸣，惊醒蛰伏于地下冬眠的昆虫。蛰是藏的意思。《月令七十二候集解》中说：“二月节，万物出乎震，震为雷，故曰惊蛰。是蛰虫惊而出走矣。”晋代诗人陶渊明有诗曰：“促春遘（gòu）时雨，始雷发东隅，众蛰各潜骇，草木纵横舒。”实际上，昆虫是听不到雷声的，大地回春，天气变暖才是使它们结束冬眠，“惊而出走”的原因。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "祭白虎化解是非",
                    "中国的民间传说白虎是口舌、是非之神，每年都会在这天出来觅食，开口噬人，犯之则在这年之内，常遭邪恶小人对你兴波作浪，阻挠你的前程发展，引致百般不顺。大家为了自保，便在惊蛰那天祭白虎。所谓祭白虎，是指拜祭用纸绘制的白老虎，纸老虎一般为黄色黑斑纹，口角画有一对獠牙。拜祭时，需以肥猪血喂之，使其吃饱后不再出口伤人，继而以生猪肉抹在纸老虎的嘴上，使之充满油水，不能张口说人是非。",
                    "",
                    "惊蛰吃梨",
                    "在民间素有“惊蛰吃梨”的习俗。惊蛰吃梨源于何时，无迹可寻，但祁县民间却有这样一则代代相传的故事。传说闻名海内的晋商渠家，先祖渠济是上党长子县人，明代洪武初年，带着信、义两个儿子，用上党的潞麻与梨倒换祁县的粗布、红枣，往返两地间从中赢利，天长日久有了积蓄，在祁县城定居下来。雍正年间，十四世渠百川走西口，正是惊蛰之日，其父拿出梨让他吃后说，先祖贩梨创业，历经艰辛，定居祁县，今日惊蛰你要走西口，吃梨是让你不忘先祖，努力创业光宗耀祖。渠百川走西口经商致富，将开设的字号取名“长源厚”。后来走西口者也仿效吃梨，多有“离家创业”之意，再后来惊蛰日也吃梨，亦有“努力荣祖”之念。",
                    "",
                    "蒙鼓皮",
                    "惊蛰是雷声引起的。古人想象雷神是位鸟嘴人身，长了翅膀的大神，一手持锤，一手连击环绕周身的许多天鼓，发出隆隆的雷声。惊蛰这天，天庭有雷神击天鼓，人间也利用这个时机来蒙鼓皮。《周礼》卷四十《挥人》篇上说：\"凡冒鼓必以启蛰之日。“注：“惊蛰，孟春之中也，蛰虫始闻雷声而动；鼓，所取象也；冒，蒙鼓以革。”可见不但百虫的生态与一年四季的运行相契合，万物之灵的人类也要顺应天时，凡事才能达到事半功倍之效。",
                    "",
                    "“打小人”驱赶霉运",
                    "惊蛰象征二月份的开始，会平地一声雷，唤醒所有冬眠中的蛇虫鼠蚁，家中的爬虫走蚁又会应声而起，四处觅食。所以古时惊蛰当日，人们会手持清香、艾草，熏家中四角，以香味驱赶蛇、虫、蚊、鼠和霉味，久而久之，渐渐演变成不顺心者拍打对头人和驱赶霉运的习惯，亦即“打小人”的前身。",
                    "所以每年惊蛰那天便会出现一个有趣的场景：妇人一边用木拖鞋拍打纸公仔，一边口中念念有词地念：“打你个小人头，打到你有气冇定抖，打到你食亲野都呕”的打小人咒语。",
                    "",
                    "抒发内心不忿",
                    "很多人都将“打小人”神化，其实此纯粹是民间习俗而已，打小人的用意在于透过拍打代表对头人的纸公仔，驱赶身边的小人瘟神，宣泄内心的不满，大部分人去打小人，一般目的是求新一年事事如意，冇小人搞搞震，而被“打”的对象，主要是二奶以及工作上的“金手指”。打小人的目的，就是要希望他们知难而退及抒发个人内心的不忿。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "春分",
        "short_desc": "春分到，昼夜平分，万物复苏",
        "area": 0,
        "type": 1,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "别称",
                        "value": "仲春之月"
                    },
                    {
                        "title": "节气时间",
                        "value": "每年3月20日或21日"
                    },
                    {
                        "title": "代表寓意",
                        "value": "昼夜几乎相等"
                    },
                    {
                        "title": "气候特点",
                        "value": "昼夜平分，气候温暖潮湿"
                    },
                    {
                        "title": "节气养生",
                        "value": "应注意保持人体的阴阳平衡状态"
                    },
                    {
                        "title": "节气三候",
                        "value": "元鸟至；雷乃发声；始电"
                    }
                ],
                "detail": [
                    "春分，古时又称为“日中”、“日夜分”、“仲春之月”。《明史·历一》说：“分者，黄赤相交之点，太阳行至此，乃昼夜平分。”所以，春分的意义，一是指一天时间白天黑夜平分，各为12小时；二是古时以立春至立夏为春季，春分正当春季三个月之中，平分了春季。"
                ]
            },
            {
                "title": "养生",
                "detail": [
                    "春分时节，在大自然中阴阳各占一半，因此日常饮食方面要遵循阴阳平衡原则，保持机体功能的平衡协调稳定。此外，春分时节气候十分适宜各种病菌的繁殖和传播，要注意经常打开门窗给房屋通风，在屋内种一些花草，提高空气质量和负离子浓度，对人体的健康和心理调适非常有利。",
                    "虽然春分天气日渐暖和，但日夜温差较大，而且仍不时会有寒流侵袭。此时，要注意添减衣被，“勿极寒，勿太热” ，穿衣可以下厚上薄，注意下肢及脚部保暖，最好能够微微汗出，以散去冬天潜伏的寒邪。尤其是老人及小孩，抵抗力差，容易患感冒或风疹等传染病，更应注意适时添减衣被， 可以多晒太阳，以利祛散寒邪。",
                    "此外，要保证室内空气清新洁净，减少呼吸系统疾病。经常消毒，杀死病毒。保持室内外卫生，角落和阴暗死角的污垢都要清扫干净，并经常消毒，杀死病毒，减少传染病的扩散。从冬季的寒冷中舒缓过来不久，外出运动锻炼需要循序渐进，不可太过激烈，以免身体不适应。"
                ]
            },
            {
                "title": "饮食",
                "detail": [
                    "春分时节，饮食方面应多食时令菜，并调养肠胃。同时，也要少喝酒，以避免对肝脏带来过大的负担。春令时菜包括有养阳之用的韭菜助长生机的豆芽、莴苣、葱、豆苗、蒜苗；滋养肝肺的水果，如草莓、青梅、杏、李、桑葚、樱桃等。",
                    "春应于肝、夏应于心、长夏应于脾、秋应于肺、冬应于肾，所以春天重在养肝。春天养肝的目的在于协调肝的阴阳平衡，在中医里，甘味食物能滋补脾胃，大枣和山药就是不错的选择。酒伤肝肠，因此春季更不应饮酒。菊花茶、金银花甚至白开水才是适合春季的佳饮。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "春分，是春季九十天的中分点。二十四节气之一，每年公历大约为3月20日左右，太阳位于黄经0°（春分点）时。春分这一天太阳直射地球赤道，南北半球季节相反，北半球是春分，在南半球来说就是秋分。春分是伊朗、土耳其、阿富汗、乌兹别克斯坦等国的新年，有着3000年的历史。《月令七十二候集解》：“ 二月中， 分者半也， 此当九十日之半， 故谓之分。 秋同义。”《 春秋繁露· 阴阳出入上下篇》 说：“ 春分者， 阴阳相半也， 故昼夜均而寒暑平。” ",
                    "春分也是节日和祭祀庆典，古代帝王有春天祭日，秋天祭月的礼制。周礼天子日坛祭日。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "竖蛋",
                    "在每年的春分那一天，世界各地都会有数以千万计的人在做“竖蛋”试验。",
                    "",
                    "吃春菜",
                    "“春菜”是一种野苋菜，乡人称之为“春碧蒿”。逢春分那天，全村人都去采摘春菜。在田野中搜寻时，多见是嫩绿的，细细棵，约有巴掌那样长短。采回的春菜一般家里与鱼片“滚汤”，名曰“春汤”。有顺口溜道：“春汤灌脏，洗涤肝肠。阖家老少，平安健康。”一年自春，人们祈求的还是家宅安宁，身壮力健。",
                    "",
                    "春祭",
                    "二月春分，开始扫墓祭祖，也叫春祭。扫墓前先要在祠堂举行隆重的祭祖仪式，杀猪、宰羊，请鼓手吹奏，由礼生念祭文，带引行三献礼。春分扫墓开始时，首先扫祭开基祖和远祖坟墓，全族和全村都要出动，规模很大，队伍往往达几百甚至上千人。开基祖和远祖墓扫完之后，然后分房扫祭各房祖先坟墓，最后各家扫祭家庭私墓。",
                    "",
                    "放风筝",
                    "春分期间还是孩子们放风筝的好时候。尤其是春分当天。甚至大人们也参与。风筝类别有王字风筝，鲢鱼风筝，眯蛾风筝，雷公虫风筝，月儿光风筝，其大者有两米高，小的也有二、三尺。市场上有卖风筝的，多比较小，适宜于小孩子们玩耍，而大多数还是自己糊的，较大，放时还要相互竞争看哪个的放得高。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "清明",
        "short_desc": "清明时节雨纷纷，路上行人欲断魂",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节气时间",
                        "value": "一般是在公历4月5号前后"
                    },
                    {
                        "title": "代表寓意",
                        "value": "天气晴朗，草木繁茂"
                    },
                    {
                        "title": "气候特点",
                        "value": "气温回暖，阳气升腾"
                    },
                    {
                        "title": "节气养生",
                        "value": "护肝养肺"
                    },
                    {
                        "title": "节气三候",
                        "value": "桐始华；田鼠化为鹌；虹始见"
                    }
                ],
                "detail": [
                    "清明，二十四节气之一，春季的第五个节气。太阳到达黄经15°，便为清明。清明时，气清景明，万物皆显，因此得名。清明，既是节气，又是节日。节气的清明，是春耕春种的大好时机；节日的清明，是民间寄放情感和慰劳自己的传统日子。清明节气在时间和天气物候特点上为清明节俗的形成提供了重要条件，该节气被看作清明节的源流之一。",
                    "中国古代将清明分为三候初候桐始华；二候田鼠化为驽；三候虹始见意即在这个时节先是白桐花开放，接着喜阴的田鼠不见了，全回到了地下的洞中，然后是雨后的天空可以见到彩虹了。到了清明，中国大部分地区的天气就不冷了，进入到春季。但此间的天气，南方与北方好似两重天，北方干燥少雨，南方湿润多雨。"
                ]
            },
            {
                "title": "养生",
                "detail": [
                    "关于清明养生，在《黄帝内经·素问》“四气调神大论”里说得非常清楚：春天是万物复苏的季节，为了适应春天阳气生发的规律，人们应当晚睡一点、早起一些，舒缓形体，以使神志随着春气而舒畅怡然，这是养生的自然法则，违背了就会伤肝。这个时节，应该掌握春令之气升发舒畅的特点，注意保卫体内的阳气，使之不断充沛，逐渐旺盛起来，凡有耗伤阳气及阻碍阳气的情况皆应避免。",
                    "",
                    "清明节有踏青、放风筝、荡秋千等放松身心的习俗。不过，踏青登山一定要量力而行。踏青等活动与传统意义的锻炼有区别，除了长期坚持锻炼的人，这个季节并不主张人们大幅度地“动起来”。",
                    "",
                    "清明时节的着衣，开始除去冬装，轻装外出。清明时节，人们往往容易衣着单薄，遇上阴雨绵绵的天气，就应及时添衣，防止受寒、淋雨。外出要随带雨具，防止淋雨而感冒。晴天外出和运动易于出汗，出汗后要及时换衣，保持温暖干燥。"
                ]
            },
            {
                "title": "饮食",
                "detail": [
                    "吃——“两多一少”很重要：",
                    "1、多吃“柔肝”的食物",
                    "中医学认为“春与肝相应”，清明时节应多吃“柔肝”的食物，饮食宜温，以清补为主。如荠菜、山药、菠菜、韭菜、银耳、大枣等。",
                    "2、多吃“祛湿”的食物",
                    "由于清明雨多湿气较重，在饮食上要注意选择一些温胃祛湿的食物，如白菜、萝卜、芋头等。",
                    "3、慎吃“生发”的食物",
                    "中医认为，清明正值肝阳上升时节，故不宜进食竹笋、咸菜、鸡、海鱼等“发物”。有慢性病的人要忌食。",
                    "喝——两种养生茶可以常喝。以下两款清明养生茶，大家不妨尝试喝一喝：",
                    "1、黄芩茶",
                    "做法：黄芩6克，绿茶3克，将黄芩用200毫升水煮沸，然后用它来冲泡绿茶，5～10分钟后即可饮用。",
                    "功效：具有清热燥湿、泻肺火、解毒、利尿、降压、镇静等功效。",
                    "2、桂圆红枣茶",
                    "做法：桂圆红枣各5颗，用沸水冲泡，最好将红枣划开。",
                    "功效：具有补心脾、益气血、安神、润肺等功效，主治倦怠无力、失眠健忘。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "清明节的起源，据传始于古代帝王将相“墓祭”之礼，后来民间亦相仿效，于此日祭祖扫墓，历代沿袭而成为中华民族一种固定的风俗。",
                    "",
                    "清明最早只是一种节气的名称，其变成纪念祖先的节日与寒食节有关。寒食节是中国古代较早的节日，传说是在春秋时代为纪念晋国的忠义之臣介子推而设立的。",
                    "",
                    "秦汉时代，祭扫坟墓的风气更盛。据《汉书》记载，大臣严延年即使离京千里，也要定期还乡祭扫墓地。在唐代，不论士人还是平民，都将寒食节扫墓视为返本追宗的仪节，由于清明距寒食节很近，人们还常常将扫墓延至清明。",
                    "",
                    "宋元时期，清明节逐渐由附属于寒食节的地位，上升到取代寒食节的地位。这不仅表上坟扫墓等仪式多在清明举行，就连寒食节原有的风俗活动如冷食、蹴鞠、荡秋千等，也都被清明节收归所有了。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "清明节的习俗除了讲究禁火、扫墓，还有踏青、荡秋千、蹴鞠、打马球、插柳等一系列风俗体育活动。相传这是因为寒食节要寒食禁火，为了防止寒食冷餐伤身，所以大家来参加一些体育活动，来锻炼身体。清明节，民间忌使针，忌洗衣，大部分地区妇女忌行路。傍晚以前，要在大门前洒一条灰线，据说可以阻止鬼魂进宅。 因此，这个节日中既有祭扫新坟生离死别的悲酸泪，又有踏青游玩的欢笑声，是一个富有特色的节日。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "谷雨",
        "short_desc": "播谷降雨，雨生百谷",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节气时间",
                        "value": "每年4月19日或20日"
                    },
                    {
                        "title": "代表寓意",
                        "value": "雨量充足而及时，谷类作物能茁壮成长"
                    },
                    {
                        "title": "气候特点",
                        "value": "气候晴暖，早晚时冷时热"
                    },
                    {
                        "title": "节气养生",
                        "value": "补血益气，提升体质"
                    },
                    {
                        "title": "节气三候",
                        "value": "萍始生；呜鸠拂其羽；戴任降于桑"
                    }
                ],
                "detail": [
                    "谷雨是春季最后一个节气，谷雨节气的到来意味着寒潮天气基本结束，气温回升加快，大大有利于谷类农作物的生长。"
                ]
            },
            {
                "title": "养生",
                "detail": [
                    "谷雨时节，是农作物茂盛生长的时节，但也是人们养生保健的关键时节。在谷雨时节，我们身体的三处是最需要养护的，养好了，你将一年身体健康，疾病难侵，更是可以帮助身体驱除老病根，一起来看看吧！",
                    "一、养关节",
                    "身体关节处的灵活与康健，尤其是腿部关节，是我们正常运动的基础。而关节最怕的就是湿与寒，谷雨时节前后，空气中降雨明显增加，空气中的湿度逐渐增加，如不注意养护，容易导致湿气入侵。同时，谷雨时节，也是很多风湿关节疾病患者病情严重发作的时节，在这一时节，最需要的就是关节的养护。",
                    "二、养脾胃",
                    "初春时节，肝气旺盛，脾胃较衰弱，而到来谷雨前后，脾胃会逐渐旺盛起来，这时候，是调养脾胃的关键时期。脾胃调养好，不仅可以使我们身体营养吸收能力提升，增强人体免疫力，更可帮助我们排出身体浊气和废物，防疾病，除疾病，对健康大有裨益。",
                    "三、养气血",
                    "春季是一年的出始，是身体阳气生发的时节，因而，春季是养阳气，调气血的最佳时节。谷雨节气，是春季即将结束的预兆，大家要抓住春季的尾巴，调养气血，强身健康，预防疾病。"
                ]
            },
            {
                "title": "饮食",
                "detail": [
                    "谷雨阳气升发，平素胃肠有积热的人一旦饮食不当容易上火，甚至诱发春季腹泻、胃炎、胃溃疡等疾病。饮食上可以吃些低脂肪、低蛋白、高维生素的蔬菜，如荠菜、菠菜、香椿、蒲公英等，有清热祛火的作用。谷雨时节清补的菜肴——土茯苓眉豆猪脊骨汤，土茯苓气味鲜美清润，眉豆可祛脚气水湿，猪脊骨增液补髓，三者一起熬汤，有健脾益气、清热祛湿之功。以这种清补的方式可以达到补益脾气的效果。",
                    "此外，雨前茶是谷雨时节采制的春茶，滋味鲜活。春季温度适中，雨量充沛，加上茶树经半年冬季的休养生息，使得春梢芽叶肥硕，色泽翠绿，叶质柔软，富含多种维生素和氨基酸，使春茶香气怡人。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "谷雨是“雨生百谷”的意思，每年4月20日或21日太阳到达黄经30°时为谷雨。",
                    "",
                    "中国古代将谷雨分为三候：“第一候萍始生；第二候呜鸠拂其羽；第三候为戴任降于桑。”是说谷雨后降雨量增多，浮萍开始生长，接着布谷鸟便开始提醒人们播种了，然后是桑树上开始见到戴胜鸟。",
                    "",
                    "大约在4000年前，轩辕皇帝由部落首领被拥戴为部落联盟领袖，他命仓颉为左史官。仓颉做了史官以后，用不同类型的贝壳和绳结的大小、横竖为标记记载事务。可是，随着仓颉主管事务日益繁多，老办法已远远不能适应需求，仓颉很犯愁。",
                    "",
                    "每年谷雨节，仓颉庙都要举行传统庙会，会期长达七至十天。年复一年，成千上万的人们从四面八方来到此地，举行隆重热烈的迎仓圣进庙和盛大庄严的祭奠仪式，缅怀和祭祀文字始祖仓颉。人们扭秧歌，跑竹马，耍社火，表演武术，敲锣打鼓，演大戏，载歌载舞，表达对仓圣的崇敬和怀念。戏班子、商号也来赴会凑兴，热闹非凡。仓颉庙会已经成为当地一个隆重节日。甚至当地人入学拜师、敬惜字、爱喝红豆稀饭、喜住窑洞、乞雨、乞子，祈福禳灾等习俗也都是与仓颉有关。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "古时有“走谷雨”的风俗，谷雨这天青年妇女走村串亲，有的到野外走一圈就回来。",
                    "",
                    "南方谷雨摘茶习俗，传说谷雨这天的茶喝了会清火、辟邪、明目等。所以谷雨这天不管是什么天气，人们都会去茶山摘一些新茶回来喝。",
                    "",
                    "北方谷雨食香椿习俗，谷雨前后是香椿上市的时节，这时的香椿醇香爽口营养价值高，有“雨前香椿嫩如丝”之说。香椿具有提高机体免疫力，健胃、理气、止泻、润肤、抗菌、消炎、杀虫之功效。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "立夏",
        "short_desc": "立夏到，万物生长，欣欣向荣",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节气时间",
                        "value": "四月初一前后(公历5月5-6日)"
                    },
                    {
                        "title": "代表寓意",
                        "value": "夏季的开始"
                    },
                    {
                        "title": "气候特点",
                        "value": "炎暑将临，雷雨增多"
                    },
                    {
                        "title": "节气养生",
                        "value": "调养精神，保养心脏"
                    },
                    {
                        "title": "节气三候",
                        "value": "蝼蝈鸣；蚯蚓出；王瓜生"
                    }
                ],
                "detail": [
                    "在天文学上，立夏表示即将告别春天，是夏天的开始。人们习惯上都把立夏当作是温度明显升高，炎暑将临，雷雨增多，农作物进入旺季生长的一个重要节气。"
                ]
            },
            {
                "title": "养生",
                "detail": [
                    "一年四季中，夏天属火，火气通于心，故夏季与心气相通， 夏天人们易感到烦躁不安，容易出现失眠、口腔溃疡等上火症状，因此立夏养生首先要“养心”。",
                    "心在五行属火，与夏季阳热之气相应，故为阳脏，其主要生理功能是主血脉并主神志，起着主宰生命活动的作用。其次心在体合脉，其华在面，其应虚里，在液为汗，心开窍于舌，心与小肠相表里，从而构成一个动态的、整体联系的心功能系统。",
                    "夏季与心气相通，有利于心脏的生理活动，因此，要顺应节气的这种变化，保养心脏，专家表示，此时节，老年人要注意避免气血淤积，预防心脏病发作。",
                    "立夏后，人们易感到烦躁不安，因此立夏养生要做到“戒燥戒怒”，切忌大喜大悲，要保持精神安静、心志安闲，心情舒畅，笑口常开。"
                ]
            },
            {
                "title": "饮食",
                "detail": [
                    "立夏节气吃啥养生又健康？民间常说夏天是“苦夏”，为什么称为苦夏，也是因为夏天多吃“苦”味的东西有利于五脏六腑的“五行”运作。一起来看看适合立夏甚至整个夏天的食物：",
                    "1、鸭蛋补夏",
                    "俗传，立夏吃蛋，叫做“补夏”，使人在夏天不会消瘦，不减轻体重，劲头足，干活有力。老中医说咸鸭蛋在夏天吃了人有劲，这和营养师的说法不谋而合，营养师称鸭蛋中钙质、铁质等无机盐含量丰富，含钙量、含铁量比鸡蛋、鲜鸭蛋都高，是夏日补充钙、铁的首选。",
                    "2、苦瓜降火",
                    "不要觉得苦瓜苦，苦瓜越苦，败火清热降火的效果就越好，但这并不代表所含的其他成分有什么不同，因此只要按照您喜欢的口感和偏好挑选，就可以啦!",
                    "3、莲子养心",
                    "养心季节是夏，此时心脏最脆弱，暑热逼人容易烦躁伤心，易伤心血。莲子芯的味道虽然比较苦，但可以清心火，是养心安神的佳品，亦可壮肠胃。",
                    "4、章鱼补血",
                    "章鱼有补血益气、治痈疽肿毒的作用。它含有丰富的蛋白质、矿物质等营养元素，并还富含抗疲劳、抗衰老、能延长人类寿命等重要保健因子——天然牛黄酸。一般人都可食用，尤适宜体质虚弱、气血不足、营养不良之人食用。",
                    "5、草莓解毒",
                    "夏季是盛产草莓的季节。食用草莓能促进人体细胞的形成，维持牙齿、骨、血管、肌肉的正常功能和促进伤口愈合，并且还有解毒作用，古代人有伤口会多吃些草莓。现代营养师也认为草莓能增强人体抵抗力，排除多余的胆固醇和有害重金属。",
                    "6、莴苣通气",
                    "莴苣含有大量植物纤维素，能促进肠壁蠕动、通利消化道，可治疗便秘，是贫血患者的最佳食料。推荐鱼腥草拌莴苣。具有清热解毒，利湿祛痰的功效。",
                    "7、豌豆清肠",
                    "豌豆中富含人体所需的各种营养物质，尤其是含有优质蛋白质，可以提高机体的抗病能力和康复能力。而豌豆中富含的粗纤维，能促进大肠蠕动，保持大便顺畅，起到清洁大肠的作用。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "立夏这个节气在战国末年（公元前239年）就已经确立了，预示着季节的转换，为一年四季之夏季开始的日子。",
                    "实际上，若按气候学的标准，日平均气温稳定升达22℃以上为夏季开始，“立夏”前后，我国只有福州到南岭一线以南地区是真正的“绿树浓阴夏日长，楼台倒影入池塘”的夏季，而东北和西北的部分地区这时则刚刚进入春季，全国大部分地区平均气温在18～20℃上下，正是“百般红紫斗芳菲”的仲春和暮春季节。进入了五月，很多地方槐花也正开。立夏时节，万物繁茂。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "迎夏仪式",
                    "",
                    "“立夏”的“夏”是“大”的意思，是指春天播种的植物已经直立长大了。古代，人们非常重视立夏的礼俗",
                    "在立夏的这一天，古代帝王要率文武百官到京城南郊去迎夏，举行迎夏仪式。君臣一律穿朱色礼服，配朱色玉佩，连马匹、车旗都要朱红色的，以表达对丰收的祈求和美好的愿望。宫廷里“立夏日启冰，赐文武大臣”。冰是上年冬天贮藏的，由皇帝赐给百官。",
                    "",
                    "斗蛋游戏",
                    "",
                    "那日中午，家家户户煮好囫囵蛋（鸡蛋带壳清煮，不能破损），用冷水浸上数分钟之后再套上早已编织好的丝网袋，挂于孩子颈上。孩子们便三五成群，进行斗蛋游戏。蛋分两端，尖者为头，圆者为尾。斗蛋时蛋头斗蛋头，蛋尾击蛋尾。一个一个斗过去，破者认输，最后分出高低。",
                    "",
                    "立夏“秤人”",
                    "",
                    "立夏吃罢中饭还有秤人的习俗。人们在村口或台门里挂起一杆大木秤，秤钩悬一根凳子，大家轮流坐到凳子上面秤人。司秤人一面打秤花，一面讲着吉利话。秤老人要说“秤花八十七，活到九十一”。秤姑娘说“一百零五斤，员外人家找上门。勿肯勿肯偏勿肯，状元公子有缘分。”秤小孩则说“秤花一打二十三，小官人长大会出山。七品县官勿犯难，三公九卿也好攀”。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "小满",
        "short_desc": "小满小满，麦粒渐满",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节气时间",
                        "value": "每年5月20日到22日"
                    },
                    {
                        "title": "代表寓意",
                        "value": "夏熟作物籽粒开始饱满"
                    },
                    {
                        "title": "气候特点",
                        "value": "南北温差缩小，降水增多"
                    },
                    {
                        "title": "节气养生",
                        "value": "避免过量进食生冷食物"
                    },
                    {
                        "title": "节气三候",
                        "value": "苦菜秀；靡草死；麦秋至"
                    }
                ],
                "detail": [
                    "小满——其含义是夏熟作物的籽粒开始灌浆饱满，但还未成熟，只是小满，还未大满。"
                ]
            },
            {
                "title": "养生",
                "detail": [
                    "小满是二十四节气中的第八个节气。作为夏季的第二个节气，而夏季是阳气最盛的时期，从这时候起就要调整我们的日常饮食起居，才能平安的度过炎热的夏季。",
                    "小满养生讲究“未病先防”，建议您这样做：",
                    "1、重点防风诊",
                    "小满节气气温明显增高，如若贪凉卧睡必将引发风湿症、湿性皮肤病等疾病。在小满节气的养生中，特别提出“未病先防”的养生观点，就是在未病之前，做好各种预防工作，以防止疾病的发生。在未病先防的养生中仍然强调：天人相应的整体观和正气内存，邪不可干的病理观。中医学认为人体是一个有机的整体，人与外界环境也是息息相关的，并提出人类必须掌握自然规律，顺应自然界的变化，保持体内外环境的协调，才能达到防病保健的目的。",
                    "2、适时添衣，保证午睡",
                    "小满后气温明显升高，雨量增多，但早晚仍会较凉，气温日差仍较大，尤其是降雨后气温下降更明显，因此，要注意适时添加衣服，尤其是晚上睡觉时，要注意保暖，避免着凉受风而患感冒。同时也应当顺应夏季阳消阴长的则律，早起晚睡，但要保证睡眠时间，以保持精力充沛。",
                    "3、心境平和，防止情绪中暑",
                    "小满时气温升高，人们也易感到烦躁不安，此时要调适心情，注意保持心情舒畅，胸怀宽广，以防情绪剧烈波动后引发高血压、脑血管意外等心脑血管病。气温不断升高，人们往往喜爱用冷饮消暑降温，但冷饮过量会导致腹痛、腹泻等病症。",
                    "此时可多参与一些户外活动如下棋、书法、钓鱼等怡养性情，同时也可在清晨参加体育锻炼，以散步、慢跑、打太极拳等为宜，不宜做过于剧烈的运动，避免大汗淋漓，伤阴也伤阳。",
                    "4、饮食宜清淡",
                    "另外，小满后不但天气炎热，汗出较多，雨水也较多，饮食调养宜以清爽清淡的素食为主，常吃具有清利湿热、养阴作用的食物，如赤小豆、薏苡仁、绿豆、冬瓜、黄瓜、黄花菜、水芹、荸荠、黑木耳、胡萝卜、西红柿、西瓜、山药、鲫鱼、草鱼、鸭肉等，忌吃膏粱厚味、甘肥滋腻、生湿助湿的食物，当然也可配合药膳进行调理，还可以常饮些生脉饮以益气生津。忌食高粱厚味，甘肥滋腻，生湿助湿的食物，如动物脂肪、海腥鱼类、酸涩辛辣、性属温热助火之品及油煎熏烤之物，如生葱、生蒜、生姜、芥末、胡椒、辣椒、茴香、桂皮、韭菜、茄子、蘑菇、海鱼、虾、蟹各种海鲜发物、牛、羊、狗、鹅肉类等。"
                ]
            },
            {
                "title": "饮食",
                "detail": [
                    "小满节气的到来，意味着夏季的味道更重了。同时天气会越来越热，雨水量也会增多，也容易下大雨，在这样多变的天气了，大家必须要做好养生措施，否则身体健康会受到严重的影响，那么小满节气如何饮食养生呢？",
                    "1、学会吃水果",
                    "初夏是大量水果上市的时候，有很多的应季水果供大家挑选食用，但是从中医的角度来说，不同的水果属性是不同的，分寒性和热性，如果不注意乱吃的话，导致寒性或者说热性的水果吃太多的话，会给身体带来负面影响。常见就有西瓜，它是寒性的，吃太多了很容易腹泻，同时还会导致女人们痛经。而龙眼则是热性的，吃太多了容易上火长痘。",
                    "所以说，人们在选择水果的时候，要看看它的属性，如果你的身体寒气比较重的话，则要避免吃太多的寒性水果，应该选择热性水果吃。如果你的身体内热重，比较容易上火的话，则要避免吃太多的热性水果，应该多吃一些寒性的水果。",
                    "2、酸味食物要多吃",
                    "小满节气前后一定要调理自己的饮食，因为现在天气越来越热，出汗量增大。建议在饮食上要注意多吃酸味的食物，帮助补益肝肾，调养脾胃。比如说山楂、乌梅、西红柿等酸味的食物就可以多吃，帮助你增强消化功能，同时还能起到滋养肝脏的作用。",
                    "3、少吃生冷食物",
                    "天气变热了，越来越多的人喜爱吃生冷的食物，比如说冰棒、冰淇淋等，这些冰冻的食物吃太多了，会导致腹痛等病症。",
                    "4、少吃辛辣肥腻的食物",
                    "小满节气过后，雨水会逐渐增多，空气比较潮湿闷热，很容易导致皮肤生热而引发湿疹等皮肤病。为了避免此种情况的发生，建议要少吃辛辣肥腻的食物，以免助火生热。",
                    "5、少吃烧烤类食物",
                    "天气热了很多人晚上的时候，喜欢外出吃烧烤(比如说羊肉串、烤肉、烤虾等)，搭配冰镇的啤酒进食，觉得这样吃相当刺激。但事实上这样吃，对身体健康是非常不利的，很容易刺激肠胃，造成脾胃不适的情况。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "《月令七十二候集解》：“四月中，小满者，物致于此小得盈满。”",
                    "这时全国北方地区麦类等夏熟作物籽粒已开始饱满，但还没有成熟，约相当乳熟后期，所以叫小满。",
                    "南方地区的农谚赋予小满以新的寓意：“小满不满，干断田坎”；“小满不满，芒种不管”。把“满”用来形容雨水的盈缺，指出小满时田里如果蓄不满水，就可能造成田坎干裂，甚至芒种时也无法栽插水稻。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "祭车神",
                    "",
                    "祭车神是一些农村地区古老的小满习俗。在相关的传说里二车神，是一条白龙在小满时节，人们在水车蓦上放上鱼肉、香烛等物品祭拜，最有趣的地方是，在祭品中会有一杯白水，祭拜时将白水泼入田中，有祝福水砚涌旺的意思。",
                    "",
                    "祭蚕",
                    "",
                    "相传小满为蚕神诞辰，因此江浙一带在小满节气期间有一个祈蚕节。我国农耕文化以“男耕女织”为典型。女织的原料北方以棉花为主，南方以蚕理为主。蚕丝需靠养蚕结茧抽丝而得，所以我国南方农村养蚕极为兴盛，尤其是江浙一带。",
                    "",
                    "蚕是娇养的“宠物”，很难养活。气沮、湿度，桑叶的冷、熟、干、湿等均影响蚕的生存。由于蚕难养，古代把蚕视作“天物”。为了祈求“天物。的宽怒和养蚕有个好的收成，因此人们在四月放蚕时节举行祈蚕节。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "芒种",
        "short_desc": "愿你收获美好，播种希望",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节气时间",
                        "value": "每年6月5日或6日"
                    },
                    {
                        "title": "代表寓意",
                        "value": "麦类等有芒作物成熟，农民朋友散播播种"
                    },
                    {
                        "title": "气候特点",
                        "value": "雨量充沛，气温显著升高"
                    },
                    {
                        "title": "节气养生",
                        "value": "饮食清淡、调养精神"
                    },
                    {
                        "title": "节气三候",
                        "value": "螳螂生；鹏始鸣；反舌无声"
                    }
                ],
                "detail": [
                    "芒种是农历二十四节气中的第9个节气，夏季的第三个节气，表示仲夏时节的正式开始。"
                ]
            },
            {
                "title": "养生",
                "detail": [
                    "芒种节气，要注意增强体质，避免季节性疾病和传染病的发生，如中暑、腮腺炎、水痘等。芒种的养生重点要在精神调养上，应该使自己的精神保持轻松、愉快的状态，不要恼怒忧郁，气机得以宣畅、通泄。",
                    "从芒种节气开始，天气闷热，雨水较多，湿度增大。北方进入雷雨、阵雨天，南方则已进入梅雨天，人体的湿热也随之加重，因此要注意防湿，避免引发各种疾病。",
                    "芒种节气养生需要防范的四种疾病：",
                    "1、口舌生疮。中医认为心与小肠相表里，芒种开始，湿热更重，如湿热内积，心火重，小肠积热，就会出现小便黄短，舌红苔黄，大便秘结，口舌生疮，就应该少吃辛热之品，如白酒、羊肉等。多吃黄瓜、青菜、绿豆。",
                    "2、心脏疾病。芒种节气，气温更高，湿度更大，心脏负荷逐渐加重，有心脏病、冠心病的人要注意保养，少熬夜，避免工作过分紧张，生活要有节奏，可以吃一些保养心脏的药食。",
                    "3、妇科病。妇女应少吃辛热食物，多吃一些清热利湿之品，如绿豆，还应常吃健脾利湿之品，如薏米、山药、白扁豆、粳米粥。",
                    "4、防真菌。在湿热天气中更要经常换洗内衣。"
                ]
            },
            {
                "title": "饮食",
                "detail": [
                    "夏季体温调节、水盐代谢发生了显著的变化：一方面人体组织蛋白分解加速，营养消耗增加；另一方面天热大量出汗，导致了许多营养从汗液流失；第三方面夏季消化功能相对减弱。这些均有可能导致机体营养代谢的失衡，从而引起相应的疾病，所以此时饮食调养就尤为重要。",
                    "《摄生消息论》主张：“饮食温暖，不令大饱，常常进之，宜桂汤豆蔻熟水，其于肥腻当戒”，夏季心火旺盛，而肾水相对衰弱，天气炎热也要养护脾胃，饮食少苦寒，节冷饮，少油腻等。西瓜和绿豆性味甘寒，入心胃经，二者皆为盛夏消暑解热、养心除烦、排毒养颜之佳品。夏在五行属火，五味为苦，苦味入心，夏季适当食味苦之物如苦瓜、百合、莲子、苦丁茶等有解热除烦、抗菌消炎、促进消化、提神醒脑等功效。然需注意苦味过多又会损伤肺气，此外适当食入酸味如醋、乌梅均可抑菌杀菌、开胃消食、敛阴止汗。",
                    "夏季是阳气蒸蒸日上、发散于外的时候，人体为了排出体内的热量，皮肤腠理多处于开放的状态，热量容易散失，如果加之多食冷饮，体内就相对寒凉，故此时常是“阳气在表，胃中虚冷”的状态。俗话有“冬吃萝卜夏吃姜”，生姜性味辛温，有散寒解表、和胃止呕，温肺止咳等多种功效，芒种时节，自制一些姜糖水、鲜藕姜蜜饮等，有助于温中养胃，培固正气，也是“春夏养阳，秋冬养阴”旨意所在。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "芒种的“芒”字，是指麦类等有芒植物的收获，芒种的“种”字，是指谷黍类作物播种的节令。“芒种”二字谐音，表明一切作物都在“忙种”了。所以，“芒种”也称为“忙种”、“忙着种”，是农民朋友的散播播种。“芒种”到来预示着农民开始了忙碌的田间生活。",
                    "左河水的《芒种》诗称其气象和农忙的情况为“艳阳辣辣卸衣装，梅雨潇潇涨柳塘。南岭四邻禾壮日，大江两岸麦收忙。”"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "送花神",
                    "",
                    "芒种已近五月间，百花开始凋残、零落，民间多在芒种日举行祭祀花神仪式，饯送花神归位，同时表达对花神的感激之情，盼望来年再次相会。",
                    "",
                    "安苗",
                    "每到芒种时节，种完水稻，为祈求秋天有个好收成，各地都要举行安苗祭祀活动。家家户户用新麦面蒸发包，把面捏成五谷六畜、瓜果蔬菜等形状，然后用蔬菜汁染上颜色，作为祭祀供品，祈求五谷丰登、村民平安",
                    "",
                    "打泥巴仗",
                    "",
                    "贵州东南部一带的侗族青年男女，每年芒种前后都要举办打泥巴仗节。当天，新婚夫妇由要好的男女青年陪同，集体插秧，边插秧边打闹，互扔泥巴。活动结束，检查战果，身上泥巴最多的，就是最受欢迎的人。",
                    "",
                    "煮梅",
                    "",
                    "在南方，每年五、六月是梅子成熟的季节，三国时有“青梅煮酒论英雄”的典故。青梅含有多种天然优质有机酸和丰富的矿物质，具有净血、整肠、降血脂、消除疲劳、美容、调节酸碱平衡，增强人体免疫力等独特营养保健功能。但是，新鲜梅子大多味道酸涩，难以直接入口，需加工后方可食用，这种加工过程便是煮梅。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "夏至",
        "short_desc": "夏已至，风里充满夏日的味道",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节气时间",
                        "value": "每年的6月21日或22日"
                    },
                    {
                        "title": "代表寓意",
                        "value": "炎热的夏天来临"
                    },
                    {
                        "title": "节气养生",
                        "value": "多吃苦味食物，调养精神"
                    },
                    {
                        "title": "气候特点",
                        "value": "江淮梅雨，高温桑拿"
                    },
                    {
                        "title": "节气三候",
                        "value": "鹿角解；蝉始鸣；半夏生"
                    }
                ],
                "detail": [
                    "夏至是太阳的转折点，这天过后它将走“回头路”，阳光直射点开始从北回归线向南移动，北半球白昼将会逐日减短。夏至日过后，北回归线及其以北的地区，正午太阳高度角也会逐日降低。同时，夏至到来后，夜空星象也逐渐变成夏季星空。"
                ]
            },
            {
                "title": "养生",
                "detail": [
                    "夏至养生二注意",
                    "1、夏至养生先养心",
                    "在中医理论中，人和自然界是一个统一的整体，自然界的四季消长变化和人体的五脏功能活动相互关联对应。心对应“夏”，也就是说夏季心阳最旺，同时提醒人们，在春夏之交要顺应天气的变化，重点关注心脏保养。",
                    "现代医学研究发现，人的心理、情绪与躯体可通过神经—内分泌—免疫系统互相联系、互相影响。此时不仅是情绪波动起伏，机体的免疫功能也较为低下，起居、饮食稍有不妥，就会发生各种疾病。特别是老年人，心肌缺血、心律失常、血压升高的情况并不少见。所以，在“夏至”之时要做好自我调节、笑口常开。",
                    "2、夏至养生须防暑",
                    "夏至期间，全国气温高、光照足、雨水也多，农作物生长旺盛。夏至过后，一年中最热的时期就要到了。人的胃肠功能因受暑热刺激，其功能就会相对减弱，容易发生头重倦怠、胸脘郁闷、食欲不振等不适，甚至引起中暑，伤害健康。天气热，尽量避免在强烈阳光下进行户外工作或活动，特别是午后高温时段和老、弱、病、幼人群；在进行户外工作或活动时，要避免长时间在阳光下曝晒，同时采取防晒措施：穿浅色或素色的服装，带遮阳帽、草帽或打遮阳伞；多喝水，特别是盐开水，随身携带防暑药物，如：人丹、清凉油、风油精等。",
                    "夏至养生三忌",
                    "1、忌夜食生冷、空腹饮茶",
                    "夏季夜短，年事稍长之人，或体质稍弱者，腹中常冷，不易消化饮食，生菜、瓜类等，本为夏季忌食之品，夜间尤其要注意食用此类食物。夜间亦要注意不要吃肉、面、生冷、粘腻之物，否则可见腹胀、吐泻交作等病症。 过多饮茶，尤其是空腹时饮茶，极易导致茶水消耗人体的阳气，如果再是喜欢食咸之人，咸味引茶入肾，消烁下焦肾阳，使人易于罹患手足疼痛之痹症，以及下元虚冷的腹泻、阳痿、痛经等病症。因此，夏季饮茶，应是在餐后饮二到三杯为宜，有饥饿感则立刻停饮。",
                    "2、忌冷水洗浴",
                    "冷水洗浴是很多青中男性最喜欢的消暑方式，但夏季天气炎热，人的汗孔肌腠均处于开泄状态，因此，寒气极易侵入人体，导致阳气暗损。可以见到的病症如手足发冷，小腿转筋，双目视物不清，甚则变生原因不明的发热证。同时，即使是热水洗浴，亦要注意浴后避风，对小儿尤其如此。",
                    "3、忌夜卧贪凉",
                    "不可晚上睡觉整夜开空调冷气，这种习惯易导致伤风、面瘫、关节疼痛、腹痛腹泻，对身体的损伤是严重的。对小儿甚至不要在其睡着之后扇风取凉，否则，易于罹患手足抽搐、口噤不开、风痹等病症。"
                ]
            },
            {
                "title": "饮食",
                "detail": [
                    "夏季阳气盛于外。从夏至开始，阳极阴生，阴气居于内，所以，在夏至后，饮食要以清泄暑热、增进食欲为目的，因此要多吃苦味食物，宜清补。《吕氏春秋·尽数篇》指出：“凡食无强厚味，无以烈味重酒。”唐朝的孙思邈提倡人们“常宜轻清甜淡之物，大小麦曲，粳米为佳”，又说：“善养生者常须少食肉，多食饭”。在强调饮食清补的同时，勿过咸、过甜，宜多吃具有祛暑益气、生津止渴的食物。",
                    "绿叶菜和瓜果类等水分多的蔬菜水果都是不错的选择，如白菜、苦瓜、丝瓜、黄瓜等，都是很好的健胃食物。而面条，也是夏至后被推荐的美食之一。在中国北方流行一句谚语：“冬至饺子夏至面”，尤其是凉面，既能降火开胃，又不至于因寒凉而损害健康。",
                    "此外，还要推荐一种食材——生姜。有俗话说：“饭不香，吃生姜”、“冬吃萝卜，夏吃姜”、“早上三片姜，赛过喝参汤”，都是对生姜所具有的营养价值和医疗作用所做的精辟的概括。夏季暑热，多数人食欲不振，而生姜有利于食物的消化和吸收，对于防暑度夏有一定益处。另有“男子不可百日无姜”的说法。夏季万物生长，阳极阴生，心旺肾衰，因此，适量进补对男子性功能的保护和提升还是很有好处的。",
                    "夏至后，气温逐渐升高，人体出汗量也会随之增加，因此人体需水量大。对此，还可以在饮食上加以调节，如喝些绿豆汤、淡盐水等。需注意的是，绿豆汤不要多喝，更不能当水喝。属于寒凉体质和体质虚弱之人也不适宜饮用绿豆汤。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "夏至是二十四节气中最早被确定的一个节气。公元前七世纪，先人采用土圭测日影，就确定了夏至。每年的夏至从6月21日（或22日）开始，至7月7日（或8日）结束。据《恪遵宪度抄本》：“日北至，日长之至，日影短至，故曰夏至。至者，极也。”",
                    "",
                    "中国民间把夏至后的15天分成3“时”，一般头时3天，中时5天，末时7天。这期间我国大部分地区气温较高，日照充足，作物生长很快，生理和生态需水均较多。此时的降水对农业产量影响很大，有“夏至雨点值千金”之说。一般年份，这时长江中下游地区和黄淮地区降水一般可满足作物生长的要求。《荆楚岁时记》中记有：“六月必有三时雨，田家以为甘泽，邑里相贺。”可见在1000多年前人们已对此降雨特点有明确的认识。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "祭神祀祖",
                    "",
                    "夏至时值麦收，自古以来有在此时庆祝丰收、祭祀祖先之俗，以祈求消灾年丰。因此，夏至作为节日，纳入了古代祭神礼典。《周礼·春官》载：“以夏日至，致地方物魈。”周代夏至祭神，意为清除荒年、饥饿和死亡。夏至日正是麦收之后，农人既感谢天赐丰收，又祈求获得“秋报”。夏至前后，有的地方举办隆重的“过夏麦”，系古代“夏祭”活动的遗存。",
                    "",
                    "消夏避伏：",
                    "",
                    "夏至日，妇女们即互相赠送折扇、脂粉等什物。《酉阳杂俎·礼异》：“夏至日，进扇及粉脂囊，皆有辞。”“扇”，借以生风；“粉脂”，以之涂抹，散体热所生浊气，防生痱子。在朝廷，“夏至”之后，皇家则拿出“冬藏夏用”的冰“消夏避伏”，而且从周代始，历朝沿用，进而成为制度。",
                    "",
                    "“夏至”食品：",
                    "",
                    "“麦粽”与“夏至饼”",
                    "",
                    "江南食俗，一般有麦粽、角黍、李子、馄饨、汤面等。《吴江县志》：“夏至日，作麦粽，祭先毕，则以相饷。”不仅食“麦粽”，而且将“麦粽”作为礼物，互相馈赠。夏至日，农家还擀面为薄饼，烤熟，夹以青菜、豆荚、豆腐、及腊肉等，祭祖后食用，俗谓“夏至饼”，或分赠亲友。",
                    "",
                    "冬至馄饨夏至面",
                    "",
                    "自古以来，中国民间就有“冬至馄饨夏至面”的说法，江南一带夏至吃面是很多地区的重要习俗，民间有“吃过夏至面，一天短一线”的说法。南方的面条品种多，如阳春面、干汤面、肉丝面、三鲜面、过桥面及麻油凉拌面等，而北方则是打卤面和炸酱面。“因夏至新麦已经登场，所以夏至吃面也有尝新的意思。”"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "小暑",
        "short_desc": "突觉温风至，因是小暑来",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节气时间",
                        "value": "每年7月7日或8日"
                    },
                    {
                        "title": "代表寓意",
                        "value": "气候开始炎热"
                    },
                    {
                        "title": "气候特点",
                        "value": "气温升高，进入雷暴期"
                    },
                    {
                        "title": "节气养生",
                        "value": "劳逸结合，保护阳气"
                    },
                    {
                        "title": "节气三候",
                        "value": "温风至；蟋蟀居宇；鹰始鸷"
                    }
                ],
                "detail": [
                    "暑，表示炎热的意思，小暑为小热，还不十分热。意指天气开始炎热，但还没到最热，全国大部分地区基本符合。全国的农作物都进入了茁壮成长阶段，需加强田间管理。"
                ]
            },
            {
                "title": "养生",
                "detail": [
                    "小暑时节炎热，人体消耗大，所谓“无病三分虚”，会有精神疲惫、口苦苔腻、胸腹胀闷等征象，所以此时强调解热防暑，补充体力。",
                    "时当小暑之季，气候炎热，人易感心烦不安，疲倦乏力，在自我养护和锻炼时，我们应按五脏主时，夏季为心所主而顾护心阳，平心静气，确保心脏机能的旺盛，以符合“春夏养阳”之原则。《灵枢•百病始生》曰：“喜怒不节则伤脏”，这是因为人体的情志活动与内脏有密切关系，有其一定规律。不同的情志刺激可伤及不同的脏腑，产生不同的病理变化。",
                    "中医养生主张一个“平”字，即在任何情况之下不可有过激之处，如喜过则伤心，心伤则心跳神荡，精神涣散，思想不能集中，甚则精神市场等。心为五脏六腑之大主，一切生命活动都是五脏功能的集中表现，心神受损则必涉及其他脏腑。故夏季养生重点突出“心静”二字就是这个道理，应平心静气，确保心脏机能的旺盛，以符合“春夏养阳”之原则。"
                ]
            },
            {
                "title": "饮食",
                "detail": [
                    "小暑饮食有何讲究？",
                    "1、早餐勿冷食",
                    "在闷热的天气里，早餐也应该先食用热粥、热豆浆、热牛奶等热食，然后再吃一些面食和鸡蛋等食物。水果要等到九十点钟再吃才好。",
                    "2、冷饮凉菜勿过量",
                    "由于天热，有的人以冷饮、凉菜为每天的主要食物；有的人进餐时饮用大量冰镇啤酒；有的人正餐食量很小，餐前或餐后进食大量冰冻的西瓜、桃子、甜瓜、葡萄等水果。这些冰凉的食物大量进入体内，会使胃肠黏膜内血管急剧收缩，导致胃肠痉挛，影响正常消化功能；而且以这类食物为主要饮食，势必造成如氨基酸、必需脂肪酸等营养素的缺乏，导致体力和抵抗力下降。",
                    "此外，饮料和含水量丰富的水果会使人体尿频，尤其在晚餐或晚餐后大量进食这些食物，使人夜尿频多，影响睡眠质量。平时体质虚弱，经常自觉四肢发凉的人更不适宜过多吃冷饮和冷冻的绿豆汤。",
                    "3、辛辣性食物要适量",
                    "天热得让人没有食欲，欲的作用，但是辣椒、花椒吃得过度会刺激胃黏膜，引发胃炎、胃溃疡等疾病。",
                    "4、温热食物要少吃",
                    "食物有性味之分，人体有寒热之别。体质偏热的人在三伏天尤其要注意少吃温性、热性食物。这类食物主要有：羊肉、草鱼、鲢鱼、带鱼、黄鳝，以及核桃仁、杏、樱桃、龙眼、杨梅、椰子等。",
                    "5、温热茶水消暑佳饮",
                    "饮温热茶水可使汗腺舒张排汗，散发体内热量，从而降低体温，有助于消暑。茶叶中还含有丰富的微量元素钾，可弥补出汗时所排出的钾离子，有助于纠正倦怠无力和食欲减退。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "当太阳到达黄经105°时为小暑。",
                    "",
                    "《月令七十二候集解》：“暑，热也，就热之中分为大小，月初为小，月中为大，今则热气犹小也。”小暑虽不是一年中最炎热的季节，但紧接着就是一年中最热的季节大暑，民间有“小暑大暑，上蒸下煮”之说。由于出汗多，消耗大，再加之劳累，人们更不能忽略对身体的养护。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "过去民间有小暑“食新”习俗，即在小暑过后尝新米，农民将新割的稻谷碾成米后，做好饭供祀五谷大神和祖先，然后人人吃尝新酒等。据说“吃新”乃“吃辛”，是小暑节后第一个辛日。城市一般买少量新米与老米同煮，加上新上市的蔬菜等。所以，民间有小暑吃黍，大暑吃谷之说",
                    "",
                    "民间有小暑吃藕的习俗，藕中含有大量的碳水化合物及丰富的钙磷铁等和多种维生素，Vc钾和膳食纤维比较多，具有清热养血除烦等功效，适合夏天食用鲜藕以小火煨烂，切片后加适量蜂蜜，可随意食用，有安神入睡之功效，可治血虚失眠。",
                    "",
                    "伏天民谚有“头伏萝卜二伏菜，三伏还能种荞麦”，“头伏饺子，二伏面，三伏烙饼摊鸡蛋”。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "大暑",
        "short_desc": "大暑热不透，大热在秋后",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节气时间",
                        "value": "每年7月22日或23日"
                    },
                    {
                        "title": "代表寓意",
                        "value": "一年中最热的时候"
                    },
                    {
                        "title": "气候特点",
                        "value": "酷热多雨，容易中暑"
                    },
                    {
                        "title": "节气养生",
                        "value": "补脾健胃"
                    },
                    {
                        "title": "节气三候",
                        "value": "腐草为萤；土润溽暑；大雨时行"
                    }
                ],
                "detail": [
                    "大暑节气正值“三伏天”里的“中伏”前后，是一年中最热的时期，气温最高，农作物生长最快，同时，很多地区的旱、涝、风灾等各种气象灾害也最为频繁。"
                ]
            },
            {
                "title": "养生",
                "detail": [
                    "每一个节气的来临，我们的身体都会做出相应的反应，那么，在大暑这个时间段如何做好养生呢？",
                    "第一、合理的饮食",
                    "在中医的角度里面认为天人相应，我们的身体要适应季节的变化，所以在夏天的时候我们要选择性地挑选食物，让我们的身体能够适应季节的变化。在大暑这个时间段，想要做到养生，要保证我们每天有足够的蛋白质摄入，日常生活当中蛋白质有豆制品，鱼虾等富含优质蛋白，因为天气比较热的话，那么我们人体的新陈代谢也会比较快，热量的消耗量也比较大，所以足够的蛋白是每天必要的。另外，我们可以适当地给自己增加一些清热解暑的食物摄入，例如绿豆汤，百合粥以及苦瓜等等。",
                    "第二、不要经常烦躁",
                    "现在天气逐渐的热炎热，那么人们也会出现心烦意乱的情况，可能经常会出现吃不下饭，的情况，其实这样的情况就代表着我们已经动了肝火，也就是情绪中暑，其实情绪中暑，我们要引起一定的关注，因为对于我们身体健康来说，有着一定的损害，尤其是对于老年人，容易造成心律失常，血压升高等的问题。",
                    "第三、做好防护",
                    "现在夏天一年比一年炎热，而且还处在大暑的时节，太阳会非常的猛烈。所以在大暑时节想要出门游玩的话，一定要准备好一些，防止中暑的防护措施，提前准备好一些解暑的药物，例如风油精，当自己出门游玩的时候，出现恶心，大量出汗以及体热等症状，要立即赶往到阴凉的通风处进行休息，然后再喝一些绿豆汤，或者是淡盐开水。",
                    "第四、少喝冰饮",
                    "当天气出现炎热的情况，很多人都喜欢喝凉水，甚至是冰水，或者是冰的饮料来进行解渴，其实天气越热，我们身体水分的流失就会越多，及时补水是正确的，但是如果们大量的吃喝冰水，或者是冰饮料，不仅会刺激到我们肠胃系统，同时女生还会出现痛经，血液淤积的情况。",
                    "第五、运动量不要太大",
                    "夏天的时候很多人都喜欢出去运动，但是运动消耗性比较大，流失大量的体液就等于流失了，我们体内大量的水分，身体内水分不足的话，心情也会容易干燥不安。所以在夏天的时候，我们尽量从事一些温和的运动。例如打太极。选择比较缓和的运动，让我们的呼吸平缓，从容不迫，身体内的血管以及经脉，有机会得到足够的休息，我们晚上散步的时候可以听一些比较悠闲的音乐，在日常生活当中，不要过于的激动，更加不要经常发怒急躁。"
                ]
            },
            {
                "title": "饮食",
                "detail": [
                    "夏季的饮食调养是以暑天的气候特点为基础，由于夏令气候炎热，易伤津耗气，因此常选用药粥滋补养生。《黄帝内经》有载，“药以去之，食以随之”、“谷肉果菜，食养尽之”，这个时节，肠胃的消化功能较为薄弱，饮食以清淡为主，不可多吃肥腻、辛辣、煎炸食物。除了多喝水、常食粥、多吃新鲜果蔬外，还可适当多食用些清热、健脾、利湿、益气等食物。",
                    "三瓜二豆一肉，即冬瓜、苦瓜、西瓜、赤小豆、绿豆、鸭肉，它们是夏季里最适合拿来清热解燥、利水祛湿的食物。",
                    "1、冬瓜",
                    "盛夏时最常用的瓜蔬之一，它清甜、瓜肉厚，且水分多。中医认为它有消暑湿、养胃液、涤秽、消痈、行水、消肿之功。可用于暑热口渴、痰热咳喘、水肿，还能解酒。",
                    "2、苦瓜",
                    "苦瓜又名“凉瓜”，性寒，苦，无毒，入心、肝、脾、肺经，是大暑时节消暑的最好蔬菜，清热解暑、健脾开胃、降血糖。",
                    "3、西瓜",
                    "“瓜中之王”，清爽解渴，味道甘味多汁，是盛夏佳果。其味甘甜、性寒，是瓜类中消暑解渴的首选。西瓜性冷降火，故大暑时节，出现中暑、发热、心烦、口渴等，可用西瓜解之。",
                    "4、赤小豆",
                    "赤小豆和红豆很像，但相较于红豆较扁长，而且中医入药一般用赤小豆，红豆一般只供食用。赤小豆性甘，利水消肿，清热退黄，夏季食用对心脏特别有益。",
                    "5、绿豆",
                    "绿豆是夏季饮食中的上品，营养丰富，有清热消暑、美容养颜、止渴利尿等功效，有“济世之食谷”之美誉。在大热天，可煮绿豆汤或绿豆粥喝。",
                    "6、鸭肉",
                    "鸭肉性寒，是夏季进补的优良食品，营养很高，有除湿、解毒、滋阴、去燥、养胃、补肾、消水肿、止咳化痰等功效，非常适合大暑的时候吃。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "大暑是农历二十四节气中的第十二个节气，此时太阳到达黄经120度。",
                    "《通纬·孝经援神契》：“小暑后十五日斗指未为大暑，六月中。小大者，就极热之中，分为大小，初后为小，望后为大也。”",
                    "这时正值中伏前后，中国大部分地区为一年最热时期，也是喜热作物生长速度最快的时期。汉族劳动人民将大暑分为三候：“一候腐草为萤；二候土润溽暑；三候大雨时行。”世上萤火虫约有二千多种，分水生与陆生两种，陆生的萤火虫产卵于枯草上，大暑时，萤火虫卵化而出，所以古人认为萤火虫是腐草变成的；第二候是说天气开始变得闷热，土地也很潮湿；第三候是说时常有大的雷雨会出现，这大雨使暑湿减弱，天气开始向立秋过渡。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "大暑送“大暑船”活动在浙江台州沿海已有几百年的历史。“大暑船”完全按照旧时的三桅帆船缩小比例后建造，船内载各种祭品。活动开始后，50多名渔民轮流抬着“大暑船”在街道上行进，鼓号喧天，鞭炮齐鸣，街道两旁站满祈福人群。“大暑船”最终被运送至码头，进行一系列祈福仪式。随后，这艘“大暑船”被渔船拉出渔港，然后在大海上点燃，任其沉浮，以此祝福人们五谷丰登，生活安康。台州椒江人还有大暑节气吃姜汁调蛋的风俗，姜汁能去除体内湿气，姜汁调蛋“补人”，也有老年人喜欢吃鸡粥，谓能补阳。",
                    "",
                    "山东不少地区有在大暑到来这一天“喝暑羊”（即喝羊肉汤）的习俗。在枣庄市，不少市民大暑这天到当地的羊肉汤馆“喝暑羊”。",
                    "",
                    "大暑是乡村田野蟋蟀最多的季节，中国有些地区的人们茶余饭后有以斗蟋蟀为乐的风俗。",
                    "",
                    "大暑期间，台湾有吃凤梨的习俗，汉族民间百姓认为这个时节的凤梨最好吃。加上凤梨的闽南语发音和“旺来”相同，所以也被用来作为祈求平安吉祥、生意兴隆的象征。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "立秋",
        "short_desc": "秋风起，一叶落便知天下秋",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节气时间",
                        "value": "每年8月6-9日"
                    },
                    {
                        "title": "代表寓意",
                        "value": "秋季的开始"
                    },
                    {
                        "title": "气候特点",
                        "value": "降雨、风暴、湿度等趋于下降或减少"
                    },
                    {
                        "title": "节气养生",
                        "value": "以养收为原则"
                    },
                    {
                        "title": "节气三候",
                        "value": "凉风至；白露生；寒蝉鸣"
                    }
                ],
                "detail": [
                    "立秋日对农民朋友显得尤为重要，有农谚说：“雷打秋，冬半收”，“立秋晴一日，农夫不用力”。"
                ]
            },
            {
                "title": "养生",
                "detail": [
                    "第一，精神方面。夏季阳气旺盛，进入立秋以后，阳气机会慢慢减弱，大家在此时要远离悲伤忧愁的情绪，尽量做到平心静气、心情舒畅，同时还应该收敛神气。",
                    "第二，运动方面。秋季应以“收养”为原则。记得将保养身体内的阴气为关键任务。也就是说运动的时候，运动不宜过大，以免出汗过多，耗损过多的阳气致使体内阴阳失衡。建议选择平缓轻松的运动项目，如散步、太极等。",
                    "第三，起居方面。应早睡早起，以便收敛阳气舒展肺气。而且要记得尽量早起，这样子有助减少血栓的形成，预防心血管疾病。如果早睡睡不着的话，可以考虑睡前喝些莲子汤、龙眼肉汤，促进入眠。当然，也可以晚上的时候用热水泡泡脚，同样起到的助眠效果也是很不错的。",
                    "第四，艾灸方面。立秋的气候是由热转凉的交接节气，也是阳气渐收，阴气渐长，由阳盛逐渐转变为阴盛的时期，是万物成熟收获的季节，也是人体阴阳代谢出现阳消阴长的过渡时期。因此立秋时节的艾灸，以平稳收养为原则，不宜过分宣散。",
                    "第五，饮食方面。秋季干燥，少吃辛辣，润燥、养阴、润肺，可多吃一些莲藕、百合、银耳，有养阴润燥，润肺止咳的作用。少吃西瓜，多吃豆制品，不论西瓜或香瓜、菜瓜，都不能任性多吃，否则会损伤脾胃阳气，可以多喝三豆（绿豆、红豆、黑豆）汤，能清热解毒、健脾利湿。"
                ]
            },
            {
                "title": "饮食",
                "detail": [
                    "《素问·脏气法时论》说：“肺主秋……肺收敛，急食酸以收之，用酸补之，辛泻之”。可见酸味收敛肺气，辛味发散泻肺，秋天宜收不宜散，所以要尽量少吃葱、姜等辛味之品，适当多食酸味果蔬。",
                    "立秋至，秋风起，肺燥猛，秋天燥邪为盛，最易伤人肺阴，如何养肺成为大家最为关心的话题。秋天是最容易引发肺病的季节。因为入秋后气候会逐渐干燥，人的皮肤黏膜水分蒸发会加快，因此容易出现皮肤干涩、鼻子燥热、嘴唇干裂、咽喉痛等症状，这时要注意补水，饮食也要多加重视。",
                    "秋时肺金当令，肺金太旺则克肝木，故《金匮要略》又有“秋不食肺”之说。秋季燥气当令，易伤津液，故饮食应以滋阴润肺为宜。《饮膳正要》说：“秋气燥，宜食麻以润其燥，禁寒饮”。更有主张入秋宜食生地粥，以滋阴润燥者。总之，秋季时节，可适当食用芝麻、糯米、粳米、蜂蜜、枇杷、菠萝、乳品等柔润食物，以益胃生津。",
                    "秋天是收获的季节，因此有很多时令水果上市，这些水果都是我们消燥的好食物。例如梨，能够生津解渴、止咳化痰、清热降火，养血生肌、润肺去燥；例如“维生素大王”大枣，汗丰富蛋白质、脂肪、糖类、有机酸等等营养成分，能够促进人体造血；还有酸酸甜甜的葡萄，不仅含有丰富的葡萄糖、果糖、氨基酸、维生素等，还能抗氧化、抗衰老、维持皮肤弹性。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "立秋，是农历二十四节气中的第13个节气，更是秋天的第一个节气，标志着孟秋时节的正式开始：“秋”就是指暑去凉来。到了立秋，梧桐树开始落叶，因此有“落叶知秋”的成语。从文字角度来看，“秋”字由禾与火字组成，是禾谷成熟的意思。秋季是天气由热转凉，再由凉转寒的过渡性季节。",
                    "立秋，七月节。 立字解见春。秋，揫也，物于此而揫敛也。",
                    "初候，凉风至【《礼记》作盲风至】。西方凄清之风曰凉风。温变而凉气始肃也。《周语》曰火见而清风戒寒是也。",
                    "二候，白露降。大雨之後，清凉风来，而天气下降茫茫而白者，尚未凝珠，故曰白露降，示秋金之白色也。",
                    "三候，寒蝉鸣。寒蝉，《尔雅》曰寒螿蝉，小而青紫者；马氏曰物生于暑者，其声变之矣。",
                    "宋时立秋这天宫内要把栽在盆里的梧桐移入殿内，等到“立秋”时辰一到，太史官便高声奏道：“秋来了。”奏毕，梧桐应声落下一两片叶子，以寓报秋之意。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "立秋节",
                    "立秋节，也称七月节。周代是日天子亲率三公六卿诸侯大夫，到西郊迎秋，并举行祭祀少嗥、蓐收的仪式(见《礼祀·月令》)。汉代仍承此俗。《后汉书·祭祀志》：“立秋之日，迎秋于西郊，祭白帝蓐收，车旗服饰皆白，歌《西皓》、八佾舞《育命》之舞。并有天子入圃射牲，以荐宗庙之礼，名日躯刘。杀兽以祭，表示秋来扬武之意。”到了唐代，每逢立秋日，也祭祀五帝。《新唐书·礼乐志》：“立秋立冬祀五帝于四郊。”宋代，立秋之日，男女都戴楸叶，以应时序。有以石楠红叶剪刻花瓣簪插鬓边的风俗，也有以秋水吞食小赤豆七粒的风俗(见《临安岁时记》)，明承宋俗。清代在立秋节这天，悬秤称人，和立夏日所秤之数相比，以验夏中之肥瘦。民国以来，在广大农村中，在立秋这天的白天或夜晚，有预卜天气凉热之俗。还有以西瓜、四季豆尝新、奠祖的风俗。又有在立秋前一日，陈冰瓜，蒸茄脯，煎香薷饮等风俗。",
                    "",
                    "晒秋",
                    "晒秋是一种典型的农俗现象，具有极强的地域特色。在湖南、江西、安徽等生活在山区的村民，由于地势复杂，村庄平地极少，只好利用房前屋后及自家窗台、屋顶架晒或挂晒农作物，久而久之就演变成一种传统农俗现象。",
                    "",
                    "贴秋膘",
                    "民间流行在立秋这天以悬秤称人，将体重与立夏时对比。因为人到夏天，本就没有什么胃口，饭食清淡简单，两三个月下来，体重大都要减少一点。秋风一起，胃口大开，想吃点好的，增加一点营养，补偿夏天的损失，补的办法就是“贴秋膘”：在立秋这天各种各样的肉，炖肉烤肉红烧肉等等，“以肉贴膘”。",
                    "",
                    "秋忙会",
                    "秋忙会一般在农历七八月份举行，是为了迎接秋忙而做准备的经营贸易大会。有与庙会活动结合起来举办的，也有单一为了秋忙而举办的贸易大会。其目的是为了交流生产工具，变卖牲口，交换粮食以及生活用品等。其规模和夏忙会一样，设有骡马市、粮食市、农具生产市、布匹、京广杂货市等。现今把这类集会，都叫做“经济贸易交流大会”。过会期间还有戏剧演出、跑马、耍猴等文艺节目助兴。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "处暑",
        "short_desc": "暑气渐去，秋高气爽",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节气时间",
                        "value": "公历每年8月23日前后"
                    },
                    {
                        "title": "代表寓意",
                        "value": "炎热的暑天即将过去"
                    },
                    {
                        "title": "气候特点",
                        "value": "暑热余威明显，但气温慢慢转凉"
                    },
                    {
                        "title": "节气养生",
                        "value": "滋阴润燥"
                    },
                    {
                        "title": "节气三候",
                        "value": "鹰乃祭鸟；天地始肃；禾乃登"
                    }
                ],
                "detail": [
                    "处暑节气意味着进入气象意义的秋天，处暑后中国长江以北地区气温逐渐下降。"
                ]
            },
            {
                "title": "养生",
                "detail": [
                    "处暑气温下降明显，昼夜温差加大，人们一不小心就容易引发呼吸道、肠胃炎、感冒等疾病，故有“多事之秋”之说。干燥的气候为一些细菌、病菌的繁殖与传播创造了有利条件，应做好呼吸道疾病的预防工作，少到人多的地方凑热闹，必要时戴上口罩。",
                    "处暑之后，秋意渐浓，正是人们畅游郊野迎秋赏景的好时节。秋天，秋高气爽，适合户外运动。可根据个人的体质，做一些登山、慢跑、郊游等户外运动。但要多注意滋脾补筋。因为秋天筋容易克本，而肝是主筋的。所以，在运动的时候要注意不要剧烈，做好准备活动，避免伤筋。",
                    "秋季是身体的生理休整阶段，会出现一些潜伏在夏季的症状，身体总是产生莫名的疲惫感，清晨醒来还想再睡，白天也犯困，就是人们常说的“秋乏”。其实原因也很简单，因为夏天夜晚比较短，天气还热，这个季节的睡眠质量通常没有别的季节好，处于长期睡眠不足的情况下，到了秋天天气凉爽的时候自然而然就会很容易犯困。解决的方式也非常好办，每天晚上提早一点儿睡，防止第二天上班时犯困。等到下午累了多伸几个懒腰，适当增加对心、肺挤压，促进心脏泵血，增加全身供氧。大脑血流充足了，人自然感到清醒、舒适了。"
                ]
            },
            {
                "title": "饮食",
                "detail": [
                    "“病从口入”，饮食与疾病很容易“挂钩”。处暑之后，早晚温差变化开始明显，处暑节气适宜进食清热安神的食物，如银耳、百合、莲子、蜂蜜、黄鱼、干贝、海带、海蜇、芹菜、菠菜、糯米、芝麻、豆类及奶类，但这些食物一次进食不可太多，做到少食多餐。",
                    "另外，随着气侯渐渐干燥，身体里肺经当值，这时可多吃滋阴润燥食物，防止燥邪损伤。如梨、冰糖、银耳、沙参、鸭子等养阴生津的食物，或黄芪、党参、乌贼、甲鱼等能益气保健的食物。",
                    "当然，多吃蔬果可以起到生津润燥、消热通便的功效，能补充人体的津液。应少吃或不吃煎炸食物，因为这些食物会加重秋燥的症状。",
                    "总得来说需要滋阴润燥，但根据不同人的不同情况，处暑期间饮食方面，又有不同具体的建议：",
                    "1、疏肝理气，健脾生津润燥，如猪肉、鸡肉、白芍、百合、银耳、木耳、梨等。",
                    "2、疏肝健脾、益气生津，如太子参、山药、甘蔗、蜂蜜、芝麻等。",
                    "3、清热健脾益气，如猪瘦肉、鸡肉、百合、太子参、淮山、白术、茯苓、粉葛、沙葛、荸荠、薏苡仁等。",
                    "4、清热润燥、健脾益气功效的食物，如山药、甘蔗、山楂、荸荠、玉米、蜂蜜、太子参、玉竹、石斛、猪肉、鱼肉、鸡肉等。",
                    "5、清热生津、益气养肝功效的食物，如太子参、萝卜、茉莉花、猪肉、鸡肉、淮山、梨、荠菜等。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "太阳到达黄经150°时是农历二十四节气的处暑。处暑是反映气温变化的一个节气。“处”含有躲藏、终止意思，“处暑”表示炎热暑天结束了。《月令七十二候集解》说：“处，止也，暑气至此而止矣。”“处”是终止的意思，表示炎热即将过去，暑气将于这一天结束，我国大部分地区气温逐渐下降。处暑既不同于小暑、大暑、也不同于小寒、大寒节气，它是代表气温由炎热向寒冷过渡的节气。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "出游迎秋",
                    "",
                    "祭祖、迎秋处暑节气前后的民俗多与祭祖及迎秋有关。处暑前后民间会有庆赞中元的民俗活动，俗称“作七月半”或“中元节”。旧时民间从七月初一起，就有开鬼门的仪式，直到月底关鬼门止，都会举行普渡布施活动。据说普度活动由开鬼门开始，然后竖灯篙，放河灯招致孤魂；而主体则在搭建普度坛,架设孤棚，穿插抢孤等行事，最后以关鬼门结束。时至今日，已成为祭祖的重大活动时段。此外，处暑之后，秋意渐浓，正是人们畅游郊野迎秋赏景的好时节。处暑过，暑气止，就连天上的那些云彩也显得疏散而自如，而不像夏天大暑之时浓云成块。民间向来就有“七月八月看巧云”之说，其间就有“出游迎秋”之意。",
                    "",
                    "放河灯",
                    "",
                    "河灯也叫“荷花灯”，一般是在底座上放灯盏或蜡烛，中元夜放在江河湖海之中，任其漂泛。放河灯是为了普渡水中的落水鬼和其他孤魂野鬼。肖红《呼兰河传》中的一段文字，是这种习俗的最好注脚：“七月十五是个鬼节；死了的冤魂怨鬼，不得托生，缠绵在地狱里非常苦，想托生，又找不着路。这一天若是有个死鬼托着一盏河灯，就得托生。”",
                    "",
                    "开渔节",
                    "",
                    "对于沿海渔民来说，处暑以后渔业收获的时节，每年处暑期间；在浙江省沿海都要举行一年一度的隆重的开渔节，决定在东海休渔结束的那一天，举行盛大的开渔仪式，欢送渔民开船出海。2006年第九届中国开渔节，在9月6日浙江省象山县举行。因为；这时海域水温依然偏高，鱼群还是会停留在海域周围，鱼虾贝类发育成熟。因此，从这一时间开始，人们往往可以享受到种类繁多的海鲜。",
                    "",
                    "泼水习俗",
                    "",
                    "在日本，从大暑到处暑的一个月时间内，日本各地有组织泼水降温的习俗。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "白露",
        "short_desc": "朝露凝白，凉气渐重，白露后，莫贪凉",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节气时间",
                        "value": "每年9月7日或8日"
                    },
                    {
                        "title": "代表寓意",
                        "value": "天气转凉，露凝而白"
                    },
                    {
                        "title": "气候特点",
                        "value": "干燥，昼夜温差较大，天气渐渐转凉"
                    },
                    {
                        "title": "节气养生",
                        "value": "预防秋燥、滋阴益气、贴秋膘"
                    },
                    {
                        "title": "节气三候",
                        "value": "鸿雁来；玄鸟归；群鸟养羞"
                    }
                ],
                "detail": [
                    "“白露”是反映自然界气温变化的节令。露是“白露”节气后特有的一种自然现象。此时的天气，正如《礼记》中所云的：“凉风至，白露降，寒蝉鸣。”"
                ]
            },
            {
                "title": "养生",
                "detail": [
                    "白露过后，气温逐渐转凉，特别是一早一晚，更添几分凉意，因此穿衣不能赤膊露体，以防寒邪侵袭。过了白露，人们容易出现口干、唇干、咽干、皮肤干燥等症状，这就是典型的“秋燥”。因此，饮食应当以健脾润燥为主，宜吃性平味甘或甘温之物，或是容易消化的平补食品。",
                    "白露昼夜温差较大，一般昼夜温差在10℃左右，因此要注重耐寒锻炼，预防感冒。白露节气运动锻炼，做到“六防”：",
                    "第一要注重运动锻炼，也要讲究耐寒锻炼；",
                    "第二要防感冒，要注意呼吸道疾病的防治；",
                    "第三要防肠胃病，要慎食一些瓜类、水果之类的东西；",
                    "第四是防乱补。比如说可以吃一点乌骨鸡汤、蜂蜜、芝麻、花生之类的；",
                    "第五是防肥胖，到秋天的话天气转凉了，胃口也好了，食欲大增，往往饮食过多；",
                    "第六是防伤害，秋令时节要坚持锻炼，但运动时要防受凉感冒、运动损伤和运动过度。"
                ]
            },
            {
                "title": "饮食",
                "detail": [
                    "白露时节为典型的秋季气候，容易出现口干、唇干、鼻干、咽干及大便干结、皮肤干裂等症状。预防秋燥的方法很多，可适当地多服一些富含维生素的食品，也可选用一些宣肺化痰、滋阴益气的中药，如人参、沙参、西洋参、百合、杏仁、川贝等，对缓解秋燥多有良效。对普通大众来说，简单实用的药膳、食疗似乎更容易接受。",
                    "白露饮食宜“养心肝脾胃”为原则，可多吃酸味食物以养肝，但不宜进食过饱，以免肠胃积滞，变生胃肠疾病。老人与小孩饮食更应注意少量多餐，而且以温、软食物为主，不可过食生冷、硬的食物。另一方面，也要预防秋燥，可多吃辛润食物，如梨、百合、甘蔗、沙葛、萝卜、银耳、蜜枣等，也可结合药膳进行调理。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "白露是农历二十四节气之一，当太阳到达黄经一百六十五度时交白露节气。白露是秋天的第三个节气，表示孟秋时节的结束和仲秋时节的开始。露水是由于温度降低，水汽在地面或近地物体上凝结而成的水珠。所以，白露实际上是表征天气已经转凉。这时，人们就会明显地感觉到炎热的夏天已过，而凉爽的秋天已经到来了。昼夜温差可达十多度。阳气是在夏至达到顶点，物极必反，阴气也在此时兴起。到了白露，阴气逐渐加重，清晨的露水随之日益加厚，凝结成一层白白的水滴，所以就称之为白露。俗语云：“处暑十八盆，白露勿露身。”这两句话的意思是说，处暑仍热，每天须用一盆水洗澡，过了十八天，到了白露，就不要赤膊裸体了，以免着凉。还有句俗话：“白露白迷迷，秋分稻秀齐。”意思是说，白露前后若有露，则晚稻将有好收成。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "白露节气生活小贴士",
                    "",
                    "俗语云：“处暑十八盆，白露勿露身。”这句话的意思是表示，处暑还会热，每天需要用一盆水洗澡，在过了十八天，到了白露节气，就不能赤膊露体了，会着凉受寒。",
                    "",
                    "白露即为典型的秋季气候，容易出现口干、唇干、鼻干、咽干及大便干结、皮肤干裂等症状。预防秋燥的方法很多，可适当地多服一些富含维生素的食品，也可选用一些宣肺化痰、滋阴益气的中药，如人参、沙参、西洋参、百合、杏仁、川贝等，对缓解秋燥多有良效。对普通大众来说，简单实用的药膳、食疗似乎更容易接受。",
                    "",
                    "春捂秋冻是一条经典的养生保健要诀。当然，秋冻并非人人皆宜。如糖尿病患者局部供血较差，如果血管一下子受到冷空气刺激，很容易发生血管痉挛，使血流量进一步减少，易引起组织坏死和糖尿病足，再加上糖尿病和心脑血管疾病常常伴发，冷空气刺激更易诱发心脑血管疾病，甚至导致心梗等后果。因此，糖尿病患者最好不要秋冻。除此之外，像体质较弱的老人和儿童、心脑血管疾病患者、慢性支气管炎患者、哮喘病患者和关节炎患者都不适合“秋冻”。",
                    "",
                    "早晚温差大就应该及时添加衣被，否则，极容易患上感冒，而支气管炎、哮喘、消化性溃疡等慢性病患者，也容易诱发或加重病情。肚脐部位的表皮最薄，皮下没有脂肪组织，但有丰富的神经末梢和神经丛，对外部刺激敏感。若防护不当，晚上睡觉暴露腹部或爱美穿露脐装，寒气极易通过肚脐侵入人体。如果寒气直中肠胃，就会发生急性腹痛、腹泻、呕吐；天长日久，寒气逐渐积聚在小腹，还会导致泌尿生殖系统疾病。脚部分布着人体的6条重要经脉，并且脚远离心脏，血液循环最为不畅。所以，有“寒从脚起，热从头散”的说法。研究证实，双脚受凉是引发感冒、支气管炎、消化不良、失眠等病症的元凶。因此，白露应注意脚的保暖，鞋袜宜宽松、舒适、吸汗。",
                    "",
                    "清茶",
                    "",
                    "到了白露节气，秋意渐浓。旧时南京人十分重视节气的“来”和“去”，逐渐形成了具有南京地方特色的节气习俗。",
                    "",
                    "米酒",
                    "",
                    "资兴兴宁、三都、蓼江一带历来有酿酒习俗。每年白露节一到，家家酿酒，待客接人必喝“土酒”。其酒温中含热，略带甜味，称“白露米酒”。白露米酒中的精品是“程酒”，是因取程江水酿制而得名。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "秋分",
        "short_desc": "秋分到，暝日平分",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节气时间",
                        "value": "每年9月22日-24日"
                    },
                    {
                        "title": "代表寓意",
                        "value": "秋季中间，昼夜等长"
                    },
                    {
                        "title": "气候特点",
                        "value": "昼夜温差逐渐加大，气温逐日下降"
                    },
                    {
                        "title": "节气养生",
                        "value": "阴平阳秘、收敛闭藏"
                    },
                    {
                        "title": "节气三候",
                        "value": "雷始收声；蛰虫坯户；水始涸"
                    }
                ],
                "detail": [
                    "秋分时节，我国大部分地区已经进入凉爽的秋季，南下的冷空气与逐渐衰减的暖湿空气相遇，产生一次次的降水，气温也一次次地下降。"
                ]
            },
            {
                "title": "养生",
                "detail": [
                    "秋季天气干燥，主要外邪为燥邪。秋分之前有暑热的余气，故多见于温燥；秋分之后，阵阵秋风袭来，使气温逐渐下降，寒凉渐重，所以多出现凉燥。要防止凉燥，就得坚持锻炼身体，增强体质，提高抗病能力。秋季锻炼，重在益肺润燥，如练吐纳功、叩齿咽津润燥功。饮食调养方面，应多喝水，吃清润、温润的食物，如芝麻、核桃、糯米、蜂蜜、乳品、梨等，可以起到滋阴润肺、养阴生津的作用。",
                    "在精神养生方面，要看到秋季气候渐转干燥，日照减少，气温渐降，人们的情绪未免有些垂暮之感，故有“秋风秋雨愁煞人”之言。所以这时，人们应保持神志安宁，减缓秋肃杀之气对人体的影响，收敛神气，以适应秋天容平之气。同时精神情绪上要看到积极的一面，那就是金秋季节时，天高气爽，是开展各种运动锻炼的好时机，登山、慢跑、散步、打球、游泳等。"
                ]
            },
            {
                "title": "饮食",
                "detail": [
                    "秋分饮食有三“宜”：",
                    "1、宜清淡膳食",
                    "烹调食物时， 遵循“少油、少盐”的原则，可减少发生高血脂、高血压和肥胖的风险。烹调以蒸、炖、煮为优，其次是炒，避免反复油炸食品。还应该控制甜食，尽量少加糖，控制各类糕点、饮料。",
                    "2、宜常吃蔬果",
                    "中医食疗认为秋季应“减辛增酸”。“辛”是指辛辣刺激性食物，应该严格控制，而“酸”是指各类有含有酸性成分的水果，包括苹果、梨、石榴、芒果、柚子、葡萄、杨桃、山楂等。如果有条件，可以选择秋季时令的蔬果，例如菠菜、卷心菜、萝卜、莲藕、茭白、花菜、柿子、秋梨、番茄、枣、苹果、柚子、石榴、葡萄等。",
                    "3、宜多喝水",
                    "尽管秋天不如夏天一样的炎热，但空气相对干燥，也需要多喝水，以及多吃水分含量较高的蔬果，例如雪梨、瓜类蔬菜。每日饮水量在1500-1700毫升，南方秋季温度仍然较高，饮水量在1800毫升或以上为宜。",
                    "秋季饮食有八“忌“：",
                    "1、勿吃油腻和重口味食物",
                    "秋天普遍比较干燥，应减少油腻和辛辣食物的摄入，特别是要控制麻辣火锅，以免加重“秋燥”，引起体内不适。",
                    "2、勿暴饮暴食",
                    "秋季气候宜人，食欲大增，味觉增强，而且食物丰富，往往会造成饮食过量。如果摄入热量过剩，转化成脂肪聚积，加之汗液分泌减少，消耗减少，容易使体重增加而导致超重、肥胖。",
                    "3、勿在吃火锅时只吃肉",
                    "肉类过多，容易上火，尤其是秋天干燥季节，更不适宜。为了健康，在吃火锅的时候，肉类食物与蔬菜应交替进食，这样不仅不容易感到过分油腻，还可以帮助控制肉类的总量摄入。",
                    "4、忌贪吃生冷食品",
                    "由于夏季炎热的影响，秋季肠胃变得比较敏感，如果生冷食品一次摄入较多，势必造成对肠胃刺激大，可能引起肠胃不适。",
                    "5、忌盲目控制饮食",
                    "为了保持身材，避免冬天“攒膘”，有的女性选择在秋季严格控制饮食，却造成不少造成贫血、月经失调和肠胃疾病、抵抗力下降等健康问题。",
                    "6、勿盲目进补",
                    "进补要看个人体质，而不是人云亦云。例如，低血压和高血压的食物要求不同，前者可适当增加口味，后者则需要严格控制咸味食品、控制油盐摄入。",
                    "7、忌盲目吃蟹",
                    "俗话说“秋风起，闸蟹肥”。每年秋天正是蟹肉最肥美、也最滋补的时候。然而，有过敏史的人忌吃蟹，胆囊炎和痛风患者急性发作期间不宜吃螃蟹，患有冠心病、动脉硬化、高血脂的人应忌食蟹黄，脾胃虚寒者尤应注意避免吃过多。",
                    "8、勿多食月饼",
                    "月饼的主要成分是面粉、白糖、油脂和配料，热量非常高，因此，切勿多食。若是高血糖、高血脂、高血压的“三高”人群，更应浅尝辄止，并适当减少当天主食和油脂的进食量。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "我国古籍《春秋繁露·阴阳出入上下篇》中说：“秋分者，阴阳相半也，故昼夜均而寒暑平。”秋分之“分”为“半”之意。“秋分”的意思有二：",
                    "",
                    "一是，日夜时间均等，并由日长夜短逐步日短夜长。太阳在这一天到达黄经180度，直射地球赤道，因此全球大部分地区这一天的24小时昼夜均分，各12小时；在北极点与南极点附近，这一天可以观测到“太阳整日在地平线上转圈”的特殊现象。",
                    "",
                    "二是，气候由热转凉。按我国古代以立春、立夏、立秋、立冬为四季开始的季节划分法，秋分日居秋季90天之中，平分了秋季。",
                    "秋分在每年公历的9月22日，23日或24日，为中国传统节日中的二十四节气之一，此时太阳到达黄经180度。秋分的含义《月令七十二候集解》：“八月中……解见春分”。“分”示昼夜平分之意，同春分一样，此日阳光直射地球赤道，昼夜相等。此后，阳光直射位置开始进入南半球，北半球开始昼短夜长，气温降低，在全国具有普遍意义。秋分，“分”即为“半”。秋分时，地球绝大部分地区这一天昼夜均分，各12小时，在北极点（北纬90°）与南极点（南纬90°）附近，这一天可以观测到“太阳整日在地平线上转圈”的特殊现象。秋分之后，北半球各地昼短夜长，南半球各地昼长夜短。”秋分的特点秋分节气是二十四节气中比较特殊的。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "秋祭月",
                    "",
                    "秋分曾是传统的“祭月节”。如古有“春祭日，秋祭月”之说。现在的中秋节则是由传统的“祭月节”而来。据考证，最初“祭月节”是定在“秋分”这一天，不过由于这一天在农历八月里的日子每年不同，不一定都有圆月。而祭月无月则是大煞风景的。所以，后来就将“祭月节”由“秋分”调至中秋。",
                    "",
                    "吃秋菜",
                    "",
                    "在岭南地区，昔日四邑（现在加上鹤山为五邑）的开平苍城镇的谢姓，有个不成节的习俗，叫做“秋分吃秋菜”。“秋菜”是一种野苋菜，乡人称之为“秋碧蒿”。逢秋分那天，全村人都去采摘秋菜。在田野中搜寻时，多见是嫩绿的，细细棵，约有巴掌那样长短。采回的秋菜一般家里与鱼片“滚汤”，名曰“秋汤”。有顺口溜道：“秋汤灌脏，洗涤肝肠。阖家老少，平安健康。”一年自秋，人们祈求的还是家宅安宁，身壮力健。",
                    "",
                    "送秋牛",
                    "",
                    "秋分随之即到，其时便出现挨家送秋牛图的。其图是把二开红纸或黄纸印上全年农历节气，还要印上农夫耕田图样，名曰“秋牛图”。送图者都是些民间善言唱者，主要说些秋耕和吉祥不违农时的话，每到一家更是即景生情，见啥说啥，说得主人乐而给钱为止。言词虽随口而出，却句句有韵动听。俗称“说秋”，说秋人便叫“秋官”。",
                    "",
                    "放风筝",
                    "",
                    "秋分期间还是孩子们放风筝的好时候。尤其是秋分当天。甚至大人们也参与。风筝类别有王字风筝，鲢鱼风筝，眯蛾风筝，雷公虫风筝，月儿光风筝，其大者有两米高，小的也有二、三尺。市场上有卖风筝的，多比较小，适宜于小孩子们玩耍，而大多数还是自己糊的，较大，放时还要相互竞争看哪个的放得高。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "寒露",
        "short_desc": "寒露十月已深秋，添衣保暖最重要",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节气时间",
                        "value": "每年10月7日~9日"
                    },
                    {
                        "title": "代表寓意",
                        "value": "气温比白露时更低"
                    },
                    {
                        "title": "气候特点",
                        "value": "气温下降，少雨干燥，晨露更凉"
                    },
                    {
                        "title": "节气养生",
                        "value": "润肺生津、健脾益胃"
                    },
                    {
                        "title": "节气三候",
                        "value": "鸿雁来宾、雀入水为蛤、菊有黄华"
                    }
                ],
                "detail": [
                    "寒露时节，南岭及以北的广大地区均已进入秋季，东北和西北地区已进入或即将进入冬季。首都北京大部分年份这时已可见初霜，除全年飞雪的青藏高原外，东北北部和新疆北部地区一般已开始降雪。"
                ]
            },
            {
                "title": "养生",
                "detail": [
                    "一般而言，秋天人的情绪不太稳定，易于烦躁或悲愁伤感，特别是身临花木凋零，秋风萧瑟的深秋，常在一些人(特别是老年人)心中引起苦闷与垂暮之感。因此，秋季养生以调达情志、培养乐观情绪、保持心理平衡为首要。在阳光明媚的天气里，外出观赏风景，喜悦溢于言表，可使忧郁愁烦顿消，令人心旷神怡，给生活增添无穷乐趣。",
                    "民间有句谚语叫做“白露不露身，寒露不露脚”，这句话是说，白露时节的时候，老人和小孩子就不要赤膊了，而到了寒露的时候，就要注意足部保暖了。与白露相比，寒露的“寒”字表明凉气更甚。古人把“露”作为天气转凉的表征。因此，老年人要注意穿上保暖性好的鞋袜，脚踝部也不要露在外面。每天晚上要养成用热水泡脚的习惯，因为脚离心脏最远，血液供应较少，热水泡脚可以使血管扩张，改善脚部的血液循环和组织营养，提高机体的免疫能力。还可以减少腿部酸困，有助于睡眠。"
                ]
            },
            {
                "title": "饮食",
                "detail": [
                    "寒露节气到了，饮食要少吃“三冷”，多吃“三暖”：",
                    "少吃“三冷”",
                    "1、生冷瓜果",
                    "水果类一般都比较寒凉，夏季的一些水果如西瓜、甜瓜、草莓等都不适合在这个季节里过多食用，一些可以生吃的蔬菜如黄瓜、生菜等，最好都加热做熟以后食用，秋季的一些寒凉水果如梨、山楂、柚子等也可采用蒸、煮、煲汤等方法食用。",
                    "2、凉性蔬菜",
                    "一般来说带“苦味、酸味”的蔬菜都偏凉性，如苦瓜、蒲公英、竹笋、石花菜、发菜等，而且也不是应季的蔬菜，寒露过后还是建议少食用的。",
                    "3、贝类海鲜",
                    "贝类的海鲜如花甲、扇贝、田螺等都是寒性很足的食材，不适合多吃。深秋季节也正是吃螃蟹的时候，但要注意螃蟹也是寒性很大的食材，虽然异常鲜美，不要过多食用，吃螃蟹的时候可以搭配黄酒、姜茶等来抵消寒性。",
                    "多吃“三暖”",
                    "1、生姜",
                    "生姜可以驱风寒，属于温性食物，刺激性也不是特别大，因为生姜中含有姜油酮、姜酚等生理活性物质，能促进消化，温中散寒，注意补充水分的情况下，每日食用几片姜是再好不过的。",
                    "2、羊肉",
                    "羊肉被称为“秋冬补品”，肉质鲜嫩，营养价值高，羊肉味甘而不腻，性温而不燥，可以暖中祛寒、温补气血，不但滋味鲜美，而且能滋补身体，一举两得。",
                    "3、蜂蜜",
                    "寒露节气除了“寒”，因为少雨多风，还有一个明显的特征就是“燥”，也就是人们常说的“秋燥”，我国古代就有对付秋燥的良方：“朝朝盐水，晚晚蜜汤。”蜂蜜被称为“天赐的礼物”，既是良药，又是上等饮料，除了滋润营养的果糖和葡萄糖，蜂蜜中还含有天然的含有活性酶，经常食用蜂蜜对健康有很大的好处。"
                ]
            },
            {
                "title": "起源",
                "detail": [
                    "公历每年10月8日或9日是太阳到达黄经195°时为寒露 。",
                    "《月令七十二候集解》说：“九月节，露气寒冷，将凝结也。”寒露的意思是气温比白露时更低，地面的露水更冷，快要凝结成霜了。",
                    "",
                    "寒露时节，南岭及以北的广大地区均已进入秋季，东北和西北地区已进入或即将进入冬季。首都北京大部分年份这时已可见初霜，除全年飞雪的青藏高原外，东北北部和新疆北部地区一般已开始降雪。",
                    "",
                    "我国将寒露分为三候：“一候鸿雁来宾；二候雀入大水为蛤；三候菊有黄华。”此节气中鸿雁排成一字或人字形的队列大举南迁；深秋天寒，雀鸟都不见了，古人看到海边突然出现很多蛤蜊，并且贝壳的条纹及颜色与雀鸟很相似，所以便以为是雀鸟变成的；第三候的“菊始黄华”是说在此时菊花已普遍开放。"
                ]
            },
            {
                "title": "气候",
                "detail": [
                    "寒露之后，露水增多，气温更低。此时我国有些地区会出现霜冻，北方已呈深秋景象，白云红叶，偶见早霜，南方也秋意渐浓，蝉噤荷残。",
                    "我国传统将露作为天气转凉变冷的表征。仲秋白露节气“露凝而白”，至季秋寒露时已是“露气寒冷，将凝结为霜了”。",
                    "",
                    "这时，我国南方大部分地区各地气温继续下降。华南日平均气温多不到20℃，即使在长江沿岸地区，水银柱也很难升到30℃以上，而最低气温却可降至10℃以下。西北高原除了少数河谷低地以外，候（5天）平均气温普遍低于10℃，用气候学划分四季的标准衡量，已是冬季了。千里霜铺，万里雪飘，与华南秋色迥然不同。"
                ]
            },
            {
                "title": "影响",
                "detail": [
                    "寒露以后，北方冷空气已有一定势力，我国大部分地区在冷高压控制之下，雨季结束。天气常是昼暖夜凉，晴空万里，对秋收十分有利。我国大陆上绝大部分地区雷暴已消失，只有云南、四川和贵州局部地区尚可听到雷声。华北10月份降水量一般只有9月降水量的一半或更少，西北地区则只有几毫米到20多毫米。干旱少雨往往给冬小麦的适时播种带来困难，成为旱地小麦争取高产的主要限制因子之一。",
                    "",
                    "海南和西南地区这时一般仍然是秋雨连绵，少数年份江淮和江南也会出现阴雨天气，对秋收秋种有一定的影响。",
                    "“寒露不摘棉，霜打莫怨天”。趁天晴要抓紧采收棉花，遇降温早的年份，还可以趁气温不算太低时把棉花收回来。江淮及江南的单季晚稻即将成熟，双季晚稻正在灌浆，要注意间歇灌溉，保持田间湿润。",
                    "",
                    "南方稻区还要注意防御“寒露风”的危害。华北地区要抓紧播种小麦，这时，若遇干旱少雨的天气应设法造墒抢墒播种，保证在霜降前后播完，切不可被动等雨导致早茬种晚麦。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "霜降",
        "short_desc": "天气渐冷，初霜出现，秋末冬始也",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节气时间",
                        "value": "每年的10月23日或24日"
                    },
                    {
                        "title": "代表寓意",
                        "value": "天气渐冷，开始有霜"
                    },
                    {
                        "title": "气候特点",
                        "value": "早晚天气较冷，中午较热，秋燥明显"
                    },
                    {
                        "title": "节气养生",
                        "value": "防秋燥、防秋郁、防寒"
                    },
                    {
                        "title": "节气三候",
                        "value": "豺乃祭兽，草木黄落，蜇虫咸俯"
                    }
                ],
                "detail": [
                    "霜降，二十四节气之一，每年公历10月23日左右，霜降节气含有天气渐冷、初霜出现的意思，是秋季的最后一个节气，也意味着冬天即将开始。霜降时节，养生保健尤为重要，民间有谚语“一年补透透，不如补霜降”，足见这个节气对人们的影响。"
                ]
            },
            {
                "title": "养生",
                "detail": [
                    "七十二候中关于霜降的有“三候”。“初候豺乃祭兽”，是说豺这类动物从霜降开始要为过冬储备食物;“二候草木黄落”，此时节草木枯黄，落叶满地;“三候蛰虫咸俯”，准备冬眠的动物开始藏在洞穴中过冬了。此时节大自然都处在一个向冬天过渡的阶段，我们人类也应该把“秋冻”放在一边，为健康地度过寒冷的冬天做些准备了。",
                    "",
                    "秋末时节，是呼吸道疾病的高发期，多吃生津润燥、宣肺止咳作用的梨、苹果、橄榄、白果、洋葱、芥菜、萝卜等食物，搓揉迎香穴鼻翼两侧，练练呬“嘶”音字功等，都有助于我们预防呼吸道疾病。",
                    "",
                    "天气逐渐变冷，身体局部保暖不当或人体为适应寒冷的刺激而有所增加的新陈代谢等原因，使得慢性胃病、“老寒腿”等疾病的发病随之增多。尤其是有消化道溃疡病史的人，要特别注意自我保养，一定要坚持医生的指导治疗，避免服用对胃肠黏膜刺激性大的食物和药物。",
                    "",
                    "“霜叶红于二月花”。霜降过后，枫树、黄栌树等树木在秋霜的抚慰下，开始漫山遍野地变成红黄色，如火似锦，非常壮观。大家在外出登山、欣赏美景的时候，一定要注意保暖，尤其要保护膝关节，切不可运动过量。膝关节在遇到寒冷刺激时，血管收缩，血液循环变差，往往使疼痛加重，故在天冷时应注意保暖，必要时戴上护膝。老年人运动时，不宜做屈膝动作时间较长的运动，要尽量减少膝关节的负重。"
                ]
            },
            {
                "title": "饮食",
                "detail": [
                    "霜降前后要多吃牛肉。牛肉蛋白质含量高，脂肪含量低，有“肉中骄子”的美称。霜降养生吃一些牛肉，可以治疗中气不足、气短体虚、筋骨酸软、久病贫血、面黄体瘦、头晕目眩等症状。",
                    "霜降节气的到来意味着冬季将要到来，养生首先推荐白萝卜。“冬吃萝卜夏吃姜，不要医生开药方”中说的就是萝卜所具有的功效。中医认为，白萝卜性凉、味甘，具有清热生津、下气宽中、开胃健脾、顺气化痰之功效。白萝卜中含有丰富的维生素C和微量元素，可帮助机体增强免疫力，促进肠胃蠕动，以增加食欲等。",
                    "鸭肉是霜降时节必吃的食物之一。鸭肉不仅富含蛋白质，可及时补充夏季的过度消耗，且其性凉，具有滋阴养胃、健脾补虚的作用。",
                    "山药为补中益气药，具有补益脾胃的作用，特别适合脾胃虚弱者进补前食用。将山药、大枣20克、米(粳米、糯米各一半)熬成粥，就是很好的选择。另外，多汗、反复感冒的气虚患者在秋季应该适度增加山药的摄入量。",
                    "霜降时节吃秋梨是个不错的选择。梨味甘性凉、微酸，具有清热生津、润肺化痰的功效，是防治秋燥的佳果。梨能维持细胞组织的健康状态，还能软化血管。生食梨有清热解毒、生津润燥、清心降火作用;煎水或加蜜熬膏，有清热润肺、化痰止咳的作用。若与荸荠、蜂蜜、甘蔗等榨汁同服，效果更佳。",
                    "霜降前后苹果大量上市，这时吃苹果最养生。苹果中的有机酸和纤维素可促进肠蠕动，能使大便松软，便于排泄，因此食用苹果能促进通便，治疗大便干燥。苹果中含有的大量维生素、苹果酸，能促使积存于人体内的脂肪分解，经常食用苹果可以防止肥胖。"
                ]
            },
            {
                "title": "来源",
                "detail": [
                    "每年阳历10月23日前后，太阳到达黄经210度时为二十四节气中的霜降。霜降是秋季的最后一个节气，是秋季到冬季的过渡节气。秋晚地面上散热很多，温度骤然下降到0度以下，空气中的水蒸气在地面或植物上直接凝结形成细微的冰针，有的成为六角形的霜花，色白且结构疏松。",
                    "",
                    "《月令七十二候集解》关于霜降说：九月中，气肃而凝，露结为霜矣。“霜降”表示天气逐渐变冷，露水凝结成霜。我国古代将霜降分为三候：一候豺乃祭兽；二候草木黄落；三候蜇虫咸俯。豺狼开始捕获猎物，祭兽，以兽而祭天报本也，方铺而祭秋金之义；大地上的树叶枯黄掉落；蜇虫也全在洞中不动不食，垂下头来进入冬眠状态中。"
                ]
            },
            {
                "title": "风俗",
                "detail": [
                    "在中国的一些地方，霜降时节要吃红柿子，在当地人看来，这样不但可以御寒保暖，同时还能补筋骨，是非常不错的霜降食品。",
                    "泉州老人对于霜降吃柿子的说法是：霜降吃丁柿，不会流鼻涕。",
                    "有些地方对于这个习俗的解释是：霜降这天要吃杮子，不然整个冬天嘴唇都会裂开。",
                    "住在农村的人们到了这个时候，则会爬上一棵棵高大的柿子树，摘几个光鲜香甜的柿子吃。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "立冬",
        "short_desc": "冬日将至，天气寒凉，及时防寒保暖",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节气时间",
                        "value": "每年11月7日或8日"
                    },
                    {
                        "title": "代表寓意",
                        "value": "冬季的开始"
                    },
                    {
                        "title": "气候特点",
                        "value": "由少雨干燥向阴雨寒冻过渡"
                    },
                    {
                        "title": "节气养生",
                        "value": "滋阴潜阳、少食生冷"
                    },
                    {
                        "title": "节气三候",
                        "value": "水始冰；地始冻；雉入大水为蜃"
                    }
                ],
                "detail": [
                    "立，建始也，表示冬季自此开始。冬是终了的意思，有农作物收割后要收藏起来的含意，中国又把立冬作为冬季的开始。"
                ]
            },
            {
                "title": "养生",
                "detail": [
                    "冬季要保证头暖、背暖、脚暖。女性在生理期还要特别注意腹部保暖。中医的经验是，“冬伤于寒，春必温病。”冬天过分受凉，冬寒进入体内，到春天就会患温病（是以发热为特征的外感急性病，如流感等）。",
                    "阳虚体质的人，冬天做身体按摩时，要减少背部按摩的时间。中医认为，腹为阴，背为阳，背部裸露和按摩容易扰动阳气而导致阳气外泄。相反，应该重视阴经的保健，可以在位于胸腹部正中线的任脉上艾灸，任脉与六阴经有联系，称为“阴脉之海”，具有调节全身诸阴经经气的作用。可以重点做膻中穴（双乳中间）、神阙（肚脐）、关元（肚脐下四指）等穴位。",
                    "如果不小心受凉风寒，可以用姜片、红糖煮水喝。还可以用葱白、姜片、糯米煮粥，加适量米醋，制成“神仙粥”，预防风寒感冒，效果非常好。",
                    "从“立冬”开始一直到“立春”都叫“冬三月”，是一年中最冷的时节。要顺应自然的变化，入冬以后起居调养都应该以“养藏”为主。冬季健康养生应讲究进补均衡，冬季除了进食热食或性热的食物外，适当吃些性冷食物和凉菜，不但对身体无害，反而有益。“冬令进补”应根据实际情况有针对性地选择清补、温补、小补、大补，万不可盲目“进补”。"
                ]
            },
            {
                "title": "饮食",
                "detail": [
                    "立冬时节，天气逐渐变冷，人体热量散失较多，饮食应以增加热能为主，肉类、鸡蛋、鱼类、乳类、豆类及富含碳水化合物和脂肪的食物，是这个季节饮食中不可缺少的。",
                    "冬季对应五脏肾，所以立冬时节养生首重养肾。但是需要注意的是，补肾固然重要，但五行中的侧重，是要在五行均衡中略有侧重，而不是不顾及五行均衡的过分重视。",
                    "一、黑色食材养护肾阳",
                    "黑色入肾，吃黑色的食物对养肾有好处，黑豆、黑米、海参、何首乌、黑木耳等食材，都是不错的选择。",
                    "二、冬要温补绿色蔬菜不可少",
                    "源于易学的中医理论认为，春要升补、夏要清补、长夏要淡补、秋要平补、冬要温补。立冬过后，在进食营养丰富的食品进补的同时，也要多吃新鲜蔬菜，尤其是绿色蔬菜，以避免维生素的缺乏。",
                    "三、水果补充营养还养颜",
                    "《黄帝内经》有云：“五谷为养，五果为助，五畜为益，五菜为充，气味合而服之，以补精益气。”说的是水果有助健康、有养生的功效。水果中含有的多种营养成分，可以调节体内代谢，预防疾病，增进健康。立冬时节多吃水果不仅有利于健康，还可以美肤养颜。",
                    "四、干果、杂粮调理肌肤",
                    "立冬后的天气，风大、寒冷，对皮肤的损害不小。为了调理肌肤，缓解皮肤的干燥，可吃板栗、花生、红枣、核桃等这些应季的干果，和玉米、小米、燕麦、豆类等杂粮。",
                    "五、喝粥、热饮滋阴润肺",
                    "多喝粥、热饮，不仅有助于增加热量和营养，还可滋阴润肺。立冬过后，为了御寒，暖气、空调成了取暖必备，以至于室内空气干燥，且与室外温差加大，容易引起感冒、上火等症状。诸如芝麻粥、萝卜粥、胡桃粥、茯苓粥、大枣粥、银耳枸杞汤、鲫鱼汤、银耳莲子汤、冰糖梨汤等，有助于缓解上火、喉干等症状。"
                ]
            },
            {
                "title": "气候",
                "detail": [
                    "我国古代将立冬节气分为3个5天，并由此定出三候：“一候水始冰；二候地始冻；三候雉入大水为蜃。” 此节气水已经能结成冰；土地也开始冻结；三候“雉入大水为蜃”中的雉即指野鸡一类的大鸟，蜃为大蛤，立冬后，野鸡一类的大鸟便不多见了，而海边却可以看到外壳与野鸡的线条及颜色相似的大蛤。所以古人认为雉到立冬后便变成大蛤了。",
                    "",
                    "对“立冬”的理解，我们还不能仅仅停留在冬天开始的意思上。追根溯源，古人对“立”的理解与现代人一样，是建立、开始的意思。但“冬”字就不那么简单了，在古籍《月令七十二候集解》中对“冬”的解释是：“冬，终也，万物收藏也”，意思是说秋季作物全部收晒完毕，收藏入库，动物也已藏起来准备冬眠。看来，立冬不仅仅代表着冬天的来临，完整地说，立冬是表示冬季开始，万物收藏，归避寒冷的意思。左河水《立冬》诗云：“北风往复几寒凉，疏木摇空半绿黄。四野修堤防旱涝，万家晒物备收藏。”"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "迎冬",
                    "",
                    "立冬与立春、立夏、立秋合称四立、古代社会中是个重要的节日。过去是个农耕社会，劳动了一年，利用立冬这一天要休息，顺便犒赏一家人的辛苦。",
                    "",
                    "贺冬",
                    "",
                    "贺冬亦称“拜冬”，在汉代即有此俗。东汉崔定《四民月令》：“冬至之日进酒肴，贺谒君师耆老，一如正日。”宋代每逢此日，人们更换新衣，庆贺往来，一如年节。清代“至日为冬至朝，士大夫家拜贺尊长，又交相出谒。细民男女，亦必更鲜衣以相揖，谓之“拜冬。”(见顾禄《清嘉录》卷十一)。民国以来，贺冬的传统风俗，似有简化的趋势。但有些活动，逐渐固定化、程式化、更有普遍性。如办冬学、拜师活动，都在冬季举行。",
                    "",
                    "食俗",
                    "",
                    "立冬则有吃水饺的风俗。立冬时，包饺子，味道既同大白菜有异，还要蘸醋加烂蒜吃，才算别有一番滋味。",
                    "",
                    "立冬为什么吃饺子？因我国以农立国，很重视二十四节气，“节”者，草木新的生长点也。秋收冬藏，这一天，改善一下生活，就选择了“好吃不过饺子”。所以《礼记》中有“食瓜亦祭先也”的说法。冬至，中国古有“冬至节”的风俗。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "小雪",
        "short_desc": "北风呼啸，气温骤降，雪花易现",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节气时间",
                        "value": "每年11月22日或23日"
                    },
                    {
                        "title": "代表寓意",
                        "value": "开始下雪"
                    },
                    {
                        "title": "气候特点",
                        "value": "冷空气南下，气温下降"
                    },
                    {
                        "title": "节气养生",
                        "value": "合理饮食、温补益肾"
                    },
                    {
                        "title": "节气三候",
                        "value": "虹藏不见；天气上升；闭塞成冬"
                    }
                ],
                "detail": [
                    "小雪，是二十四节气中的第20个。11月22或23日，太阳到达黄经240°，此时称为小雪节气。进入该节气，中国广大地区西北风开始成为常客，气温下降，逐渐降到0℃以下，但大地尚未过于寒冷，虽开始降雪，但雪量不大，故称小雪。"
                ]
            },
            {
                "title": "养生",
                "detail": [
                    "气虚者的补法",
                    "",
                    "所谓气虚即气不够用，动则气喘、体倦、懒言、常自汗出、面色眺白、舌淡白、脉虚弱无力。气虚之人可选用人参进补。人参性温、味甘微苦，可大补元气，是补气要药，《本经》谓“明目开心益智，久服轻身延年”，近代研究证明，人参可调节网状皮肤系统功能，其所含人参皂甙。确实具有抗衰老作用。使用时。可用人参一味煎汤，名独参汤，具有益气固脱之功效，年老体弱之人，长服此汤，可强身体。人参若切成饮片，可补益身体，防御疾病，增强肌体抗病能力。",
                    "",
                    "血虚者的补法",
                    "",
                    "所谓血虚，即是营养人体的物质不足，不能发挥濡养人体的作用，表现为不耐劳作、面色无华、苍白、且易健忘、失眠、舌淡、脉细。血虚体质者当选龙眼肉进补。"
                ]
            },
            {
                "title": "饮食",
                "detail": [
                    "一、滋补益肾多吃黑",
                    "小雪节气应当遵循“秋冬养阴”“无扰乎阳”的原则，既不宜生冷，也不宜燥热，最宜食用滋阴潜阳、热量较高的食物，如腰果、山药、芡实、核桃、花生等。\n同时，还要注意多吃富含维生素C的蔬果，如胡萝卜、辣椒、土豆等蔬菜以及苹果等水果。\n另外，冬宜补肾，黑色食物入肾补肾，所以小雪时节，也要多吃黑色的益肾食物，如黑米、紫米、黑豆、黑芝麻、黑木耳、黑枣等，不仅可以补养肾气，还可以抵抗寒冷、润肺生津，具有很好的保健功能。",
                    "二、多食粥汤 多饮热水",
                    "冬天多食热粥有益健康，如有养心除烦作用的小麦粥、消食化痰的萝卜粥、益气养阴的大枣粥等。\n鸡汤有很好的补虚功效，冬季多喝有助于提高人体免疫力。鱼汤则有健脾开胃、止咳平喘等功能。\n多饮水可以促进新陈代谢。最好在每天清晨空腹喝一杯温开水，还能降低血液黏度，预防心脑血管疾病。蜂蜜水可润燥解毒，白萝卜水能清热利尿，梨水可以润肺止咳，都是这个时节不错的选择。",
                    "三、少咸多苦",
                    "多吃苦味食物防“上火”。小雪时节，外面寒冷，屋内燥热，再加上人们穿得多，很容易出现口干舌燥、口腔溃疡、皮肤干燥的症状，也就是民间所谓的“上火”了。\n为了防止“上火”，少吃辛辣食物，多喝水，戒烟戒酒，适当多吃些芹菜、莴笋、生菜、苦菊等苦味食物。"
                ]
            },
            {
                "title": "气候",
                "detail": [
                    "小雪阶段比入冬阶段气温低。 到了小雪节气，意味着我国华北地区将有降雪。冷空气使我国北方大部地区气温逐步达到0℃以下。",
                    "",
                    "黄河中下游平均初雪期基本与小雪节令一致。虽然开始下雪，一般雪量较小，并且夜冻昼化。如果冷空气势力较强，暖湿气流又比较活跃的话，也有可能下大雪；南方地区北部开始进入冬季。“荷尽已无擎雨盖，菊残犹有傲霜枝”，已呈初冬景象。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "腌腊肉",
                    "",
                    "民间有：“冬腊风腌，蓄以御冬”的习俗。小雪后气温急剧下降，天气变得干燥，是加工腊肉的好时候。小雪节气后，一些农家开始动手做香肠、腊肉，把多余的肉类用传统方法储备起来，等到春节时正好享受美食。",
                    "",
                    "吃糍粑",
                    "",
                    "在南方某些地方，还有农历十月吃糍粑的习俗。糍粑是用糯米蒸熟捣烂后所制成的一种食品，是中国南方一些地区流行的美食。古时，糍粑是南方地区传统的节日祭品，最早是农民用来祭牛神的供品。有俗语“十月朝，糍粑禄禄烧”，就是指的祭祀事件。",
                    "",
                    "晒鱼干",
                    "",
                    "小雪时台湾中南部海边的渔民们会开始晒鱼干、储存乾粮。乌鱼群会在小雪前后来到台湾海峡，另外还有旗鱼、沙鱼等。台湾俗谚：十月豆，肥到不见头，是指在嘉义县布袋一带，到了农历十月可以捕到“豆仔鱼”。",
                    "",
                    "吃刨汤",
                    "",
                    "小雪前后，土家族群众又开始了一年一度的“杀年猪，迎新年”民俗活动，给寒冷的冬天增添了热烈的气氛。吃“刨汤”，是土家族的风俗习惯；在“杀年猪，迎新年”民俗活动中，用热气尚存的上等新鲜猪肉，精心烹饪而成的美食称为“刨汤”。",
                    "",
                    "今年农历小雪之际，湘西土家族苗族自治州龙山县石牌镇的土家族农民，用刚刚杀年猪的新鲜猪肉，在家中烹饪土家族传统美食“刨汤”，准备用来款待亲朋好友。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "大雪",
        "short_desc": "大雪至，雪满天，防寒防滑最重要",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节气时间",
                        "value": "每年12月7日或8日"
                    },
                    {
                        "title": "代表寓意",
                        "value": "降雪量增多，地面可能积雪"
                    },
                    {
                        "title": "气候特点",
                        "value": "气温将显著下降，降水量增多"
                    },
                    {
                        "title": "节气养生",
                        "value": "防寒保暖"
                    },
                    {
                        "title": "风俗活动",
                        "value": "观赏封河、腌制“咸货”"
                    }
                ],
                "detail": [
                    "“大雪”是农历二十四节气中的第21个节气，更是冬季的第三个节气，标志着仲冬时节的正式开始。《月令七十二候集解》说：“大雪，十一月节，至此而雪盛也。”大雪的意思是天气更冷，降雪的可能性比小雪时更大了，并不指降雪量一定很大。"
                ]
            },
            {
                "title": "养生",
                "detail": [
                    "大雪时节，万物潜藏，养生也要顺应自然规律，在“藏”字上下功夫。起居调养宜早眠早起，并要收敛神气，特别在南方要保持肺气清肃不受秋燥的损害。此外，衣服要随着温度的降低而增加，宜保暖贴身。夜晚温度会更低，要加多衣被，使四肢暖和，气血流畅，这样则可以避免许多疾病的发生，如感冒、支气管炎、支气管哮喘、脑血栓形成等。",
                    "另外，大雪时节天气寒冷，要预防感冒：",
                    "1、冷水浴面。每天洗脸时要用冷水，用手掬一捧水洗鼻孔，即用鼻孔轻轻吸入少许水（注意勿吸入过深以免呛着）再擤出，反复多次。",
                    "2、盐水漱口。每日早晚、餐后用淡盐水漱口，以清除口腔病菌。在流感流行的时候更应注意盐水漱口，此时，仰头含漱使盐水充分冲洗咽部效果更佳。",
                    "3、热水泡脚。每晚用较热的水（温度以热到几乎不能忍受为止）泡脚15分钟，要注意泡脚时水量要没过脚面，泡后双脚要发红，可预防感冒。",
                    "4、生吃大葱。生吃大葱时，可将油烧热浇在切细的葱丝上，再与豆腐等凉拌吃，不仅可口，而且可以预防感冒。",
                    "5、按摩鼻沟。两手对搓，掌心热后按摩迎香穴（位于鼻沟内、横平鼻外缘中点）十余次，可以预防感冒及在感冒后减轻鼻塞症状。",
                    "6、勤洗手。有些病毒可以在患者手摸过的地方存活3小时，因此，经常洗手的人能远离感冒。另外，不要养成揉鼻子、抠鼻孔的坏习惯，这样很容易把手上的病毒带到最易被传染的部位。",
                    "7、多喝水。大量的水可以将病毒从身体中带走，防止脱水症（脱水症是感冒的并发症之一）。",
                    "8、有氧运动。每天进行30-45分钟的有氧锻炼，如散步、骑车、跳舞等，可极大增强人体抵御感冒的能力，避免患上呼吸道传染病。"
                ]
            },
            {
                "title": "饮食",
                "detail": [
                    "大雪是二十四节气之一，标志着仲冬时节的开启，大雪节气过后，天气更冷，降雪的可能性更大，强冷空气来临时，冷暖空气交锋的地区，甚至可能发生暴雪。在这寒冷的天气，人们需要更多的热量来抵御寒冷，所以民间又有“小雪腌菜，大雪腌肉”，“冬天进补，开春打虎”的说法。那么大雪进补吃什么好？",
                    "1、牛羊肉\n牛肉与羊肉都属于红肉，且中医认为二者性温，有滋补养生的功效。牛羊肉中都含有丰富的蛋白质，能帮助增强人体的免疫力，牛羊肉很有丰富的矿物质，特别是铁元素，能帮助人体补气养血，驱寒暖身。",
                    "2、食用菌\n香菇等食用菌中含有大量的蛋白质，维生素与矿物质，食用菌自古就是珍贵的保健食品，现代科学证明，食用菌中的香菇多糖，核酸、香菇嘌呤、维生素及矿物质锌、硒等成分能够抑制人体对胆固醇的吸收和合成，防止胆固醇沉积在血管壁，保持血管年轻有弹性。",
                    "3、白萝卜\n白萝卜味辛，甘，性凉，有清热生津、凉血止血、消食化滞的功效。冬季吃多了油腻食物，也需要多吃些白萝卜，来帮助肠道蠕动，同时也能缓解干燥内热引起的咽干，咽痛，咳嗽等症。",
                    "4、藕\n藕含有丰富的蛋白质、维生素、膳食纤维、淀粉、糖类、多酚类物质、钙、铁、磷等营养成分，生吃生津清热、止血凉血，熟吃健脾开胃、止泻固精、强身补虚，是冬季进补蔬菜之一，也是火锅的首选配菜之一。",
                    "5、山药\n山药享有“神仙之食”的美誉，营养丰富，同时还具有丰富的药用价值。人类所需的18种氨基酸中，山药中含有16种。山药可以补虚健脾胃，山药对食少体倦的病人有很好的补益功效。",
                    "6、橙\n橙中含有多种有机酸，能帮助人体代谢，有利于氨基酸的吸收，帮助增强新陈代谢，橙中还含有丰富的维生素，能帮助提高免疫力，预防感冒，同时橙的香味还能帮助缓解紧张情绪，舒缓神经。"
                ]
            },
            {
                "title": "节气由来",
                "detail": [
                    "大雪节气，太阳黄经达255度。大雪，顾名思义，雪量大。古人云：“大者，盛也，至此而雪盛也”。到了这个时段，雪往往下得大、范围也广。这时我国大部分地区的最低温度都降到了0℃或以下。往往在强冷空气前沿冷暖空气交锋的地区，会降大雪，甚至暴雪。可见，大雪节气是表示这一时期，降大雪的起始时间和雪量程度，它和小雪、雨水、谷雨等节气一样，都是直接反映降水的节气。",
                    "",
                    "大雪时节分为三候：“一候鹖鴠不鸣；二候虎始交；三候荔挺出。”是说此时因天气寒冷，寒号鸟也不再鸣叫了；此时是阴气最盛时期，所谓盛极而衰，阳气已有所萌动，老虎开始有求偶行为；“荔挺”为兰草的一种，感到阳气的萌动而抽出新芽。",
                    "",
                    "常说，“瑞雪兆丰年”。严冬积雪覆盖大地，保持地面及作物周围的温度不会因寒流侵袭而降得很低，冬作物创造了良好的越冬环境。积雪融化时又增加了土壤水分含量，供作物春季生长的需要。雪水中氮化物的含量是普通雨水的5倍，有一定的肥田作用。有“今年麦盖三层被，来年枕着馒头睡”的农谚。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "腌肉",
                    "",
                    "老南京有句俗语，叫做“小雪腌菜，大雪腌肉”。大雪节气一到，家家户户忙着腌制“咸货”。将大盐加八角、桂皮、花椒、白糖等入锅炒熟，待炒过的花椒盐凉透后，涂抹在鱼、肉和光禽内外，反复揉搓，直到肉色由鲜转暗，表面有液体渗出时，再把肉连剩下的盐放进缸内，用石头压住，放在阴凉背光的地方，半月后取出，将腌出的卤汁入锅加水烧开，撇去浮沫，放入晾干的禽畜肉，一层层码在缸内，倒入盐卤，再压上大石头，十日后取出，挂在朝阳的屋檐下晾晒干，以迎接新年。",
                    "",
                    "观赏封河",
                    "",
                    "“小雪封地，大雪封河”，北方有“千里冰封，万里雪飘”的自然景观，南方也有“雪花飞舞，漫天银色”的迷人图画。到了大雪节气，河里的冰都冻住了，人们可以尽情地滑冰嬉戏。",
                    "",
                    "进补",
                    "",
                    "大雪是“进补”的好时节，素有“冬天进补，开春打虎”的说法。冬令进补能提高人体的免疫功能，促进新陈代谢，使畏寒的现象得到改善。冬令进补还能调节体内的物质代谢，使营养物质转化的能量最大限度地贮存于体内，有助于体内阳气的升发，俗话说“三九补一冬，来年无病痛”。此时宜温补助阳、补肾壮骨、养阴益精。冬季食补应供给富含蛋白质、维生素和易于消化的食物。大雪节气前后，柑桔类水果大量上市，像南丰蜜桔、官西柚子、脐橙雪橙都是的当家水果。适当吃一些可以防治鼻炎，消痰止咳。大雪时北半球各地日短夜长，因而有农谚“大雪小雪、煮饭不息”等说法，用以形容白昼短到了农妇们几乎要连着做三顿饭的程度。可常喝喝姜枣汤抗寒；吃桔子、用薄荷油防治鼻炎，消痰止咳。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "冬至",
        "short_desc": "凛冽严冬，防寒保暖吃饺子",
        "area": 0,
        "type": 0,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "别称",
                        "value": "冬节、长至节、亚岁"
                    },
                    {
                        "title": "节气时间",
                        "value": "每年12月21日-23日"
                    },
                    {
                        "title": "代表寓意",
                        "value": "寒冷的冬天来临"
                    },
                    {
                        "title": "气候特点",
                        "value": "冷空气活动频繁"
                    },
                    {
                        "title": "节气养生",
                        "value": "防寒保暖，调整心态"
                    },
                    {
                        "title": "节气三候",
                        "value": "蚯蚓结；麋角解；水泉动"
                    },
                    {
                        "title": "风俗食物",
                        "value": "吃水饺、汤圆、喝羊肉汤"
                    }
                ],
                "detail": [
                    "古人对冬至的说法是：阴极之至，阳气始生，日南至，日短之至，日影长之至，故曰“冬至”。早在二千五百多年前的春秋时代，中国就已经用土圭观测太阳，测定出了冬至"
                ]
            },
            {
                "title": "养生",
                "detail": [
                    "从中医阴阳理论说，冬至是阴气盛极而转衰，阳气开始萌芽的时候，阴阳转化，在运动中构成了自然界与人体的平衡与和谐，养生也要顺应此规律。",
                    "1、饮食补充津液\n冬至日不可吃太过辛辣刺激的食品，过食辛辣只可生阳动火，导致内热积聚，郁热上冲。过食羊肉、狗肉等肥甘厚味，易导致饮食不化，聚湿生痰。北方在补充热性食物的同时，也得吃一些补充津液的食物，如荸荠、藕、梨、萝卜、白菜等。",
                    "2、养心宽厚畅达\n养生也要重点养心，要养生先养善良、宽厚之心，心底宽自无忧。保持精神畅达乐观，不为琐事劳神，不要强求名利、患得患失。儿女要对老人嘘寒问暖，使老年人在精神上得到保养。",
                    "3、保暖从脚开始\n冬至阴寒之气隆盛，成为“进九”之始。在冬至养生中我们首先要注意的就是避寒邪，我国大部分地区习惯自冬至起“数九”。所以冬至养生首先要多着衣、避寒邪，特别是要注重足部的保暖。",
                    "4、运动动中求静\n冬至起，自然界的阳气开始上升，我们体内的阳气如何升发呢?“动则生阳、静则生阴”。通过合理的运动就可以帮助我们升发体内的阳气，但是过度的升发阳气就会耗散真元反而不合冬季蛰藏的养生道理了。冬季运动，首先要避免寒邪的侵袭，其次运动量不宜过大，要在动中求静，以臻安身静体、气定神闲的境界。八段锦、太极拳等平和的运动都是很好的选择。"
                ]
            },
            {
                "title": "饮食",
                "detail": [
                    "俗话说，吃了冬至饭，一天长一线。过了冬至后白天的时间越来越长，即“冬至阳生”。此时人体内阳气蓬勃生发，最易吸收外来的营养而发挥其滋补功效。也就是说，冬至前后是进补的最好时机。但是，并非随意乱补就能达到效果，冬季进补也需要一定的方法。",
                    "一忌盲目食狗肉。一些体质虚弱和患有关节炎等病的人，在严冬季节，多吃些狗肉是有好处的。但不宜盲目食狗肉，以免食用狂犬肉，染上狂犬病。吃狗肉后不要喝茶，这是因为茶叶中的鞣酸与狗肉中的蛋白质结合，会生成一种物质。这种物质具有一定的收敛作用，可使肠蠕动减弱，大便里的水分减少。因此，大便中的有毒物质和致癌物质，就会因在肠内停留时间过长而极易被人体吸收。",
                    "二忌虚实不分。中医的治疗原则是“虚者补之”。虚则补，不虚则正常饮食就可以了，同时应当分清补品的性能和适用范围，是否适合自己。专家认为，进补主要作用是“补虚益损”，而虚又分气虚、血虚、阴虚和阳虚四种，各有各不同的补法。",
                    "三忌慕名进补。鸡汤不是所有的人都能喝的，鸡汤(包括炖鸡汤和下药材熬的鸡汤)营养丰富，鸡汤所含的营养物质是从鸡油、鸡皮、鸡肉和鸡骨溶解出的少量水溶性小分子，其蛋白质仅为鸡肉的7%左右，而汤里的鸡油大都属于饱和脂肪酸。因为鸡汤中这一特有的营养成分和刺激作用，胆道疾病患者胆囊炎和胆结石症经常发作者、肾功能不全者等病人不宜喝鸡汤。",
                    "四忌无病进补。无病进补，既增加开支，又会伤害身体，如服用鱼肝油过量可引起中毒，长期服用葡萄糖会引起发胖。另外，补药也不能多多益善，任何补药服用过量都有害。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "冬至是24节气中最早被制订的一个，然而多数人并不知道，冬至的起源居然是来自于一次国家层面的都城规划。早在3000多年前，周公始用土圭法测影，在洛邑测得天下之中的位置，定此为土中，这在当时有着政治意义的举动，却成了影响后世几千年的节日之一。",
                    "",
                    "由周到秦，以冬至日当作岁首一直不变。至汉代依然如此，《汉书》有云：“冬至阳气起，君道长，故贺……”也就是说，人们最初过冬至节是为了庆祝新的一年的到来。古人认为自冬至起，天地阳气开始兴作渐强，代表下一个循环开始，是大吉之日。因此，后来一般春节期间的祭祖、家庭聚餐等习俗，也往往出现在冬至。冬至又被称为“小年”，一是说明年关将近，余日不多；二是表示冬至的重要性。把冬至作为节日来过源于周代，盛于唐宋，并相沿至今。周历的正月为夏历的十一月，因此，周代的正月等于如今的十一月，所以拜岁和贺冬并没有分别。直到汉武帝采用夏历后，才把正月和冬至分开。因此，也可以说专门过“冬至节”是自汉代以后才有，盛于唐宋，相沿至今。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "冬至是养生的大好时机，主要是因为“气始于冬至”。因为从冬季开始，生命活动开始由衰转盛，由静转动。此时科学养生有助于保证旺盛的精力而防早衰，达到延年益寿的目的。冬至时节饮食宜多样，谷、果、肉、蔬合理搭配，适当选用高钙食品。",
                    "各地在冬至时有不同的风俗，中国北方多数地方有冬至吃饺子的习俗。冬至经过数千年发展，形成了独特的节令食文化。吃饺子成为多数中国人冬至的风俗。当然也有例外如在山东省滕州市流行冬至当天喝羊肉汤的习俗，寓意驱除寒冷之意。",
                    "",
                    "北方冬至吃水饺：每年农历冬至这天，不论贫富，饺子是必不可少的节日饭。谚云：“十月一，冬至到，家家户户吃水饺。”",
                    "",
                    "滕州羊肉汤：冬至吃羊肉的习俗据说是从汉代开始的。相传，汉高祖刘邦在冬至这一天吃了樊哙煮的羊肉，觉得味道特别鲜美，赞不绝口。从此在民间形成了冬至吃羊肉的习俗。",
                    "",
                    "江南米饭：在江南水乡，有冬至之夜全家欢聚一堂共吃赤豆糯米饭的习俗。相传，共工氏有不才子，作恶多端，死于冬至这一天，死后变成疫鬼，继续残害百姓。但是，这个疫鬼最怕赤豆，于是，人们就在冬至这一天煮吃赤豆饭，用以驱避疫鬼，防灾祛病。",
                    "",
                    "还有番薯汤果、台湾糯糕、苏州酿酒等等。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "小寒",
        "short_desc": "小寒至，围炉取暖忆团圆",
        "area": 0,
        "type": 1,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节气时间",
                        "value": "每年1月5日或6日"
                    },
                    {
                        "title": "代表寓意",
                        "value": "开始进入一年中最寒冷的日子"
                    },
                    {
                        "title": "气候特点",
                        "value": "常有寒潮暴发，会带来剧烈降温"
                    },
                    {
                        "title": "节气养生",
                        "value": "多食用一些温热食物"
                    },
                    {
                        "title": "节气三候",
                        "value": "雁北乡；鹊始巢；雉始雊"
                    }
                ],
                "detail": [
                    "小寒，为农历二十四节气中的第23个节气，也是冬季的第五个节气，标志着季冬时节的正式开始。当太阳到达黄经285°(小寒)时，对于神州大地而言，标志着一年中最寒冷的日子到来了。根据中国的气象资料，小寒是气温最低的节气，只有少数年份的大寒气温低于小寒的。"
                ]
            },
            {
                "title": "养生",
                "detail": [
                    "小寒养生，应遵循以下原则：",
                    "原则一：小寒养生要以养肾为先",
                    "1、按摩养肾疗法\n按摩疗法是冬季养肾一种很不错的有效方法。推荐两种按摩养肾的疗法：一是搓擦腰眼。两手搓热后紧按腰部，用力搓30次。所谓“腰为肾之府”，搓擦腰眼可疏通筋脉，增强肾脏功能。二是揉按丹田。两手搓热，在用部丹田按摩30-50次。常用这种方法，可增强人体的免疫功能，起到强肾固本，延年益寿的作用。",
                    "2、冬季养肾要注意起居\n冬季养生养肾在起居上宜坚持早睡晚起.避寒保暖，空气新鲜。因为冬季昼短夜长，人们要尽量早睡早起，穿贴身柔和的衣物，多呼吸新鲜空气，多晒晒太阳，以保持人体的体温、体力及正气。",
                    "3、药食同源补肾\n传统中医认为，“药食同源”，我们平时所食用的食物有很多实际上也是中药，比如：山药、枸杞、核桃、芝麻等，平时也可以多食一些炖羊肉、鸡汤、鸭肉等一类温肾补肾的食物。中医还认为五谷可以补肾，历代养生家一直提倡健康的饮食，需要以“五谷为充，五果为养”，也就是说人每天必须摄入一定量的主食和蔬菜水果。主食摄入不足，容易导致气血亏虚，肾气不足；五果含有丰富的维生素、无机盐和纤维素，因可生吃，可获得更多的营养成分，所以经常食用，可辅助五谷使人体获得更全面的营养。因此，平时适当摄入一些益肾、养血的食物，对处于亚健康状态下的人们是大有裨益的。",
                    "原则二：饮食保养 滋补防寒",
                    "冬季天气寒冷，此时也正是阴邪最盛的时候，也就是中医上所谓的阴邪。从饮食养生的角度讲，要特别注意在日常饮食中多食用一些温热食物以补益身体，防御寒冷气候对人体的侵袭。常用补药有人参、黄芪、阿胶、冬虫夏草、首乌、枸杞、当归等；食补要根据阴阳气血的偏盛偏衰，结合食物之性来选择羊肉、狗肉、猪肉、鸡肉、鸭肉、鳝鱼、甲鱼、鲅鱼和海虾等，其他食物如核桃仁、大枣、龙眼肉、芝麻、山药、莲子、百合、栗子等。以上食物均有补脾胃、温肾阳、健脾化痰、止咳补肺的功效。当然对体质偏热、偏实、易上火者应注意缓补、少食为好。",
                    "原则三：起居上要注意保暖",
                    "小寒是一年中最冷的节气之一，此时在起居上一定要注意保暖。\n中医认为“寒性凝滞，寒性收引”，天气寒冷，则关节痛、颈椎病甚至是心脑血管疾病都容易发病。保暖是第一要务，尤其是对肩颈部、脚部等易受凉的部位要倍加呵护。对于老人家，则在保暖的同时还要注意通风，密切防范心脑血管疾病的发生。",
                    "原则四：适当锻炼，强身健体防病",
                    "民谚曰：“冬天动一动，少闹一场病；冬到懒一懒，多喝药一碗。”这说明了冬季锻炼的重要性。在这干冷的日子里，宜多进行户外的运动，如晨早的慢跑、跳绳、踢毽等。还要在精神上宜静神少虑、畅达乐观，不为琐事劳神，心态平和，增添乐趣。在此节气里，患心脏病和高血压病的人往往会病情加重，患“中风”者增多。中医认为，人体内的血液，得温则易于流动，得寒就容易停滞，所谓“血遇寒则凝”，说的就是这个道理。所以保暖工作一定要做好，尤其是老年人。"
                ]
            },
            {
                "title": "饮食",
                "detail": [
                    "小寒应减甘增苦：“三九补一冬”，小寒时切记不可大补。在饮食上可多吃羊肉、牛肉、芝麻、核桃、杏仁、瓜子、花生、棒子、松子、葡萄干等，也可结合药膳进行调补。小寒因处隆冬，土气旺，肾气弱，因此，饮食方面宜减甘增苦，补心助肺，调理肾脏。",
                    "小寒吃粥喝汤保健康：首乌煲鸡蛋，健脾益肾，益气养血；太子参炖鹌鹑，补五脏、益中气；山药羊肉汤，补脾益肾，温中暖下；远志枣仁粥：补益肝肾，养血安神；羊肾红粥，益气壮阳、填精补髓。适用于虚弱无力、腰膝酸软、畏寒怕冷、耳聋耳鸣、性功能减退等肾阳不足的人群。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "为什么叫小寒而不叫大寒呢？这是因为节气起源于黄河流域。《月令七十二候集解》中说“月初寒尚小……月半则大矣”，就是说，在黄河流域，当时大寒是比小寒冷的。又由于小寒还处于“二九”的最后几天里，小寒过几天后，才进入“三九”，并且冬季的小寒正好与夏季的小暑相对应，所以称为小寒。位于小寒节气之后的大寒，处于“四九夜眠如露宿”的“四九”也是很冷的，并且冬季的大寒恰好与夏季的大暑相对应，所以成为大寒。",
                    "小寒中的三候，其物候反映分别是：“一候雁北乡；二候鹊始巢；三候雉始”。在候鸟中，一候，阳气已动，大雁开始向北迁移，但还不是迁移到我国的最北方，只是离开了南方最热的地方；二候，喜鹊此时感觉到阳气而开始筑巢；到了三候，野鸡也感到了阳气的滋长而鸣叫。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "生活上，除注意日常保暖外，进入小寒年味渐浓，人们开始忙着写春联、剪窗花，赶集买年画、彩灯、鞭炮、香火等，陆续为春节作准备。饮食上，涮羊肉火锅、吃糖炒栗子、烤白薯成为小寒时尚。俗语说“三九补一冬，来年无病痛”，说的就是冬令食羊肉调养身体的做法。古时，如皋人对小寒颇重视，但随着时代变迁，已渐渐淡化，人们只能从生活中寻找出点点痕迹。",
                    "居民日常饮食也偏重于暖性食物，如羊肉、狗肉，其中又以羊肉汤最为常见，有的餐馆还推出当归生姜羊肉汤，一些传统的冬令羊肉菜肴重现餐桌，再现了如皋寒冬食俗。",
                    "俗话说，“小寒大寒，冷成冰团”。这个节气年轻人注意不要因过食肥甘厚味、辛辣之品而长出痤疮。"
                ]
            }
        ]
    },
    {
        "id": 1,
        "disp_name": "大寒",
        "short_desc": "大寒至，天气转凉，添件衣裳",
        "area": 0,
        "type": 1,
        "cards": [{
                "title": "简介",
                "tags": [{
                        "title": "节气时间",
                        "value": "每年1月19—21日"
                    },
                    {
                        "title": "代表寓意",
                        "value": "一年中最冷的时期到来"
                    },
                    {
                        "title": "气候特点",
                        "value": "冷空气刺骨，气候相当寒冷"
                    },
                    {
                        "title": "节气养生",
                        "value": "神志深藏于内，避免急躁发怒"
                    },
                    {
                        "title": "节气三候",
                        "value": "鸡乳；征鸟厉疾；水泽腹坚"
                    }
                ],
                "detail": [
                    "我国古代将大寒分为三候：“一候鸡乳；二候征鸟厉疾；三候水泽腹坚。”就是说到大寒节气便可以孵小鸡了；而鹰隼之类的征鸟，却正处于捕食能力极强的状态中，盘旋于空中到处寻找食物，以补充身体的能量抵御严寒；在一年的最后五天内，水域中的冰一直冻到水中央，且最结实、最厚"
                ]
            },
            {
                "title": "养生",
                "detail": [
                    "1、起居注意护足\n天气寒冷，要顺应冬季闭藏的特性，最好养成睡前泡脚的好习惯。俗话说“寒从脚起，冷从腿来”，人的腿脚一冷，全身皆冷。入睡前以热水泡脚，能使血管扩张，血流加快，改善脚部的皮肤和组织营养，降低肌张力，改善睡眠质量，对于预防冻脚和防病保健都有益处，特别是那些常在夜间看书写作、久坐到深夜的人更是如此。",
                    "2、合适温度湿度保健康\n冬季寒冷易使人患感冒、咳嗽等呼吸道疾病。而大寒时节的天气特点除了寒冷外，空气也比较干燥，白天的平均相对湿度一般低于50％，加之室内采暖，居室内的湿度常常只有30％左右。这种干燥的气候会加重呼吸道疾病的症状。所以，注意保暖的同时，也要关注身边的湿度。早晚要多开窗通气(因早晚室外湿度相对较高，雾霾天气就别开窗了，等到太阳出来空气好时再通风)，室内取暖时也要注意利用绿植或使用加湿器等方法增加空气湿度。",
                    "3、早睡晚起 注意保暖\n大寒养生要顺应冬季“藏”的原则，最简单的方法是早睡晚起，每天多睡1小时。早睡可以养人体的阳气，晚起可以养阴气，使精气内聚以润五脏，从而增强身体的免疫力。对于上班族特别提倡早睡1小时；老年人尤其要注意不宜过早起床，晨练要推迟一些，最好待日出后再出门。因为早晨寒气生发，有时还有雾气，极易寒邪侵入。",
                    "4、欲御寒首当养肾\n古人早就说过：“寒为阴邪，易伤阳气”。由于人身阳气根源于肾，所以寒邪最易中伤肾阳，容易发生腰膝冷痛，易感风寒等疾病；肾阳气虚又伤及肾阴，出现咽干口燥，头晕耳鸣等疾病。可见，数九严冬养肾十分重要。"
                ]
            },
            {
                "title": "饮食",
                "detail": [
                    "1、多食“三冬”\n大寒时节，天气更加寒冷，人体血管遇冷收缩后，容易导致血压升高。此时人们的饮食也偏于高热量、高脂肪，容易导致血糖和血脂偏高。这时，不妨多吃些“三冬”：冬瓜、冬枣、冬甘蔗。冬瓜的吃法很多，例如冬瓜烧肉、冬瓜扒虾、冬瓜虾皮粉丝汤等，老少皆宜。",
                    "2、进补宜“封藏”\n中医认为，冬季进补适宜“封藏”。进补时，要使肠胃有个适应过程，最好先做“引补”。一般来说，可选用红枣炖牛肉、花生仁加红糖，也可煮些生姜大枣牛肉汤来吃，以调整脾胃功能。为抵御严寒、补充元气，大寒时节尤其要少食生冷之物。进补的另一原则是多饮水，多吃些新鲜蔬菜水果，少吃辛辣等刺激性食物，少饮烈酒。营养摄入应以增加热能为主，可适当多摄入富含碳水化合物和脂肪的食物，如坚果、米面制品等。",
                    "3、食补温热为主\n大寒时节是调养的好时机，具体的进补的方法有食补与药补等。俗话说，药补不如食补。偏于阳虚的人食补以温热食物为宜，如羊肉、鸡肉等;偏于阴虚者以滋阴食物为宜，如鸭肉、龟、木耳等。药补要结合自己的体质和病状选择服用，如体质虚弱、气虚之人可服人参汤；阴虚者可服六味地黄丸等。能饮酒的人也可以结合药酒进补，常见的有十全大补酒、枸杞酒、虫草补酒等。"
                ]
            },
            {
                "title": "来历",
                "detail": [
                    "大寒是二十四节气中最后一个节气，每年1月20日前后太阳到达黄经300°时为“大寒”。大寒，是天气寒冷到极点的意思。《授时通考·天时》引《三礼义宗》：“大寒为中者，上形于小寒，故谓之大......寒气之逆极，故谓大寒。”这时寒潮南下频繁，是我国大部地区一年中的寒冷时期，风大，低温，地面积雪不化，呈现出冰天雪地、天寒地冻的严寒景象。大寒是中国二十四节气最后一个节气，过了大寒，又迎来新一年的节气轮回。"
                ]
            },
            {
                "title": "习俗",
                "detail": [
                    "按我国的风俗，特别是在农村，每到大寒节，人们便开始忙着除旧布新，腌制年肴，准备年货。在大寒至立春这段时间，有很多重要的民俗和节庆。如尾牙祭、祭灶和除夕等，有时甚至连我国最大的节庆春节也处于这一节气中。大寒节气中充满了喜悦与欢乐的气氛，是一个欢快轻松的节气。尾牙源自于拜土地公做“牙”的习俗。所谓二月二为头牙，以后每逢初二和十六都要做“牙”到了农历十二月十六日正好是尾牙。尾牙同二月二一样有春饼(南方叫润饼)吃，这一天买卖人要设宴，白斩鸡为宴席上不可缺的一道菜。据说鸡头朝谁，就表示老板第二年要解雇谁。因此有些老板一般将鸡头朝向自己，以使员工们能放心地享用佳肴，回家后也能过个安稳年。"
                ]
            }
        ]
    }
]

export default {
    getFestival(key) {
        let festival = festival_col.find(item => item.disp_name === key);
        if (festival) {
            for (let i = 0; i < festival.cards.length; i++) {
                festival.cards[i].show = false;
            }
        }
        return festival;
    },
    getFestivalNameArr() {
        return festival_col.map(item => item.disp_name)
    }
}