<template>
  <div data-own="1" class="tradition">
    <!-- 背景图 -->
    <div class="traditionClass" v-show="show_background"></div>
    <!-- 节气内容 -->
    <div id="container" class="container">
      <!-- 顶部banner -->
      <div
        ref="banner"
        class="banner"
        :style="{ height: bannerH + 'px', backgroundImage: background_image }"
      >
        <div class="banner-img"></div>
      </div>
      <!-- 节气title -->
      <div ref="title" class="header" :style="{ height: titleH + 'px' }">
        <div class="title" :class="{ s_title: festival.disp_name.length > 11 }">
          {{ festival.disp_name }}
        </div>
        <div class="time">{{ disTime }}</div>
      </div>
      <!-- 节气详情 -->
      <div
        class="content"
        v-for="(item, index) in festival.cards"
        :class="{ 'main-content': index == 0 }"
        :key="index"
      >
        <!-- 第一个模块隐藏title -->
        <div v-if="index !== 0" class="content-title">
          <div class="dec-icon"></div>
          <div>{{ item.title }}</div>
        </div>

        <div
          class="content-detail"
          :class="{ hide: !item.show }"
          @click="showMore(index, $event)"
        >
          <div v-if="item.tags && item.tags.length" class="content-list">
            <div class="content-item" v-for="(tag, i) in item.tags" :key="i">
              <span class="title">{{ tag.title }}：</span>
              <span class="desc">{{ tag.value }}</span>
            </div>
          </div>
          <div
            :ref="'brief' + index"
            class="content-detail-brief"
            :class="{ 'content-detail-all': item.show }"
          >
            <p v-for="(p, i) in item.detail" :key="i">
              <span v-if="p != ''">{{ p }}</span>
            </p>
          </div>
          <div v-if="!item.show" class="show-more">
            <div>查看更多</div>
            <div class="arrow"></div>
          </div>
        </div>
        <img
          v-if="index !== festival.cards.length - 1"
          class="line"
          src="../assets/images/festival/line.png"
        />
      </div>
    </div>

    <!-- 回到顶部 -->
    <div v-if="showTop" class="to-top" @click="toTop"></div>
  </div>
</template>

<script>
import festival_utils from "../lib/festival/festival.js";
import holidays from "../lib/festival/holiday-in-law.js";
import {
  getJieqiInfo,
  getFatherDay,
  getMotherDay,
  getThanksgivingDay,
  arrayNMonth,
  arrayNDate,
} from "../lib/suishen.wnl.js";
import calendar from "../lib/calendar.js";
import b_img from "../assets/images/festival/festival_details_bg.jpg";
import { isIOS } from "../lib/utils.js";
import moment from "moment";
import tStatistic from "tstatistic";
tStatistic &&
  tStatistic.init({
    app_key: 61452902,
  });
export default {
  name: "festival",
  data() {
    return {
      isIOS: isIOS(),
      festival: {},
      disTime: "",
      traditionImg: null,
      show_background: true,
      name: this.$route.query.name || "元旦",

      bannerW: 0,
      bannerH: 0,
      titleW: 0,
      titleH: 0,

      screenH: 0,
      showTop: false,

      // 当前节气索引
      cur_index: 0,

      // 回到顶部
      screenH: 800,

      // 节日节气时间轴数据
      festivalArr: [
        // {
        //     date: '1月1日',
        //     md: '0101',
        //     name: '元旦'
        // }
      ],

      today: moment().format("YYYYMMDD"),
    };
  },
  computed: {
    background_image() {
      let url = this.traditionImg ? this.traditionImg : b_img;
      return `url(${url})`;
    },
  },
  created() {
    document.title = "节日节气";

    this.timestr = this.$route.query.dis_time;
    this.channel = this.$route.query.channel;
    this.ver = this.$route.query.ver;

    try {
      tStatistic &&
        tStatistic.send({
          event: "page_view",
          md: 1,
          c_id: -1061,
          args: {
            from: this.$route.query.from,
          },
        });
    } catch (err) {
      console.log(err);
    }

    // 从广告位链接返回后，定位到离开时的节日
    if (localStorage.getItem("festival_name")) {
      this.name = localStorage.getItem("festival_name");
      localStorage.removeItem("festival_name");
    }
    this.initDays();
    this.init();
  },
  mounted() {
    this.bannerW = this.$refs.banner.offsetWidth;
    this.bannerH = this.bannerW * 0.758;

    this.titleW = this.$refs.title.offsetWidth;
    this.titleH = this.titleW * 0.222;

    // 节气详情的内容小于4行直接展示
    this.festival.cards.forEach((card, index) => {
      let h = this.$refs["brief" + index][0].offsetHeight;
      console.log("h", h);
      //   if (h < 124) this.showMore(index);
      //   this.showMore(index);
    });

    this.screenH = window.screen.height;
    window.onscroll = (e) => {
      let y = e.path[1].scrollY;
      if (y > this.screenH) {
        this.showTop = true;
      } else {
        this.showTop = false;
      }
    };
  },
  methods: {
    initDays() {
      // 展示规则:对于今年已经过去的节日节气, 滑动展示为明年对应的日期, 对于今年还没有到来的节日, 正常展示今年对应的日期
      // 节气数组
      let jieqiArr = getJieqiInfo(new Date().getFullYear()); // 今年的节气
      let jieqiArr2 = getJieqiInfo(new Date().getFullYear() + 1); // 明年的节气
      let jieqiList = [];
      for (let i = 0; i < jieqiArr.length; i++) {
        let date = jieqiArr[i];
        if (moment(date.date).format("YYYYMMDD") < this.today) {
          date = jieqiArr2[i]; // 今年已经过去的节气,就选取明年该节气对应的月日
        }

        let m = date.date.split("-")[1];
        let d = date.date.split("-")[2];
        if (m.length === 1) m = "0" + m;
        if (d.length === 1) d = "0" + d;
        jieqiList.push({
          date: date.time.substr(5),
          md: m + d,
          name: date.title,
        });

        // 中国农民丰收节定在每年的秋分
        if (date.title === "秋分") {
          jieqiList.push({
            date: date.time.substr(5),
            md: m + d,
            name: "中国农民丰收节",
          });
        }

        // 清明节和清明是同一天
        if (date.title === "清明") {
          jieqiList.push({
            date: date.time.substr(5),
            md: m + d,
            name: "清明节",
          });
        }
      }
      console.log(jieqiList);

      // 节日数组
      let festivalsArr = [
        ...holidays.festivals.cn,
        // ...holidays.festivals.hk,
        // ...holidays.festivals.ma,
        ...holidays.festivals.pub,
        // ...holidays.festivals.tw,
      ];
      let holidayList = festivalsArr.map((item) => {
        // 公历节日
        if (item.gl === 1) {
          let m = item.m.toString();
          let d = item.d.toString();
          if (m.length === 1) m = "0" + m;
          if (d.length === 1) d = "0" + d;
          return {
            date: Number(m) + "月" + Number(d) + "日",
            md: m + d,
            name: item.n,
          };
        }
        // 农历节日
        else if (item.gl === 0) {
          let nongliDay = calendar.lunar2solar(
            new Date().getFullYear(),
            item.m,
            item.d
          );

          // 判断今年腊月是否有30天
          if (nongliDay === -1 && item.m === 12 && item.d === 30) {
            nongliDay = calendar.lunar2solar(
              new Date().getFullYear(),
              item.m,
              item.d - 1
            );
          }

          let date = moment(
            `${nongliDay.cYear}-${nongliDay.cMonth}-${nongliDay.cDay}`
          ).format("YYYYMMDD");

          // 今年已经过去的农历节日,取明年该节日对应的日期展示
          if (date < this.today) {
            nongliDay = calendar.lunar2solar(
              new Date().getFullYear() + 1,
              item.m,
              item.d
            );
          }

          let m = nongliDay.cMonth.toString();
          let d = nongliDay.cDay.toString();
          if (m.length === 1) m = "0" + m;
          if (d.length === 1) d = "0" + d;
          return {
            date:
              arrayNMonth[nongliDay.lMonth - 1] +
              arrayNDate[nongliDay.lDay - 1], // 展示农历
            // date: Number(m) + "月" + Number(d) + "日", // 展示农历该年对应的公历日期
            md: m + d,
            name: item.n,
          };
        }
        // 不固定的公历节日,如父亲节母亲节
        else if (item.gl === -1) {
          if (item.n === "父亲节") {
            let fatherDay = getFatherDay(moment().year());
            if (fatherDay.format("YYYYMMDD") < this.today) {
              fatherDay = getFatherDay(moment().year() + 1);
            }
            return {
              date: fatherDay.month() + 1 + "月" + fatherDay.date() + "日",
              md: fatherDay.format("MMDD"),
              name: "父亲节",
            };
          } else if (item.n === "母亲节") {
            let motherDay = getMotherDay(moment().year());
            if (motherDay.format("YYYYMMDD") < this.today) {
              motherDay = getMotherDay(moment().year() + 1);
            }
            return {
              date: motherDay.month() + 1 + "月" + motherDay.date() + "日",
              md: motherDay.format("MMDD"),
              name: "母亲节",
            };
          } else if (item.n === "感恩节") {
            let motherDay = getThanksgivingDay(moment().year());
            if (motherDay.format("YYYYMMDD") < this.today) {
              motherDay = getThanksgivingDay(moment().year() + 1);
            }
            return {
              date: motherDay.month() + 1 + "月" + motherDay.date() + "日",
              md: motherDay.format("MMDD"),
              name: "感恩节",
            };
          }
        }
      });

      // 组合并排序节日节气数组
      this.festivalArr = [...jieqiList, ...holidayList].sort(
        (a, b) => a.md - b.md
      );

      // 初始化索引
      for (let i = 0; i < this.festivalArr.length; i++) {
        if (this.festivalArr[i].name === this.name) {
          this.cur_index = i;
          break;
        }
      }
    },
    link(url, id) {
      localStorage.setItem("festival_name", this.name);
      setTimeout(function () {
        location.href = url;
      }, 500);
    },
    toTop() {
      scrollTo(0, 0);
    },
    init() {
      let festival = festival_utils.getFestival(this.name);
      if (!festival)
        festival = this.initData(
          this.name,
          this.timestr,
          getQueryString("desc")
        );

      this.festival = festival;
      if (this.name === "数九") {
        this.disTime = '从冬至算起每九天算一"九"';
      } else if (this.name === "三伏") {
        this.disTime = "公历七月中旬到八月中旬";
      } else {
        this.disTime = this.festivalArr[this.cur_index].date;
      }
      this.getRequest();
    },
    showMore(index) {
      this.$set(this.festival.cards[index], "show", true);
    },
    getRequest() {
      let url =
        "http://pc-wl.yjianzhan.cn/peacock/api/activity?dex=51&publisher=suishen&page=1";

      url += this.ver ? "&client_version=" + this.ver : "&client_version=1.0.0";
      url += this.channel ? "&channel=" + this.channel : "&channel=own";

      url += this.isIOS ? "&appid=99817882" : "&appid=99817749";
      url += this.isIOS ? "&platform=IPHONE" : "&platform=Android";

      url += "&festival=" + encodeURI(this.festival.disp_name);
      url += "&alert_date=" + this.festival.noe_time_stamp;

      let that = this;
      this.$http.jsonp(url).then(function (response) {
        if ((response.status = 1000 && response.body.data)) {
          let res = response.body.data[0];
          if (res.layout) {
            //计算背景色，图片加载完成再替换
            let imgUrl = res.layout[0] ? res.layout[0].ads[0].banner : b_img;
            if (imgUrl) {
              let image = new Image();
              image.src = imgUrl;
              image.onload = function () {
                that.show_background = true;
                that.traditionImg = imgUrl;
              };
            }
          }
        }
      });
    },
    initData(festival, dis_time, desc) {
      let data = {};
      data.disp_name = festival;
      data.type = 1;

      if (dis_time || desc) {
        data.cards = [];
        var item = {};
        item.title = "简介";
        if (dis_time) {
          item.tags = [];
          item.tags.push({
            title: "节日时间",
            value: dis_time,
          });
        }
        if (desc) {
          item.detail = [];
          item.detail.push(desc);
        }
        data.cards.push(item);
      }
      return data;
    },
  },
};
</script>

<style scoped lang="scss">
.tradition {
  font-size: 16px;
  .container {
    position: relative;
    padding: 0 12px;
    padding-bottom: 10px;
    transition-duration: 0.2s;
    background-color: #f7f5ef;
    .banner {
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      .banner-img {
        height: 100%;
        background-image: url("../assets/images/festival/border.png");
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }
  .traditionClass {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: -1;
    background-size: cover;
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000;
    -webkit-animation: opacityIn 0.8s linear 1;
    animation: opacityIn 0.8s linear 1;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  .header {
    margin: 20px 0;
    text-align: center;
    background-image: url("../assets/images/festival/title-border.png");
    background-repeat: no-repeat;
    background-size: contain;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .title {
      font-size: 30px;
      line-height: 1.1;
      font-weight: bold;
      color: #9b6a31;
    }
    .s_title {
      font-size: 23px;
    }
    .time {
      font-size: 16px;
      line-height: 1;
      margin-top: 6px;
      color: #9b6a31;
    }
    .pre {
      width: 40px;
      height: 40px;
      background-image: url("../assets/images/festival/btn-pre.png");
      background-size: contain;
      background-repeat: no-repeat;
      border-radius: 50%;
      z-index: 3;
    }
    .next {
      position: absolute;
      top: 10vh;
      right: 15px;
      width: 40px;
      height: 40px;
      background-image: url("../assets/images/festival/btn-next.png");
      background-size: contain;
      background-repeat: no-repeat;
      border-radius: 50%;
      z-index: 3;
    }
  }
  .content {
    overflow: hidden;
    color: #222;
    .content-title {
      display: flex;
      justify-content: center;
      margin: 12px auto 24px;
      width: 197.8px;
      height: 43px;
      font-size: 22px;
      line-height: 43px;
      color: #9b6a31;
      font-weight: bold;
      text-align: center;
      background-image: url("../assets/images/festival/sub-title-border.png");
      background-size: contain;
      background-repeat: no-repeat;
      div {
        flex: none;
      }
      .dec-icon {
        width: 14px;
        height: 14px;
        margin-top: 15px;
        margin-right: 5px;
        margin-left: -5px;
        background-image: url("../assets/images/festival/dec-icon.png");
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    .content-list {
      margin-bottom: 15px;
      .content-item {
        font-size: 22px;
        line-height: 31px;
        margin-bottom: 12px;
        display: flex;
        .title {
          color: rgba(0, 0, 0, 0.82);
          flex: none;
        }
        .desc {
          color: rgba(0, 0, 0, 0.82);
        }
      }
    }
    .content-detail {
      font-size: 22px;
      line-height: 31px;
      color: rgba(0, 0, 0, 0.6);
      padding: 0 4px;
      text-align: justify;
      .content-detail-brief {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
        height: 124px;
      }
      .content-detail-all {
        height: auto;
        display: block;
      }
      .show-more {
        text-align: center;
        font-size: 16px;
        line-height: 23px;
        color: #9b6a31;
        display: flex;
        justify-content: center;
        margin: 12px 0 12px;
        div {
          flex: none;
        }
        .arrow {
          margin-top: 6px;
          margin-left: 3px;
          width: 12px;
          height: 12px;
          background-image: url("../assets/images/festival/arrow-bottom-icon.png");
          background-size: contain;
        }
      }
      p {
        margin: 0;
        margin-bottom: 14px;
        word-wrap: word-break;
        white-space: pre-wrap;
        &:last-child {
          margin: 0;
        }
      }
    }
    & > img {
      width: 100%;
      margin: 16px 0;
    }
  }
}

.to-top {
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 35px;
  right: 15px;
  background-image: url("../assets/images/history_day/icon-top.png");
  background-size: contain;
  transition-duration: 0.2s;
  z-index: 10;
}
</style>