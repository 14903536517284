<template>
  <div class="privacy">
    <h3>隐私权政策</h3>
    <p>
      欢迎您使用“{{
        appName
      }}”（以下简称“本应用”）软件及相关服务。如您未明示同意本隐私政策并开始使用，我们将仅收集浏览推荐所必需的信息，这将导致我们无法为您提供完整的产品和服务，但我们同样会采用去标识化、加密等措施来保护这些信息。如果您不同意本隐私政策，我们无法为您提供完整的产品和服务，您也可以选择停止使用。当您选择继续或再次使用时，基于提供产品和服务所必需，将视为您接受和认可我们按照本政策对您的相关信息进行处理。
    </p>
    <p>
      本应用深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。本应用致力于维持您对本应用的信任，恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。同时，本应用承诺，本应用将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。
    </p>
    <p>请在使用本应用的产品或服务前，仔细阅读并了解《隐私权政策》。</p>

    <!-- 一 -->
    <h5>一、本应用如何收集和使用您的个人信息</h5>
    <p>
      个人信息是指以电子或其他方式记录的能够单独或与其他信息结合识别特定自然人或反映特定自然人活动情况的各种信息。
    </p>
    <p>本应用仅会处于本政策所述的以下目的，收集和使用您的个人信息：</p>
    <p>
      1.当您使用本应用或应用再后台运行时，我们会收集您的设备型号、软件系统版本、软件安装列表、Mac地址、唯一设备标识符（IMEI、IMSI、Android_ID、IDFA）、IP、设备序列号用于标识唯一用户、数据统计及信息推送。
    </p>
    <p>
      2.存储空间：当您使用黄历、记事等功能时，我们需要申请存储权限，以通过写入本地缓存的方式存储应用的相关数据，以保证您的信息不会丢失，并降低流量消耗。
    </p>
    <p>
      3.读取日历权限：当您需要展示系统日历的日程信息时，我们会请求您日历权限。如果您拒绝授权提供，将无法使用此功能。
    </p>
    <p>
      4.为了正常接收节假日、日程、生日纪念日等重要提醒，请前往手机开启微鲤万年历自启动或关联启动，保证APP在后台时正常提醒。
    </p>

    <!-- 二 -->
    <h5>
      二、通过内部数据分析和研究以及第三方SDK统计服务，改善本应用的产品或服务
    </h5>
    <p>
      本应用收集数据是根据您与本应用的互动和您所做出的选择，包括您的隐私设置以及使用的产品和功能。手机的数据包括SDK/API/JS代码版本、浏览器、互联网服务提供商、IP地址、平台、时间戳、应用标识符、应用程序版本、应用分发渠道、软件安装列表、独立设备标识符（IMEI、IMSI、Android_ID）、Mac地址、IOS广告标识符(IDFA)。当本应用要将信息用于本策略未载明的其他用途时，会事先征求您的同意。
    </p>
    <p>第三方SDK需要获取的权限及隐私政策：</p>
    <p>SDK名称：友盟统计分析SDK</p>
    <p>SDK服务商：友盟同欣（北京）科技有限公司</p>
    <p>使用目的：进行APP运营统计与分析</p>
    <p>收集个人信息：设备信息（IMEI/MAC/Android ID/IDFA/OAID/OpenUDID/GUID/SIM卡IMSI/ICCID）、位置信息、网络信息</p>
    <p>
      隐私权政策链接：<a href="https://www.umeng.com/policy"
        >https://www.umeng.com/page/policy</a
      >
    </p>

    <!-- 三 -->
    <h5>三、本应用如何使用cookie和同类技术</h5>
    <p>
      Cookie
      和同类技术是互联网中的通用常用技术。当您使用本应用及相关服务时，我们可能会使用相关技术向您的设备发送一个或多个
      Cookie 或匿名标识符，以收集和存储您访问、使用本产品时的信息。我们使用
      Cookie 和同类技术主要为了实现以下功能或服务：
    </p>
    <h6>1.保障产品与服务的安全、高效运转</h6>
    <p>
      我们可能会设置认证与保障安全性的 cookie
      或匿名标识符，使我们确认你是否安全登录服务，或者是否遇到盗用、欺诈等不法行为。这些技术还会帮助我们改进服务效率，提升登录和响应速度。
    </p>
    <h6>2.帮助你获得更轻松的访问体验</h6>
    <p>
      使用此类技术可以帮助你省去重复你填写个人信息、输入搜索内容的步骤和流程（例如：记录搜索历史）。
    </p>
    <h6>3.为你推荐、展示、推送你可能感兴趣的内容或账号</h6>
    <p>
      我们可能会利用 Cookie 和同类技术（例如 Beacon、Proxy
      等技术）了解你的偏好和使用习惯，进行咨询或数据分析，以改善产品服务及用户体验，并优化你对广告的选择。
    </p>
    <p>
      您可以通过浏览器设置或拒绝管理Cookie。但请注意，如果停用的话，您有可能无法享受最佳的服务体验，某些服务也可能无法正常使用。同时，您还会收到同样数量的广告，但这些广告与您的相关性会降低。
    </p>
    <p>
      我们承诺，我们不会将 Cookie 用于本隐私政策所述目的之外的任何其他用途。
    </p>

    <!-- 四 -->
    <h5>四、本应用如何共享、转让、公开披露您的个人信息</h5>
    <h6>
      1.共享：本应用不会向其他任何公司、组织和个人分享您的个人信息，但以下情况除外：
    </h6>
    <p class="mb_0">
      (1)
      在获取明确同意的情况下共享：获得您的明确同意后，本应用会与其他方共享您的个人信息。
    </p>
    <p class="mb_0">
      (2)
      本应用可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。
    </p>
    <p class="mb_0">
      (3)
      与本应用的关联公司共享：您的个人信息可能会与本应用关联公司共享。本应用只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。关联公司如要改变个人信息的处理目的，将再次征求您的授权同意。
    </p>
    <p class="mb_0">
      (4)
      与授权合作伙伴共享：仅为实现本隐私政策中声明的目的，本应用的某些服务将由授权合作伙伴提供。本应用可能会与合作伙伴共享您的ID信息及相关设备信息（硬件型号、操作系统版本号、国际移动设备身份识别码（IMEI）、网络设备硬件地址（MAC））等，以提供更好的客户服务和用户个性化体验。例如，本应用聘请来提供第三方数据统计和分析服务的公司可能需要采集和访问个人数据以进行数据统计和分析等服务。在这种情况下，这些公司必须遵守本应用的数据隐私和安全要求。本应用仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必须的个人信息。对本应用与之共享个人信息的公司、组织和个人，本应用会与其签署严格的保密协定，要求他们按照本应用的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。
    </p>
    <h6>
      2.转让：本应用不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
    </h6>
    <p class="mb_0">
      (1)
      在获取明确同意的情况下转让：获得您的明确同意后，本应用会向其他方转让您的个人信息；
    </p>
    <p class="mb_0">
      (2)
      在涉及合并、收购或破产清算时，如涉及到个人信息转让，本应用会在要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则本应用将要求该公司、组织重新向您征求授权同意。
    </p>
    <h6>3.公开披露：本应用仅会在以下情况下，公开披露您的个人信息：</h6>
    <p class="mb_0">(1) 获得您的明确同意后；</p>
    <p class="mb_0">
      (2)
      基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，本应用可能会公开披露您的个人信息。
    </p>
    <p class="mb_0">
      (3)
      您知悉，即使已经取得您的授权同意，我们也仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并尽量对共享内容中的个人信息进行去标识化处理
    </p>

    <!-- 五 -->
    <h5>五、本应用如何保护您的个人信息</h5>
    <p>
      1.本应用已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。本应用会采取一切合理可行的措施，保护您的个人信息。例如，在您的设备与服务之间交换数据时受SSL加密保护；对网站提供https安全浏览方式；本应用会使用加密技术确保数据的保密性；本应用会使用受信赖的保护机制防止数据遭到恶意攻击；本应用会部署访问控制机制，确保只有授权人员才可访问个人信息；以及本应用会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
    </p>
    <p>
      2.本应用会采取一切合理可行的措施，确保未收集无关的个人信息。本应用只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或收到法律的允许。
    </p>
    <p>
      3.互联网并非绝对安全的环境，本应用将尽力确保或担保您发送给本应用的任何信息的安全性。如果本应用的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，本应用将承担相应的法律责任
    </p>
    <p>
      4.在不幸发生个人信息安全事件后，本应用将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、本应用已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。本应用将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，本应用将会采取合理、有效的方式发布公告。同时，本应用还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
    </p>

    <!-- 六 -->
    <h5>六、您的权利</h5>
    <p>
      按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，本应用保障您对自己的个人信息行使以下权利：
    </p>
    <p>
      1.您有权删除您存储的记事信息，可以通过“记事”-“备忘详情”-“删除”执行此类操作。
    </p>
    <p>
      2.更正您的个人信息，当您发现本应用处理的关于您的个人信息有错误时，您有权要求本应用做出更正。
    </p>
    <p>
      3.删除您的个人信息：在以下情形中，您可以向本应用提出删除个人信息的请求：
    </p>
    <p class="mb_0">(1) 如果本应用处理个人信息的行为违反法律法规；</p>
    <p class="mb_0">(2) 如果本应用收集、使用您的个人信息，却未征得您的同意；</p>
    <p class="mb_0">(3) 如果您不再使用本应用的产品或服务，或您注销了账号；</p>
    <p class="mb_0">(4) 如果本应用不再为您提供产品或服务。</p>
    <p>
      若本应用决定响应您的删除请求，本应用还将同时通知从本应用获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。当您从本应用的产品或服务中删除信息后，本应用可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。
    </p>
    <p>
      4.若本应用决定响应您的删除请求，本应用还将同时通知从本应用获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。当您从本应用的产品或服务中删除信息后，本应用可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。
    </p>
    <p>
      5.个人信息主体获取个人信息副本：您有权获取您的个人信息副本，在技术可行的前提下，例如数据接口匹配，本应用还可按照您的要求，直接将您的个人信息副本传输给您指定的第三方。
    </p>
    <p>
      6.约束信息系统自动决策：在某些业务功能中，本应用可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求本应用做出解释，本应用将提供适当的救济方式。
    </p>
    <p>
      7.响应您的上述请求：为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。本应用可能会要求您验证自己的身份，然后再处理您的请求。
    </p>
    <p>
      本应用将在十五天内做出答复。对于您合理的请求，本应用原则上不收取费用，但对多次重复、超出合理限度的请求，本应用将视情收取一定的成本费用。对于那些无端重复、需要过多技术手段(例如，需要开发新系统或从根本上改变现行惯例)、给他人合法权益带来风险或非常不切实际(例如涉及备份磁盘上存放的信息)的请求，本应用可能会予以拒绝。
      (例如，需要开发新系统或从根本上改变现行惯例)、给他人合法权益带来风险或非常不切实际(例如涉及备份磁盘上存放的信息)的请求，本应用可能会予以拒绝。
    </p>
    <p>在以下情形中，按照法律法规要求，本应用将无法响应您的请求：</p>
    <p class="mb_0">(1) 与国家安全、国防安全有关的；</p>
    <p class="mb_0">(2) 与公共安全、公共卫生、重大公共利益有关的；</p>
    <p class="mb_0">(3) 与犯罪侦查、起诉、审判有关的；</p>
    <p class="mb_0">(4) 有充分证据证明您存在主观恶意或滥用权利的；</p>
    <p class="mb_0">
      (5) 响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。
    </p>

    <!-- 七 -->
    <h5>七、儿童个人信息保护</h5>
    <p>
      1.本应用的产品和服务主要面向成人。我们重视儿童的信息保护，如您为未满14岁的未成年人，建议您请您的父母或监护人仔细阅读本隐私权政策，并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。
    </p>
    <p>
      2.本应用将不满14周岁的未成年人均视为儿童。如果没有父母或监护人的同意，儿童不得对本应用账号绑定手机号，在使用提现功能绑定身份证时，本应用将会对年龄作以校验。我们只会在法律法规允许，父母或监护人明确统一或者保护未成年人所必要的情况下使用，共享，转让或披露此信息。我们将根据国家相关法律法规及本政策的规定保护未成年人的个人信息。如果本应用发现自己的未事先获得可证实的父母同意的情况下收集了儿童的个人信息，则会设法尽快删除相关数据。
    </p>
    <p>
      3.若您是未成年人的监护人，当您对您所监护的未呈现人的个人信息有相关疑问时或者想对此信息作出处理时，请通过本隐私政策公示的联系方式与我们联系（本应用设有专项负责儿童个人信息保护的专员）。
    </p>

    <!-- 八 -->
    <h5>八、您的个人信息不会在全球范围内转移</h5>
    <p>
      本应用在中华人民共和国境内收集和产生的个人信息，将存储于中华人民共和国境内，不会出境或进行全球范围转移。
    </p>

    <!-- 九 -->
    <h5>九、本隐私权政策如何更新</h5>
    <p>
      本应用可能适时会对本隐私政策进行调整或变更，本隐私政策的任何更新将以标注更新时间的方式公布在本应用的网站上，除法律法规或监管规定另有强制性规定外，经调整或变更的内容一经通知或公布后的7日内生效。<span
        class="bold"
        >如您在隐私权政策调整或变更后继续使用本应用的任一服务或本应用的产品，本应用相信这代表您已充分阅读、理解并接受修改后的隐私权政策并受其约束。</span
      >
    </p>

    <!-- 十 -->
    <h5>十、如何联系本应用</h5>
    <p>如果您对本隐私政策有任何疑问、意见或建议，通过以下方式与本应用联系：</p>
    <!-- <p>公司：{{ company.name }}</p> -->
    <!-- <p>公司地址：{{ company.address }}</p> -->
    <p>客服电话：{{ company.phone }}</p>

    <!-- 十三 -->
    <h5>十三、隐私政策有效期及更新时间</h5>
    <p class="bold">
      本隐私政策于2021年08月12日更新并同时生效。本隐私政策长期有效。本协议最终解释权归属于本应用。
    </p>
  </div>
</template>

<script>
import { getQueryString } from '../lib/utils.js';
const packages = getQueryString('package');
const from = getQueryString('from');
const channel = getQueryString('channel');
export default {
  data() {
    return {
      appName: '微鲤万年历',
      companyInfo: {
        wl: {
          name: '北京微鲤科技有限公司',
          address: '北京市朝阳区广顺北大街33号院1号楼6层2单元701室',
          // phone: '010-86409287',
          phone: '025-52883986',
        },
        sztd: {
          name: '南京数字跳动网络技术有限公司',
          address: '南京市高淳区经济开发区古檀大道3号',
          phone: '025-52883986',
        },
        njwl: {
          name: '南京微鲤科技有限公司',
          address: '南京市雨花台区花神大道17号华博智慧园5楼',
          phone: '025-52883986',
        },
        ssy: {
          name: '随身云（南京）信息技术有限公司',
          address: '江苏省南京市雨花台区花神大道17号华博智慧园5楼',
          phone: '025-52883986',
        },
        ssybj: {
          name: '随身云（北京）信息技术有限公司',
          address: '北京市朝阳区广顺北大街33号院1号楼6层2单元701室',
          phone: '025-52883986',
        },
      },
    };
  },
  created() {
    console.log(packages, channel, from);

    document.title = this.appName;
  },
  computed: {
    company() {
      return this.companyInfo['sztd'];
    },
  },
  methods: {},
};
</script>

<style scoped>
.privacy {
  width: 100vw;
  box-sizing: border-box;
  font-size: 16px;
  padding: 15px;
  font-family: 微软雅黑;
}
h3 {
  font-size: 22px;
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
}
h5 {
  font-size: 16px;
  line-height: 32px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
}
h6 {
  font-size: 16px;
  line-height: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
  text-indent: 1.5em;
}
p {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 16px;
  line-height: 30px;
  text-align: left;
  text-indent: 1.5em;
}
.underline {
}
.bold {
  font-weight: bold;
}
.mb_0 {
  margin-bottom: 0;
}
.no_indent {
  text-indent: 0em;
}
</style>
