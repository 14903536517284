<!--
 * @Author: wujiang@weli.cn
 * @Date: 2024-06-11 17:59:19
 * @LastEditors: wujiang 
 * @LastEditTime: 2024-06-11 18:36:58
 * @Description: 
-->
<template>
  <div class="index">
    <div class="title">南京微鲤</div>
    <div class="list">
      <div class="item">
        <router-link to="privacy">微鲤万年历-隐私政策</router-link>
      </div>
      <div class="item">
        <router-link to="service">微鲤万年历-用户协议</router-link>
      </div>
      <div class="item">
        <router-link to="festival">微鲤万年历-节日节气</router-link>
      </div>
      <div class="item">
        <router-link to="/history/index">微鲤万年历-历史上的今天</router-link>
      </div>
    </div>
    <div class="footer">
      <div>南京微鲤科技有限公司</div>
      <div class="ml-5">版权所有</div>
      <div class="line ml-5">苏ICP备13006982号-26</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'index',
};
</script>

<style scoped>
.title {
  font-size: 20px;
  text-align: center;
  line-height: 80px;
}
.list {
  text-align: center;
}
.item a {
  line-height: 30px;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  font-size: 14px;
}
.ml-5 {
  margin-left: 5px;
}
.line {
  text-decoration: underline;
}
</style>
