import Fingerprint2 from 'fingerprintjs2'
import md5 from 'md5';

const ua = navigator.userAgent.toLowerCase().toString();
const uaInfo = {};

ua.replace(/\(.*\)/, '').split(' ').forEach((item) => {
    if (item && typeof item === 'string') {
        const tempItemArray = item.split('/');
        // eslint-disable-next-line prefer-destructuring
        uaInfo[tempItemArray[0]] = tempItemArray[1];
    }
});

const getOpenId = () => uaInfo.open_id;

const getAppSign = (method, path, timestamp) => md5(`${method.toUpperCase()}&${path}&${timestamp}`);
const getAppKey = () => uaInfo.app_key;
const getAccessToken = () => uaInfo.access_token;

function jsonp(url,data){
    if(!url)
        throw new Error('url is necessary')
    const callback = 'CALLBACK' + Math.random().toString().substr(9,18)
    const JSONP = document.createElement('script')
          JSONP.setAttribute('type','text/javascript')

    const headEle = document.getElementsByTagName('head')[0]

    let ret = '';
    if(data){
        if(typeof data === 'string')
            ret = '&' + data;
        else if(typeof data === 'object') {
            for(let key in data)
                ret += '&' + key + '=' + encodeURIComponent(data[key]);
        }
        ret += '&_time=' + Date.now();
    }
    JSONP.src = `${url}?callback=${callback}${ret}`;
    return new Promise( (resolve,reject) => {
        window[callback] = r => {
          resolve(r)
          headEle.removeChild(JSONP)
          delete window[callback]
        }
        headEle.appendChild(JSONP)
    })
    
}

/**
 * 获取url参数
 *
 * @param {any} 参数key值
 * @returns
 */
function getQueryString(key) {
    let url = location.search;
    let theRequest = new Object();
    if (url.indexOf("?") != -1) {
        let str = url.substr(1);
        let strs = str.split("&");
        for (let i = 0; i < strs.length; i++) {
            theRequest[strs[i].split("=")[0]] = decodeURI(strs[i].split("=")[1]);
        }
    }
    return theRequest[key] == null ? "" : theRequest[key];
}

/**
 * 是否ios系统
 *
 * @returns
 */
function isIOS() {
    var userAgent = navigator.userAgent.toLowerCase();
    return (/iphone/i).test(userAgent) || (/ipad/i).test(userAgent) || (/ios/i).test(userAgent);
}

const getFingerPrint = () => {
    return new Promise((resolve, reject) => {
        let options = {
            excludes: {
                language: true,
                colorDepth: true,
                deviceMemory: true,
                pixelRatio: true,
                availableScreenResolution: true,
                timezoneOffset: true,
                timezone: true,
                sessionStorage: true,
                localStorage: true,
                indexedDb: true,
                addBehavior: true,
                openDatabase: true,
                cpuClass: true,
                doNotTrack: true,
                plugins: true,
                canvas: true,
                webglVendorAndRenderer: true,
                adBlock: true,
                hasLiedLanguages: true,
                hasLiedResolution: true,
                hasLiedOs: true,
                hasLiedBrowser: true,
                touchSupport: true,
                audio: false,
                enumerateDevices: true,
                hardwareConcurrency: true,
            },
        };
        try {
            Fingerprint2.get(options, function (components) {
                const values = components.map(function (component, index) {
                    if (index === 0) { //把微信浏览器里UA的wifi或4G等网络替换成空,不然切换网络会ID不一样
                        return component.value.replace(/\bNetType\/\w+\b/, '')
                    }
                    return component.value
                })
                // 生成最终id murmur   
                const murmur = Fingerprint2.x64hash128(values.join(''), 31)
                resolve(murmur)
            })
        } catch (e) {
            reject(e)
        }
    })
}

export {
    getQueryString,
    getAppSign,
    getAppKey,
    getOpenId,
    getAccessToken,
    getFingerPrint,
    isIOS,
    jsonp
}