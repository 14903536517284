<template>
  <div class="service">
    <h3>用户协议</h3>

    <!-- 一 -->
    <h5>一、总则</h5>
    <p>
      1、为了保护网络信息安全，保障公民、法人和其他组织的合法权益，维护国家安全和社会公共利益，根据国家法律法规以及全国人大常委会的相关规定，我们制定并按照本协议提供网络服务。用户应当充分阅读并同意本协议的全部条款并按照页面上的提示完成全部的注册程序（未成年人应与法定监护人共同完成）。用户选择访问或使用本应用有关服务，将视为同意接受本协议全部条款的约束。
      {{ companyInfo[getCompany()].name }}（以下简称“{{
        companyInfo[getCompany()].shortName
      }}”）制定并按照本协议提供网络服务。用户应当充分阅读并同意本协议的全部条款并按照页面上的提示完成全部的注册程序（未成年人应与法定监护人共同完成）。用户选择访问或使用{{
        companyInfo[getCompany()].shortName
      }}有关服务，将视为同意接受本协议全部条款的约束。
    </p>
    <p>
      2、除非另有明确规定，本应用所推出的新产品、新功能和新服务，均无条件的适用本协议。
    </p>
    <p>
      3、我们保留在任何时候修改本协议条款的权利，且无需另行通知。用户在使用服务时应关注并遵守。
    </p>
    <p>
      4、用户在使用本应用提供的各项服务之前，应仔细阅读本协议。如果您不同意本协议的修改，可以主动取消本引用提供的服务；如果您继续使用本应用提供的服务，则视为您已经接受本协议全部内容，包括我们对本协议所做的任何修改。
    </p>
    <p>5、用户无论通过何种方式使用本应用的服务，均受本协议约束。</p>
    <p>
      6、本协议可由本应用随时更新，更新后的协议及规则条款一旦公布即代替原来的条款，恕不再另行通知。用户可在相关网站查阅最新版协议及规则条款。在我们修改协议或规则条款后，如果用户不接受修改后的条款，请立即停止使用对应的本应用的服务，用户继续使用本应用的服务将被视为已接受了修改后的条款。
    </p>

    <!-- 二 -->
    <h5>二、协议的性质与条款的接受</h5>
    <p>
      1、本协议是用户（无论个人或单位）与本应用的使用和管理所约定的协议（下称“本协议”）。接受本协议的全部条款，构成用户使用本应用所提供的服务之先决条件，本应用将严格依照本协议中的条款提供服务。
    </p>
    <p>
      2、用户访问或使用本应用提供的服务，则表示用户接受本协议的全部条款；如用户不愿意接受本协议的全部条款，则应不使用或主动退订本应用提供的服务。本应用有权随时修改本协议中的服务条款，一旦条款发生变更或修改，本应用将在相关页面上公示；如果用户继续使用本应用提供的服务，则视为用户已接受本协议的修改内容；如果用户不同意本协议的修改，可以退订已经订阅的服务并停止使用。
    </p>
    <p>
      3、本应用向用户提供的收费服务，对于用户已经缴纳的服务费用不进行任何形式的退款。当用户选择付费时，表明用户已经完全同意此条款；如果用户不同意本条款，可以退订已经订阅的服务并停止使用。
    </p>

    <!-- 三 -->
    <h5>三、用户使用规则</h5>
    <p>
      1、用户在使用本应用服务的过程中，同意遵守《中华人民共和国保守国家秘密法》、《中华人民共和国著作权法》、《中华人民共和国商标法》、《中华人民共和国专利法》、《中华人民共和国计算机信息系统安全保护条例》、《计算机软件保护条例》、《互联网电子公告服务管理规定》、《信息网络传播权保护条例》等相关法律、法规。在任何情况下，本应用一旦有合理理由认为用户的行为可能违反上述法律、法规，可以在任何时候不经事先通知终止向该用户提供服务。
    </p>
    <p>
      2、用户同意：本应用在提供服务的过程中以各种方式投放推广信息（包括但不限于：电子邮件、网站联络方式），用户同意接受本应用以上述方式向用户发送推广信息。
    </p>
    <h6>3、禁止用户从事以下行为：</h6>
    <p class="mb_0">
      （3.1）传送包含任何反对宪法所确定的基本原则、危害国家安全、泄露国家秘密、颠覆国家政权、破坏国家统一、破坏民族团结、损害国家荣誉和利益、煽动民族仇恨、民族歧视、破坏民族团结、破坏国家宗教政策、宣扬邪教和封建迷信、淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪、侮辱或者诽谤他人，侵害他人合法权益的等法律、行政法规禁止的内容，包括但不限于资讯、资料、文字、软件、音乐、照片、图形、信息或其他资料；
    </p>
    <p class="mb_0">
      （3.2）将侵犯任何人的姓名权、肖像权、知识产权（包括但不限于专利权、商标权、著作权、商业秘密等）或其他专属权利的内容、或无权传送的内容（包括但不限于内部资料、机密资料）加以上载、张贴、发送电子邮件或以其他方式传送；
    </p>
    <p class="mb_0">（3.3）以任何方式危害未成年人；</p>
    <p class="mb_0">
      （3.4）伪造标题或以其他方式操控识别资料，使人误认为该内容为本应用所传送；
    </p>
    <p class="mb_0">
      （3.5）冒充任何人或机构，或以虚伪不实的方式谎称或使人误认为与任何人或任何机构有关从事诈骗、欺骗行为；
    </p>
    <p class="mb_0">
      （3.6）传播病毒软件或其他破坏计算机代码、档案和程序之资料
    </p>
    <p class="mb_0">
      （3.7）干扰或破坏本应用服务或与本应用网络服务相连的服务器和网络；
    </p>
    <p class="mb_0">
      （3.8）将广告函件、促销资料、“垃圾邮件”等，加以上载、张贴、发送电子邮件或以其他方式传送，但供前述目的使用的专用区域除外；
    </p>
    <p class="mb_0">（3.9）跟踪或以其他方式骚扰他人；</p>
    <p class="mb_0">
      （3.10）其他违反任何相关的中国法律、法规、规章、条例等具有法律效力的规范的行为。
    </p>

    <!-- 四 -->
    <h5>四、知识产权</h5>
    <p>
      1、本应用对产品服务及本产品所使用的软件所包含的受知识产权法或其他法律保护的资料享有相应的权利。本产品所有设计图样以及其他图样、产品及服务名称，均为本应用所享有，任何人不得使用、复制或用作其他用途。用户对本产品所使用的软件有非专属性使用权，但不得自行或许可任何第三方复制、修改、出售或衍生产品。
    </p>
    <p>
      2、经由本产品传送的文字、图片、音频、视频及其他内容，受到著作权法、商标法、专利法或其他法律的保护；除该文字、图片、音频、视频及其他内容的上载用户所享有的著作权，未经本应用书面授权许可，第三方不得进行修改、出租、售卖或衍生其他作品。
    </p>
    <p>
      3、本应用对其专有内容、原创内容和其他通过授权取得的独占或者独家内容享有知识产权。未经本应用书面许可，任何单位和个人不得私自转载、传播和提供收听服务或者有其他侵犯知识产权的行为。否则，将承担法律责任。
    </p>
    <p>
      4、除非本应用收到相反通知，我们将用户视为其在本产品上载或发布的内容的版权所有人。用户访问或使用本应用提供的服务，则表示用户接受本协议的全部条款，即表明该用户主动将其在任何时间段在本产品发表的任何形式的内容的著作财产权无偿授权给本应用使用，本应用有权通过微鲤万年历客户端对内容进行复制、下载、编辑、修改、展示及网络传播。同时用户许可本应用有权利就任何主体侵权而单独提起诉讼，并获得赔偿。本协议已经构成《著作权法》第二十五条所规定的书面协议，其效力及于用户在本产品发布的任何受著作权法保护的内容，无论该内容形成于本协议签订前还是本协议签订后。
    </p>

    <!-- 五 -->
    <h5>五、免责声明</h5>
    <p>
      1、本应用对于任何包含、经由或链接、下载或从任何与有关平台所获得的任何内容、信息或广告，不声明或保证其正确性或可靠性；并且对于用户经本应用平台上的广告、展示而购买、取得的任何产品、信息或资料，本应用不负保证责任。用户自行负担使用上述内容、信息或广告的风险。
    </p>
    <p>2、本应用不保证（包括但不限于）：</p>
    <p class="mb_0">（2.1）本服务适合用户的使用要求；</p>
    <p class="mb_0">
      （2.2）本服务不受干扰，及时、安全、可靠或不出现错误，本服务可能出现瑕疵，包括但不限于黑客入侵、网络中断、电信问题及其他不可抗力等；
    </p>
    <p class="mb_0">
      （2.3）用户经由本服务取得的任何产品、服务或其他材料符合用户的期望；
    </p>
    <p>
      3、用户使用经由本应用平台下载的或取得的任何资料，其风险自行负担，因该使用而导致用户电脑系统损坏或资料流失，本应用不承担任何责任；
    </p>
    <p>
      4、基于以下原因而造成的利润、商业信誉、资料损失或其他有形或无形损失，本应用不承担任何责任，也不承担赔偿义务：
    </p>
    <p class="mb_0">（4.1）本服务的使用或无法使用；</p>
    <p class="mb_0">（4.2）经由本服务购买或取得的任何产品、资料或服务；</p>
    <p class="mb_0">（4.3）用户资料遭到未授权的使用或修改；</p>
    <p class="mb_0">（4.4）其他与本服务相关的事宜。</p>
    <p>
      5、本应用在服务过程中可能会提供与其他国际互联网网站或任何第三方资源的链接。除非另有声明，本应用无法对第三方网站、资源所提供之服务进行控制，用户因使用上述网站或资源所产生的损失或损害，本应用不承担任何责任。
    </p>
  </div>
</template>

<script>
import { getQueryString } from '../lib/utils.js';
const packages = getQueryString('package');
const from = getQueryString('from');
const channel = getQueryString('channel');
export default {
  data() {
    return {
      appName: '微鲤万年历',
      companyInfo: {
        wl: {
          name: '北京微鲤科技有限公司',
          address: '北京市朝阳区广顺北大街33号院1号楼6层2单元701室',
          shortName: '微鲤',
          phone: '025-52883986',
        },
        njwl: {
          name: '南京微鲤科技有限公司',
          address: '南京市雨花台区花神大道17号华博智慧园5楼',
          shortName: '微鲤',
          phone: '025-52883986',
        },
        ssy: {
          name: '随身云（南京）信息技术有限公司',
          address: '江苏省南京市雨花台区花神大道17号华博智慧园5楼',
          shortName: '随身云',
          phone: '025-52883986',
        },
        ssybj: {
          name: '随身云（北京）信息技术有限公司',
          address: '北京市朝阳区广顺北大街33号院1号楼6层2单元701室',
          shortName: '随身云',
          phone: '025-52883986',
        },
      },
    };
  },
  created() {
    console.log(packages, channel, from);

    document.title = this.appName;
  },
  methods: {
    getCompany() {
      return 'njwl';
    },
  },
};
</script>

<style scoped>
.service {
  width: 100vw;
  box-sizing: border-box;
  font-size: 16px;
  padding: 15px;
  font-family: 微软雅黑;
}
h3 {
  font-size: 22px;
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
}
h5 {
  font-size: 16px;
  line-height: 32px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
}
h6 {
  font-size: 16px;
  line-height: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
  text-indent: 1.5em;
}
p {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 16px;
  line-height: 30px;
  text-align: left;
  text-indent: 1.5em;
}
.underline {
}
.bold {
  font-weight: bold;
}
.mb_0 {
  margin-bottom: 0;
}
.no_indent {
  text-indent: 0em;
}
</style>
