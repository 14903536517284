import Vue from 'vue';
import VueRouter from 'vue-router'
import Index from './views/index'
import Privacy from './views/privacy'
import Service from './views/service'
import Festival from './views/festival'

Vue.use(VueRouter)

const router = new VueRouter({
    routes: [
        {
            path: '/', 
            component: Index,
            meta: {title: '南京微鲤'}
        },
        {
            path: '/privacy', 
            component: Privacy,
            meta: {title: '隐私政策'}
        },
        {
            path: '/service', 
            component: Service,
            meta: {title: '用户协议'}
        },
        {
            path: '/festival', 
            component: Festival,
            meta: {title: '节日节气'}
        },
        {
            path: '/history', 
            component: () => import('@/views/history/index'),
            children: [
                {
                    path: 'index', 
                    component: () => import('@/views/history/list'),
                    meta: {title: '历史上的今天'},
                },
                {
                    path: 'post', 
                    component: () => import('./views/history/post'),
                    meta: {title: '文章详情'},
                }
            ]
        }
    ]
})

export default router
